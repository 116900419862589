import { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import InputField from 'common/forms/InputField';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PermissionTable from './PermissionTable';
import {
  useAddUserRoleMutation,
  GetUserRoleListDocument,
  RoleSubjectsEntity,
  useUpdateRoleMutation,
  AdminRolesEntity,
} from 'gql/generated/query.graphql';
import { RoleContext, RoleContextType } from 'pages/role-management';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
    margin: '30px 30px 0 30px',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
});

const schema = Yup.object().shape({
  roleName: Yup.string().required('Role Title is required').default(''),
  roleDescription: Yup.string().required('Role Description is required').default(''),
});

function RoleModal({
  open,
  handleClose,
  title,
  roleObject = null,
  showOnly = false,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  roleObject?: AdminRolesEntity | null;
  showOnly?: boolean;
}) {
  const classes = useStyles();
  const { subjects } = useContext(RoleContext) as RoleContextType;
  const [addUserRole] = useAddUserRoleMutation({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
    onCompleted() {
      handleClose();
    },
  });

  const [updateRole] = useUpdateRoleMutation({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
    onCompleted() {
      handleClose();
    },
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = methods;

  const { title: roleNameErrorMsg, description: roleDescriptionErrorMsg } = errors;

  const onSave = (data: any) => {
    if (!roleObject) {
      addUserRole({
        variables: {
          inputs: {
            ...data,
            rolePermissions: JSON.stringify(
              data.rolePermissions.reduce((obj: any, item: any[]) => Object.assign(obj, { [item[0]]: item[1] }), {})
            ),
          },
        },
        refetchQueries: [GetUserRoleListDocument, 'getUserRoleList'],
      });
    } else {
      updateRole({
        variables: {
          input: {
            roleId: Number(roleObject.idAdminUserRoles),
            data: {
              ...data,
              rolePermissions: JSON.stringify(
                data.rolePermissions.reduce((obj: any, item: any[]) => Object.assign(obj, { [item[0]]: item[1] }), {})
              ),
            },
          },
        },
        refetchQueries: [GetUserRoleListDocument, 'getUserRoleList'],
      });
    }
  };

  useEffect(() => {
    setValue('roleName', roleObject ? roleObject?.roleName : '');
    setValue('roleDescription', roleObject ? roleObject?.roleDescription : '');
    setValue(
      'rolePermissions',
      roleObject
        ? Object.entries(roleObject?.rolePermissions)
        : Object.entries(
            subjects?.reduce((result: any, item: RoleSubjectsEntity) => {
              let key: string = item.idRoleSubjects;
              result[key] = [0, 0, 0, 0];
              return result;
            }, {})
          )
    );
  }, [setValue]);

  const defaultValues = {
    roleName: roleObject ? roleObject?.roleName : '',
    roleDescription: roleObject ? roleObject?.roleDescription : '',
    rolePermissions: roleObject
      ? Object.entries(roleObject?.rolePermissions)
      : Object.entries(
          subjects?.reduce((result: any, item: RoleSubjectsEntity) => {
            let key: string = item.idRoleSubjects;
            result[key] = [0, 0, 0, 0];
            return result;
          }, {})
        ),
  };

  const handleCloseAndReset = () => {
    handleClose();
    reset(defaultValues);
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)} autoComplete='off'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <>
            <InputField
              control={control}
              margin='normal'
              fullWidth
              id='roleName'
              placeholder='Role Title'
              name='roleName'
              variant='filled'
              InputProps={{ disableUnderline: true }}
              autoFocus
              helperText={roleNameErrorMsg?.message}
              error={roleNameErrorMsg ? true : false}
              disabled={showOnly}
            />
            <InputField
              control={control}
              margin='normal'
              fullWidth
              name='roleDescription'
              placeholder='Description'
              id='roleDescription'
              variant='filled'
              multiline
              rows={3}
              InputProps={{ disableUnderline: true }}
              helperText={roleDescriptionErrorMsg?.message}
              error={roleDescriptionErrorMsg ? true : false}
              disabled={showOnly}
            />
            <FormProvider {...methods}>
              <PermissionTable showOnly={showOnly} />
            </FormProvider>
            <div className={classes.formGrid}>
              <div className={classes.actionBtn}>
                <Button size='medium' type='submit' disabled={showOnly}>
                  Save
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleCloseAndReset}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        </form>
      </div>
    </Dialog>
  );
}

export default RoleModal;
