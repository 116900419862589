import { useCallback ,useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import Rating from '@mui/material/Rating';
import Box from '@mui/system/Box';
import { makeStyles } from '@mui/styles';
import TableActionComponent from 'components/driver-management/TableActionComponent';
import { GetDriverLeave } from 'gql/generated/query.graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import DriverTableAction from './DriverTableAction';

type DriverLeave = {
  availabilityStatus: boolean;
  idDriverLeave: string;
  id: string;
  leaveType: string;
  startDate: string;
  endDate: string;
  status: string;
  refIdDriverUser: {
    emailId: string;
    firstName: string;
    lastName: string;
    __typename: string;
  };
  driverLeaveScheduleDays: {
    idUnavailabilityDays: string;
    days: string;
    startTime: string;
    endTime: string;
    status: string;
    __typename: string;
  }[];
  __typename: string;
  firstName: string;
  emailId: string;
  startTime: string;
  endTime: string;
};

interface ListDriversLeavesResponse {
    getDriversLeavesAdmin: {
      totalRows: number;
      results: DriverLeave[];
      __typename: string;
    };
  }


const useStyles = makeStyles({
    tableHeader: {
        height: 'calc(100vh - 200px)',
        '& .super-app-theme--header': {
            backgroundColor: GRAY_86,
            fontWeight: 500,
            fontSize: 18,
        },
        '& .super-app-theme--cell': {
            fontWeight: 300,
            fontSize: 18,
        },
    },
    ratingStyle: {
        color: GRAY_8A,
        marginTop: 1,
    },
    ratingGrid: {
        display: 'grid',
        gridTemplateColumns: '40px auto',
    },
    idGrid: {
        display: 'grid',
        gridTemplateColumns: '50px auto',
        alignItems: 'center',
    },
    userImage: {
        width: 40,
    },
    ActionGrid: {
        display: 'grid',
        gridTemplateColumns: '30px 30px 30px',
        gridColumnGap: 2,
    },
});

function DriverLeaveTable({ searchInput }: { searchInput: any }) {
    const classes = useStyles();
    const moment = require('moment');
    const [driversData, setDriversData] = useState([] as any);

    const [totalRows, setTotalRows] = useState(0 as any);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(10);


    const [addDriverLeave, { data: successData }] = useLazyQuery(GetDriverLeave);

    useEffect(() => {
        addDriverLeave({
            variables: {
                input: {
                    limit: 100,
                    offset: 0,
                    filter: {},
                },
            },
        });
    }, []);

  useEffect(() => {
    if (successData && successData?.getDriversLeavesAdmin) setLeaveData(successData);
  }, [successData]);

  const [deleteDriverLeave, { data: leaveData }] = useLazyQuery(GetDriverLeave);
  useEffect(() => {
    deleteDriverLeave({
      variables: {
        input: {
          limit: 100,
          offset: 0,
          filter: {},
        },
      },
    });
  }, []);

  useEffect(() => {
    if (leaveData && leaveData?.getDriversLeavesAdmin) setLeaveData(leaveData);
  }, [leaveData]);

  const setLeaveData = useCallback((leaveData: ListDriversLeavesResponse) => {
    let arrData: DriverLeave[] = [];

    leaveData?.getDriversLeavesAdmin?.results.forEach((element: DriverLeave) => {
      arrData.push({
        ...element,
        id: element['idDriverLeave'],
        firstName: element['refIdDriverUser'].firstName,
        emailId: element['refIdDriverUser'].emailId,
        endDate: moment(element['endDate']).format('ddd, Do MMM YYYY'),
        leaveType: element['driverLeaveScheduleDays']?.length > 0 ? 'Repeat' : 'One Time',
        startDate: moment(element['startDate']).format('ddd, Do MMM YYYY'),
        startTime: moment(element['startDate']).format('h:mm a'),
        endTime: moment(element['endDate']).format('h:mm a'),
      });
    });
    setDriversData(arrData);
    setTotalRows(leaveData?.getDriversLeavesAdmin?.totalRows);
  }, []);

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 50,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 250,
    },
    {
      field: 'status',
      headerName: 'Leave Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
        field: 'leaveType',
        headerName: 'Leave Type',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        cellClassName: 'super-app-theme--cell',
        minWidth: 200,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 250,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => <DriverTableAction params={params.row} />,
      sortable: false,
    },
  ];

    return (
        <Box className={classes.tableHeader}>
            <DataGrid
                rows={driversData}
                columns={column}
                rowHeight={75}
                disableColumnFilter
                disableColumnMenu
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                paginationMode='server'
                onPageChange={(newPage) => {
                    setPage(newPage);
                    setOffset(newPage);
                }}
                rowsPerPageOptions={[10, 20, 100]}
                rowCount={totalRows}
            />
        </Box>
    );
}

export default DriverLeaveTable;
