import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86 } from 'common/theme/colors';
import { AdminRolesEntity, useGetUserRoleListLazyQuery } from 'gql/generated/query.graphql';
import TableActionComponent from './TableActionComponent';
import { RoleContext, RoleContextType } from 'pages/role-management';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
});

function RoleListTable() {
  const [rolesData, setRolesData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0 as number);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();
  const { searchInput } = useContext(RoleContext) as RoleContextType;

  const [getUserRoleList, { data, loading }] = useGetUserRoleListLazyQuery({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
  });

  useEffect(() => {
    getUserRoleList({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          name: searchInput?.name,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!loading && data && data.getUserRoleList) {
      const arrData: any = [];
      data.getUserRoleList.results.forEach((element: AdminRolesEntity) => {
        arrData.push({ ...element, id: element['idAdminUserRoles'] });
      });
      setRolesData(arrData);
      setTotalRows(data.getUserRoleList.totalRows);
    }
  }, [data]);

  const columns = [
    {
      field: 'roleName',
      headerName: 'Role',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell, textCapitalize',
      flex: 1,
      renderCell: (params: GridRenderCellParams<AdminRolesEntity>) => (
        <div className={classes.textCapitalize}>{params?.value}</div>
      ),
    },
    {
      field: 'action',
      headerName: 'Actions',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      renderCell: (params: GridRenderCellParams<string>) => <TableActionComponent params={params.row} />,
    },
  ];

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        sx={{
          borderLeftStyle: 'none',
          borderRightStyle: 'none',
        }}
        rows={rolesData}
        columns={columns}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[5, 10, 20]}
        rowCount={totalRows}
      />
    </Box>
  );
}

export default RoleListTable;
