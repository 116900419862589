import { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { WHITE, MID_GREY } from 'common/theme/colors';

import { isLoggedInVar } from 'gql/cache';
import { handleClick, handleClose, handleLogoutClick } from './helpers';

const drawerWidth = 360;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const rightMenu = [
  {
    name: 'notifications',
    icon: '/images/notify.png',
  },
  {
    name: 'profile',
    icon: '/images/user.png',
  },
  {
    name: 'settings',
    icon: '/images/setting.png',
  },
];

const useStyles = makeStyles({
  root: {
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: WHITE,
      borderBottom: '1px solid' + MID_GREY,
      boxShadow: 'none',
    },
  },
});

const Header = ({ openHeader, clickSidebar }: { openHeader: boolean; clickSidebar: () => void }) => {
  const { root } = useStyles();
  const history = useNavigate();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  return (
    <Box>
      <AppBar
        position='fixed'
        open={openHeader}
        className={root}
        sx={{ left: openHeader ? '0' : '75px', width: openHeader ? 'inherit' : 'calc(100% - 75px)' }}
      >
        <Toolbar sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
          {openHeader && (
            <IconButton color='inherit' aria-label='open drawer' onClick={clickSidebar} edge='start'>
              <img style={{ maxWidth: '100%' }} src={'/images/menu.png'} alt='logo' />
            </IconButton>
          )}
          <Box sx={{ marginLeft: 'auto' }}>
            {rightMenu.map((item, key) => {
              return (
                <IconButton
                  key={key}
                  onClick={(event) => handleClick(event, item.name, openModal, setOpenModal, setAnchorElement)}
                >
                  <img style={{ maxWidth: '100%' }} src={item.icon} alt='logo' />
                  <Menu
                    open={openModal}
                    anchorEl={anchorElement}
                    onClose={() => handleClose(openModal, setOpenModal, setAnchorElement)}
                  >
                    <MenuItem onClick={() => handleLogoutClick(openModal, history, setOpenModal, isLoggedInVar)}>
                      Logout
                    </MenuItem>
                  </Menu>
                </IconButton>
              );
            })}
            ;
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
