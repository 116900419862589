import Box from '@mui/system/Box';
import { Button, Dialog, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { jsPDF } from 'jspdf';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { bodyFontSize, bookingStages, headerFontSize } from 'components/promo-code-management/constants/constants';
import { GRAY_86 } from 'common/theme/colors';
import {
  GetCustomersAdminDocument,
  useGetCustomersAdminLazyQuery,
  useGetReferralJourneyLazyQuery,
  useUpdateCustomerReferralMutation,
} from 'gql/generated/query.graphql';


const ReferralCodeList = () => {
  const [customerData, setCustomerData] = useState([] as any);
  const [isSelected, setIsSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [referralDetails, setReferralDetails] = useState([] as any);
  const [disable, setDisable] = useState(true);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0 as number);

  const classes = useStyles();
  const [getCustomersAdmin, { data, loading }] = useGetCustomersAdminLazyQuery();
  const [updateCustomerReferralMutation] = useUpdateCustomerReferralMutation();
  const [getReferralJourney, { data: referraldata }] = useGetReferralJourneyLazyQuery({
    variables: {
      referralCode: '',
      totalReferralBonus: 0,
      paidReferralBonus: 0,
    },
  });

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 50,
      flex: 1,
      sortable: false,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'referralCode',
      headerName: 'Referral Code',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'differenceInBonus',
      headerName: 'Referral Bonus',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 50,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Select',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      sortable: false,
      renderHeader: () => (
        <p onClick={handleSelectAll} className={classes.selectAll}>
          Select All
        </p>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <input type='checkbox' checked={params?.row?.checked} onChange={() => handleSingleSelect(params.id)} />
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button onClick={() => handleOpen(params?.row)}>Details</Button>
      ),
    },
  ];

  useEffect(() => {
    getCustomersAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: {
            differenceInReferralBonus: 1,
          },
        },
      },
    });
  }, [pageSize, offset, page]);

  useEffect(() => {
    const isAnySelected = customerData.filter((customer: any) => customer.checked === true);
    setDisable(isAnySelected.length === 0);
  }, [customerData]);

  useEffect(() => {
    if (referraldata) setReferralDetails(referraldata?.GetReferralJourney);
  }, [referraldata]);

  const handleSingleSelect = (id: any) => {
    let copyCustomerData = [...customerData];
    copyCustomerData.map(customer => {
      if (customer.id === id) customer.checked = !customer.checked;
    });
    setCustomerData(copyCustomerData);
  };

  const handleSelectAll = () => {
    let copyCustomerData = [...customerData];
    copyCustomerData.map(customer => customer.checked = !isSelected);
    setIsSelected(!isSelected);
    setCustomerData(copyCustomerData);
  };

  const onPayClickHandler = () => {
    const payableCustomer: any = [];
    customerData.forEach((customer: any) => {
      if (customer?.checked === true)
        payableCustomer.push({ key: customer?.key, totalReferralBonus: customer?.totalReferralBonus });
    });

    payableCustomer.length > 0 &&
      updateCustomerReferralMutation({
        variables: {
          input: {
            referralData: JSON.stringify(payableCustomer),
          },
        },
        refetchQueries: [GetCustomersAdminDocument, 'getCustomersAdmin'],
      });
  };

  const handleOpen = (row: any) => {
    setOpen(true);
    getReferralJourney({
      variables: {
        referralCode: row?.referralCode,
        totalReferralBonus: row?.totalReferralBonus,
        paidReferralBonus: row?.paidReferralBonus,
      },
    });
  };

  const generatePDF = () => {
    const report = new jsPDF('p', 'pt', 'a4', true);
    const headers = ['First Name', 'Last Name', 'Referral Code', 'Bonus Amount'];
    const selectedUsers = customerData.filter((user: any) => user?.checked === true);

    const data = selectedUsers.map((customer: any) => [
      JSON.stringify(customer?.firstName).replaceAll('"', ''),
      JSON.stringify(customer?.lastName).replaceAll('"', ''),
      JSON.stringify(customer?.referralCode).replaceAll('"', ''),
      JSON.stringify(customer?.differenceInBonus).replaceAll('"', ''),
    ]);

    report.setFontSize(headerFontSize);
    headers.forEach((header, i) => {
      report.text(header, 40 + i * 125, 50);
    });

    report.setFontSize(bodyFontSize);
    data.forEach((row: any, i: any) => {
      row.forEach((cell: any, j: any) => {
        report.text(cell, 40 + j * 125, 70 + (i + 1) * 15);
      });
    });
    report.save('Referral-Report.pdf');
  };

  useEffect(() => {
    if (!loading && data && data.getCustomersAdmin) {
      const unpaidUsers = data?.getCustomersAdmin.results.filter(user => user?.totalReferralBonus - user?.paidReferralBonus > 0);
      const unpaidUsersData = unpaidUsers.map((element) => ({
        key: element?.idCustomerUser,
        id: element?.idCustomerUser.split('-')[0].toUpperCase(),
        firstName: element?.firstName,
        lastName: element?.lastName,
        referralCode: element?.referralCode,
        totalReferralBonus: element?.totalReferralBonus,
        differenceInBonus: element?.totalReferralBonus - element?.paidReferralBonus,
        paidReferralBonus: element?.paidReferralBonus,
        checked: false,
      }));
      setCustomerData(unpaidUsersData);
      setTotalRows(10);
    }
  }, [data]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth='md'
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className={classes.boxContent}>
          <Typography fontWeight={500} variant='h5'>
            Referral Details
          </Typography>
          {referralDetails.map((user: any, index: number) => (
            <ul key={index}>
              <li>
                <p>
                  {`${user?.firstName} ${user?.lastName}'s ${bookingStages[user?.refereePromoUsedCount - 1]} booking`}
                </p>
              </li>
            </ul>
          ))}
        </div>
      </Dialog>
      <div className={classes.tableGrid}>
        <Typography fontWeight={500} variant='h5'>
          Referral Management List
        </Typography>
        <div className={classes.header}>
          <div className={classes.buttonContainer}>
            <Button disabled={disable} onClick={onPayClickHandler} className={classes.button}>
              Pay
            </Button>
            <Button disabled={disable} onClick={generatePDF} className={classes.button}>
              Download
            </Button>
          </div>
        </div>
        <Box className={classes.tableHeader}>
          <DataGrid
            rows={customerData}
            columns={column}
            rowHeight={75}
            disableColumnFilter
            disableColumnMenu
            loading={loading}
            rowCount={totalRows}
          />
        </Box>
      </div>
    </>
  );
};

export default ReferralCodeList;

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  button: {
    marginRight: '20px !important',
    marginBottom: 30,
  },
  selectButton: {
    marginBottom: 30,
  },
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  selectAll: {
    cursor: 'pointer',
  },
});
