import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Fab, Stack, Collapse, Box, Grid, IconButton } from '@mui/material';
import BookingCard from './BookingCard';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { BLACK, GRAY_66, GRAY_86, SILVER, WHITE } from 'common/theme/colors';
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { BOOK_A_RIDE } from 'routers/config';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';

const useStyles = makeStyles({
  bookingContainer: {
    display: 'grid',
    gridRowGap: 25,
  },
  createBookingGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  activeButton: {
    background: GRAY_66,
    color: BLACK,
  },
  inActiveButton: {
    background: WHITE,
    color: BLACK,
  },
  filterGrid: {
    display: 'grid',
    gridTemplateColumns: '600px 200px',
    gridColumnGap: 25,
  },
});

const styles = {
  '&.MuiButton-contained': {
    color: BLACK,
    textTransform: 'none',
    background: SILVER,
    height: 50,
  },
  '&.MuiButton-outlined': {
    color: BLACK,
    background: WHITE,
    border: '1px solid',
    borderColor: GRAY_86,
    textTransform: 'none',
    height: 50,
  },
};

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

function BookingCreate(props: any) {
  const classes = useStyles();
  const history = useNavigate();
  const [filter, setFilter] = useState('Today');
  const [cities] = useState(['Dubai']);
  const [open, setOpen] = useState(false);
  const [customDateFilter, setCustomDateFilter] = useState({
    startDate: moment(new Date()).startOf('day'),
    endDate: moment(new Date()).endOf('day'),
  });

  const filterBookingData = useCallback(
    (data: string) => {
      setFilter(data);
      if (data === 'Custom') {
        props.setDateFilter(customDateFilter);
      }
    },
    [customDateFilter]
  );

  useEffect(() => {
    switch (filter) {
      case 'Today':
        props.setDateFilter({
          startDate: moment(new Date()).startOf('day'),
          endDate: moment(new Date()).endOf('day'),
        });
        break;
      case 'Week':
        props.setDateFilter({
          startDate: moment().clone().startOf('isoWeek'),
          endDate: moment().clone().endOf('isoWeek'),
        });
        break;
      case 'Month':
        props.setDateFilter({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
        });
        break;
      case 'Custom':
        props.setDateFilter(customDateFilter);
        break;

      default:
        props.setDateFilter({
          startDate: moment(new Date()).startOf('day'),
          endDate: moment(new Date()).endOf('day'),
        });
        break;
    }
    return () => {};
  }, [filter]);

  const goToBookRide = () => {
    history(BOOK_A_RIDE);
  };

  return (
    <div className={classes.bookingContainer}>
      <div className={classes.createBookingGrid}>
        <Typography fontWeight={500} variant='h5'>
          Bookings
        </Typography>
        <Fab
          variant='extended'
          onClick={goToBookRide}
          size='medium'
          color='primary'
          sx={{ textTransform: 'none' }}
          disabled // TODO: remove disabled when trying to create booking
        >
          {'Create Booking'}
        </Fab>
      </div>
      <div className={classes.filterGrid}>
        <ButtonGroup>
          <Button
            sx={styles}
            variant={filter === 'Today' ? 'contained' : 'outlined'}
            onClick={() => filterBookingData('Today')}
          >
            Today
          </Button>
          <Button
            sx={styles}
            variant={filter === 'Week' ? 'contained' : 'outlined'}
            onClick={() => filterBookingData('Week')}
          >
            Week
          </Button>
          <Button
            sx={styles}
            variant={filter === 'Month' ? 'contained' : 'outlined'}
            onClick={() => filterBookingData('Month')}
          >
            Month
          </Button>
          <Button sx={styles} variant={filter === 'Custom' ? 'contained' : 'outlined'} onClick={() => setOpen(!open)}>
            Custom {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </ButtonGroup>
        <Autocomplete
          disablePortal
          id='select-city-textbox'
          size='small'
          fullWidth
          popupIcon={<KeyboardArrowDownIcon />}
          options={cities}
          defaultValue={cities[0]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
              label='Select City'
            />
          )}
        />

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Grid container spacing={2} columns={14}>
            <Grid item xs={6}>
              <TextField
                type='datetime-local'
                id='startDate'
                name='startDate'
                label='Start Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { margin: 7 } }}
                defaultValue={moment(customDateFilter.startDate).format('YYYY-MM-DD HH:mm:ss')}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setCustomDateFilter((prevState: any) => ({
                    ...prevState,
                    startDate: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='datetime-local'
                id='endDate'
                name='endDate'
                label='End Date'
                margin='normal'
                fullWidth
                variant='filled'
                defaultValue={moment(customDateFilter.endDate).format('YYYY-MM-DD HH:mm:ss')}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, style: { margin: 7 } }}
                onChange={(e) =>
                  setCustomDateFilter((prevState: any) => ({
                    ...prevState,
                    endDate: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={2} alignItems='stretch' style={{ display: 'flex' }}>
              <IconButton aria-label='delete' size='large' onClick={() => filterBookingData('Custom')}>
                <SendIcon fontSize='inherit' />
              </IconButton>
            </Grid>
          </Grid>
        </Collapse>
      </div>
      <Stack direction='row' spacing={3}>
        <BookingCard count={props.totalRows} subTitle={'Total Rides'} />
        <BookingCard count={props.statistics.nexthour} subTitle={'In next hour'} />
        <BookingCard count={props.statistics.cancelled} subTitle={'Cancelled Rides'} />
        <BookingCard count={props.statistics.completed} subTitle={'Completed Rides'} />
      </Stack>
    </div>
  );
}

export default BookingCreate;
