import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import AddPromoCode from 'components/promo-code-management/AddPromoCode';
import PromoCodeListTable from 'components/promo-code-management/PromoCodeListTable';
import SearchHeader from 'common/search-header';
import { SUBJECTS } from 'common/casl/ability';

const searchListKeys = [
  {
    value: 'name',
    label: 'Promo Code',
  },
];

const PromoCodeList = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState({});

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Promo Code List'}
        buttonText={'Add Promo Code'}
        handleOpen={() => setOpen(true)}
        showButton={true}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <PromoCodeListTable searchInput={searchInput} />
      {open && <AddPromoCode open={open} handleClose={() => setOpen(false)} title={'Add New Promo Code'} params={''} />}
    </div>
  );
};

export default PromoCodeList;

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});
