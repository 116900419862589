import { Ability, SubjectRawRule } from '@casl/ability';
import jwtDecode from 'jwt-decode';

const actions: any = { 0: 'create', 1: 'read', 2: 'update', 3: 'delete' };
// export const SUBJECTS = JSON.parse(localStorage.getItem('SUBJECTS') ?? '[]');

export const SUBJECTS = {
  USER_MANAGEMENT: 1,
  USER_ROLE_MANAGEMENT: 2,
  KPI: 3,
  HEAT_MAPS: 4,
  CONFIGURATION: 5,
  BOOKING_MANAGEMENT: 6,
};

export type ActionType = 'create' | 'read' | 'update' | 'delete';

export type AppAbility = Ability<[ActionType, string]>;

export const getRules = () => {
  let rules: SubjectRawRule<string, string, any>[] = [];
  const accessToken: string | null = localStorage.getItem('token') ?? null;
  if (!accessToken) return rules;
  const token = jwtDecode<any>(accessToken ?? ''); // as JwtPayload;
  for (const key in token?.permissions) {
    const rule: SubjectRawRule<string, string, any>[] = [];
    token?.permissions[key].forEach((permission: number, index: number) => {
      if (permission === 1) {
        rule.push(Object.assign({}, { subject: key, action: actions[index] }));
      }
    });
    rules = [...rules, ...rule];
  }
  return rules;
};

export default new Ability(getRules(), {
  detectSubjectType: (subject) => (typeof subject === 'string' ? subject : subject.subject),
});
