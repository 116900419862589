import Box from '@mui/system/Box';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import { useGetAllPromotionsQueryResult, useGetAreasLazyQuery } from 'gql/generated/query.graphql';

import { column } from './constants/constants';

const PromoCodeListTable = ({ searchInput }: { searchInput: any }) => {
  const classes = useStyles();

  const [promoCodeData, setPromoCodeData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, loading } = useGetAllPromotionsQueryResult({});
  const [getAreas, { data: areasData }] = useGetAreasLazyQuery();

  let promoDetails = data?.getAllPromotions || [];

  useEffect(() => {
    if (!loading && promoDetails && promoDetails) {
      let filteredPromoCodes = promoDetails?.filter(promotion =>
        promotion.promoCode.toLowerCase().includes(searchInput.name.toLowerCase())
      );
      filteredPromoCodes = filteredPromoCodes.map(element => ({ ...element, id: element['idPromotion'] }));
      setPromoCodeData(filteredPromoCodes);
    }
  }, [searchInput]);

  useEffect(() => {
    if (!loading && promoDetails) {
      let arrData = promoDetails.map(element => ({ ...element, id: element['idPromotion'] }));
      arrData?.forEach(promo => {
        promo.discount = promo?.discount === 0 ? promo?.tierDiscounts : promo?.discount;
        const matchingAreaId =
          promo?.refIdZedGeometry !== 0 && areasData?.getAreas.find(area => area.idArea === promo.refIdZedGeometry);
        matchingAreaId && (promo.refIdZedGeometry = matchingAreaId.areaName);
      });
      setPromoCodeData(arrData);
      setTotalRows(arrData.length);
    }
  }, [promoDetails, areasData]);

  const getAllPolygonAreas = (input: string | null) =>
    getAreas({
      variables: {
        filter: {
          name: input || '',
        },
      },
    });

  useEffect(() => {
    getAllPolygonAreas('');
  }, []);

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={promoCodeData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={newPage => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
      />
    </Box>
  );
};

export default PromoCodeListTable;

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
      overflowX: 'visible !important'
    },
    '& .MuiDataGrid-cellContent': {
      overflow: 'unset',
    }
  },
});
