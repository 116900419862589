import * as Yup from 'yup';

import { RegEx } from 'utils/Regex';

export const schema = Yup.object().shape({
  promoCode: Yup.string().required('Promo Code is required').matches(RegEx.PROMO_CODE, 'Enter a valid Promo Code'),
  promoDescription: Yup.string().required('Promo Description is required'),
  promoTitle: Yup.string().required('Promo Title is required'),
  unit: Yup.string().required('Promo Unit is required'),
  discount: Yup.number().required('Discount is required'),
  minimumBaseFare: Yup.number().required('Minimum Base Fare is required'),
  totalUsageLimit: Yup.number().required('Promo Code is required'),
  perCustomerUsageLimit: Yup.number().required('Per Customer Usage Limit is required'),
  activeFromDate: Yup.string().required('Active From Date is required'),
  activeTillDate: Yup.string().required('Active Till Date is required'),
  isActive: Yup.string().required('Active Status is required'),
  maxDiscount: Yup.number().required('Max Discount is required'),
  promoUsageLimitPerMonth: Yup.number().required('Promo Usage Limit Per Month is required'),
});
