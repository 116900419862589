import { DARK_GREY, WHITE } from '../colors';

const muiButton = {
  MuiButton: {
    // defaultProps: {
    //   disableRipple: true,
    // },
    styleOverrides: {
      root: {
        background: DARK_GREY,
        borderRadius: 5,
        border: 0,
        colorInherit: WHITE,
        color: WHITE,
        height: 40,
        fontSize: 18,
        padding: '10px 16px',
        '&.MuiButton-sizeMedium': {
          minWidth: '150px',
          minHeight: '40px',
          fontSize: '18px',
          fontWeight: 500,
        },
        '&:hover': {
          background: 'rgb(148 148 148 / 50%)',
        },
      },
    },
  },
};

export default muiButton;
