import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DRIVER_PROFILE } from 'routers/config';
import DeleteModal from 'common/deleteModal';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';
import { GetVehiclesAndPartnersDocument, useDeleteVehicleMutation } from 'gql/generated/query.graphql';
import AddVehicle from './AddVehicle';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

const TableActionComponent = ({
  params,
  disabledShowButton = false,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: any;
  disabledShowButton?: boolean;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isOpenDelete, setOpenDelete] = React.useState(false);

  const [deleteVehicleList] = useDeleteVehicleMutation();

  const handleClose = () => {
    setOpen(false);
  };
  const deleteVehicle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const goToProfile = (event: React.MouseEvent) => {
    event.stopPropagation();
    history(DRIVER_PROFILE, { state: { driverId: params.idDriverUser } });
  };

  const openEditDialog = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const deleteSelectedVehicle = (idVehicle: string) => {
    deleteVehicleList({
      variables: {
        idVehicle,
      },
      onCompleted: () => setOpenDelete(false),
      onError: () => setOpenDelete(false),
      refetchQueries: [GetVehiclesAndPartnersDocument, 'getVehiclesAndPartners'],
    });
    setOpenDelete(false);
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog} disabled={disabledEditButton}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteVehicle(e)} disabled={disabledDeleteButton}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      {editOpen && (
        <AddVehicle
          open={editOpen}
          handleClose={handleEditClose}
          title='Update Vehicle'
          action='update'
          existingVehice={params}
        />
      )}
      <DeleteModal
        open={isOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onSubmit={() => deleteSelectedVehicle(params?.idVehicle.toString())}
      />
    </div>
  );
};

export default TableActionComponent;
