import Link from '@mui/material/Link';
import { listStyles } from 'components/area-management';
import { useState } from 'react';
import AddFare from '../add-fare';
import SalikFare from '../salik-fare';

export interface FareListProps {
  fareList: any[];
  fare?: any | null;
  selectFare: (data: any) => void;
  addFareData: (data: any) => void;
  addSalikFare: (data: any) => void;
}

export interface FareProps {
  id: number;
  areaName: string;
  areaType: string;
  fare: string;
}

const FareList: React.FC<FareListProps> = ({
  selectFare,
  fare,
  fareList,
  addFareData,
  addSalikFare,
}: FareListProps) => {
  const { customTable } = listStyles();
  const [isOpenBase, setOpenBase] = useState(!1);
  const [isSalikModal, setSalikModal] = useState(!1);

  const addBaseFare = (data: any) => {
    setOpenBase(!1);
    addFareData(data);
  };
  const addSalik = (data: any) => {
    setSalikModal(!1);
    addSalikFare(data);
  };

  const renderContent = (row: any) => {
    switch (row?.refIdAreaType?.areaTypeName) {
      case 'TOWN':
      case 'CITY':
      case 'HOSPITAL':
      case 'OTHERS': {
        if (!row.fare)
          return (
            <Link component='button' variant='body2' onClick={() => setOpenBase(!0)}>
              Add Fare
            </Link>
          );
        return `${row.fare.bookingFee} AED`;
      }
      case 'SALIK': {
        if (!row.salik)
          return (
            <Link component='button' variant='body2' onClick={() => setSalikModal(!0)}>
              Add Fee
            </Link>
          );
        return `${row.salik.salikFee} AED`;
      }

      default:
        return (
          <Link component='button' variant='body2' onClick={() => setOpenBase(!0)}>
            Add Fare
          </Link>
        );
    }
  };

  return (
    <div className={` fare--table ${customTable}`}>
      <div className='table--head row'>
        <div className='column'>Area Name</div>
        <div className='column'>Area Type</div>
        <div className='column'>Fare</div>
      </div>
      <div className='table--body'>
        {fareList.map((row: any, i: number) => {
          return (
            <div
              onClick={() => selectFare(row)}
              className={`row ${fare?.idArea == row?.idArea ? 'active' : ''} `}
              key={i}
            >
              <div className='column'>{row.areaName}</div>
              <div className='column'>{row?.refIdAreaType?.areaTypeName}</div>
              <div className='column'>{renderContent(row)}</div>
            </div>
          );
        })}
        {isOpenBase && (
          <AddFare
            isOpen={isOpenBase}
            fare={fare}
            fareList={fareList}
            onSubmit={addBaseFare}
            onClose={() => setOpenBase(!1)}
          />
        )}
        {isSalikModal && (
          <SalikFare isOpen={isSalikModal} fare={fare} onSubmit={addSalik} onClose={() => setSalikModal(!1)} />
        )}
      </div>
    </div>
  );
};

export default FareList;
