import { Box, Typography, Button } from '@mui/material';
import ModalDialog from 'common/modalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputField from 'common/forms/InputField';
import * as Yup from 'yup';
import { DARK_GREY, LIGHT_GREY, WHITE } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';

const schema = Yup.object().shape({
  areaName: Yup.string().required('Area name is required'),
  salikFare: Yup.string().required('Area Type is required'),
});

interface IFormInput {
  areaName: string;
  salikFare: string;
}

interface ModalFare {
  isOpen: boolean;
  onClose: any;
  onSubmit?: any;
  fare: any;
  type?: string;
}

let useStyles = makeStyles({
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    marginTop: '15px',
  },
});

const SalikFare: React.FC<ModalFare> = ({ isOpen, fare, onSubmit, onClose, type = 'add' }: ModalFare) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { actionBtn } = useStyles();

  const onSubmitData = (inputData: any) => {
    let data = {
      areaId: fare.idArea,
      salikFee: inputData.salikFare,
    };
    onSubmit(data);
  };

  const { salikFare: salikFareError } = errors || {};

  return (
    <ModalDialog
      title={`${type == 'add' ? 'Add' : 'Update'} Salik Fare`}
      open={isOpen}
      cancelName={null}
      submitName={null}
      onClose={onClose}
    >
      <Box component={'form'} onSubmit={handleSubmit(onSubmitData)}>
        <Box display='flex' gap='10px' flexDirection='column'>
          <InputField
            control={control}
            placeholder='Area Name'
            name='areaName'
            defaultValue={fare.areaName}
            disabled
            InputProps={{
              disableUnderline: true,
            }}
          />
          <InputField
            control={control}
            placeholder='Salik Fare'
            name='salikFare'
            type="number"
            defaultValue={fare.salik?.salikFee}
            InputProps={{
              disableUnderline: true,
            }}
            helperText={salikFareError?.message}
            error={salikFareError ? true : false}
          />
          <div className={actionBtn}>
            <Button sx={{ color: WHITE }} type='submit'>
              {type == 'add' ? 'Save' : 'Update'}
            </Button>
            <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default SalikFare;
