import { Box, ListItem, Typography } from '@mui/material';
import { useState } from 'react';
import SurgeFare from '../surge-fare';
import FareGridLayout from '../fareGridLayout';
import { FareTabs } from 'pages/fare-management';
import DeleteModal from 'common/deleteModal';

interface ControlProps {
  fare: any;
  updateSurgeFare: (data: UpdateSurgeFareInput) => void;
  deleteSurgeFare: (surgeId: number) => void;
}

export interface UpdateSurgeFareInput {
  idSurge: number;
  isActive?: boolean;
  surgeEndTime?: string;
  surgeFare?: string;
  surgeStartTime?: string;
}

const SurgeControl: React.FC<ControlProps> = ({ fare, updateSurgeFare, deleteSurgeFare }: ControlProps) => {
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);
  const updateData = (data: UpdateSurgeFareInput) => {
    setOpenEdit(false);
    updateSurgeFare({
      idSurge: fare.surge.idSurge,
      isActive: data?.isActive,
      surgeEndTime: data?.surgeEndTime,
      surgeFare: data?.surgeFare,
      surgeStartTime: data?.surgeStartTime,
    });
  };
  const deleteSelectedSurge = (area: any) => {
    setOpenDelete(false);
    deleteSurgeFare(area.surge.idSurge);
  };
  const calcBase =
    (Number(fare.fare.bookingFee) + Number(fare.surge.surgeFare)) / Number(fare.fare.bookingFee)
      ? Number(fare.fare.bookingFee)
      : 1;
  return (
    <FareGridLayout
      type={FareTabs.SURGE}
      multipleOf={`${calcBase}x`}
      minFare={fare?.surge?.surgeFare}
      onEdit={() => setOpenEdit(true)}
      onDelete={() => setOpenDelete(true)}
    >
      {isOpenEdit && (
        <SurgeFare type='edit' fare={fare} isOpen={isOpenEdit} onClose={() => setOpenEdit(!1)} onSubmit={updateData} />
      )}
      {isOpenDelete && (
        <DeleteModal
          open={isOpenDelete}
          onCancel={() => setOpenDelete(false)}
          onSubmit={() => deleteSelectedSurge(fare)}
        />
      )}
      <ListItem sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h6' component='div'>
          {fare?.areaName}
        </Typography>
        <Typography display='flex' alignItems='center' gap='5px' variant='subtitle1' component='div'>
          Area Type:
          <Typography mb='0' variant='subtitle2' component='div'>
            {fare?.refIdAreaType?.areaTypeName}
          </Typography>
        </Typography>
      </ListItem>
      <ListItem sx={{ justifyContent: 'space-between', pt: '0', pb: '0' }}>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle1' fontWeight='100' component='div'>
            Start Time
          </Typography>
          <Typography ml='3px' variant='subtitle2' component='div'>
            {fare?.surge?.surgeStartTime}
          </Typography>
          {/* <Typography variant='caption' display='block'>
              /Minute
            </Typography> */}
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle1' fontWeight='100' component='div'>
            End Time
          </Typography>
          <Typography ml='3px' variant='subtitle2' component='div'>
            {fare?.surge?.surgeEndTime}
          </Typography>
          {/* <Typography variant='caption' display='block'>
              /Mile
            </Typography> */}
        </Box>
      </ListItem>
      {/* <ListItem sx={{ pt: '0', pb: '0' }}>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle1' fontWeight='100' gutterBottom component='div'>
              AED
            </Typography>
            <Typography ml='3px' variant='subtitle2' gutterBottom component='div'>
              0.5
            </Typography>
            <Typography variant='caption' display='block' gutterBottom>
              /Waiting per Min
            </Typography>
          </Box>
        </ListItem> */}
    </FareGridLayout>
  );
};

export default SurgeControl;
