import { object, string } from 'yup';

const UAE_MOBILE_NUMBER = /^(?:971)[0-9]{9}$/;

export const schema = object().shape({
  emailId: string().email().required('Email is required'),
  mobileNumber: string().required('Phone is required').matches(UAE_MOBILE_NUMBER, 'Phone number is not valid'),
});

export const searchListKeys = [
  {
    value: 'emailId',
    label: 'Email',
  },
  {
    value: 'mobileNumber',
    label: 'Phone',
  },
];

export interface Params {
  idWaitingList: string
  emailId: string
  mobileNumber: string
}

export interface FieldValues {
  emailId?: string
  mobileNumber?: string
}

export interface WaitingListData {
  id: number;
  createdDate: string;
  __typename?: "WaitingList" | undefined;
  emailId?: string | null | undefined;
  mobileNumber: string;
  idWaitingList: number;
}
