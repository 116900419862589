export const DARK_GREY = '#949494';
export const LIGHT_GREY = '#EBEBEB';
export const GRAY_86 = '#DBDBDB';
export const GRAY_5C = '#5C5C5C';
export const WHITE = '#FFFFFF';
export const BLACK = 'black';
export const LIGHT_NAVY = '#556cd6';
export const CYAN = '#19857b';
export const GRAY_8A = '#8A8A8A';
export const MILD_GREY = '#EEEEEE';
export const MID_GREY = '#CECECE';
export const SILVER = '#C4C4C4';
export const DARK_SILVER = '#BFBFBF';
export const DIRE_WOLF = '#282828';
export const GRAY_83 = '#D4D4D4';
export const GRAY_90 = '#E5E5E542';
export const GRAY_66 = '#A8A8A8';
export const LIGHT_SHADE_GRAY = '#DDDDDD';
export const ALTO = '#D5D5D5';
export const RED = '#E13333';
export const LIGHT_BLACK = '#333333';
