import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Autocomplete, TextField, Fab, Checkbox } from '@mui/material';
import { GRAY_86 } from 'common/theme/colors';
import BookingSuccessModal from 'components/booking-management/BookingSuccessModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles({
  rideGrid: {
    backgroundImage: `url(${'/images/rideBg.png'})`,
    height: 'calc(100vh - 130px)',
  },
  createBookingCard: {
    height: 420,
    width: 600,
    position: 'absolute',
    margin: 20,
    padding: 20,
  },
  bookFormGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 20,
    marginTop: 10,
  },
  rideButton: {
    marginTop: 20,
  },
  roundTripGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 50px',
    alignItems: 'center',
  },
  roundTripText:{
      justifySelf: 'end'
  }
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

function BookRide() {
  const classes = useStyles();
  const [mode] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const openSuccessModal = () =>{
    setOpenModal(true);
  };

  const closeSuccessModal = () =>{
    setOpenModal(false);
  };


  return (
    <div className={classes.rideGrid}>
      <Paper className={classes.createBookingCard}>
        <Typography variant='h4' fontWeight='500'>
          Create Booking
        </Typography>
        <div className={classes.bookFormGrid}>
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Select Mode'
              />
            )}
          />
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Select Cab Category'
              />
            )}
          />
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Pickup Location'
              />
            )}
          />
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Dropoff Location'
              />
            )}
          />
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Pick Date'
              />
            )}
          />
          <div className={classes.roundTripGrid}>
            <Autocomplete
              disablePortal
              id='select-city-textbox'
              size='small'
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={mode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Pick Time'
                />
              )}
            />
            <Typography className={classes.roundTripText}>Round Trip</Typography>
            <Checkbox />
          </div>
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Add Addons'
              />
            )}
          />
          <Autocomplete
            disablePortal
            id='select-city-textbox'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={mode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Select Nearby Driver'
              />
            )}
          />
        </div>
        <div className={classes.rideButton}>
          <Fab variant='extended' size='medium' color='primary' onClick={openSuccessModal} sx={{ textTransform: 'none' }}>
            {'Book This Ride'}
          </Fab>
        </div>
        <BookingSuccessModal open={openModal} onClose={closeSuccessModal}/>
      </Paper>
    </div>
  );
}

export default BookRide;
