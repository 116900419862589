import React from 'react';
import { Box } from '@mui/material';
import Header from 'common/header';
import Sidebar from 'common/sidebar';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const [open, setOpen] = React.useState(false);

  const toggleChecked = () => {
    setOpen((value) => !value);
  };
  return (
    <Box>
      <Header openHeader={open} clickSidebar={toggleChecked} />
      <Box>
        <Sidebar openDrawer={open} clickToggle={toggleChecked} />
      </Box>
      <Box>
        <Box sx={{ marginLeft: open ? '360px' : '75px' }} className='contentWrapper' p='30px' mt='70px'>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
