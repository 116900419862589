import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { SUBJECTS } from 'common/casl/ability';
import SearchHeader from 'common/search-header';
import { searchListKeys } from 'components/waiting-list-management/helpers';
import WaitingListManagementTable from 'components/waiting-list-management/WaitingListTable';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const WaitingListManagementManagement = () => {
  const [searchInput, setSearchInput] = useState({});
  const classes = useStyles();

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title='Waiting List'
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <WaitingListManagementTable searchInput={searchInput} />
    </div>
  );
};

export default WaitingListManagementManagement;
