import TableActionComponent from '../TableActionComponent';
import { GridRenderCellParams } from '@mui/x-data-grid';

export const unitTypes = [
  { value: 'FIXED_AMOUNT', label: 'Fixed Amount' },
  { value: 'PERCENTAGE', label: 'Percentage' },
];

export const statusTypes = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Not Active' },
];

export const promoTypes = [
  { value: 'UNIQUE', label: 'Unique' },
  { value: 'GENERIC', label: 'Generic' },
];

export const typesOfUsers = [
  { value: 'NEW', label: 'New' },
  { value: 'EXISTING', label: 'Existing' },
];

export const promoApplications = [
  { value: 'SIMPLE', label: 'Simple' },
  { value: 'TIER', label: 'Tier' },
];

export const promoSpecification = [
  { value: 'GENERIC', label: 'Generic' },
  { value: 'SPECIFIC', label: 'Specific' },
];

export const column = [
  {
    field: 'idPromotion',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'promoCode',
    headerName: 'Promo Code',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'promoDescription',
    headerName: 'Description',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'promoApplication',
    headerName: 'Promo Applicability',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'discount',
    headerName: 'Discount',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'unit',
    headerName: 'Unit',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'refIdZedGeometry',
    headerName: 'Area Applicability',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'promoType',
    headerName: 'Promotion Type',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'minimumBaseFare',
    headerName: 'Min Base Fare',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'totalUsageLimit',
    headerName: 'Total Usage Limit',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'perCustomerUsageLimit',
    headerName: 'Promo Usage limit(per customer)',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'activeFromDate',
    headerName: 'Active From',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'activeTillDate',
    headerName: 'Active Till',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'isActive',
    headerName: 'Status',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'maxDiscount',
    headerName: 'Max Discount',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'promoUsageLimitPerMonth',
    headerName: 'Customer Usage Limit(per month)',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'totalAwailedDiscount',
    headerName: 'Total Awailed discount',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'typesOfUsers',
    headerName: 'Type of User',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'promoSpecification',
    headerName: 'Promo Specification',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<string>) => <TableActionComponent params={params.row} />,
    sortable: false,
  },
];

export const bookingStages = ['1st', '2nd', '3rd'];
export const headerFontSize = 16;
export const bodyFontSize = 12;
