import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { clientProvider } from 'gql/client-provider';
import './index.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_IO_STATUS ? "https://a2cd0418115a4249881bf838c536ee7a@o1244679.ingest.sentry.io/6427845" : "" ,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_APP_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={clientProvider}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
