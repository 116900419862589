import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Autocomplete, TextField, Fab, Checkbox } from '@mui/material';
import { GRAY_86 } from 'common/theme/colors';
import BookingSuccessModal from 'components/booking-management/BookingSuccessModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  useGetGlobalModeDetailsLazyQuery,
  useAdminEditBookingMutation,
  GetAdminBookingSummaryDocument,
} from 'gql/generated/query.graphql';
import moment from 'moment';
import InputField from 'common/forms/InputField';
import Map from 'common/map/map';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import PlacesAutocomplete from 'common/forms/PlacesAutocomplete';

const useStyles = makeStyles({
  rideGrid: {
    height: 'calc(100vh - 130px)',
    position: 'relative',
  },
  createBookingCard: {
    height: 480,
    width: 600,
    position: 'absolute',
    margin: 20,
    padding: 20,
    top: '0',
    left: '0',
  },
  bookFormGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 20,
    marginTop: 10,
  },
  rideButton: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roundTripGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 50px',
    alignItems: 'center',
  },
  roundTripText: {
    justifySelf: 'end',
  },
  editForm: {
    display: 'grid',
    gridRowGap: 20,
  },
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

interface LocationStateType {
  state: {
    params: any;
  };
}

interface IFormInput {
  pickTime: string;
  pickDate: Date;
  refIdModes: number;
  pickUpLocation: any;
  dropOffLocation: any;
}

const schema = Yup.object().shape({
  pickDate: Yup.date().optional(),
  refIdModes: Yup.number().integer().optional(),
  pickUpLocation: Yup.object().optional(),
  dropOffLocation: Yup.object().optional(),
});

function EditBooking() {
  const {
    state: { params },
  } = useLocation() as unknown as LocationStateType;
  const classes = useStyles();
  const [mode] = useState([]);
  const [mapData, setMapData] = useState<object | null>(null);
  const [currArea, setCurrArea] = useState<any | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [getGlobalModeDetails, { data: modeList }] = useGetGlobalModeDetailsLazyQuery();
  const [editJourney] = useAdminEditBookingMutation({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
    onCompleted() {
      openSuccessModal();
    },
  });
  useEffect(() => {
    getGlobalModeDetails();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { refIdModes: refIdModesErrorMsg, pickTime: pickTimeErrorMsg, pickDate: pickDateErrorMsg } = errors;

  const openSuccessModal = () => {
    setOpenModal(true);
  };

  const closeSuccessModal = () => {
    setOpenModal(false);
  };

  const handleSelect = useCallback(async (placeId: string) => {
    try {
      const results = await getGeocode({ placeId });
      const { lat, lng } = getLatLng(results[0]);
      return {
        lat: lat.toString(),
        long: lng.toString(),
      };
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  }, []);

  const onSave = async (values: any) => {
    const [hour, minute] = values.pickTime.split(':');
    const pickupTime = moment(values.pickDate).set({ hour, minute }).toDate();
    values = { ...values, pickupTime };
    if (values.pickUpLocation) {
      const latLong = await handleSelect(values.pickUpLocation.placeId);
      values.pickUpLocation = { ...values.pickUpLocation, lat: latLong?.lat, long: latLong?.long };
    }
    if (values.dropOffLocation) {
      const latLong = await handleSelect(values.dropOffLocation.placeId);
      values.dropOffLocation = { ...values.dropOffLocation, lat: latLong?.lat, long: latLong?.long };
    }
    delete values.pickTime;
    delete values.pickDate;

    editJourney({
      variables: {
        idJourney: parseFloat(params.idJourney),
        input: values,
      },
      refetchQueries: [GetAdminBookingSummaryDocument, 'getAdminBookingSummary'],
    });
  };

  const polygonDataFn = (coordinates: any) => {
    setMapData(coordinates);
  };
  
  return (
    <div className={classes.rideGrid}>
      <Map
        type='view'
        currArea={currArea}
        polygonDataFn={polygonDataFn}
        style={{ width: '100%', height: '100%' }}
      ></Map>
      <Paper className={classes.createBookingCard}>
        <div className={classes.editForm}>
          <Typography variant='h4' fontWeight='500'>
            Edit Booking
          </Typography>
          <div className={classes.bookFormGrid}>
            <Controller
              control={control}
              name='refIdModes'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  id='select-mode-textbox'
                  size='small'
                  fullWidth
                  popupIcon={<KeyboardArrowDownIcon />}
                  options={modeList?.getGlobalModeDetails || []}
                  getOptionLabel={(option) => (option.modeName ? option.modeName : '')}
                  onChange={(event, item) => {
                    onChange(item.idModes);
                  }}
                  defaultValue={params?.refIdModes ? params.refIdModes : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                      label='Select Mode'
                      helperText={refIdModesErrorMsg?.message}
                      error={refIdModesErrorMsg ? true : false}
                    />
                  )}
                />
              )}
            />

            <Autocomplete
              disabled
              disablePortal
              id='select-city-textbox'
              size='small'
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={mode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Select Cab Category'
                />
              )}
            />

            <PlacesAutocomplete
              control={control}
              margin='normal'
              fullWidth
              name='pickUpLocation'
              label='Pickup Location'
              id='pickUpLocation'
              variant='filled'
              InputProps={{ disableUnderline: true }}
              defaultValue={params?.pickupRefIdLocation ? params?.pickupRefIdLocation : ''}
            />

            <PlacesAutocomplete
              control={control}
              margin='normal'
              fullWidth
              name='dropOffLocation'
              label='Dropoff Location'
              id='dropOffLocation'
              variant='filled'
              InputProps={{ disableUnderline: true }}
              defaultValue={params?.dropoffRefIdLocation ? params?.dropoffRefIdLocation : ''}
            />

            <InputField
              control={control}
              type='date'
              id='pickDate'
              name='pickDate'
              label='Pick Date'
              margin='normal'
              fullWidth
              variant='filled'
              InputProps={{ disableUnderline: true }}
              defaultValue={moment(params?.pickupTime).format('YYYY-MM-DD')}
              InputLabelProps={{ shrink: true }}
              helperText={pickDateErrorMsg?.message}
              error={pickDateErrorMsg ? true : false}
            />

            <div className={classes.roundTripGrid}>
              <InputField
                control={control}
                type='time'
                id='pickTime'
                name='pickTime'
                label='Pick Time'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true }}
                defaultValue={moment(params?.pickupTime).format('HH:mm')}
                helperText={pickTimeErrorMsg?.message}
                error={pickTimeErrorMsg ? true : false}
              />
              <Typography className={classes.roundTripText}>Round Trip</Typography>
              <Checkbox disabled />
            </div>

            <Autocomplete
              disabled
              disablePortal
              id='select-city-textbox'
              size='small'
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={mode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Add Addons'
                />
              )}
            />

            <Autocomplete
              disabled
              disablePortal
              id='select-city-textbox'
              size='small'
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={mode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Select Nearby Driver'
                />
              )}
            />
            <div className={classes.rideButton}>
              <Fab
                variant='extended'
                size='medium'
                color='primary'
                onClick={handleSubmit(onSave)}
                sx={{ textTransform: 'none' }}
              >
                {'Book This Ride'}
              </Fab>
              <Fab
                variant='extended'
                size='medium'
                color='primary'
                onClick={() => navigate(-1)}
                sx={{ textTransform: 'none' }}
              >
                Cancel
              </Fab>
            </div>
          </div>
          <BookingSuccessModal open={openModal} onClose={closeSuccessModal} />
        </div>
      </Paper>
    </div>
  );
}

export default EditBooking;
