export const ROOT = '/';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot_password';
export const CODE_VERIFICATION = '/code_verification';
export const NEW_PASSWORD = '/new_password';
export const HOME = '/home';
export const CUSTOMERPROFILE = '/admin/customer-profile';
export const DRIVER_PROFILE = '/admin/driver-profile';
export const BOOK_A_RIDE = '/admin/book-a-ride';
export const BOOKING ='/admin/booking';
export const RESCHEDULE_RIDE = '/admin/reschedule-ride';
export const BOOK_RESCHEDULE = '/admin/booking-reschedule';
export const DRIVER_LEAVE_MANAGEMENT ='/admin/driver-leave';
export const DRIVER_LEAVE = '/admin/driver-leave-management';
export const EDIT_BOOKING = '/admin/booking/edit';
export const USERPROFILE = '/admin/user-profile';
export const ACTIVITY_LIFECYCLE = '/admin/lifecycle';
