import useStyles from './styled';
import { AreaListProps } from './types';

const AreaList: React.FC<AreaListProps> = ({ areaList, currentArea = null, onSelect }: AreaListProps) => {
  const { customTable } = useStyles();
  return (
    <div className={customTable}>
      <div className="table--wrapper">
        <div className='table--head row'>
          <div className='column'>Area Name</div>
          <div className='column'>Area Type</div>
        </div>
        <div className='table--body'>
          {areaList.map((row: any, i: number) => (
            <div onClick={() => onSelect(row)} className={`row ${currentArea?.idArea == row.idArea ? 'active' : ''} `} key={i}>
              <div className='column'>{row['areaName']}</div>
              <div className='column'>{row['refIdAreaType']['areaTypeName']}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AreaList;
