import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SearchHeader from 'common/search-header';
import { MapControl, AreaList, AddArea } from 'components/area-management';
import Map from 'common/map/map';
import {
  useCreateAreaMutation,
  useDeleteAreaMutation,
  useGetAreaLazyQuery,
  useGetAreasLazyQuery,
  useUpdateAreaMutation,
} from 'gql/generated/query.graphql';
import { areaEditInput, IFormInput } from 'components/area-management/add-area/types';
import { toastMessage } from 'gql/cache';
import { SUBJECTS } from 'common/casl/ability';

const searchListKeys = [
  {
    value: 'name',
    label: 'Name',
  },
];
const AreaManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currArea, setCurrArea] = useState<any | null>({});
  const [areaData, setAreaList] = useState<any | []>([]);
  const [searchInput, setSearchInput] = useState({ name: '' });
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(true);

  const [GetArea] = useGetAreaLazyQuery();
  const [getAreas, { data, error, loading }] = useGetAreasLazyQuery();
  const [createAreaMutation] = useCreateAreaMutation();
  const [updateAreaMutation] = useUpdateAreaMutation();
  const [deleteAreaMutation] = useDeleteAreaMutation();

  useEffect(() => {
    getAllData(searchInput.name);
  }, [searchInput]);

  useEffect(() => {
    setIsEnabled(currArea?.isEnable);
  }, [currArea]);

  const getAllData = (input: string | null) => {
    getAreas({
      variables: {
        filter: {
          name: input || '',
        },
      },
    });
  };

  useEffect(() => {
    if (data && typeof data === 'object') {
      setAreaList(data?.getAreas);
    }
  }, [data]);

  const onSelectArea = (data: any) => {
    GetArea({
      variables: {
        areaId: data?.idArea,
      },
    }).then((val) => {
      let tempData = val?.data?.getArea || null;
      setCurrArea(tempData);
      setIsEnabled(tempData?.isEnable);
    });
  };

  const updateArea = (type: string, id: number, data: areaEditInput | null) => {
    if (type == 'delete') {
      deleteAreaMutation({
        variables: {
          areaId: id,
        },
        onCompleted: () => {
          getAllData('');
        },
      });
    } else if (type === 'edit') {
      updateAreaMutation({
        variables: {
          input: { ...data, areaId: id },
        },
        onCompleted: () => {
          getAllData('');
        },
      });
    }
    setCurrArea(null);
  };

  const handleSubmit = (data: IFormInput) => {
    setOpenModal(false);
    createAreaMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: (res) => {
        toastMessage({ type: 'success', message: 'added successfully' });
        getAllData('');
      },
    });
  };

  const onChange = (e: any) => {
    getAllData(e?.target?.value);
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='calc(100vh - 130px)'>
      <SearchHeader
        title={'Area Management'}
        buttonText={'Add Area'}
        showButton={true}
        handleOpen={() => setOpenModal(true)}
        onChangeInput={onChange}
        searchListKeys={searchListKeys}
        setSearchInput={setSearchInput}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <Box display='flex' height='calc(100% - 70px)'>
        <Box display='flex' flex='0 0 60%' position='relative'>
          <Map type={currArea ? 'view' : ''} currArea={currArea} style={{ width: '100%' }} zoom={16}>
            {currArea ? (
              <>
                <MapControl updateArea={updateArea} area={currArea} isEnabled={isEnabled} setIsEnabled={setIsEnabled} />
              </>
            ) : (
              <></>
            )}
          </Map>
        </Box>
        <Box display='flex' flex='0 0 40%'>
          <AreaList areaList={areaData} currentArea={currArea} onSelect={onSelectArea} />
        </Box>
      </Box>
      {openModal && <AddArea isOpen={openModal} onCancel={() => setOpenModal(false)} onSubmit={handleSubmit} />}
    </Box>
  );
};

export default AreaManagement;
