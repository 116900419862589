import { Box, Typography, Card, IconButton, Divider, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CardWithEditDelete, { CardWithEditDeleteProps } from 'common/card/cardWithEditDelete';
import { BLACK, LIGHT_SHADE_GRAY, RED } from 'common/theme/colors';
import { FareTabs } from 'pages/fare-management';

type FareGridLayoutProps = Omit<CardWithEditDeleteProps, 'title'> & {
  type: FareTabs;
  multipleOf?: string;
  minFare: number;
};

const FareGridLayout = ({ type = FareTabs.BASE, multipleOf, minFare, children, ...rest }: FareGridLayoutProps) => {
  return (
    <CardWithEditDelete
      title={
        <>
          {multipleOf && (
            <Typography noWrap fontWeight={700} variant='h4' color={type === FareTabs.SURGE ? RED : BLACK}>
              {multipleOf}
            </Typography>
          )}
          <Box
            display='flex'
            alignItems='center'
            borderRadius='4px'
            ml={1.25}
            py={0.3}
            px={0.8}
            sx={{ backgroundColor: LIGHT_SHADE_GRAY }}
          >
            <Typography mb='0' variant='subtitle1' fontWeight='100' component='div'>
              AED
            </Typography>
            <Typography mb='0' ml='3px' variant='subtitle2' component='div'>
              {minFare}
            </Typography>
            <Typography mb='0' ml='3px' variant='caption' display='block'>
              Minimum Fare
            </Typography>
          </Box>
        </>
      }
      {...rest}
    >
      {children}
    </CardWithEditDelete>
  );
};

export default FareGridLayout;
