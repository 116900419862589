import { Autocomplete, Button, Dialog, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputField from 'common/forms/InputField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DARK_GREY, GRAY_86, LIGHT_GREY } from 'common/theme/colors';
import {
  idKidsCategory,
  isTaxi,
  licenseNumberValidate,
  modeFeatures,
  schema,
  vehicleColors,
  vehicleFields,
  vehicleLabels,
} from './constants';
import { onSaveForm } from 'utils/externalApiCalls';
import {
  GetVehiclesAndPartnersDocument,
  useCreateVehicleMutation,
  useGetAllSuppliersLazyQuery,
  useGetRegCitiesLazyQuery,
  useGetSaveAdminSignedUrlLazyQuery,
  useGetVehicleBrandsLazyQuery,
  useGetVehicleCategoryLazyQuery,
  useGetVehicleColorLazyQuery,
  useGetVehicleModelLazyQuery,
  useGetVehicleTypeLazyQuery,
  useGetVehiclesAndPartnersLazyQuery,
  useUpdateVehicleMutation,
} from 'gql/generated/query.graphql';
import VehicleOptions from './VehicleOptions';
import moment from 'moment';
import { ValidImage, fileTypeMessage } from 'components/driver-management/constants';
import { color } from '@mui/system';
import { isEmpty, range } from 'lodash';
import { format } from 'path';
const AddVehicle = ({
  open,
  handleClose,
  title,
  action: mainAction,
  existingVehice: mainExistingVehice = {},
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  action: string;
  existingVehice?: any;
}): React.ReactElement => {
  const [brandsData, setBrandsData] = useState([] as any);
  const [vehicleCategoryData, setVehicleCategoryData] = useState([] as any);
  const [vehicleSeatingData, setVehicleSeatingData] = useState([] as any);
  const [vehicleModelData, setVehicleModelData] = useState([] as any);
  const [regCitiesData, setRegCitiesData] = useState([] as any);
  const [vehicleColorData, setVehicleColorData] = useState([] as any);
  const [vehicleTypeData, setVehicleTypeData] = useState([] as any);
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');
  const [vehicleLicensePlateFront, setVehicleLicensePlateFront] = useState('');
  const [vehicleModes, setVehicleModes] = useState('');
  const [currentSupplier, setCurrentSupplier] = useState<any>({});
  const [selectedSeating, setSelectedSeating] = useState<any>(0);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [selectedBrand, setSelectedBrand] = useState<any>('');
  const [vehicleNumberError, setVehicleNumberError] = useState('');
  const [mainError, setMainError] = useState('');
  const [suppliersData, setSuppliersData] = useState<any>([]);
  const [existingSupplier, setExistingSupplier] = useState<any>({});
  const [renderDefaultInput, setRenderDefaultInput] = useState(true);
  const [save, setSave] = useState(false);
  const [action, setAction] = useState(mainAction);
  const [drftAction, setDrftAction] = useState(false);
  const [existingVehice, setExistingVehice] = useState<any>(mainExistingVehice);

  const [getVehiclesAndPartners, { data }] = useGetVehiclesAndPartnersLazyQuery();
  const [getAdminSignedUrl, { data: imageData }] = useGetSaveAdminSignedUrlLazyQuery();
  const [createVehicleAdmin] = useCreateVehicleMutation({
    onCompleted() {
      handleClose();
    },
  });
  const [getVehiclesBrands, { data: vehicleBrandsList }] = useGetVehicleBrandsLazyQuery();
  const [getVehiclesCategory, { data: vehicleCategoryList }] = useGetVehicleCategoryLazyQuery();
  const [getVehiclesModel, { data: vehicleModelList }] = useGetVehicleModelLazyQuery();
  const [getRegCities, { data: regCitiesList }] = useGetRegCitiesLazyQuery();
  const [getVehicleColor, { data: vehicleColorList }] = useGetVehicleColorLazyQuery();
  const [getVehicleType, { data: vehicleTypeList }] = useGetVehicleTypeLazyQuery();
  const [getAllSuppliers, { data: supplierData }] = useGetAllSuppliersLazyQuery();
  const [updateVehicle] = useUpdateVehicleMutation({ onCompleted: () => handleClose() });

  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (action === 'add') {
      let draftForm: string = localStorage.getItem('vehicleForm') || '';
      if (!isEmpty(draftForm) || draftForm) {
        draftForm = JSON.parse(draftForm);
        setDrftAction(true);
        setAction('update');
        setExistingVehice(draftForm);
      }
    }
  }, []);

  useEffect(() => {
    if (suppliersData) {
      const defaultSupplier = suppliersData.filter((supplier: any) => {
        const copySupplier = { ...supplier };
        if (supplier?.idSupplier === existingVehice?.refIdSupplier) {
          if (copySupplier?.name != null) return copySupplier;
          else {
            copySupplier.name = `${copySupplier.taxiSupplierColor.type}(${copySupplier.taxiSupplierColor.color})`;
            return copySupplier;
          }
        }
      })[0];

      setExistingSupplier(defaultSupplier);
    }
  }, [suppliersData]);

  useEffect(() => {
    setVehicleModes(existingVehice?.modes);
    if (action === 'update') {
      setValue('vehicleNumber', existingVehice?.licensePlate);
      setValue('vehicleChasisNumber', existingVehice?.chasisNo);
      setValue('vehicleLicenseNumber', existingVehice?.idDocument);
    }
  }, [action, existingVehice]);

  const currentYear = new Date().getFullYear();
  const yearArray = Array.from({ length: 6 }, (value, lastYear) => currentYear - lastYear);

  const {
    vehicleNumber,
    vehicleChasisNumber,
    vehicleRegistration,
    vehicleClassification,
    vehicleBrand,
    vehicleCategory,
    carType,
    vehicleSeating,
    supplierName,
    kidSeatsAvailableInVehicle,
    vehicleColour,
    vehicleFuel,
    vehicleModel,
    manufacturingYear,
    vehicleLicenseNumber,
    licenseExpiryDate,
    vehicleInsuranceValidity,
  } = errors;

  const getError = (name: string) => {
    if (name === 'Vehicle Brand') return vehicleBrand?.message;
    if (name === 'Vehicle Classification') return vehicleClassification?.message;
    if (name === 'Vehicle Category') return vehicleCategory?.message;
    if (name === 'Vehicle Seating Capacity') return vehicleSeating?.message;
    if (name === 'Kid Seats Available in Vehicle') return kidSeatsAvailableInVehicle?.message;
    if (name === 'Vehicle Fuel Type') return vehicleFuel?.message;
    if (name === 'Vehicle Model Name') return vehicleModel?.message;
    if (name === 'Vehicle Registration Emirate') return vehicleRegistration?.message;
    if (name === 'Vehicle Colour') return vehicleColour?.message;
    if (name === 'Car Type') return carType?.message;
    if (name === 'Supplier Name') return supplierName?.message;
    if (name === 'vehicleNumber') return vehicleNumber?.message;
    if (name === 'vehicleChasisNumber') return vehicleChasisNumber?.message;
    if (name === 'manufacturingYear') return manufacturingYear?.message;
    if (name === 'vehicleLicenseNumber') return vehicleLicenseNumber?.message;
    if (name === 'licenseExpiryDate') return licenseExpiryDate?.message;
    if (name === 'vehicleInsuranceValidity') return vehicleInsuranceValidity?.message;
  };

  useEffect(() => {
    getVehiclesAndPartners();
    getVehiclesBrands();
    getVehiclesCategory();
    getVehiclesModel();
    getRegCities();
    getVehicleColor();
    getVehicleType();
    getAdminSignedUrl();
    getAllSuppliers();
  }, []);

  useEffect(() => {
    vehicleBrandsList && setBrandsData(vehicleBrandsList?.getVehiclesBrands.map((brands) => brands?.manufacturer));
    vehicleModelList && setVehicleModelData(vehicleModelList?.getVehiclesModel.map((model) => model?.model));
    regCitiesList && setRegCitiesData(regCitiesList?.getRegCities.map((cities) => cities?.name));
    vehicleColorList && setVehicleColorData(vehicleColorList?.getVehicleColour.map((color) => color?.color));
    vehicleTypeList && setVehicleTypeData(vehicleTypeList?.getVehicleType.map((type) => type?.name));
    if (supplierData) {
      let copySupplies: any[] = [];
      copySupplies.push(supplierData?.getSuppliers);
      copySupplies = copySupplies[0].map((supplier: any) => {
        if (supplier?.name) return supplier;
        else {
          supplier.name = `${supplier.taxiSupplierColor.type}(${supplier.taxiSupplierColor.color})`;
          return supplier;
        }
      });
      setSuppliersData(copySupplies);
    }
  }, [vehicleBrandsList, vehicleModelList, regCitiesList, vehicleColorList, vehicleTypeList, supplierData]);

  useEffect(() => {
    if (vehicleCategoryList) {
      const filteredCateforyNames: string[] = [];

      vehicleCategoryList?.getVehiclesCategory?.forEach((category) => {
        if (currentSupplier?.type === category.type.toUpperCase() && selectedSeating === category.seating)
          filteredCateforyNames.push(category?.categoryName);
      });

      setVehicleCategoryData(filteredCateforyNames);
      setVehicleSeatingData(vehicleCategoryList?.getVehiclesCategory.map((category) => category?.seating));
    }
  }, [vehicleCategoryList, currentSupplier, selectedSeating]);

  useEffect(() => {
    if (action === 'update') {
      setVehicleLicensePlateFront(existingVehice?.frontImageUrl);
      setVehicleLicensePlate(existingVehice?.backImageUrl);
    }
  }, [existingVehice, action]);

  const handleFileInput = async (fileData: any, type: string) => {
    if (!ValidImage(fileData)) {
      if (type == 'front') setVehicleLicensePlateFront('');
      else setVehicleLicensePlate('');
      return;
    }

    await getAdminSignedUrl();
    const { url } = await onSaveForm(imageData, fileData?.target?.files[0]);

    if (type == 'front') setVehicleLicensePlateFront(url);
    else setVehicleLicensePlate(url);
  };


  const onSave = (data: any) => {
    if (currentSupplier?.type.toUpperCase() !== data?.vehicleClassification?.toUpperCase())
      return setMainError('Please select valid Vehicle Classification');
    else setMainError('');

    if (vehicleModes === '') return setVehicleModes('invalid');

    if (selectedCategory.toLocaleUpperCase().includes('KIDS') && parseInt(data?.kidSeatsAvailableInVehicle) === 0)
      return setMainError("kid's Vehicle should have minimum 1 kids seat available");
    else setMainError('');

    if (!licenseNumberValidate(data?.vehicleNumber, data?.vehicleRegistration))
      return setVehicleNumberError(`Vehicle number is invalid for ${data?.vehicleRegistration}`);

    setVehicleNumberError('');
    if (vehicleLicensePlateFront === '' && vehicleLicensePlate === '' && action !== 'update') return;

    if (save) {
      const draftFata = {
        backImageUrl: vehicleLicensePlate,
        brand: data?.vehicleBrand.toUpperCase(),
        carType: data?.carType,
        category: data?.vehicleCategory,
        documentType: 'LICENCE',
        expiryDate: data?.licenseExpiryDate,
        chasisNo: data?.vehicleChasisNumber,
        color: data?.vehicleColour,
        frontImageUrl: vehicleLicensePlateFront,
        fuelType: data.vehicleFuel?.toUpperCase(),
        insuranceExpDate: data?.vehicleInsuranceValidity,
        kidsSeats: parseInt(data?.kidSeatsAvailableInVehicle),
        licensePlate: data?.vehicleNumber,
        manufacturingYear: parseInt(data?.manufacturingYear),
        model: data?.vehicleModel,
        modes: vehicleModes,
        refIdSupplier: currentSupplier?.idSupplier,
        regCity: data?.vehicleRegistration,
        seats: parseInt(data?.vehicleSeating),
        type: data?.carType,
        vehicleType: data?.vehicleClassification.toUpperCase(),
        idDocument: data?.vehicleNumber,
      };
      handleClose();
      setSave(false);
      return localStorage.setItem('vehicleForm', JSON.stringify(draftFata));
    }
    if (action === 'add' || drftAction) {
      localStorage.removeItem('vehicleForm');
      setSave(false);
      createVehicleAdmin({
        variables: {
          input: {
            kidsSeats: parseInt(data?.kidSeatsAvailableInVehicle),
            carType: data?.carType,
            licensePlate: vehicleLicensePlate,
            refIdSupplier: currentSupplier?.idSupplier,
            manufacturingYear: parseInt(data?.manufacturingYear),
            brand: data?.vehicleBrand.toUpperCase(),
            category: data?.vehicleCategory,
            chasisNo: data?.vehicleChasisNumber,
            color: data?.vehicleColour,
            model: data?.vehicleModel,
            fuelType: data.vehicleFuel?.toUpperCase(),
            vehicleLicensePlateNo: data?.vehicleNumber,
            modes: vehicleModes,
            seats: parseInt(data?.vehicleSeating),
            regCity: data?.vehicleRegistration,
            vehicleType: data?.vehicleClassification.toUpperCase(),
            vehicleLicenseNumber: data?.vehicleLicenseNumber.toString(),
            licenseExpiryDate: data?.licenseExpiryDate,
            vehicleInsuranceValidity: data?.vehicleInsuranceValidity,
            licensePlateFront: vehicleLicensePlateFront,
          },
        },
        refetchQueries: [GetVehiclesAndPartnersDocument, 'getVehiclesAndPartners'],
      });
    } else if (action === 'update') {
      updateVehicle({
        variables: {
          idVehicle: existingVehice?.idVehicle,
          input: {
            kidsSeats: parseInt(data?.kidSeatsAvailableInVehicle),
            carType: data?.carType,
            licensePlate: vehicleLicensePlate,
            refIdSupplier: data.supplierName,
            manufacturingYear: parseInt(data?.manufacturingYear),
            brand: data?.vehicleBrand.toUpperCase(),
            category: data?.vehicleCategory,
            chasisNo: data?.vehicleChasisNumber,
            color: data?.vehicleColour,
            model: data?.vehicleModel,
            fuelType: data.vehicleFuel?.toUpperCase(),
            vehicleLicensePlateNo: data?.vehicleNumber,
            modes: vehicleModes,
            seats: parseInt(data?.vehicleSeating),
            regCity: data?.vehicleRegistration,
            vehicleType: data?.vehicleClassification.toUpperCase(),
            vehicleLicenseNumber: data?.vehicleLicenseNumber.toString(),
            licenseExpiryDate: data?.licenseExpiryDate,
            vehicleInsuranceValidity: data?.vehicleInsuranceValidity,
            licensePlateFront: vehicleLicensePlateFront,
          },
        },
        refetchQueries: [GetVehiclesAndPartnersDocument, 'getVehiclesAndPartners'],
      });
    }
  };

  const currentSpplierColorValid =
    currentSupplier?.name === 'DUBAI TAXI CARPORATION(Red)' || currentSupplier?.name === 'DUBAI TAXI CARPORATION(Pink)';

  const getOptions = (name: string) => {
    if (name === 'Vehicle Brand') return currentSupplier?.type === 'TAXI' ? ['Toyota', 'Hyundai'] : brandsData;
    if (name === 'Vehicle Classification') return [currentSupplier?.type];
    if (name === 'Vehicle Category') return vehicleCategoryData;
    if (name === 'Vehicle Seating Capacity') return range(2, 9);
    if (name === 'Kid Seats Available in Vehicle') {
      const minRange = selectedCategory.toLocaleUpperCase().includes('KIDS') ? 1 : 0;
      const maxRange = selectedSeating >= 7 ? 7 : selectedSeating + 1;
      return selectedSeating ? range(minRange, maxRange) : range(minRange, 7);
    }
    if (name === 'Vehicle Fuel Type') return ['Petrol', 'Diesel', 'Electric', 'Hybrid'];
    if (name === 'Vehicle Model Name') {
      const filteresModalData =
        currentSupplier?.type === 'TAXI' ? ['Toyota Camry', 'Hyundai Sonata', 'Toyota Highlander'] : vehicleModelData;
      return filteresModalData.filter((model: string) => model.toUpperCase().includes(selectedBrand.toUpperCase()));
    }

    if (name === 'Vehicle Registration Emirate') return regCitiesData;
    if (name === 'Vehicle Colour') {
      let taxiColors = [];
      if (currentSpplierColorValid) taxiColors = ['DUBAI TAXI CARPORATION(Red)', 'DUBAI TAXI CARPORATION(Pink)'];
      else taxiColors = [currentSupplier?.name];
      return currentSupplier?.type === 'TAXI' ? taxiColors : vehicleColors.limo;
    }
    if (name === 'Car Type') return vehicleTypeData;
    if (name === 'Supplier Name') return suppliersData;
  };

  const handleMultiSelect = (modes: any) => {
    const modesLabels: any = [];
    modes.forEach((mode: any) => {
      modesLabels.push(mode.value);
    });
    setVehicleModes(modesLabels.join(', '));
  };

  const defaultModes = () => {
    if (existingVehice?.modes) {
      const modes = existingVehice?.modes?.split(', ');
      return modes.map((mode: any, index: number) => ({
        key: index,
        value: mode,
        label: mode,
      }));
    }
  };

  const defaults: any = {
    vehicleClassification: existingVehice?.vehicleType,
    vehicleRegistration: existingVehice?.regCity,
    vehicleSeating: existingVehice?.seats,
    vehicleCategory: existingVehice?.category,
    kidSeatsAvailableInVehicle: existingVehice?.kidsSeats,
    vehicleBrand: existingVehice?.brand,
    carType: existingVehice?.carType,
    vehicleColour: existingVehice?.color,
    vehicleFuel: existingVehice?.fuelType,
    vehicleModel: existingVehice?.model,
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <React.Fragment>
            <div className={classes.formGrid}>
              {vehicleFields.map((field, index) => (
                <InputField
                  key={index}
                  control={control}
                  margin='normal'
                  fullWidth
                  id={field.name}
                  name={field.name}
                  autoComplete={field.name}
                  placeholder={field.placeHolder}
                  variant='filled'
                  InputProps={{ disableUnderline: true }}
                  helperText={
                    field.name == 'vehicleNumber' && vehicleNumberError !== ''
                      ? vehicleNumberError
                      : getError(field.name)
                  }
                  error={getError(field.name) || vehicleNumberError !== '' ? true : false}
                />
              ))}
              <InputField
                control={control}
                type='date'
                id='licenseexpirydate'
                name='licenseExpiryDate'
                label='License Expiry Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                defaultValue={
                  action === 'update'
                    ? moment(existingVehice?.expiryDate).format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
                }
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
                helperText={getError('licenseExpiryDate')}
                error={getError('licenseExpiryDate') ? true : false}
              />
              <InputField
                control={control}
                type='date'
                id='vehicleinsurancevalidity'
                name='vehicleInsuranceValidity'
                label='Vehicle Insurance Validity'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                defaultValue={
                  action === 'update'
                    ? moment(existingVehice?.insuranceExpDate).format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
                }
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
                helperText={getError('vehicleInsuranceValidity')}
                error={getError('vehicleInsuranceValidity') ? true : false}
              />
              <div>
                <p style={{ fontSize: 12, marginBottom: 8 }}>Select Modes</p>
                <ReactSelect
                  options={modeFeatures}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option: VehicleOptions,
                  }}
                  defaultValue={action === 'update' && defaultModes()}
                  onChange={handleMultiSelect}
                />
                {vehicleModes === 'invalid' && (
                  <p style={{ color: 'red', fontSize: 12, marginLeft: 10 }}>Mode is required</p>
                )}
              </div>
              {action === 'update' && !isEmpty(existingSupplier) && (
                <Controller
                  control={control}
                  name='supplierName'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      id='supplierName'
                      size='small'
                      fullWidth
                      popupIcon={<KeyboardArrowDownIcon />}
                      options={suppliersData}
                      getOptionLabel={(option: any) => option?.name}
                      onChange={(event, supplier) => {
                        setRenderDefaultInput(false);
                        onChange(supplier?.idSupplier);
                        setCurrentSupplier(supplier);
                      }}
                      onInputChange={() => {
                        if (renderDefaultInput) {
                          onChange(existingSupplier?.name);
                          setCurrentSupplier(existingSupplier);
                        }
                      }}
                      defaultValue={existingSupplier}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='filled'
                          className={classes.label}
                          InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                          label='Supplier Name'
                          helperText={getError('Supplier Name')}
                          error={getError('Supplier Name') ? true : false}
                        />
                      )}
                    />
                  )}
                />
              )}
              {(action === 'add' || isEmpty(existingSupplier)) && (
                <Controller
                  control={control}
                  name='supplierName'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      id='supplierName'
                      size='small'
                      fullWidth
                      popupIcon={<KeyboardArrowDownIcon />}
                      options={suppliersData}
                      getOptionLabel={(option: any) => option.name}
                      onChange={(event, supplier) => {
                        onChange(supplier?.idSupplier);
                        setCurrentSupplier(supplier);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='filled'
                          className={classes.label}
                          InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                          label='Supplier Name'
                          helperText={getError('Supplier Name')}
                          error={getError('Supplier Name') ? true : false}
                        />
                      )}
                    />
                  )}
                />
              )}

              {vehicleLabels.map((label, index) => (
                <Controller
                  key={index}
                  control={control}
                  name={label?.name}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disablePortal
                      id='select-city-textbox'
                      size='small'
                      fullWidth
                      popupIcon={<KeyboardArrowDownIcon />}
                      options={getOptions(label?.placeHolder)}
                      getOptionLabel={(option: any) => option}
                      defaultValue={
                        action === 'update'
                          ? defaults[label.name]
                          : label.name === 'Vehicle Colour' && currentSpplierColorValid
                          ? 'DUBAI TAXI CARPORATION(Red)'
                          : ''
                      }
                      onInputChange={() => {
                        if (renderDefaultInput) {
                          onChange(defaults[label.name]);
                          if (label.name === 'vehicleSeating') setSelectedSeating(defaults[label.name]);
                          if (label.name === 'vehicleCategory') setSelectedCategory(defaults[label.name]);
                          if (label.name === 'vehicleBrand') setSelectedBrand(defaults[label.name]);
                        }
                      }}
                      onChange={(event, item) => {
                        setRenderDefaultInput(false);
                        onChange(item);
                        if (label.name === 'vehicleSeating') setSelectedSeating(item);
                        if (label.name === 'vehicleCategory') setSelectedCategory(item);
                        if (label.name === 'vehicleBrand') setSelectedBrand(item);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='filled'
                          className={classes.label}
                          InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                          label={label.placeHolder}
                          helperText={getError(label?.placeHolder)}
                          error={getError(label?.placeHolder) ? true : false}
                        />
                      )}
                    />
                  )}
                />
              ))}
              <Controller
                control={control}
                name='manufacturingYear'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disablePortal
                    id='select-city-textbox'
                    size='small'
                    fullWidth
                    popupIcon={<KeyboardArrowDownIcon />}
                    options={yearArray}
                    getOptionLabel={(option: any) => option.toString()}
                    onChange={(event, item) => onChange(item)}
                    onInputChange={(event, item) => onChange(existingVehice?.manufacturingYear)}
                    defaultValue={existingVehice?.manufacturingYear}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='filled'
                        className={classes.label}
                        InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                        label='Vehicle Manufacturing Year'
                        helperText={getError('manufacturingYear')}
                        error={getError('manufacturingYear') ? true : false}
                      />
                    )}
                  />
                )}
              />
              <div>
                <p style={{ fontSize: 14, marginBottom: -5 }}>Upload Vehicle License - Front Image</p>
                <TextField
                  type='file'
                  margin='normal'
                  fullWidth
                  variant='filled'
                  InputProps={{ disableUnderline: true }}
                  onChange={(file) => handleFileInput(file, 'front')}
                  helperText={action !== 'update' && vehicleLicensePlateFront == '' ? fileTypeMessage : ''}
                  error={vehicleLicensePlateFront == '' ? true : false}
                />
                {action === 'update' && existingVehice?.frontImageUrl && (
                  <img
                    onClick={() => window.open(existingVehice?.frontImageUrl)}
                    src={existingVehice?.frontImageUrl}
                    style={{ width: 80, height: 80, cursor: 'pointer' }}
                  />
                )}
              </div>
              <div>
                <p style={{ fontSize: 14, marginBottom: -5 }}>Upload Vehicle License - Back Image</p>
                <TextField
                  type='file'
                  margin='normal'
                  fullWidth
                  variant='filled'
                  InputProps={{ disableUnderline: true }}
                  onChange={(file) => handleFileInput(file, 'back')}
                  helperText={action !== 'update' && vehicleLicensePlate == '' ? fileTypeMessage : ''}
                  error={vehicleLicensePlate == '' ? true : false}
                />
                {action === 'update' && existingVehice?.backImageUrl && (
                  <img
                    onClick={() => window.open(existingVehice?.backImageUrl)}
                    src={existingVehice?.backImageUrl}
                    style={{ width: 80, height: 80, cursor: 'pointer' }}
                  />
                )}
              </div>

              {mainError ? <p style={{ color: 'red', fontSize: 12, marginLeft: 10 }}>{mainError}</p> : <p></p>}
              <p></p>
              <div className={classes.actionBtn}>
                <Button size='medium' type='submit'>
                  Submit
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  label: {
    '& .MuiFormLabel-root': {
      zIndex: 0,
    },
  },
  dateFields: {
    margin: 0,
    borderRadius: '100',
  },
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

export default AddVehicle;
