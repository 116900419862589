import Grid from '@mui/material/Grid';
import DriverCard from './DriverCard';
import { RideDriver } from 'gql/generated/query.graphql';

function DriverCompeletedRideTable({ data }: { data: RideDriver[] }) {
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {data.map((ride: RideDriver) => (
        <Grid item xs={12} sm={8} md={6} key={ride.idJourney}>
          <DriverCard ride={ride} />
        </Grid>
      ))}
    </Grid>
  );
}

export default DriverCompeletedRideTable;
