import { useState, MouseEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { USERPROFILE } from 'routers/config';
import DeleteModal from 'common/deleteModal';
import UpdateModal from './updateAdmin';
import { useDeleteAdminUserMutation, GetAdminUsersListDocument, Admin } from 'gql/generated/query.graphql';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

function TableActionComponent({
  params,
  disabledShowButton = false,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: Admin;
  disabledShowButton?: boolean;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) {
  const classes = useStyles();
  const history = useNavigate();
  const [editOpen, setEditOpen] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [deleteAdminUser] = useDeleteAdminUserMutation();
  const deleteUser = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const goToProfile = (event: MouseEvent) => {
    event.stopPropagation();
    history(USERPROFILE, { state: { userId: params.idAdminUser } });
  };

  const openEditDialog = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const deleteSelectedUser = (userId: string) => {
    // console.log('params',params);
    deleteAdminUser({
      variables: {
        userId,
      },
      onCompleted: () => {
        setOpenDelete(false);
      },
      onError: () => {
        setOpenDelete(false);
      },
      refetchQueries: [GetAdminUsersListDocument, 'getUserList'],
    });
    //on delete user
    setOpenDelete(false);
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='read' a={SUBJECTS.USER_MANAGEMENT.toString()}>
        <IconButton aria-label='show' size='small' onClick={(e) => goToProfile(e)} disabled={disabledShowButton}>
          <VisibilityIcon />
        </IconButton>
      </Can>
      <Can I='update' a={SUBJECTS.USER_MANAGEMENT.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog} disabled={disabledEditButton}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.USER_MANAGEMENT.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteUser(e)} disabled={disabledDeleteButton}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <DeleteModal
        open={isOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onSubmit={() => deleteSelectedUser(String(params.idAdminUser))}
      />
      <UpdateModal open={editOpen} handleClose={handleEditClose} title={'Edit User'} paramsData={params} />
    </div>
  );
}

export default TableActionComponent;
