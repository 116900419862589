import { Box } from "@mui/system";
import { GridRenderCellParams } from "@mui/x-data-grid";
import TableActionComponent from "./TableActionComponent";

const Image = ({imageUrl}: any) =>{
  return (
    <img
      onClick={() => window.open(imageUrl)}
      src={imageUrl}
      style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
      alt='Profile image'
      loading='lazy'
    />
  );
};

export const TableFields = (classes: any) => [
  {
    field: 'idSupplier',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Box className={classes.idGrid}>
        <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
        <Box>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</Box>
      </Box>
    ),
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 300,
    renderCell: (params: GridRenderCellParams<string>) => {
      return <div>{params.row?.name || `${params.row?.taxiSupplierColor?.type}(${params.row?.taxiSupplierColor?.color})`}</div>;
    },
  },
  {
    field: 'license',
    headerName: 'License',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    valueGetter: (params: any) => params.row?.identityDocuments[0]?.frontImageUrl,
    renderCell: (params: any) => <Image imageUrl={params.row?.identityDocuments[0]?.frontImageUrl} />
  },
  {
    field: 'contract',
    headerName: 'Contract',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    valueGetter: (params: any) => params.row?.identityDocuments[1]?.frontImageUrl,
    renderCell: (params: any) => <Image imageUrl={params.row?.identityDocuments[1]?.frontImageUrl} />
  },
  {
    field: 'cheque',
    headerName: 'Cheque',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    valueGetter: (params: any) => params.row?.identityDocuments[2]?.frontImageUrl,
    renderCell: (params: any) => <Image imageUrl={params.row?.identityDocuments[2]?.frontImageUrl} />
  },
  {
    field: 'emirateFront',
    headerName: 'Emirate ID Front',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    valueGetter: (params: any) => params.row?.identityDocuments[3]?.frontImageUrl,
    renderCell: (params: any) => <Image imageUrl={params.row?.identityDocuments[3]?.frontImageUrl} />
  },
  {
    field: 'emirateBack',
    headerName: 'Emirate ID Back',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    valueGetter: (params: any) => params.row?.identityDocuments[3]?.backImageUrl,
    renderCell: (params: any) => <Image imageUrl={params.row?.identityDocuments[3]?.backImageUrl} />
  },
  {
    field: 'mobileNumber',
    headerName: 'Phone',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
  },
  {
    field: 'IBAN',
    headerName: 'IBAN',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<string>) => {
      return <div>{params.row?.bankDetails[0]?.IBAN}</div>;
    },
  },
  {
    field: 'Account No.',
    headerName: 'Account No.',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<string>) => {
      return <div>{params.row?.bankDetails[0]?.accName}</div>;
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    cellClassName: 'super-app-theme--cell',
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<string>) => (
      <TableActionComponent params={params.row} />
    ),
    sortable: false,
  },
];