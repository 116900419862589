import { ThemeProvider } from '@emotion/react';
import MuiTheme from 'common/theme';
import Toast from 'common/toast';
import { BrowserRouter } from 'react-router-dom';
import Routers from 'routers';

function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Toast />
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
