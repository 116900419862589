import { ListItem, Typography, ListItemText, FormControlLabel } from '@mui/material';
import CardWithEditDelete from 'common/card/cardWithEditDelete';
import DeleteMOdal from 'common/deleteModal';
import RoundSwitch from 'common/forms/RoundSwitch';
import { AddArea } from 'components/area-management';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { areaEditInput } from '../add-area/types';
import { ControlProps } from './types';

const MapControl: React.FC<ControlProps> = ({ area, updateArea, isEnabled, setIsEnabled }: ControlProps) => {
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [isOpenEdit, setOpenEdit] = useState(false);

  const updateData = (data: areaEditInput | null) => {
    setOpenEdit(false);
    let dataVal = {
      areaName: data?.areaName,
      areaTypeRefId: data?.areaTypeRefId,
      geom: data?.geom ?? area?.geometry?.geom,
    };
    updateArea('edit', area?.idArea, dataVal);
  };

  const deleteArea = (id: number) => {
    setOpenDelete(false);
    updateArea('delete', id, area);
  };

  if (isEmpty(area)) return <></>;
  
  return (
    <CardWithEditDelete
      title={
        <Typography fontWeight={700} variant='h5' noWrap>
          {area?.areaName}
        </Typography>
      }
      onEdit={() => {
        setOpenEdit(true);
      }}
      onDelete={() => {
        setOpenDelete(true);
      }}
    >
      {isOpenEdit && (
        <AddArea
          isOpen={isOpenEdit}
          title='Edit Area'
          type='viewEdit'
          data={area}
          onSubmit={updateData}
          onCancel={() => setOpenEdit(false)}
        />
      )}
      <DeleteMOdal
        open={isOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onSubmit={() => deleteArea(area?.idArea)}
      />
      <ListItem>
        <ListItemText sx={{ flex: '1.5 1 auto' }} primary='Area Type' secondary={area?.refIdAreaType?.areaTypeName} />
        <ListItemText primary='latitude and longitude' secondary='131.12, 12324.4' />
      </ListItem>
      <ListItem sx={{ pb: 0 }}>
        <FormControlLabel
          value={isEnabled ? 'true' : 'false'}
          sx={{
            m: 0,
            alignItems: 'flex-start',
            '& .MuiSwitch-root': {
              marginLeft: 0,
            },
          }}
          control={<RoundSwitch enabled={isEnabled} areaId={area?.idArea} setIsEnabled={setIsEnabled} />}
          label='Disable/Enable'
          labelPlacement='top'
        />
      </ListItem>
    </CardWithEditDelete>
  );
};

export default MapControl;
