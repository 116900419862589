import React, { useEffect, useState } from 'react';
import SearchHeader from 'common/search-header';
import SearchDriver from './SearchDriver';
import { makeStyles } from '@mui/styles';
import { Button, Fab, Typography, Container } from '@mui/material';
import InputField from 'common/forms/InputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { SUBJECTS } from 'common/casl/ability';
import dayjs from 'dayjs';
import SuccessLeaveModal, { FailedLeaveModal } from './SuccessLeaveModal';
import {
  useAddDriverLeaveMutation,
  DriverLeaveInput,
  SomeArray,
  AddDriverLeave,
  UpdateDriverLeave
} from 'gql/generated/query.graphql';

import { useMutation } from '@apollo/client';
import { DRIVER_LEAVE } from 'routers/config';

const DRIVER_LEAVE_TITLE: string = 'Driver Leave Management';
const ONE_TIME_LEAVE: string = 'One Time Unavailability';
const REPEAT_LEAVE: string = 'Repeat Unavailability';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
  rideGrid: {
    height: 'calc(100vh - 130px)',
    position: 'relative',
  },
  createBookingCard: {
    height: 480,
    width: 600,
    position: 'absolute',
    margin: 20,
    padding: 20,
    top: '0',
    left: '0',
  },
  bookFormGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 20,
    marginTop: 10,
  },
  rideButton: {
    marginTop: 10,
    marginLeft: '40%',
    alignSelf: 'center',
    paddingBottom: 20
  },
  roundTripGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 50px',
    alignItems: 'center',
  },
  roundTripText: {
    justifySelf: 'end',
  },
  backColor: {
    backgroundColor: '#D9D9D9',
  },
  buttondiv: {
    marginLeft: '31.5%',
  },
  buttondiv1: {
    fontSize: 18,
    fontFamily: 'Roboto',
  },
  buttontText: {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  marginleft: {
    marginLeft: 1,
    paddingBottom: 15
  },
  toggleContainer: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: 104,
    height: 32,
    border: '1px solid #8F8F8F',
    borderRadius: 58,
    display: 'flex'
  },
  toggleNo: {
    position: 'absolute',
    width: 52,
    height: 28,
    marginLeft: 2,
    marginTop: 1,
    marginBottom: 15,
    borderRadius: 38,
  },
  toggleYes: {
    position: 'absolute',
    width: 50,
    height: 28,
    marginLeft: 50,
    marginTop: 1,
    paddingBottom: 1,
    borderRadius: 38,
  },
  toggleText: {
    width: 16,
    height: 15,
    fontFamily: 'Roboto',
    marginTop: 5,
    fontWeight: 400,
    fontSize: 12,
    color: '#FFFFFF',
    marginLeft: 17,
  },
  topContainer: {
    display: 'flex',
    marginTop: 20
  },
  subContainer: {
    marginLeft: '22%',
    width: '8%'
  },
  daysText: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#000000'
  },
  inputFieldStyle: {
    display: 'flex',
    marginTop: 32
  },
  flexProperty: {
    display: 'flex'
  },
  statusDiv: {
    display: 'flex',
    marginTop: 30
  },
  switchContainer: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 20
  },
  switchContainerWidth: {
    width: '55%',
  },
  switchText: {
    paddingLeft: '40%',
    marginTop: 10,
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  switchDiv: {
    paddingRight: '34%',
    marginTop: 5,
    width: '30%'
  },
  daytext: {
    marginLeft: '26.5%',
    fontWeight: 'bold'
  },
  timeDiv: {
    display: 'flex',
    paddingBottom: 55
  }
});

const dayAbbreviations: { [key: string]: string } = {
  SUN: 'SUNDAY',
  MON: 'MONDAY',
  TUE: 'TUESDAY',
  WED: 'WEDNESDAY',
  THU: 'THURSDAY',
  FRI: 'FRIDAY',
  SAT: 'SATURDAY',
};

interface Day {
  days: string;
  idUnavailabilityDays: string;
  selected: boolean;
  startTime: string;
  endTime: string;
}

const schema = Yup.object().shape({
  pickDateFrom: Yup.date().optional(),
  pickDateTo: Yup.date().optional(),
  pickStartTime: Yup.date().optional(),
  pickEndTime: Yup.date().optional()
});

const searchListKeys = [
  {
    value: 'name',
    label: 'Name',
  }
];

interface LocationStateType {
  state: {
    params: any;
    edit: boolean;
  };
}

const formatRepeatLeaveTime = (inputTime: string) => {
  const dateVar = moment(new Date()).format('YYYY-MM-DD');
  const formatInputTime = moment(dateVar + inputTime, 'YYYY-MM-DD HH:mm').format();
  const momentDate = moment(formatInputTime);
  const timeWithoutOffset = momentDate.format('HH:mm');

  const offsetString = momentDate.format('Z');
  const offsetHours = parseInt(offsetString.split(':')[0]);
  const offsetMinutes = parseInt(offsetString.split(':')[1]);
  const timeOffsetMinutes = offsetHours * 60 + offsetMinutes;

  const newTime = moment(timeWithoutOffset, 'HH:mm').add(timeOffsetMinutes, 'minutes');

  const formattedTime = newTime.format('HH:mm');
  return formattedTime;
};

function DriverLeave() {
  const {
    state: { params, edit },
  } = useLocation() as unknown as LocationStateType;
  const classes = useStyles();
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [check, setcheck] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [repeat, setRepeat] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalFail, setOpenModalfail] = useState(false);
  const [data, setData] = useState('');
  const [driverSelected, setDriverSelected] = useState(false);
  const [driverEmail, setDriverEmail] = useState<string | undefined>();
  const [startDate, setStartDate] =  useState(
    edit
      ? moment(params?.startDate, 'ddd, Do MMM YYYY').format('YYYY-MM-DD')
      : moment(params?.pickupTime).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    edit
      ? moment(params?.endDate, 'ddd, Do MMM YYYY').format('YYYY-MM-DD')
      : moment(params?.pickupTime).format('YYYY-MM-DD')
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [startTime, setStartTime] = useState(
    edit ? moment(params?.startTime, 'h:mm a').format('HH:mm') : moment(params?.pickupTime).format('HH:mm')
  );
  const [endTime, setEndTime] = useState(
    edit ? moment(params?.endTime, 'h:mm a').format('HH:mm') : moment(params?.pickupTime).format('HH:mm')
  );
  const [searchInput, setSearchInput] = React.useState({ name: '' });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [days, setDays] = useState([
    {
      id: 'SUN',
      name: 'Sunday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'MON',
      name: 'Monday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'TUE',
      name: 'Tuesday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'WED',
      name: 'Wednesday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'THU',
      name: 'Thursday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'FRI',
      name: 'Friday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      id: 'SAT',
      name: 'Saturday',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
  ]);

  const [payload, setPayload] = useState([
    {
      day: 'SUNDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'MONDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'TUESDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'WEDNESDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'THURSDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'FRIDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
    {
      day: 'SATURDAY',
      selected: false,
      startTime: startTime,
      endTime: endTime
    },
  ]);

  const handleOpenn = () => {
    history(DRIVER_LEAVE, { state: { params: params } });
  };

  function getSelectedDays(start: string, end: string): string[] {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const days = [];
    while (startDate <= endDate) {
      days.push(daysOfWeek[startDate.getDay()]);
      startDate.setDate(startDate.getDate() + 1);
    }
    return days;
  }

  useEffect(() => {
  }, [isOpen, data]);

  const openSuccessModal = () => {
    setOpenModal(true);
    setTimeout(() => {
      handleOpenn();
    }, 5000);
  };

  const openFailModal = () => {
    setOpenModalfail(true);
  };


  const handleData = (driversData: any) => {
    setDriverEmail(driversData);
    setData(driversData);
  };

  const closeSuccessModal = () => {
    setOpenModal(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSwitch = () => {
    setIsOpen(prevState => !prevState);
    check ? setcheck(false) : setcheck(true);
  };

  const driverSelection = () => {
    setDriverSelected(true);
  };

  const handleDay = (index: number, dayId: string) => {
    const temp = getSelectedDays(startDate, endDate);
    const selectedDay = temp.find(day => day === dayId);
    if (selectedDay) {
      const copyDays = [...days];
      copyDays[index].selected = !copyDays[index].selected;
      setDays(copyDays);
    } else {
      openFailModal();
    }
  };

  useEffect(() => {
    if (params?.driverLeaveScheduleDays.length > 0) setRepeat(true);

    if (edit && params?.driverLeaveScheduleDays.length > 0) {
      const weekDays = [...days];
      const selectedDays = weekDays.map(day => {
        const matchedDay = params?.driverLeaveScheduleDays.find(
          (matchDay: Day) => dayAbbreviations[day.id] === matchDay.days.toUpperCase()
        );

        if (matchedDay) {
          day.selected = true;
          day.startTime = formatRepeatLeaveTime(matchedDay.startTime);
          day.endTime = formatRepeatLeaveTime(matchedDay.endTime);
        }
        return day;
      });
      if (params?.availabilityStatus) {
        setcheck(params?.availabilityStatus);
        setIsOpen(params?.availabilityStatus);
      }
    }

    const updatedPayload = payload.map(item => {
      const matchedLeave = params?.driverLeaveScheduleDays.find((leaveDay: Day) => leaveDay.days === item.day);

      if (matchedLeave) {
        return {
          ...item,
          selected: true,
          startTime: matchedLeave.startTime,
          endTime: matchedLeave.endTime,
        };
      } else {
        return item;
      }
    });
    setPayload(updatedPayload);

    if (edit) {
      setDriverEmail(params.emailId);
    }
  }, [params]);

  const handlestartTime = (index: number, event: any) => {
    const copyDays = [...days];
    copyDays[index].startTime = event.target.value;
    setDays(copyDays);
  };

  const handleendTime = (index: number, event: any) => {
    const copyDays = [...days];
    copyDays[index].endTime = event.target.value;
    setDays(copyDays);
  };

  const extractDateTime = (date: string, time: string) => {
    const convert12To24 = moment(time, 'h:mm').format('HH:mm');
    const timeVar = convert12To24;
    const darteVar = moment(date).format('YYYY-MM-DD');
    return moment(darteVar + timeVar, 'YYYY-MM-DD HH:mm').format();
  };

  const { refIdModes: refIdModesErrorMsg, pickTime: pickTimeErrorMsg, pickDate: pickDateErrorMsg } = errors;

  const input = {
    emailId: driverEmail,
    startDate: extractDateTime(startDate, startTime),
    endDate: extractDateTime(endDate, endTime),
    availabilityStatus: check,
    driverLeavesTime: payload
  };

  const [addDriverLeave, { data: successData, loading, error }] = useMutation(AddDriverLeave);
  const [updateDriverLeave] = useMutation(UpdateDriverLeave);

  const onSave = (values: any) => {
    if (driverEmail) {
      if (endDate > startDate || startDate == endDate && endTime > startTime && startTime >= moment(params?.pickupTime).locale('en').format('HH:mm') && !repeat || startDate == endDate && startDate > moment(params?.pickupTime).format('YYYY-MM-DD') && endTime > startTime || repeat && startDate < endDate) {
        if (repeat) {
          const copyDays = [...payload];
          copyDays.map((temp, index) => {
            copyDays[index].endTime = extractDateTime(endDate, days[index].endTime);
            copyDays[index].startTime = extractDateTime(startDate, days[index].startTime);
            copyDays[index].selected = days[index].selected;
          });
          setPayload(copyDays);
        } else {
          const copyDays = [...payload];
          copyDays.map((temp, index) => {
            copyDays[index].endTime = extractDateTime(endDate, endTime);
            copyDays[index].startTime = extractDateTime(startDate, startTime);
            copyDays[index].selected = days[index].selected;
          });
          setPayload(copyDays);
        }
        edit ? updateDriverLeave({ variables: { input } }) : addDriverLeave({ variables: { input } });
        !error && openSuccessModal();
      } else {
        setErrorMessage('Please Select Correct Date and time !');
        openFailModal();
      }
    } else {
      setErrorMessage('Please Select Driver First !');
      openFailModal();
    }

  };

  return (
    <div className={classes.tableGrid}>
      {!edit &&
        <SearchHeader
          title={DRIVER_LEAVE_TITLE}
          showButton={false}
          handleOpen={handleOpen}
          setSearchInput={setSearchInput}
          searchListKeys={searchListKeys}
          subject={SUBJECTS.CONFIGURATION.toString()}
        />
      }
      {
        <div onClick={driverSelection}>
          {!edit && searchInput?.name?.length > 0 && <SearchDriver searchInput={searchInput} onData={handleData} />}
        </div>
      }

      <div className={classes.backColor}>
        <div className={classes.statusDiv}>
          <div className={classes.buttondiv}>
            <Button
              variant='contained'
              style={{
                backgroundColor: repeat ? '#D9D9D9' : '',
                border: '0.5px solid #8F8F8F',
                borderRadius: '5px 0px 0px 5px',
              }}
              onClick={() => {
                setRepeat(false);
                setcheck(false);
              }}
            >
              <Typography
                className={classes.buttontText}
                style={{ color: repeat ? '#8F8F8F' : '#FFFFFF', textTransform: 'none', fontWeight: repeat ? 500 : '' }}
              >
                {ONE_TIME_LEAVE}
              </Typography>
            </Button>
          </div>
          <div className={classes.buttondiv1}>
            <Button
              variant='contained'
              style={{
                backgroundColor: repeat ? '#8F8F8F' : '#D9D9D9',
                border: '0.5px solid #8F8F8F',
                borderRadius: '0px 5px 5px 0px',
              }}
              onClick={() => setRepeat(true)}
            >
              <Typography
                className={classes.buttontText}
                style={{ color: repeat ? '#FFFFFF' : '#8F8F8F', textTransform: 'none', fontWeight: repeat ? 500 : '' }}
              >
                {REPEAT_LEAVE}
              </Typography>
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSave)}>
          <div style={{
            display: 'flex',
            marginTop: 32
          }}>
            <InputField
              control={control}
              type='date'
              id='pickDateFrom'
              name='pickDateFrom'
              InputLabelProps={{ shrink: true }}
              label='From - Start Date'
              margin='normal'
              variant='filled'
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
              style={{ width: 300, marginLeft: '26%', backgroundColor: '#EFEFEF', borderRadius: 6 }}
              InputProps={{ disableUnderline: true }}
              error={pickDateErrorMsg ? true : false}
            />
            <InputField
              control={control}
              type='date'
              id='pickDateTo'
              name='pickDateTo'
              label='To - End Date'
              margin='normal'
              style={{ width: 300, marginLeft: 16, backgroundColor: '#EFEFEF', borderRadius: 6 }}
              variant='filled'
              value={endDate}
              onChange={(event) => {
                event.target.value >= startDate && setEndDate(event.target.value);
              }}
              InputProps={{ disableUnderline: true }}
              defaultValue={startDate}
              InputLabelProps={{ shrink: true }}
              helperText={pickDateErrorMsg?.message}
              error={pickDateErrorMsg ? true : false}
            />
          </div>
          {repeat ? (
            <Container>
              <Container style={{ display: 'flex', marginTop: 20 }}>
                <Container style={{ marginLeft: '22%', width: '8%' }}>
                  <Typography style={{
                    marginTop: 10,
                    fontWeight: 400,
                    fontSize: 18,
                    fontFamily: 'Roboto',
                    color: '#000000'
                  }}>Days</Typography>
                </Container>
                <Container style={{ display: 'flex' }}>
                  {days.map((day, index) => (
                    <WeekDays
                      key={day.id}
                      Name={day.id}
                      selected={day.selected}
                      handler={() => handleDay(index, day.name)}
                    />
                  ))}
                </Container>
              </Container>
              <Container style={{
                display: 'flex',
                marginTop: 30,
                marginBottom: 20
              }}>
                <Container style={{ width: '55%' }}>
                  <Typography style={{
                    paddingLeft: '40%',
                    marginTop: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    fontFamily: 'Roboto'
                  }}>Different unavailability times each days?</Typography>
                </Container>
                <Container style={{
                  paddingRight: '34%',
                  marginTop: 5,
                  width: '30%'
                }}>
                  <div style={{
                    boxSizing: 'border-box',
                    position: 'absolute',
                    width: 104,
                    height: 32,
                    border: '1px solid #8F8F8F',
                    borderRadius: 58,
                    display: 'flex'
                  }}>
                    <div
                      style={{
                        backgroundColor: isOpen ? '#D9D9D9' : '#8F8F8F', position: 'absolute',
                        width: 52,
                        height: 28,
                        marginLeft: 2,
                        marginTop: 1,
                        marginBottom: 15,
                        borderRadius: 38,
                      }}
                      onClick={handleSwitch}
                    >
                      <Typography style={{
                        width: 16,
                        height: 15,
                        fontFamily: 'Roboto',
                        marginTop: 5,
                        fontWeight: 400,
                        fontSize: 12,
                        color: '#FFFFFF',
                        marginLeft: 17,
                      }}>No</Typography>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        width: 50,
                        height: 28,
                        marginLeft: 50,
                        marginTop: 1,
                        paddingBottom: 1,
                        borderRadius: 38, backgroundColor: isOpen ? '#8F8F8F' : ''
                      }}
                      onClick={handleSwitch}
                    >
                      <Typography style={{
                        width: 16,
                        height: 15,
                        fontFamily: 'Roboto',
                        marginTop: 5,
                        fontWeight: 400,
                        fontSize: 12,
                        color: '#FFFFFF',
                        marginLeft: 17,
                      }}>Yes</Typography>
                    </div>
                  </div>
                </Container>
              </Container>
            </Container>
          ) : (
            ''
          )}
          {check ? (
            days.map(
              (day, index) =>
                day.selected && (
                  <div key={day.id}>
                    <Typography style={{
                      marginLeft: '26.5%',
                      fontWeight: 'bold'
                    }}>{day.name}</Typography>
                    <div className={classes.flexProperty}>
                      <InputField
                        control={control}
                        type='time'
                        id='pickTime'
                        name='pickTime'
                        label='Start Time'
                        margin='normal'
                        style={{ width: 300, marginLeft: '26%', backgroundColor: '#EFEFEF', borderRadius: 6 }}
                        variant='filled'
                        value={day.startTime}
                        onChange={(event) => handlestartTime(index, event)}
                        InputProps={{ disableUnderline: true }}
                        defaultValue={moment(params?.pickupTime).format('HH:mm')}
                        helperText={pickTimeErrorMsg?.message}
                        error={pickTimeErrorMsg ? true : false}
                      />
                      <InputField
                        control={control}
                        type='time'
                        id='pickTime'
                        name='pickTime'
                        label='End Time'
                        margin='normal'
                        style={{ width: 300, marginLeft: 16, backgroundColor: '#EFEFEF', borderRadius: 6 }}
                        variant='filled'
                        value={day.endTime}
                        onChange={(event) => handleendTime(index, event)}
                        InputProps={{ disableUnderline: true }}
                        defaultValue={moment(params?.pickupTime).format('HH:mm')}
                        helperText={pickTimeErrorMsg?.message}
                        error={pickTimeErrorMsg ? true : false}
                      />
                    </div>
                  </div>
                )
            )
          ) : (
            <div style={{
              display: 'flex',
              paddingBottom: 55
            }}>
              <InputField
                control={control}
                type='time'
                id='pickStartTime'
                name='pickStartTime'
                label='Start Time'
                margin='normal'
                style={{ width: 300, marginLeft: '26%', backgroundColor: '#EFEFEF', borderRadius: 6 }}
                variant='filled'
                value={startTime}
                onChange={(event) => setStartTime(event.target.value)}
                InputProps={{ disableUnderline: true }}
                defaultValue={moment(params?.pickupTime).format('HH:mm')}
                helperText={pickTimeErrorMsg?.message}
                error={pickTimeErrorMsg ? true : false}
              />
              <InputField
                control={control}
                type='time'
                id='pickEndTime'
                name='pickEndTime'
                label='End Time'
                margin='normal'
                style={{ width: 300, marginLeft: 16, backgroundColor: '#EFEFEF', borderRadius: 6 }}
                variant='filled'
                value={endTime}
                onChange={(event) => setEndTime(event.target.value)}
                InputProps={{ disableUnderline: true }}
                defaultValue={moment(params?.pickupTime).format('HH:mm')}
                helperText={pickTimeErrorMsg?.message}
                error={pickTimeErrorMsg ? true : false}
              />
            </div>
          )}
          {repeat && (
            <div className={classes.rideButton}>
              <Button
                variant='contained'
                type='submit'
                onClick={onSave}
                style={{ width: 232, textTransform: 'none' }}
              >
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
      {!repeat && (
        <div className={classes.rideButton}>
          <Button variant='contained' type='submit' onClick={onSave} style={{ width: 232, textTransform: 'none' }}>
            Submit
          </Button>
        </div>
      )}
      <SuccessLeaveModal open={openModal} onClose={closeSuccessModal} status={repeat} id={driverEmail} />
      <FailedLeaveModal open={openModalFail} onClose={() => setOpenModalfail(false)} message={errorMessage} />
    </div>
  );
}

export default DriverLeave;

const WeekDays = (props: any) => {
  return (
    <div style={{ width: '10%' }}>
      <Fab
        style={{ width: 36, height: 32, backgroundColor: props.selected ? '' : '#D9D9D9' }}
        onClick={props.handler}
      />
      <Typography style={{ marginLeft: 2 }}>{props.Name}</Typography>
    </div>
  );
};

