import { GRAY_5C, GRAY_86, GRAY_8A, WHITE } from '../colors';

const muiFabButton = {
  MuiFab: {
    styleOverrides: {
      root: {
        background: GRAY_5C,
        color: WHITE,
        '&:hover': {
          background: GRAY_8A,
        },
      },
    },
  },
};

export default muiFabButton;
