import * as React from 'react';
import { Box } from '@mui/material';
import useStyles from './styled';
import { DeleteModalProps } from './types';
import ModalDialog from 'common/modalDialog';

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onCancel,
  onSubmit,
  massage = 'Are you sure want to delete this record?',
}: DeleteModalProps) => {
  const { imgWrapper } = useStyles();
  return (
    <ModalDialog open={open} onClose={onCancel} onSubmit={onSubmit} submitName={'Delete'} isDeleteModal={true}>
      <Box className={imgWrapper}>
        <img src='/images/exclamationMark.png' alt='' />
      </Box>
      <div className=''>{massage}</div>
    </ModalDialog>
  );
};

export default DeleteModal;
