import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import OtpInput from 'react-otp-input-rc-17';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'common/theme/colors';
import PreLoginLayout from 'common/layouts/preLoginLayout';
import { useLoginWithAdminMobileNumberMutation } from 'gql/generated/query.graphql';
import { useLocation, useNavigate } from 'react-router-dom';
import { NEW_PASSWORD } from 'routers/config';

const useStyles = makeStyles({
  boxWrapperStyle: {
    display: 'flex',
    justifyContent: 'center',
    '& .otp-input input': {
      border: '0px solid rgba(209, 213, 219, 1)',
      background: WHITE,
      width: '50px !important',
      height: '50px',
      margin: '10px',
      borderRadius: '5px',
    },
  },
});

interface LocationStateType {
  state: {
    sid: string;
    mobileNumber: string;
  };
}

function CodeVerification() {
  const [otp, setOtp] = useState('');
  const history = useNavigate();
  const { boxWrapperStyle } = useStyles();
  const {
    state: { sid, mobileNumber },
  } = useLocation() as unknown as LocationStateType;

  const [loginWithAdminMobileNumberMutation] = useLoginWithAdminMobileNumberMutation({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
    onCompleted(data) {
      localStorage.setItem('token', data?.loginWithAdminMobileNumber?.access_token);
      localStorage.setItem('admin', JSON.stringify(data?.loginWithAdminMobileNumber?.user));
      history(NEW_PASSWORD);
    },
  });

  const verifyOTP = () => {
    loginWithAdminMobileNumberMutation({
      variables: {
        loginWithMobileNumberInput: {
          mobileNumber,
          otp: btoa(otp),
          sid,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <PreLoginLayout title='Code Verification' desc='Enter the 4 digit code sent to your mobile number'>
        <Box className={boxWrapperStyle}>
          <OtpInput
            value={otp}
            onChange={(otp: any) => setOtp(otp)}
            numInputs={4}
            separator={<span></span>}
            className='otp-input'
          />
        </Box>

        <Button type='button' fullWidth variant='contained' sx={{ mt: 4 }} onClick={verifyOTP}>
          Submit
        </Button>
      </PreLoginLayout>
    </React.Fragment>
  );
}

export default CodeVerification;
