import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Rating from '@mui/material/Rating';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import TableActionComponent from 'components/customer-management/TableActionComponent';
import { useGetCustomersAdminLazyQuery } from 'gql/generated/query.graphql';
import moment from 'moment';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
  },
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

function CustomerListTable({ searchInput }: { searchInput: any }) {
  const classes = useStyles();
  const [customerData, setCustomerData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0 as any);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [getCustomersAdmin, { data, loading, error }] = useGetCustomersAdminLazyQuery({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
  });

  useEffect(() => {
    getCustomersAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!loading && data && data.getCustomersAdmin) {
      const arrData = data?.getCustomersAdmin.results.map((element) => ({
        ...element,
        createdDate: moment(element['createdDate']).format('YYYY-MM-DD'),
        fullName: element['firstName']?.trim() + ' ' + element['lastName']?.trim(),
        id: element['idCustomerUser'],
      }));
      setCustomerData(arrData);
      setTotalRows(data?.getCustomersAdmin.totalRows);
    }
  }, [data]);

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'mobileNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'createdDate',
      headerName: 'Created at',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row.rating,
      renderCell: (params: GridRenderCellParams<number>) => {
        return (
          <div className={classes.ratingGrid}>
            <div>{params.row.rating || ''}</div>
            <Rating
              value={params.row.rating || 0}
              size='small'
              readOnly
              precision={0.5}
              className={classes.ratingStyle}
            />
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TableActionComponent params={params.row} disabledEditButton disabledDeleteButton />
      ),
      sortable: false,
    },
  ];

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={customerData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
      />
    </Box>
  );
}

export default CustomerListTable;
