import { useRef, useState } from 'react';
import { Box, Button, MenuItem } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalArea, IFormInput, schema } from './types';
import ModalDialog from 'common/modalDialog';
import InputField from 'common/forms/InputField';
import useStyles from './styled';
import Map from 'common/map/map';
import { DARK_GREY, LIGHT_GREY, WHITE } from 'common/theme/colors';
import { useGetAreaTypeQuery } from 'gql/generated/query.graphql';
import { toastMessage } from 'gql/cache';

const AddArea: React.FC<ModalArea> = ({
  isOpen,
  title = 'Add New Area',
  data: newdata,
  type = 'add',
  onCancel,
  onSubmit,
}: ModalArea) => {
  const { controlContent, controlGroup, actionBtn } = useStyles();
  const [mapData, setMapData] = useState<object | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, loading, error } = useGetAreaTypeQuery({
    variables: {},
  });

  const { areaName: areaNameError, areaTypeRefId: areaTypeRefIdError } = errors || {};

  const polygonDataFn = (coordinates: any) => {
    setMapData(coordinates);
  };

  const onSubmitData = (inputData: any) => {
    const formData = {
      areaName: inputData.areaName,
      areaTypeRefId: Number(inputData.areaTypeRefId),
      geom: mapData ? JSON.stringify(mapData).replace(/\\"/g, '"') : null,
    };

    if (type === 'add' && !formData.geom)
      return toastMessage({ type: 'error', message: 'Please mark the polygon on the map.' });

    onSubmit(formData);
  };

  if (loading) {
    return null;
  }

  return (
    <ModalDialog open={isOpen} cancelName={null} submitName={null} title={title} onSubmit={() => {}} onClose={onCancel}>
      <Box className={controlContent}>
        <Box component={'form'} onSubmit={handleSubmit(onSubmitData)}>
          <InputField
            control={control}
            defaultValue={newdata?.areaName}
            placeholder='Area Name'
            name='areaName'
           
            helperText={areaNameError?.message}
            error={areaNameError ? true : false}
          />

          <Box className={controlGroup}>
            <InputField
              control={control}
              select
              defaultValue={newdata?.refIdAreaType?.idAreaType}
              placeholder='Area Type'
              name='areaTypeRefId'
              InputProps={{
                disableUnderline: true,
              }}
              helperText={areaTypeRefIdError?.message}
              error={areaTypeRefIdError ? true : false}
              sx={{ mr: 1, display: 'flex', flex: '1' }}
            >
              {data?.getAreaType?.map((row: any) => (
                <MenuItem value={row['idAreaType']} key={row['idAreaType']}>
                  {row['areaTypeName']}
                </MenuItem>
              ))}
            </InputField>

            {/* <Button
              variant='outlined'
              size='small'
              onClick={() => mapFileRef.current?.click()}
              sx={{ flex: 1, color: 'currentColor', background: '#f0f0f0', minHeight: '51px' }}
            >
              Upload Google File
              <InputField
                placeholder='Area Map'
                control={control}
                name='geom'
                sx={{ display: 'none' }}
                type='file'
                inputRef={mapFileRef}
              />
            </Button> */}
            <Box sx={{ flex: 1, color: 'currentColor', minHeight: '51px' }}></Box>
          </Box>
          <Map
            type={type}
            polygonDataFn={polygonDataFn}
            currArea={newdata}
            style={{ height: '250px', width: '100%' }}
          />
          <div className={actionBtn}>
            <Button sx={{ color: WHITE }} type='submit'>
              {type == 'add' ? 'Save' : 'Update'}
            </Button>
            <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default AddArea;
