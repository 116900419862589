import { Button, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useForm } from 'react-hook-form';

import { schema, Params, FieldValues } from './helpers';
import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import { GetWaitingListAdminDocument, useUpdateWaitingListMutation } from 'gql/generated/query.graphql';
import InputField from 'common/forms/InputField';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },

  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },

  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },

  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
});

const UpdateUser = ({
  open,
  handleClose,
  title,
  params,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  params: Params;
}): React.ReactElement => {
  const classes = useStyles();
  const [updateWaitingList] = useUpdateWaitingListMutation({ onCompleted: () => handleClose() });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { emailId: emailErrorMsg, mobileNumber: phoneErrorMsg } = errors;

  const onSave = (values: any) => {
    const data: any = {
      emailId: params.emailId,
      mobileNumber: params.mobileNumber,
    };

    const difference: FieldValues = Object.keys(values)
      .filter(keys => values[keys] !== data[keys])
      .reduce((object: any, keys) => {
        object[keys] = values[keys];
        return object;
      }, {});

    if (Object.keys(difference).length === 0) return;

    updateWaitingList({
      variables: {
        inputs: {
          idWaitingList: params?.idWaitingList,
          data: difference,
        },
      },
      refetchQueries: [GetWaitingListAdminDocument, 'getWaitingListAdmin'],
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <React.Fragment>
            <div className={classes.formGrid}>
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='emailId'
                placeholder='Email'
                name='emailId'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='email'
                helperText={emailErrorMsg?.message}
                error={emailErrorMsg ? true : false}
                defaultValue={params ? params.emailId : ''}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='mobileNumber'
                placeholder='Phone'
                type='number'
                id='mobileNumber'
                InputProps={{ disableUnderline: true }}
                variant='filled'
                autoComplete='mobile'
                helperText={phoneErrorMsg?.message}
                error={phoneErrorMsg ? true : false}
                defaultValue={params ? params.mobileNumber : ''}
              />
              <div className={classes.actionBtn}>
                <Button size='medium' type='submit'>
                  Save
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    </Dialog>
  );
};

export default UpdateUser;
