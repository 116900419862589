export const JOURNEY_STATUS: {[key: number]: string} = {
    1 : "SCHEDULED",
    2: "DISPATCHED",
    3:"ASSIGNED",
    4: "ON_ROUTE",
    5: "ARRIVED",
    6: "STARTED",
    7: "ENDED",
    8: "PAYMENT_GENERATED",
    9: "PAYMENT_NOT_RECEIVED",
    10: "COMPLETED",
    11: "USER_CANCELLED",
    12 : "ZED_CANCELLED",
    13: "NO_DRIVER_FOUND",
    14 : "REJECTED",
    15 : "NO_SHOW",
    16: "ACCEPTED",
  };