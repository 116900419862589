import { useState, MouseEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteModal from 'common/deleteModal';
import RoleModal from './RoleModal';
import { GetUserRoleListDocument, useDeleteRoleMutation } from 'gql/generated/query.graphql';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

function TableActionComponent({
  params,
  disabledShowButton = false,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: any;
  disabledShowButton?: boolean;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) {
  const classes = useStyles();
  const [editOpen, setEditOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);

  const [deleteRole] = useDeleteRoleMutation();

  const deleteHandler = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const goToSelected = (event: MouseEvent) => {
    event.stopPropagation();
    setSelectOpen(true);
  };

  const openEditDialog = () => {
    setEditOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const deleteSelected = (roleId: number) => {
    console.log('roleId deleteSelected', roleId);
    deleteRole({
      variables: {
        roleId,
      },
      onCompleted: () => {
        setOpenDelete(false);
      },
      onError: () => {
        setOpenDelete(false);
      },
      refetchQueries: [GetUserRoleListDocument, 'getUserRoleList'],
    });
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='read' a={SUBJECTS.USER_ROLE_MANAGEMENT.toString()}>
        <IconButton aria-label='show' size='small' onClick={goToSelected} disabled={disabledShowButton}>
          <VisibilityIcon />
        </IconButton>
      </Can>
      <Can I='update' a={SUBJECTS.USER_ROLE_MANAGEMENT.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog} disabled={disabledEditButton}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.USER_ROLE_MANAGEMENT.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteHandler(e)} disabled={disabledDeleteButton}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <RoleModal
        open={selectOpen}
        handleClose={handleSelectClose}
        title={'View Role'}
        roleObject={params}
        showOnly={true}
      />
      <RoleModal open={editOpen} handleClose={handleEditClose} title={'Edit Role'} roleObject={params} />
      <DeleteModal
        open={isOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onSubmit={() => {
          console.log('params.idAdminUserRoles', params.idAdminUserRoles);
          deleteSelected(Number(params.idAdminUserRoles));
        }}
        massage='Are you sure want to delete this role?'
      />
    </div>
  );
}

export default TableActionComponent;
