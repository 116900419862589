import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';

const useStyles = makeStyles({
  bookingCardGrid: {
    display: 'grid',
    height: 100,
    width: 200,
    gridRowGap: 2,
    alignContent: 'center',
    paddingLeft: 15,
    borderRadius: 6
  },
});

type BookingCardProps = {
  count: Number;
  subTitle: string;
};

function BookingCard({ count, subTitle }: BookingCardProps) {
  const classes = useStyles();
  return (
    <Paper elevation={5} sx={{borderRadius: 2}} className={classes.bookingCardGrid}>
      <Typography fontWeight={600} variant='h4'>
        {count}
      </Typography>
      <Typography fontWeight={500}>
        {subTitle}
      </Typography>
    </Paper>
  );
}

export default BookingCard;
