import * as Yup from 'yup';
import moment from 'moment';

export const supplierPartnerTypesData = [
  { idSupplier: 'LIMO', supplierPartnerType: 'Limousine Company' },
  { idSupplier: 'TAXI', supplierPartnerType: 'Taxi Company' },
];

export const schema = Yup.object().shape({
  supplierPartnerType: Yup.string().required('Supplier Type is required'),
  email: Yup.string().email('Not Valid Email').required('Email is required').max(64),
  phone: Yup.string()
    .required('Phone Number is required')
    .matches(/^(?:971)[0-9]{9}$/, 'Enter a valid phone number (9715xxxxxxxx)'),
  tradeLicense: Yup.string().required('Trade License Number is required'),
  accountName: Yup.string().required('Account Name is required'),
  accountNumber: Yup.string().required('Account Number is required'),
  ibanNumber: Yup.string().required('IBAN Number is required'),
  bankName: Yup.string().required('Bank Name is required'),
  buildingNumber: Yup.string().required('Building Number is required'),
  streetName: Yup.string().required('Street Name is required'),
  areaName: Yup.string().required('Area Name is required'),
  emirate: Yup.string().required('Emirate is required'),
  emirateId: Yup.string()
    .required('Emirate ID Number is required')
    .matches(/^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$/g, 'Enter a valid eimrateId number (e.g 784-1979-1234567-1)'),
});

export const formData = {
  contactDetails: [
    {
      id: 'email',
      placeholder: 'Email',
      name: 'email',
      autoComplete: 'email',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'phone',
      placeholder: '9715x xxx xxxx',
      name: 'phone',
      type: 'tel',
      autoComplete: 'phone',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'tradeLicense',
      placeholder: 'Trade License Number',
      name: 'tradeLicense',
      autoComplete: 'tradeLicense',
      InputProps: { disableUnderline: true },
    },
    {
      type: 'date',
      id: 'startDate',
      name: 'startDate',
      label: 'Supplier Start Date With Zed',
      InputProps: { disableUnderline: true, style: { marginTop: 7 } },
      defaultValue: moment().add(1, 'days').format('YYYY-MM-DD'),
      InputLabelProps: { shrink: true },
      className: 'dateFields',
      required: true,
    },
  ],
  documents: {
    tradeLicenseFile: {
      type: 'file',
      id: 'tradeLicenseimg',
      name: 'tradeLicenseimg',
      InputProps: {
        inputProps: {
          accept: '.jpg,.jpeg,.png,.pdf',
        },
        disableUnderline: true,
      },
      required: true,
    },
    contractFile: {
      type: 'file',
      id: 'contractDocument',
      name: 'contractDocument',
      InputProps: {
        inputProps: {
          accept: '.jpg,.jpeg,.png,.pdf',
        },
        disableUnderline: true,
      },
      required: true,
    },
    chequeFile: {
      type: 'file',
      id: 'cancelledCheque',
      name: 'cancelledCheque',
      InputProps: {
        inputProps: {
          accept: '.jpg,.jpeg,.png,.pdf',
        },
        disableUnderline: true,
      },
      required: true,
    },
    emiratesIdFileFront: {
      type: 'file',
      id: 'emiratesIdFront',
      name: 'emiratesIdFront',
      InputProps: {
        inputProps: {
          accept: '.jpg,.jpeg,.png,.pdf',
        },
        disableUnderline: true,
      },
      required: true,
    },
    emiratesIdFileBack: {
      type: 'file',
      id: 'emiratesIdBack',
      name: 'emiratesIdBack',
      InputProps: {
        inputProps: {
          accept: '.jpg,.jpeg,.png,.pdf',
        },
        disableUnderline: true,
      },
      required: true,
    },
  },
  bankDetails: [
    {
      id: 'accountName',
      placeholder: 'Account Name as Per Bank',
      name: 'accountName',
      autoComplete: 'accountName',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'accountNumber',
      placeholder: 'Account Number',
      name: 'accountNumber',
      autoComplete: 'accountNumber',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'ibanNumber',
      placeholder: 'IBAN Number',
      name: 'ibanNumber',
      autoComplete: 'ibanNumber',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'bankName',
      placeholder: 'Bank Name',
      name: 'bankName',
      autoComplete: 'bankName',
      InputProps: { disableUnderline: true },
    },
  ],
  addressDetails: [
    {
      id: 'buildingNumber',
      placeholder: 'Building/ Flat Number',
      name: 'buildingNumber',
      autoComplete: 'buildingNumber',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'streetName',
      placeholder: 'Street Name',
      name: 'streetName',
      autoComplete: 'streetName',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'areaName',
      placeholder: 'Area Name',
      name: 'areaName',
      autoComplete: 'areaName',
      InputProps: { disableUnderline: true },
    },
    {
      id: 'country',
      placeholder: 'Country',
      name: 'country',
      autoComplete: 'country',
      InputProps: { disableUnderline: true },
      defaultValue: 'UAE',
      disabled: true,
    },
  ],
  emiratesDetails: [
    {
      id: 'emirateId',
      placeholder: 'xxx-xxxx-xxxxxxx-x',
      name: 'emirateId',
      autoComplete: 'emirateId',
      InputProps: { disableUnderline: true },
    },
  ],
};
