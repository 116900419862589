import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import { DARK_GREY, WHITE } from 'common/theme/colors';

import { useUpdateAreaStateMutation } from 'gql/generated/query.graphql';

interface RoundSwitchProps {
  enabled: boolean | undefined;
  areaId: number;
  setIsEnabled: (value: boolean) => void;
}

const Root = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    border: 1px solid ${DARK_GREY};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: ${DARK_GREY};
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${DARK_GREY};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 22px;
      top: 3px;
      background-color: ${WHITE};
    }

    .${switchUnstyledClasses.track} {
      background: ${DARK_GREY};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
);

const RoundSwitch = ({ enabled, areaId, setIsEnabled }: RoundSwitchProps) => {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };

  const [updateAreaStateMutation] = useUpdateAreaStateMutation();

  const handleToggle = async () => {
    await updateAreaStateMutation({
      variables: {
        idArea: areaId,
      },
    });
    setIsEnabled(!enabled);
  };

  return (
    <SwitchUnstyled
      component={Root}
      {...label}
      className={enabled ? switchUnstyledClasses.checked : ''}
      checked={enabled}
      onChange={handleToggle}
    />
  );
};

export default RoundSwitch;
