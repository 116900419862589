import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useGetAdminBookingSummaryLazyQuery } from 'gql/generated/query.graphql';
import BookingSearch from 'components/booking-management/BookingSearch';
import BookingCreate from 'components/booking-management/BookingCreate';
import BookingListTable from 'components/booking-management/BookingListTable';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridRowGap: 30,
  },
});

function BookingManagement() {
  const classes = useStyles();
  const { filter } = useParams();
  const [bookingData, setBookingData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0 as any);
  const [statistics, setStatistics] = useState({} as any);
  const [bookingStatus, setBookingStatus] = useState(1);
  const [journeyId, setJourneyId] = useState(null);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });
  const [disableAction, setDisableAction] = useState(true);

  const [getAdminBookingSummary, { data }] = useGetAdminBookingSummaryLazyQuery({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
  });

  useEffect(() => {
    dateFilter.startDate &&
      dateFilter.endDate &&
      getAdminBookingSummary({
        variables: {
          input: {
            endDate: dateFilter.endDate,
            limit: pageSize,
            offset: page * pageSize,
            startDate: dateFilter.startDate,
            status: bookingStatus,
            journeyId,
          },
        },
      });
  }, [pageSize, offset, page, bookingStatus, dateFilter, journeyId]);

  useEffect(() => {
    if (data) {
      const _bookingData = data?.getAdminBookingSummary?.results || [];
      let arrData = _bookingData.map((element) => {
        return { ...element, id: element['idJourney'], city: 'Dubai' };
      });
      setBookingData(arrData);
      setTotalRows(data?.getAdminBookingSummary.totalRows);
      setStatistics(data?.getAdminBookingSummary.statistics);
    }
  }, [data]);
  
  return (
    <div className={classes.mainGrid}>
      <BookingCreate setDateFilter={setDateFilter} statistics={statistics} totalRows={totalRows} />
      <BookingSearch
        filter={filter}
        setBookingStatus={setBookingStatus}
        setJourneyId={setJourneyId}
        setDisableAction={setDisableAction}
      />
      <BookingListTable
        bookingData={bookingData}
        totalRows={totalRows}
        setOffset={setOffset}
        bookingStatus={bookingStatus}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        disableAction={disableAction}
      />
    </div>
  );
}

export default BookingManagement;
