import { Card, Button, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { FareProps } from '../fare-list';
import { useState } from 'react';
import AddFare from '../add-fare';

interface ControlProps {
  fare?: FareProps;
  fareList: FareProps[];
  addFareData: (data: CreateFare) => void;
}

export interface CreateFare {
  areaId: number;
  baseMorningShift: string;
  baseNightShift: string;
  bookingFee: string;
  farePerKm: string;
  farePerMin: string;
}

const useStyles = makeStyles({
  controlPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '22px 0 0 20px',
    width: '350px',
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiButton-root': {
        color: 'inherit',
        backgroundColor: 'inherit',
        border: '1px solid',
        borderRadius: '30px',
        height: '35px',
        fontSize: '13px',
      },
    },
  },
});

const AddFareControl: React.FC<ControlProps> = ({ fare, fareList, addFareData }: ControlProps) => {
  const { controlPanel } = useStyles();
  const [isOpenBase, setOpenBase] = useState(!1);

  const addBaseFare = (data: any) => {
    setOpenBase(!1);
    addFareData(data);
  };

  return (
    <Card className={controlPanel}>
      <CardContent>
        <Typography variant='subtitle1' gutterBottom component='div'>
          No Base Fare has been set up till now.
        </Typography>
        <Button onClick={() => setOpenBase(!0)} size='small'>
          Add Fare
        </Button>
      </CardContent>
      {isOpenBase && (
        <AddFare
          isOpen={isOpenBase}
          fare={fare}
          fareList={fareList}
          onSubmit={addBaseFare}
          onClose={() => setOpenBase(!1)}
        />
      )}
    </Card>
  );
};

export default AddFareControl;
