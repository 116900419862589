import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from 'gql/cache';
import routes from './urls';
import { AbilityContext } from 'common/casl/abilityContext';
import ability, { AppAbility, getRules } from 'common/casl/ability';

const Routers = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar); // this line will rerender the component alternate we can use isLoggedInVar()

  useEffect(() => {
    ability.update(getRules());
    return () => {};
  });

  const routing = useRoutes(routes(isLoggedIn));
  return <AbilityContext.Provider value={ability as AppAbility}>{routing}</AbilityContext.Provider>;
};

export default Routers;
