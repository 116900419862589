import React, { useEffect, useState } from 'react';
import CustomerDetails from 'components/customer-management/CustomerDetails';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ActivityLifeCycleTable from 'components/booking-management/ActivityLifeCycleTable';
import {
  Customer,
  RideSummaryResponse,
  Ride_Listing_Classification,
  useCustomerRideSummaryLazyQuery,
  useGetAdminCustomerRidesQuery,
  useGetCustomerQuery,
  useGetRideEventQuery
} from 'gql/generated/query.graphql';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridRowGap: 30,
  },
});

interface LocationStateType {
  state: {
    params: any;
  };
}

const DEFAULT_LIMIT = 15;
const DEFAULT_PAGE = 0;

function TripActivityLifeCycle() {
  const classes = useStyles();

  const [isUpComingRide, setIsUpComingRide] = useState<Boolean>(true);
  const [eventRides, setEventRides] = useState<any>([]);
  const [eventRidesRows, setEventRidesRow] = useState<number>(0);
  const [searchInput, setSearchInput] = useState({ journeyId: null });
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);

  const {
    state: { params },
  } = useLocation() as unknown as LocationStateType;

  const getRideStatus = (rideStatus: boolean) => {
    setIsUpComingRide(rideStatus);
  };

  const { loading: eventLoading, data: eventData }: { loading: boolean; data: any } = useGetRideEventQuery({
    variables: {
      journeyId : parseFloat(params.idJourney),
    },
    onError: (error) => {
      console.log(error.networkError?.message);
    },
  });


  useEffect(() => {
    if (!eventLoading && eventData && eventData?.getRideEvents) {
        setEventRides(eventData.getRideEvents);
        setEventRidesRow(eventData.getRideEvents.totalRows);
    }
    return () => {};
  }, [eventData, eventLoading, limit, offset]);

  return (
    <div className={classes.mainGrid}>
      <Typography fontWeight={500} variant='h5'>
        {'Trip Activity LifeCycle'}
      </Typography>
        <ActivityLifeCycleTable
          data={eventRides}
          totalRows={eventRidesRows}
          pageSize={limit}
          setPageSize={setLimit}
          setOffset={setOffset}
          setPage={setPage}
        />
    </div>
  );
}

export default TripActivityLifeCycle;
