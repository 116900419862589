import { WHITE, LIGHT_GREY } from '../colors';

const muiFilledInput = {
  MuiFilledInput: {
    styleOverrides: {
      root: {
        minHeight: '50px',
        '& input, & .MuiSelect-filled': {
          paddingTop: '14px',
          paddingBottom: '14px'
        },
      },
    },
  },
};

export default muiFilledInput;
