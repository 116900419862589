import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import Rating from '@mui/material/Rating';
import Box from '@mui/system/Box';
import { makeStyles } from '@mui/styles';
import TableActionComponent from 'components/driver-management/TableActionComponent';
import { useGetDriversAdminLazyQuery } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
  },
});

function SearchTable({ searchInput, onData }: { searchInput: any, onData: any }) {
  const classes = useStyles();
  const [driversData, setDriversData] = useState([] as any);
  const [selectedRow, setSelectedRow] = useState('');
  const [totalRows, setTotalRows] = useState(0 as any);
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const handleCellClick = (params:any) => {
    const rowIndex = params.rowIndex;
    const rowData = driversData[rowIndex];
    onData(params?.row?.emailId);
    console.log('Selected Row:', params.row.emailId);
  };

  const [getDriversAdmin, { data: driverAdminData, loading: isLoading }] = useGetDriversAdminLazyQuery({});

  useEffect(() => {
    getDriversAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!isLoading && driverAdminData && driverAdminData.getDriversAdmin) {
      let arrData: any = [];

      driverAdminData?.getDriversAdmin.results.forEach((element: any) => {
        arrData.push({ ...element, id: element['idDriverUser'] });
      });
      setDriversData(arrData);
      setTotalRows(driverAdminData?.getDriversAdmin.totalRows);
    }
  }, [driverAdminData]);

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'mobileNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
  ];
  return (
    <Box style={{ height: 350 }}>
      <DataGrid
        rows={driversData}
        columns={column}
        rowHeight={85}
        hideFooterPagination
        onCellClick={handleCellClick}
        hideFooterSelectedRowCount
      />
    </Box>
  );
}

export default SearchTable;
