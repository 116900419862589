import { Autocomplete, Typography, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState, useEffect } from 'react';

import { Codes, PromoCodeModalProps } from 'common/promocodeModal';
import useStyles, { AutoCompleteStyle } from 'common/promocodeModal/styled';
import ModalDialog from 'common/modalDialog';
import { useGetAllPromotionsQueryResult, useApplyPromoMutation } from 'gql/generated/query.graphql';

const PromoCodeModal: React.FC<PromoCodeModalProps> = ({
  open,
  onCancel,
  onSubmit,
  email,
  personUuid,
}: PromoCodeModalProps) => {
  const [promotionCode, setPromotionCode] = useState<string[]>([]);
  const [codes, setCodes] = useState<Codes[]>([]);

  const { userHeading, textStyle, flexProperty } = useStyles();
  const [applyPromoMutation] = useApplyPromoMutation();
  const { data } = useGetAllPromotionsQueryResult({});

  const addPromoCode = (promotionId: number, userId: string) => applyPromoMutation({
    variables: {
      promotionId,
      userId,
    },
  });

  useEffect(() => {
    if (data) {
      const tempCodes: Codes[] = [];
      const tempPromoCodes: string[] = [];
      data?.getAllPromotions.map(promoCode => {
        tempCodes.push({ code: promoCode.promoCode, id: promoCode.idPromotion });
        tempPromoCodes.push(promoCode.promoCode);
      });
      setCodes(tempCodes);
      setPromotionCode(tempPromoCodes);
    }
  }, [data]);

  return (
    <ModalDialog open={open} onClose={onCancel} onSubmit={onSubmit} submitName='Assign'>
      <div className={flexProperty}>
        <Typography className={userHeading} marginRight={2}>
          User :
        </Typography>
        <Typography className={textStyle}>{email}</Typography>
      </div>
      <Autocomplete
        disablePortal
        id='select-promo-code-textbox'
        size='small'
        fullWidth
        popupIcon={<KeyboardArrowDownIcon />}
        options={promotionCode}
        defaultValue={promotionCode[1]}
        renderInput={params => (
          <TextField
            {...params}
            variant='filled'
            InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
            label='Select Promo Code'
          />
        )}
        onChange={(_event, item) => codes.filter(temp => temp.code === item && addPromoCode(temp.id, personUuid))}
      />
    </ModalDialog>
  );
};

export default PromoCodeModal;
