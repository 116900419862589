import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, Autocomplete, TextField } from '@mui/material';
import { DARK_GREY, GRAY_86, LIGHT_GREY, MID_GREY, WHITE } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import InputField from 'common/forms/InputField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  dateFields,
  driverGenders,
  driverSchema,
  driverSelectOptions,
  fileTypeMessage,
  imageFields,
  imageKeys,
  ImageTypeMessage,
  inputDriverOptions,
  ValidImage,
} from './constants';
import { onSaveForm } from 'utils/externalApiCalls';
import {
  useGetVehiclesAndPartnersLazyQuery,
  useCreateDriverAdminMutation,
  GetDriverAdminDocument,
  useGetSaveAdminSignedUrlLazyQuery,
  useGetAllSuppliersLazyQuery,
  useUpdateDriverAdminMutation,
} from 'gql/generated/query.graphql';
import { camelCase, isEmpty, upperFirst } from 'lodash';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    marginTop: 30,
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  divider: {
    height: 0.05,
    marginTop: 20,
    marginRight: -10,
    backgroundColor: 'grey',
  },
  dividerSpace: {
    height: 0.05,
    marginTop: 20,
    marginRight: -10,
  },
  dateFields: {
    margin: 0,
    borderRadius: '100',
  },
  dividerText: {
    marginBottom: -20,
    fontWeight: '500',
  },
  label: {
    marginBottom: -5,
  },
  profile: {
    marginBottom: -10,
  },
});

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  password: string;
  employeeId: string;
  vehicle: number;
  partner: number;
  // uploadimg: object;
}

const FILE_SIZE = '5000000';

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

function AddUser({
  open,
  handleClose,
  title,
  action = 'add',
  existingDriver = {},
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  action?: string;
  existingDriver?: any;
}): React.ReactElement {
  const [driverPhoto, setDriverPhoto] = useState<any>('');
  const [emiratesIdFront, setEmiratesIdFront] = useState<string>('');
  const [rtaPermitPhotoFront, setRtaPermitPhotoFront] = useState<string>('');
  const [licensePhotoFront, setLicensePhotoFront] = useState<string>('');
  const [emiratesIdBack, setEmiratesIdBack] = useState<string>('');
  const [rtaPermitPhotoBack, setRtaPermitPhotoBack] = useState<string>('');
  const [licensePhotoBack, setLicensePhotoBack] = useState<string>('');
  const [driverOptions, setDriverOptions] = useState<any>(driverSelectOptions);
  const [imageUploadError, setImageUploadError] = useState<any>(imageKeys);
  const [currentSuppier, setCurrentSuppier] = useState('');
  const [renderDefaultInput, setRenderDefaultInput] = useState(true);
  const [initialDriverSeletedValues, setInitialDriverSeletedValues] = useState<any>({
    assignVehicle: {},
    driverSupplierPartner: {},
    driverGender: {},
    assignPartner: {},
  });

  const classes = useStyles();

  const [getVehiclesAndPartners, { data, error }] = useGetVehiclesAndPartnersLazyQuery();
  const [getAllSuppliers, { data: supplierData }] = useGetAllSuppliersLazyQuery();
  const [getAdminSignedUrl, { data: imageData }] = useGetSaveAdminSignedUrlLazyQuery();

  const [createDriverAdmin, { data: createDriverData, loading: createDriverLoading, error: createDriverError }] =
    useCreateDriverAdminMutation({
      onCompleted() {
        handleClose();
      },
    });

  const [updateDriverAdmin] = useUpdateDriverAdminMutation({
    onCompleted() {
      handleClose();
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(driverSchema(action)) });

  useEffect(() => {
    if (action === 'update') {
      setValue('firstName', existingDriver?.firstName);
      setValue('lastName', existingDriver?.lastName);
      setValue('email', existingDriver?.emailId);
      setValue('phone', existingDriver?.mobileNumber);
      setValue('employeeId', existingDriver?.idDriverEmp);
      setValue('driverMeterId', existingDriver?.idMeter);
      setValue('drivingLicenseExpiryDate', moment(existingDriver?.identityDocuments[2]?.expiryDate).format('YYYY-MM-DD'));
      setValue('emiratesIdExpiryDate', moment(existingDriver?.identityDocuments[0]?.expiryDate).format('YYYY-MM-DD'));
      setValue('RTAExiryDate', moment(existingDriver?.identityDocuments[1]?.expiryDate).format('YYYY-MM-DD'));
      setValue('driverStartDate', moment(existingDriver?.startDate).format('YYYY-MM-DD'));
      setValue('licenseNumber', existingDriver?.identityDocuments[2]?.idDocument);
      setValue('emiratesIdNumber', existingDriver?.identityDocuments[0]?.idDocument);
      setValue('RTAPermitNumber', existingDriver?.identityDocuments[1]?.idDocument);

      const copyInitialDriverSeletedValues = { ...initialDriverSeletedValues };
      if (data) {
        copyInitialDriverSeletedValues.assignVehicle = data?.getVehiclesAndPartners?.vehicles.filter(
          (vehicle) => vehicle?.idVehicle == existingDriver?.driverDetails?.refIdVehicle?.idVehicle
        )[0];

        copyInitialDriverSeletedValues.assignPartner = data?.getVehiclesAndPartners?.partners.filter(
          (partner) => partner.idPartner === existingDriver?.driverDetails?.refIdPartner?.idPartner
        )[0];

        copyInitialDriverSeletedValues.driverGender = {
          driverLabel: upperFirst(camelCase(existingDriver.gender)),
          value: existingDriver.gender,
        };
      }

      if (supplierData) {
        let suppliers: any[] = [];
        suppliers.push(supplierData?.getSuppliers);
        suppliers = suppliers[0]?.filter((supplier: any) => {
          if (supplier?.idSupplier === existingDriver?.refIdSupplier) {
            if (supplier?.name != null) return supplier;
            else {
              supplier.name = `${supplier.taxiSupplierColor?.type}(${supplier.taxiSupplierColor?.color})`;
              return supplier;
            }
          }
        })[0];
        copyInitialDriverSeletedValues.driverSupplierPartner = suppliers;
      }

      setInitialDriverSeletedValues(copyInitialDriverSeletedValues);
      setDriverPhoto(existingDriver?.mediaUrl);
      if (existingDriver?.identityDocuments?.length !== 0) {
        existingDriver?.identityDocuments[0]?.frontImageUrl && setEmiratesIdFront(existingDriver?.identityDocuments[0].frontImageUrl);
        existingDriver?.identityDocuments[0]?.backImageUrl && setEmiratesIdBack(existingDriver?.identityDocuments[0].backImageUrl);
        existingDriver?.identityDocuments[1]?.frontImageUrl && setRtaPermitPhotoFront(existingDriver?.identityDocuments[1].frontImageUrl);
        existingDriver?.identityDocuments[1]?.backImageUrl && setRtaPermitPhotoBack(existingDriver?.identityDocuments[1].backImageUrl);
        existingDriver?.identityDocuments[2]?.frontImageUrl && setLicensePhotoFront(existingDriver?.identityDocuments[2].frontImageUrl);
        existingDriver?.identityDocuments[3]?.backImageUrl && setLicensePhotoBack(existingDriver?.identityDocuments[2].backImageUrl);
      };
    }
  }, [supplierData, data, action]);

  useEffect(() => {
    if (supplierData) {
      const copyDriverOptions = [...driverOptions];

      let copySupplies: any[] = [];
      copySupplies.push(supplierData?.getSuppliers);
      copySupplies = copySupplies[0].map((supplier: any) => {
        if (supplier?.name) return supplier;
        else {
          supplier.name = `${supplier.taxiSupplierColor?.type}(${supplier.taxiSupplierColor?.color})`;
          return supplier;
        }
      });

      copyDriverOptions[1].data = copySupplies;
      setDriverOptions(copyDriverOptions);
    }
  }, [supplierData]);


  const {
    firstName: firstNameErrorMsg,
    lastName: lastNameErrorMsg,
    email: emailErrorMsg,
    phone: phoneErrorMsg,
    password: passwordErrorMsg,
    driverMeterId: driverMeterIdErrorMsg,
    RTAPermitNumber: RTAPermitNumberErrorMsg,
    emiratesIdNumber: emiratesIdNumberErrorMsg,
    licenseNumber: licenseNumberErrorMsg,
    RTAExiryDate: RTAExiryDateErrorMsg,
    drivingLicenseExpiryDate: drivingLicenseExpiryDateErrorMsg,
    emiratesIdExpiryDate: emiratesIdExpiryDateErrorMsg,
  } = errors;

  const isButtonEnable =
    !isEmpty(errors) ||
    Object.keys(imageUploadError)
      .map((key) => imageUploadError[key] == true)
      .includes(true) ||
    driverPhoto === '';

  useEffect(() => {
    getVehiclesAndPartners();
    getAdminSignedUrl();
    getAllSuppliers();
  }, []);

  useEffect(() => {
    if (data) {
      const copyDriverOptions = [...driverSelectOptions];
      copyDriverOptions[0].data = data?.getVehiclesAndPartners?.vehicles;
      copyDriverOptions[3].data = data?.getVehiclesAndPartners?.partners;
      setDriverOptions(copyDriverOptions);
    }
  }, [data]);

  const handleDriverPhotoFile = async (fileData: any) => {
    if (!ValidImage(fileData, 'profile')) return setDriverPhoto('');

    setDriverPhoto('valid');
    await getAdminSignedUrl();
    const { url } = await onSaveForm(imageData, fileData?.target?.files[0]);
    setDriverPhoto(url);
  };

  const uploadValidImage = async (setImage: any, fileData: any, key: string) => {
    let copyImageUploadError = { ...imageUploadError };
    copyImageUploadError[key] = true;

    if (!ValidImage(fileData)) return setImageUploadError(copyImageUploadError);

    copyImageUploadError[key] = false;
    setImageUploadError(copyImageUploadError);

    await getAdminSignedUrl();
    const { url } = await onSaveForm(imageData, fileData?.target?.files[0]);
    setImage(url);
  };

  const handleFrontPhotoFile = async (fileData: any) => {
    if (fileData?.target?.id === 'emirate')
      await uploadValidImage(setEmiratesIdFront, fileData, fileData?.target?.id);
    else if (fileData?.target?.id === 'rta')
      await uploadValidImage(setRtaPermitPhotoFront, fileData, fileData?.target?.id);
    else if (fileData?.target?.id === 'license')
      await uploadValidImage(setLicensePhotoFront, fileData, fileData?.target?.id);
  };

  const handleBackPhotoFile = async (fileData: any) => {
    if (fileData?.target?.id === 'emirateBack')
      await uploadValidImage(setEmiratesIdBack, fileData, fileData?.target?.id);
    else if (fileData?.target?.id === 'rtaBack')
      await uploadValidImage(setRtaPermitPhotoBack, fileData, fileData?.target?.id);
    else if (fileData?.target?.id === 'licenseBack')
      await uploadValidImage(setLicensePhotoBack, fileData, fileData?.target?.id);
  };

  const onSave = (data: any) => {
    if (action === 'add') {
      createDriverAdmin({
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            emailId: data.email,
            mobileNumber: data.phone.toString(),
            pwd: btoa(data.password),
            empId: data.employeeId,
            partner: parseInt(data.assignPartner),
            vehicle: parseInt(data.assignVehicle),
            mediaUrl: driverPhoto,
            idMeter: data.driverMeterId,
            refIdSupplier: data.driverSupplierPartner,
            gender: data.driverGender,
            identityDocuments: [
              {
                idDocument: data.emiratesIdNumber,
                frontImageUrl: emiratesIdFront,
                backImageUrl: emiratesIdBack,
                documentType: 'EMIRATES_ID',
                expiryDate: data.emiratesIdExpiryDate,
              },
              {
                idDocument: data.RTAPermitNumber,
                frontImageUrl: rtaPermitPhotoFront,
                backImageUrl: rtaPermitPhotoBack,
                documentType: 'RTA_PERMIT',
                expiryDate: data.RTAExiryDate,
              },
              {
                idDocument: data.licenseNumber,
                frontImageUrl: licensePhotoFront,
                backImageUrl: licensePhotoBack,
                documentType: 'LICENCE',
                expiryDate: data.drivingLicenseExpiryDate,
              },
            ],
            startDate: data.driverStartDate,
          },
        },
        refetchQueries: [GetDriverAdminDocument, 'getDriversAdmin'],
      });
    } else if (action === 'update') {
      updateDriverAdmin({
        variables: {
          input: {
            firstName: data.firstName,
            id: existingDriver?.id,
            lastName: data.lastName,
            emailId: data.email,
            mobileNumber: data.phone.toString(),
            empId: data.employeeId,
            partner: parseInt(data.assignPartner),
            vehicle: parseInt(data.assignVehicle),
            mediaUrl: driverPhoto,
            idMeter: data.driverMeterId,
            refIdSupplier: data.driverSupplierPartner,
            gender: data.driverGender,
            identityDocuments: [
              {
                idDocument: data.emiratesIdNumber,
                frontImageUrl: emiratesIdFront,
                backImageUrl: emiratesIdBack,
                documentType: 'EMIRATES_ID',
                expiryDate: data.emiratesIdExpiryDate,
              },
              {
                idDocument: data.RTAPermitNumber,
                frontImageUrl: rtaPermitPhotoFront,
                backImageUrl: rtaPermitPhotoBack,
                documentType: 'RTA_PERMIT',
                expiryDate: data.RTAExiryDate,
              },
              {
                idDocument: data.licenseNumber,
                frontImageUrl: licensePhotoFront,
                backImageUrl: licensePhotoBack,
                documentType: 'LICENCE',
                expiryDate: data.drivingLicenseExpiryDate,
              },
            ],
            startDate: data.driverStartDate,
          },
        },
        refetchQueries: [GetDriverAdminDocument, 'getDriversAdmin'],
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <React.Fragment>
            <div className={classes.formGrid}>
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='firstname'
                placeholder='First Name'
                name='firstName'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='firstname'
                autoFocus
                helperText={firstNameErrorMsg?.message}
                error={firstNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='lastName'
                placeholder='Last Name'
                id='lastname'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='lastname'
                helperText={lastNameErrorMsg?.message}
                error={lastNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='email'
                placeholder='Email'
                name='email'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='email'
                helperText={emailErrorMsg?.message}
                error={emailErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='password'
                placeholder='Password'
                name='password'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                type='password'
                autoComplete='password'
                helperText={passwordErrorMsg?.message}
                error={passwordErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='phone'
                placeholder='9715x xxx xxxx'
                type='tel'
                id='phone'
                InputProps={{ disableUnderline: true }}
                variant='filled'
                autoComplete='mobile'
                helperText={phoneErrorMsg?.message}
                error={phoneErrorMsg ? true : false}
                defaultValue={existingDriver?.mobileNumber}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='employeeId'
                placeholder='Employee Id'
                id='employeeid'
                variant='filled'
                InputProps={{ disableUnderline: true }}
              />
              <div>
                <p className={classes.profile}>Driver Profile Image</p>
                <TextField
                  type='file'
                  margin='normal'
                  fullWidth
                  variant='filled'
                  InputProps={{ disableUnderline: true }}
                  onChange={handleDriverPhotoFile}
                  helperText={action === 'add' && driverPhoto === '' ? ImageTypeMessage : null}
                  error={action === 'add' ? driverPhoto === '' : false}
                />
              </div>
              {driverOptions.map((driverOption: any, index: any) => (
                <Controller
                  key={index}
                  control={control}
                  name={driverOption.name}
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      disablePortal
                      id={driverOption.key}
                      size='small'
                      fullWidth
                      popupIcon={<KeyboardArrowDownIcon />}
                      options={driverOption.data}
                      getOptionLabel={(option: any) => option[driverOption.optionLabel]}
                      onChange={(event, item) => {
                        setRenderDefaultInput(false);
                        onChange(item[driverOption.id]);
                        if (item?.type) setCurrentSuppier(item?.type);
                      }}
                      defaultValue={action === 'update' ? initialDriverSeletedValues[driverOption.name] : null}
                      onInputChange={() => {
                        if(renderDefaultInput) {
                          onChange(initialDriverSeletedValues[driverOption.name][driverOption.id]);
                          if (initialDriverSeletedValues[driverOption.name]?.type) setCurrentSuppier(initialDriverSeletedValues[driverOption.name]?.type);
                        }
                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='filled'
                          InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                          label={driverOption?.label}
                          helperText={error?.message}
                          error={error ? true : false}
                        />
                      )}
                    />
                  )}
                />
              ))}
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='rtapermitnumber'
                placeholder='RTA Permit Number'
                name='RTAPermitNumber'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='rtapermitnumber'
                autoFocus
                helperText={RTAPermitNumberErrorMsg?.message}
                error={RTAPermitNumberErrorMsg ? true : false}
              />
              <InputField
                control={control}
                type='date'
                id='rtaexpirydate'
                name='RTAExiryDate'
                label='RTA Expiry Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                defaultValue={moment().format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
                helperText={RTAExiryDateErrorMsg?.message}
                error={RTAExiryDateErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='emiratesidnumber'
                placeholder='Emirates Id eg. 784-xxxx-xxxxxxx-x'
                name='emiratesIdNumber'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='emiratesidnumber'
                helperText={emiratesIdNumberErrorMsg?.message}
                error={emiratesIdNumberErrorMsg ? true : false}
              />
              <InputField
                control={control}
                type='date'
                id='emiratesidexpirydate'
                name='emiratesIdExpiryDate'
                label='Emirates ID Expiry Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                defaultValue={moment().format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
                helperText={emiratesIdExpiryDateErrorMsg?.message}
                error={emiratesIdExpiryDateErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='licensenumber'
                placeholder='License Number'
                name='licenseNumber'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='licensenumber'
                helperText={licenseNumberErrorMsg?.message}
                error={licenseNumberErrorMsg ? true : false}
              />
              <InputField
                control={control}
                type='date'
                id='driverstartdate'
                name='driverStartDate'
                label='Driver Start Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
              />
              <InputField
                control={control}
                type='date'
                id='drivinglicenseexpirydate'
                name='drivingLicenseExpiryDate'
                label='Driving License Expiry Date'
                margin='normal'
                fullWidth
                variant='filled'
                InputProps={{ disableUnderline: true, style: { marginTop: 7 } }}
                defaultValue={moment().format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                className={classes.dateFields}
                helperText={drivingLicenseExpiryDateErrorMsg?.message}
                error={drivingLicenseExpiryDateErrorMsg ? true : false}
              />
              {currentSuppier === 'TAXI' ? (
                <InputField
                  control={control}
                  margin='normal'
                  fullWidth
                  id='drivermeterid'
                  placeholder='Driver Meter Id'
                  name='driverMeterId'
                  variant='filled'
                  InputProps={{ disableUnderline: true }}
                  autoComplete='drivermeterid'
                  helperText={driverMeterIdErrorMsg?.message}
                  error={driverMeterIdErrorMsg ? true : false}
                />
              ) : (
                <div className={classes.dividerSpace}></div>
              )}
              <br />
              {imageFields.map((field) => (
                <>
                  <div className={classes.divider}></div>
                  <div className={classes.divider}></div>
                  <Typography className={classes.dividerText} variant='h6' fontWeight={400} paddingTop={2}>
                    {field?.name}
                  </Typography>
                  <br />
                  <div>
                    <p className={classes.label}>Front Image</p>
                    <TextField
                      name={field?.key}
                      id={field.key}
                      type='file'
                      margin='normal'
                      fullWidth
                      variant='filled'
                      InputProps={{ disableUnderline: true }}
                      onChange={handleFrontPhotoFile}
                      helperText={action === 'add' && imageUploadError[field.key] ? fileTypeMessage : null}
                      error={action === 'add' ? imageUploadError[field.key] : false}
                    />
                  </div>
                  <div>
                    <p className={classes.label}>Back Image</p>
                    <TextField
                      name={field.key}
                      id={`${field.key}Back`}
                      type='file'
                      margin='normal'
                      fullWidth
                      variant='filled'
                      InputProps={{ disableUnderline: true }}
                      onChange={handleBackPhotoFile}
                      helperText={action === 'add' && imageUploadError[`${field.key}Back`] ? fileTypeMessage : null}
                      error={action === 'add' ? imageUploadError[`${field.key}Back`] : false}
                    />
                  </div>
                </>
              ))}
              <div className={classes.actionBtn}>
                <Button disabled={action === 'add' && isButtonEnable} size='medium' type='submit'>
                  Save
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    </Dialog>
  );
}

export default AddUser;
