import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { makeStyles } from '@mui/styles';
import { GRAY_8A } from 'common/theme/colors';

const useStyles = makeStyles({
  containerGrid: {
    display: 'grid',
    gridRowGap: 20,
  },
  profileGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    gridColumnGap: 10,
    alignItems: 'center',
  },
  driverImage: {
    width: 50,
  },
  nameGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  RatingGrid: {
    display: 'grid',
    gridTemplateColumns: '30px auto',
  },
  rideInfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    justifyContent: 'space-around',
    gridColumnGap: 5,
  },
  rideDetailGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto',
    alignItems: 'center',
    gridColumnGap: 5,
  },
  rideIcon: {
    width: 30,
  },
  badgeGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    width: 250,
  },
  badgeImage: {
    width: 50,
  },
});

type driverProps = {
  driver: {
    name: string;
    rating: number;
    distance: string;
    duration: string;
    rides: string;
    miles: string;
    accidents: string;
    badges: any;
  };
};

function DriverProfile(props: driverProps) {
  const classes = useStyles();
  return (
    <div className={classes.containerGrid}>
      <div className={classes.profileGrid}>
        <img src={`/images/userimg.png`} alt='' className={classes.driverImage} />
        <div className={classes.nameGrid}>
          <Typography fontWeight='600'>{props.driver.name}</Typography>
          <Typography>{`~${props.driver.distance} Km Away`}</Typography>
          <Typography>{`~${props.driver.duration} min Approx.`}</Typography>
          <div className={classes.RatingGrid}>
            <Typography fontWeight='600'>{props.driver.rating}</Typography>
            <Rating value={props.driver.rating} size='small' readOnly precision={0.5} className={classes.ratingStyle} />
          </div>
        </div>
      </div>
      <div className={classes.rideInfoGrid}>
        <div className={classes.rideDetailGrid}>
          <img src={`/images/rideIcon.png`} alt='' className={classes.rideIcon} />
          <Typography variant='h6' fontWeight={500}>
            {props.driver.rides + '+'}
          </Typography>
          <Typography fontWeight={300}>{'Rides'}</Typography>
        </div>
        <div className={classes.rideDetailGrid}>
          <img src={`/images/mileIcon.png`} alt='' className={classes.rideIcon} />
          <Typography variant='h6' fontWeight={500}>
            {props.driver.miles + '+'}
          </Typography>
          <Typography fontWeight={300}>{'Miles'}</Typography>
        </div>
        <div className={classes.rideDetailGrid}>
          <img src={`/images/Accidents.svg`} alt='' className={classes.rideIcon} />
          <Typography variant='h6' fontWeight={500}>
            {props.driver.accidents}
          </Typography>
          <Typography fontWeight={300}>{'Accidents'}</Typography>
        </div>
      </div>
      <div className={classes.badgeGrid}>
        {/* TODO: update from API */}
        {props.driver?.badges?.map((badg: any, i: number) => (
          <img key={i} src={`/images/driverBadge4.svg`} alt='driver-badge4' className={classes.badgeImage} />
        ))}
        {/* <img src={`/images/driverBadge4.svg`} alt='driver-badge4' className={classes.badgeImage} />
        <img src={`/images/driverBadge3.svg`} alt='driver-badge3' className={classes.badgeImage} />
        <img src={`/images/driverBadge2.svg`} alt='driver-badge2' className={classes.badgeImage} />
        <img src={`/images/driverBadge1.svg`} alt='driver-badge1' className={classes.badgeImage} /> */}
      </div>
    </div>
  );
}

export default DriverProfile;
