import { makeStyles } from '@mui/styles';
import { GRAY_86 } from 'common/theme/colors';
import { Typography } from '@mui/material';
import { Admin } from 'gql/generated/query.graphql';
import { Box } from '@mui/system';

const useStyles = makeStyles({
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '120px auto',
    height: 125,
    gridColumnGap: 10,
    borderBottom: '1px solid',
    borderBottomColor: GRAY_86,
  },
  userImage: {
    width: 100,
  },
  customerInfo: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '50% 50%',
  },
  userName: {
    display: 'grid',
    gridTemplateColumns: 'auto 20px auto',
    justifyContent: 'start',
    alignItems: 'center',
    gridColumnGap: 20,
  },
  rideInfoGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'center',
    alignContent: 'space-between',
    gridColumnGap: 25,
  },
  rideDetailGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'center',
    gridColumnGap: 5,
  },
  child: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  classOne: {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: 30,
    letterSpacing: 0.15,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '85vw',
  },
  profile: {
    marginBottom: 10,
  },
  about: {
    marginBottom: 10,
  },
  personalInfo: {
    maxWidth: '82vw',
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  phone_email: {
    display: 'flex',
    flexDirection: 'column',
  },
  badges: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
  },
});

function UserDetails({ data }: { data: Admin }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.detailGrid}>
        <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
        <div className={classes.customerInfo}>
          <div className={classes.userName}>
            <Typography fontWeight={500} variant='h4'>
              {`${data.firstName} ${data.lastName}`}
            </Typography>
          </div>

          <div className={classes.rideInfoGrid}>
            <div className={classes.rideDetailGrid}>
              <Typography className={classes.child}>
                {`${data?.role?.roleName}` || 'Administrator'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Box className={classes.info}>
        <Typography variant='h4' className={classes.profile}>
          <Box sx={{ fontWeight: 'bold' }}>Profile</Box>
        </Typography>
          <div className={classes.about}>
            <Typography variant='h6'>
              <Box sx={{ fontWeight: 'bold'}} my={1}>About</Box>
            </Typography>
            <Typography variant='body1' fontSize='large'>
              {data?.details?.adminUserDescription ?? 'No Description is found'}
            </Typography>
          </div>
        <Box className={classes.personalInfo}>
          <div className={classes.phone_email}>
            <Typography variant='h6'>
              <Box sx={{ fontWeight: 'bold' }}>Email And Phone</Box>
            </Typography>
            <Typography mt={1}>{data.emailId}</Typography>
            <Typography mt={0.2}>{data.mobileNumber}</Typography>
          </div>
          <div className={classes.phone_email}>
            <Typography variant='h6'>
              <Box sx={{ fontWeight: 'bold' }}>Experience</Box>
            </Typography>
            {data?.details?.experience !== null ? (
              <Typography mt={1}>{`${data?.details?.experience}`}</Typography>
            ) : (
              <Typography mt={1}>No Experience</Typography>
            )}
          </div>
          <div>
            <Typography variant='h6'>
              <Box sx={{ fontWeight: 'bold' }}>Awards and badges</Box>
            </Typography>
            <div className={classes.badges}>
              <img src='/images/badge2.svg' height={70} />
              <img src='/images/badge2.svg' height={70} />
              <img src='/images/badge2.svg' height={70} />
              <img src='/images/badge2.svg' height={70} />
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default UserDetails;
