import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Map from 'common/map/map';
import { DARK_SILVER, DIRE_WOLF } from 'common/theme/colors';
import FareControl, { UpdateFareInterface } from 'components/fare-management/control';
import AddFareControl, { CreateFare } from 'components/fare-management/control/addFareControl';
import FareList, { FareProps } from 'components/fare-management/fare-list';
import SalikControl, { UpdateSalikFareInput } from 'components/fare-management/salik-control';
import AddSalikControl from 'components/fare-management/salik-control/addSalikFareControl';
import SurgeControl, { UpdateSurgeFareInput } from 'components/fare-management/surge-control';
import AddSurgeControl, { CreateSurgeFareInput } from 'components/fare-management/surge-control/addSurgeFareControl';

import {
  useAddFareMutation,
  useAddSalikFareMutation,
  useAddSurgeFareMutation,
  useGetAreaLazyQuery,
  useGetAreasLazyQuery,
  useUpdateFareMutation,
  useDeleteFareMutation,
  useUpdateSalikFareMutation,
  useUpdateSurgeFareMutation,
  useDeleteSurgeMutation,
  useDeleteSalikMutation,
} from 'gql/generated/query.graphql';

export enum FareTabs {
  BASE = 'BASE',
  SURGE = 'SURGE',
  SALIK = 'SALIK',
}

const useStyles = makeStyles({
  headerText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '35px',
    alignItems: 'center',
    letterSpacing: '0.15px',
    textTransform: 'capitalize',
  },
  buttonStyle: {
    display: 'flex',
    gap: '10px',
    '& .MuiButton-root': {
      color: 'inherit',
      backgroundColor: 'inherit',
      border: '1px solid',
      borderRadius: '30px',
      height: '35px',
      fontSize: '13px',
      '&.active': {
        backgroundColor: DARK_SILVER,
        color: DIRE_WOLF,
      },
    },
  },
});

const FareManagement = () => {
  const { headerText, buttonStyle } = useStyles();
  const [fareType, setFareType] = useState(FareTabs.BASE);
  const [fareList, setFareList] = useState<any | []>([]);
  const [fare, setFare] = useState<any | null>(null);
  const [currArea, setCurrArea] = useState<any | null>(null);

  const [getAreas, { data, error, loading }] = useGetAreasLazyQuery();
  const [GetArea] = useGetAreaLazyQuery();
  const [addFareMutation] = useAddFareMutation();
  const [addSurgeMutation] = useAddSurgeFareMutation();
  const [updateFareMutation] = useUpdateFareMutation();
  const [deleteFareMutation] = useDeleteFareMutation();
  const [updateSurgeMutation] = useUpdateSurgeFareMutation();
  const [deleteSurgeMutation] = useDeleteSurgeMutation();
  const [addSalikFareMutation] = useAddSalikFareMutation();
  const [updateSalikFareMutation] = useUpdateSalikFareMutation();
  const [deleteSalikMutation] = useDeleteSalikMutation();

  const fetchAreas = () => {
    getAreas({
      variables: {
        filter: {},
      },
    });
    setFare(null);
  };

  useEffect(() => {
    if (data && typeof data === 'object') {
      setFareList(data?.getAreas);
    }
  }, [data]);

  useEffect(() => {
    if (fare) {
      GetArea({
        variables: {
          areaId: fare?.idArea,
        },
      }).then((val) => {
        let tempData = val?.data?.getArea || null;
        setCurrArea(tempData);
      });
    }

    return () => {};
  }, [fare]);

  useEffect(() => {
    fetchAreas();
  }, []);

  const selectFare = (data: any) => {
    const isSalik = data?.refIdAreaType?.areaTypeName === FareTabs.SALIK;
    setFare(data);
    setFareType(isSalik ? FareTabs.SALIK : FareTabs.BASE);
  };

  const addFare = (data: CreateFare) => {
    addFareMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: (res) => {
        fetchAreas();
      },
    });
  };

  const updateFare = (data: UpdateFareInterface) => {
    updateFareMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const deleteFare = (fareId: number) => {
    deleteFareMutation({
      variables: {
        fareId,
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const addSurgeFare = (data: CreateSurgeFareInput) => {
    addSurgeMutation({
      variables: {
        input: { ...data, areaId: fare.idArea },
      },
      onCompleted: (res) => {
        fetchAreas();
      },
    });
  };

  const updateSurgeFare = (data: UpdateSurgeFareInput) => {
    updateSurgeMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const deleteSurgeFare = (surgeId: number) => {
    deleteSurgeMutation({
      variables: {
        surgeId,
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const addSalikFare = (data: any) => {
    addSalikFareMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: (res) => {
        fetchAreas();
      },
    });
  };

  const updateSalikFare = (data: UpdateSalikFareInput) => {
    updateSalikFareMutation({
      variables: {
        input: { ...data },
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const deleteSalikFare = (salikId: number) => {
    deleteSalikMutation({
      variables: {
        salikId,
      },
      onCompleted: () => {
        fetchAreas();
      },
    });
  };

  const controlElement = {
    BASE: fare?.fare ? (
      <FareControl fareList={fareList} fare={fare} updateFare={updateFare} deleteFare={deleteFare} />
    ) : (
      <AddFareControl fare={fare} fareList={fareList} addFareData={addFare} />
    ),
    SURGE: fare?.surge ? (
      <SurgeControl fare={fare} updateSurgeFare={updateSurgeFare} deleteSurgeFare={deleteSurgeFare} />
    ) : (
      <AddSurgeControl fare={fare} addSurgeFare={addSurgeFare} />
    ),
    SALIK: fare?.salik ? (
      <SalikControl fare={fare} updateSalikFare={updateSalikFare} deleteSalikFare={deleteSalikFare} />
    ) : (
      <AddSalikControl addSalikFare={addSalikFare} fare={fare} />
    ),
  };

  return (
    <Box display='flex' flexDirection='column' height='calc(100vh - 130px)'>
      <Box display='flex' height='100px' justifyContent='space-evenly' flexDirection='column'>
        <Box className={headerText}>Fare Management</Box>
        {fare && (
          <Box className={buttonStyle}>
            {fareType === FareTabs.SALIK ? (
              <Button className='active' size='small'>
                Salik Fare
              </Button>
            ) : (
              <>
                <Button
                  className={`${fareType === FareTabs.BASE ? 'active' : ''}`}
                  onClick={() => setFareType(FareTabs.BASE)}
                  size='small'
                >
                  Base Fare
                </Button>
                <Button
                  className={`${fareType === FareTabs.SURGE ? 'active' : ''}`}
                  size='small'
                  disabled={!fare?.fare}
                  onClick={() => setFareType(FareTabs.SURGE)}
                >
                  Surge Fare
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box display='flex' height='calc(100% - 70px)'>
        <Box display='flex' flex='0 0 60%' position='relative'>
          <Map type='view' currArea={currArea} style={{ width: '100%' }}>
            {fare && controlElement[fareType]}
          </Map>
        </Box>
        <Box display='flex' flex='0 0 40%'>
          <FareList
            selectFare={selectFare}
            fareList={fareList}
            fare={fare}
            addFareData={addFare}
            addSalikFare={addSalikFare}
          />
        </Box>
      </Box>
      {/* {addFareModal && (
        <AddFare
          isOpen={addFareModal}
          fare={fare}
          fareList={fareList}
          onSubmit={addFareData}
          onClose={() => setAddFareModal(!1)}
        />
      )}
      {addSalikModal && (
        <SalikFare isOpen={addSalikModal} fare={fare} onSubmit={addFareData} onClose={() => setAddSalikModal(!1)} />
      )} */}
    </Box>
  );
};

export default FareManagement;
