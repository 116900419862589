export const searchListKeys = [
  {
    value: 'supplier name',
    label: 'Supplier Name',
  },
  {
    value: 'supplier number',
    label: 'Supplier Number',
  },
];
