import { Fragment } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CODE_VERIFICATION } from 'routers/config';
import PreLoginLayout from 'common/layouts/preLoginLayout';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from 'common/forms/InputField';
import { useVerifyAdminMobileNumberMutation } from 'gql/generated/query.graphql';

interface IFormInput {
  mobileNumber: string;
}
const UAE_MOBILE_NUMBER = /^(?:971)[0-9]{9}$/; //TODO: moved to utils

const schema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required('Phone Number is required*')
    .matches(UAE_MOBILE_NUMBER, 'Enter valid phone number')
    .max(12, 'Enter valid phone number'),
});

function ForgotPassword() {
  const history = useNavigate();
  const [verifyAdminMobileNumber] = useVerifyAdminMobileNumberMutation({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
    onCompleted(data) {
      history(CODE_VERIFICATION, {
        state: { sid: data.verifyAdminMobileNumber.sid, mobileNumber: getValues('mobileNumber') },
      });
    },
  });
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mobileNumber: mobileNumberErrorMsg } = errors;

  const onForgotPasswordSubmit = (data: any) => {
    verifyAdminMobileNumber({
      variables: {
        input: {
          mobileNumber: data.mobileNumber,
        },
      },
    });
  };

  return (
    <Fragment>
      <PreLoginLayout title='Forgot Password' desc=''>
        <form onSubmit={handleSubmit(onForgotPasswordSubmit)}>
          <InputField
            className='inputRounded'
            control={control}
            margin='normal'
            fullWidth
            name='mobileNumber'
            autoComplete='mobileNumber'
            placeholder='Email or Phone Number'
            helperText={mobileNumberErrorMsg?.message}
            error={mobileNumberErrorMsg ? true : false}
            autoFocus
          />

          <Button type='submit' fullWidth variant='contained' sx={{ mt: 3 }}>
            Submit
          </Button>
        </form>
      </PreLoginLayout>
    </Fragment>
  );
}

export default ForgotPassword;
