import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { MouseEvent, useState } from 'react';

import { Can } from 'common/casl/abilityContext';
import DeleteModal from 'common/deleteModal';
import { GetWaitingListAdminDocument, useDeleteWaitingListMutation } from 'gql/generated/query.graphql';
import { Params } from './helpers';
import { SUBJECTS } from 'common/casl/ability';
import UpdateUser from './UpdateUser';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

const TableActionComponent = ({
  params,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: Params;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);

  const classes = useStyles();
  const [deleteWaitingList] = useDeleteWaitingListMutation();

  const deleteUser = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const deleteSelectedUser = (idWaitingList: string) => {
    deleteWaitingList({
      variables: {
        idWaitingList,
      },
      onCompleted: () => setOpenDelete(false),
      onError: () => setOpenDelete(false),
      refetchQueries: [GetWaitingListAdminDocument, 'getWaitingListAdmin'],
    });
    setOpenDelete(false);
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={() => setEditOpen(true)} disabled={disabledEditButton}>
          <img src='/images/editIcon.svg' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteUser(e)} disabled={disabledDeleteButton}>
          <img src='/images/deleteIcon.svg' className={classes.actionImage} />
        </IconButton>
      </Can>
      <DeleteModal
        open={isOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onSubmit={() => deleteSelectedUser(String(params.idWaitingList))}
      />
      <UpdateUser open={editOpen} handleClose={() => setEditOpen(false)} params={params} title='Edit User' />
    </div>
  );
};

export default TableActionComponent;
