import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IconButton, Typography, Menu, MenuItem } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Chip from '@mui/material/Chip';
import { GRAY_86 } from 'common/theme/colors';

const useStyles = makeStyles({
  searchGrid: {
    display: 'grid',
    gridTemplateColumns: '350px 50px',
  },
  actionCell: {
    display: 'grid',
    alignItems: 'center',
  },
  searchDropIcon: {
    background: GRAY_86,
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    borderRadius: '0px 3px 3px 0px',
    height: 45,
    width: 50,
    marginRight: -14,
  },
  chipStyle: {
    fontSize: 18,
    height: 40,
    width: 150,
    fontWeight: 500,
  },
  tabGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chipGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: 15,
  },
  searchBox: {
    width: 400,
  },
});

function BookingSearch(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tab, setTab] = useState('Onboarding');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabChange = (tabValue: string) => {
    const rideTypes: any = ['scheduled', 'dispatched', 'assigned', 'on_route', 'arrived', 'started', 'ended', 'payment_generated', 'payment_not_received','completed','user_cancelled',  'zed_cancelled','no_driver_found', 'rejected', 'no_show', 'accepted' ];
    const index: number = rideTypes.indexOf(tabValue) || 0;

    setTab(tabValue);
    props.setBookingStatus(index + 1);
  };

  const navigateToBookings = (route: string) => navigate(`/admin/booking/${route}`);

  const capitalizeFirstLetter = (word: string) => {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  useEffect(() => {
    tabChange(props?.filter);
  }, [props?.filter]);

  return (
    <div>
      <div className={classes.tabGrid}>
        <div className={classes.chipGrid}>
        <Typography fontWeight={400} fontSize={16} marginTop={0.8}>
          Filter Rides By Status: {capitalizeFirstLetter(props?.filter?.split("_")?.join(" "))}
        </Typography>
          <IconButton
            className={classes.actionCell}
            onClick={(params) => handleClick(params)}
            disabled={props.disableAction}
          >
            <FilterAltIcon sx={{ fontSize: 22 }} />
          </IconButton>
          <Menu
            id={`${1}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: '100',
              },
            }}
          >
           {['Scheduled'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('scheduled');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['User Cancelled'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('user_cancelled');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['No Driver Found'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('no_driver_found');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Dispatched'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('dispatched');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Assigned'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('assigned');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['On Route'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('on_route');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Arrived'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('arrived');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Started'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('started');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Ended'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('ended');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Payment Generated'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('payment_generated');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Payment Not Received'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('payment_not_received');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Completed'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('completed');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Zed Cancelled'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('zed_cancelled');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Rejected'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('rejected');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['No Show'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('no_show');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
            {['Accepted'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  navigateToBookings('accepted');
                  setAnchorEl(null);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <TextField
          id='search-box'
          variant='outlined'
          placeholder='Search'
          size='small'
          className={classes.searchBox}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <div className={classes.searchDropIcon}>
                <KeyboardArrowDownIcon sx={{ fontSize: 30 }} />
              </div>
            ),
          }}
          onChange={(e) => props.setJourneyId(parseFloat(e.target.value))}
        />
      </div>
    </div>
  );
}

export default BookingSearch;
