import moment from 'moment';
import * as Yup from 'yup';

export const imageFields = [
  { key: 'emirate', name: 'Upload Emirates ID' },
  { key: 'rta', name: 'Upload RTA Permit' },
  { key: 'license', name: 'Upload Driving License' },
];

export const dateFields = [
  { key: 'rtaexpirydate', name: 'RTA Expiry Date' },
  { key: 'driverstartdate', name: 'Driver Start Date' },
  { key: 'emiratesidexpirydate', name: 'Emirates ID Expiry Date' },
  { key: 'drivinglicenseexpirydate', name: 'Driving License Expiry Date' },
];

export const driverGenders = [
  { driverLabel: 'Male', value: 'MALE' },
  { driverLabel: 'Female', value: 'FEMALE' },
  { driverLabel: 'Other', value: 'OTHER' },
];

const data: any[] = [];

export const driverSelectOptions = [
  { key: 'assignvehicle', name: 'assignVehicle', label: 'Assign Vehicle', optionLabel: 'licensePlate', id: 'idVehicle', data },
  { key: 'driverssupplierpartner', name: 'driverSupplierPartner', label: "Driver's Supplier partner", optionLabel: 'name', id: 'idSupplier', data },
  { key: 'drivergender',name: 'driverGender', label: 'Driver Gender', optionLabel: 'driverLabel', id: 'value', data: driverGenders },
  { key: 'assignpartner', name: 'assignPartner', label: 'Assign Partner', optionLabel: 'partnerName', id: 'idPartner', data },
];


export const inputDriverOptions = [
  {  key: 'licensenumber', name: 'License Number' },
  {  key: 'drivermeterid', name: 'Driver Meter Id' },
  {  key: 'emiratesidnumber', name: 'Emirates Id Number' },
  {  key: 'rtapermitnumber', name: 'RTA Permit Number' },
];

export const fileTypeMessage = 'Please upload valid format (png, jpg, pdf) & maximum 5MB.';
export const ImageTypeMessage = 'Please upload valid format (png, jpg) & maximum 5MB.';
export const oneTimeLeave = 'One-time Unavailability for the above driver is captured';

export const imageKeys = {
  emirate: true,
  rta: true,
  license: true,
  emirateBack: true,
  rtaBack: true,
  licenseBack: true,
};

export const ValidImage = (fileData: any, image?: string) => {
  const MAX_FILE_SIZE = 5120;
  const fileSizeKiloBytes = fileData?.target?.files[0]?.size / 1024;
  const validImageTypes = ['image/png', 'image/jpg'];

  if (image !== 'profile')
    validImageTypes.push('application/pdf');

  return (fileSizeKiloBytes < MAX_FILE_SIZE && validImageTypes.includes(fileData?.target?.files[0]?.type));
};

export const driverSchema = (action: string) => Yup.object().shape(customDriverSchema(action));

const customDriverSchema = (action: string) => {
  const schema: any = {
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Not Valid Email').required('Email is required'),
    phone: Yup.string()
      .required('Phone Number is required')
      .matches(/^(?:971)[0-9]{9}$/, 'Enter a valid phone number (9715x xxx xxxx)'),
    assignVehicle: Yup.string().required('Vehicle is required'),
    driverSupplierPartner: Yup.string().required('Driver supplier partner is required'),
    driverGender: Yup.string().required('Driver gender is required'),
    assignPartner: Yup.string().required('Partner is required'),
    RTAPermitNumber: Yup.string()
      .required('RTA permit number is required')
      .matches(/^[0-9]{7}$/, 'RTA permit number must be exactly 7 numbers'),
    emiratesIdNumber: Yup.string()
      .required('Emirates id number is required')
      .matches(/^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$/g, 'Enter a valid EimrateId number (e.g 784-xxxx-xxxxxxx-x)'),
    licenseNumber: Yup.string()
      .required('License number is required')
      .matches(/^[0-9]{7}$/, 'License number must be exactly 7 numbers'),
    RTAExiryDate: Yup.mixed()
      .required('Expiry date is required')
      .test('Expiry date', "Expiry date is invalid", (date) => {
        return date && date > moment(new Date).format('YYYY-MM-DD');
      }),
    emiratesIdExpiryDate: Yup.mixed()
      .required('Expiry date is required')
      .test('Expiry date', "Expiry date is invalid", (date) => {
        return date && date > moment(new Date).format('YYYY-MM-DD');
      }),
    drivingLicenseExpiryDate: Yup.mixed()
      .required('Expiry date is required')
      .test('Expiry date', "Expiry date is invalid", (date) => {
        return date && date > moment(new Date).format('YYYY-MM-DD');
      }),
  };

  if (action === 'add') {
    schema['password'] = Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^.&*-]).{8,}$/,
        'Minimum 8 characters alphanumeric,One uppercase, One numeric and One special character'
      );
    };

   return schema;
};
