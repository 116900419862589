import { makeStyles } from '@mui/styles';
import React from 'react';

import { SUBJECTS } from 'common/casl/ability';
import SearchHeader from 'common/search-header';
import AddSecretCode from 'components/secret-code-management/AddSecretCode';
import SecretCodeListTable from 'components/secret-code-management/SecretCodeListTable';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const searchListKeys = [
  {
    value: 'name',
    label: 'Name',
  },
];

const SecretCodeList = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Secret Code List'}
        buttonText={'Add Secret Code'}
        handleOpen={handleOpen}
        showButton={true}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <SecretCodeListTable searchInput={searchInput} />
      {open && <AddSecretCode open={open} handleClose={handleClose} title={'Add New Secret Code'} />}
    </div>
  );
};

export default SecretCodeList;
