import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GRAY_8A } from 'common/theme/colors';
import { DriverEntity } from 'gql/generated/query.graphql';
import moment from 'moment';

const useStyles = makeStyles({
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '200px 360px',
    gridColumnGap: 25,
  },
  driverInfoGrid: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridRowGap: 10,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    gridColumnGap: 10,
  },
  infoCard: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    alignSelf: 'end',
    height: 70, // it was 150
    alignItems: 'center',
    justifyItems: 'center',
  },
  userImage: {
    width: 100,
  },
  ratingStyle: {
    color: GRAY_8A,
  },
  userName: {
    display: 'grid',
    gridTemplateColumns: 'auto 20px auto',
    justifyContent: 'start',
    alignItems: 'center',
    gridColumnGap: 20,
  },
  rideInfoGrid: {
    marginTop: 15, // my added update
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: 5,
  },
  rideDetailGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'center',
    gridColumnGap: 5,
  },
  rideIcon: {
    width: 20,
  },
});

function DriverDetails({ data }: { data: DriverEntity }) {
  const classes = useStyles();

  return (
    <div className={classes.detailGrid}>
      <div className={classes.driverInfoGrid}>
        <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
        <Typography fontWeight={500} variant='h4'>
          {`${data.firstName} ${data.lastName}`}
        </Typography>
        <div className={classes.ratingGrid}>
          <Typography fontWeight={500} variant='h6'>
            {`${data?.driverDetails?.rating ? data.driverDetails.rating : 0}`}
          </Typography>
          <Rating
            value={data?.driverDetails?.rating ? data.driverDetails.rating : 0}
            size='small'
            readOnly
            precision={0.5}
            className={classes.ratingStyle}
          />
        </div>
        <Typography fontWeight={300} color={GRAY_8A}>
          {`Member since ${moment(new Date(data.createdDate)).format('D MMM, YYYY')}`}
        </Typography>
      </div>
      <Paper className={classes.infoCard}>
        {/* <Typography fontWeight={300} color={GRAY_8A} variant='h6'>
          {'Total earnings'}
        </Typography>
        <Typography fontWeight={500} variant='h5'>
          {'$5592'}
        </Typography> */}
        <div className={classes.rideInfoGrid}>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/rideIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500}>{data.rideSummary?.totalRides}</Typography>
            <Typography fontWeight={300}>{'Rides'}</Typography>
          </div>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/mileIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500}>
              {`${data.rideSummary?.totalMiles ? (data.rideSummary.totalMiles * 0.62137).toFixed(1) : 0}+`}
            </Typography>
            {/* divided by 1609 to convert meters to miles */}
            <Typography fontWeight={300}>{'Miles'}</Typography>
          </div>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/clockIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500}>
              {`${data.rideSummary?.totalHours ? (data.rideSummary.totalHours / 60).toFixed(1) : 0}+`}
            </Typography>
            <Typography fontWeight={300}>{'Hours'}</Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default DriverDetails;
