import { useEffect, useState } from 'react';
import UserDetails from 'components/user-management/userDetailsTable';
import { makeStyles } from '@mui/styles';
import {
  Admin,
  useGetAdminUserByIdQuery,
} from 'gql/generated/query.graphql';
import { useLocation } from 'react-router-dom';
import BreadCrumb from 'common/breadCrumb';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridRowGap: 30,
  },
});

interface LocationStateType {
  state: {
    userId: string;
  };
}

function UserProfile() {
  const classes = useStyles();
  const [userData, setUserData] = useState<Admin | any>({});
  const {
    state: { userId },
  } = useLocation() as unknown as LocationStateType;

  const { loading, data }: { loading: boolean; data: any } = useGetAdminUserByIdQuery({
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setUserData(data.getAdminUserByID);
    }
    return () => {};
  }, [data, loading]);

  return (
    <div className={classes.mainGrid}>
      <BreadCrumb data={data} heading={`Users`} userData = {userData?.firstName} href={'/admin/users'}/>
      <UserDetails data={userData} />
    </div>
  );
}

export default UserProfile;
