import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog } from '@mui/material';
import { DARK_GREY, LIGHT_GREY, MID_GREY, SILVER, WHITE } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import InputField from 'common/forms/InputField';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  uploadButton: {
    background: LIGHT_GREY,
    borderRadius: 5,
    height: 52,
    display: 'grid',
    alignItems: 'center',
    paddingLeft: 10,
    marginTop: 10,
    color: DARK_GREY
  },
});

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  password: string;
  uploadimg: object;
}

const FILE_SIZE = '5000000';
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.number().positive().integer().required('Phone is required'),
  password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  uploadimg: Yup.mixed()
    .nullable()
    .required()
    .test('FILE_SIZE', 'Image Is A Required Field', (value) => {
      return !value || value.length > 0;
    })
    .test('FILE_SIZE', 'Image file is too big.', (value) => {
      return !value || (value && value.length > 0 && value[0].size <= FILE_SIZE);
    })
    .test(
      'FILE_FORMAT',
      'Image file has unsupported format.',
      (value) => !value || (value && value.length > 0 && SUPPORTED_FORMATS.includes(value[0].type))
    ),
});

function AddUser({
  open,
  handleClose,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
}): React.ReactElement {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    firstName: firstNameErrorMsg,
    lastName: lastNameErrorMsg,
    email: emailErrorMsg,
    phone: phoneErrorMsg,
    password: passwordErrorMsg,
    uploadimg: uploadimgErrorMsg,
  } = errors;


  const onSave = (data: any) => {
    // console.log(data);
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <React.Fragment>
            <div className={classes.formGrid}>
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='firstname'
                placeholder='First Name'
                name='firstName'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='firstname'
                autoFocus
                helperText={firstNameErrorMsg?.message}
                error={firstNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='lastName'
                placeholder='Last Name'
                id='lastname'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='lastname'
                helperText={lastNameErrorMsg?.message}
                error={lastNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='email'
                placeholder='Email'
                name='email'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='email'
                helperText={emailErrorMsg?.message}
                error={emailErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='phone'
                placeholder='Phone'
                type='tel'
                id='phone'
                InputProps={{ disableUnderline: true }}
                variant='filled'
                autoComplete='mobile'
                helperText={phoneErrorMsg?.message}
                error={phoneErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='password'
                placeholder='Password'
                name='password'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                type='password'
                autoComplete='password'
                helperText={passwordErrorMsg?.message}
                error={passwordErrorMsg ? true : false}
              />
              <input accept='image/*' type='file' id='select-image' style={{ display: 'none' }} />
              <label htmlFor='select-image'>
                <div className={classes.uploadButton}>Upload picture</div>
              </label>
              <div className={classes.actionBtn}>
                <Button size='medium' type='submit'>
                  Save
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    </Dialog>
  );
}

export default AddUser;
