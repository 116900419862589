import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Dialog } from '@mui/material';

import { oneTimeLeave } from 'components/driver-management/constants';

const useStyles = makeStyles({
  bookingCardGrid: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    height: 175,
    width: 450,
    gridRowGap: 10,
    alignContent: 'center',
    justifyItems: 'center',
  },
});

type SuccessLeaveProps = {
  open: boolean;
  id: string | undefined;
  onClose: (event: MouseEvent) => void;
  status: boolean;
};

type FailLeaveProps = {
  open: boolean;
  message: string;
  onClose: (event: MouseEvent) => void;
};

function SuccessLeaveModal({ open, onClose, status, id }: SuccessLeaveProps) {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper className={classes.bookingCardGrid}>
        <img src={`/images/successIcon.svg`} alt='success-logo'/>
        <Typography fontWeight={400}>{id}</Typography>
        {status ? (
          <Typography fontWeight={600}>{'Repeat Unavailability for the above driver is captured'}</Typography>
        ) : (
          <Typography fontWeight={600}>{oneTimeLeave}</Typography>
        )}
      </Paper>
    </Dialog>
  );
}

export default SuccessLeaveModal;

export const FailedLeaveModal = ({ open, onClose, message}: FailLeaveProps) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper className={classes.bookingCardGrid}>
        <Typography fontWeight={600}>{"ERROR !!!!"}</Typography>
        <Typography fontWeight={600}>{message}</Typography>
         </Paper>
    </Dialog>
  );
};
