import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useGetAdminBookingSummaryLazyQuery } from 'gql/generated/query.graphql';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A, LIGHT_BLACK } from 'common/theme/colors';
import { Typography, Chip } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { RESCHEDULE_RIDE } from 'routers/config';
import SearchHeader from 'common/search-header';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 400,
      fontSize: 18,
      color: GRAY_8A,
      overflowX: 'visible !important',
    },
    '& .super-app-theme--dark-cell': {
      fontWeight: 400,
      fontSize: 18,
      color: LIGHT_BLACK,
      overflowX: 'visible !important',
    },
  },
  overflowSet: {
    overflow: 'unset',
  },
  pickUpIcon: {
    height: 50,
  },
  pickupGrid: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
    color: LIGHT_BLACK,
  },
  userImage: {
    width: 40,
  },
  actionCell: {
    display: 'grid',
    alignItems: 'center',
  },
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
});

const searchListKeys = [
  {
    value: 'journeyId',
    label: 'ID',
  },
];
function BookingRescheduled() {
  const classes = useStyles();
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [bookingData, setBookingData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0 as any);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchInput, setSearchInput] = React.useState({ journeyId: null });
  const handleOpen = () => setOpen(true);

  const [getAdminBookingSummary, { data }] = useGetAdminBookingSummaryLazyQuery({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
  });

  const NO_DRIVER_FOUND: number = 13;

  useEffect(() => {
    getAdminBookingSummary({
      variables: {
        input: {
          endDate: '',
          limit: pageSize,
          offset: page * pageSize,
          startDate: '',
          status: NO_DRIVER_FOUND,
          journeyId: searchInput.journeyId ? parseFloat(searchInput.journeyId) : null,
        },
      },
    });
  }, [pageSize, offset, page, searchInput.journeyId]);

  useEffect(() => {
    if (data) {
      const _bookingData = data?.getAdminBookingSummary?.results || [];
      let arrData = _bookingData.map((element) => {
        return { ...element, id: element['idJourney'], city: 'Dubai' };
      });
      setBookingData(arrData);
      setTotalRows(data?.getAdminBookingSummary?.totalRows);
    }
  }, [data]);

  const onReScheduleClick = (params: any) => {
    history(RESCHEDULE_RIDE, { state: { params: params } });
  };

  const column = [
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      flex: 1,
      type: 'date',
      valueGetter: (params: { row: { pickupTime: any } }) => params.row.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <div className={classes.overflowSet}>{moment(params.row.pickupTime).format('DD MMM')}</div>
        </div>
      ),
    },
    {
      field: 'customer',
      headerName: 'Customer',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 260,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.customer?.firstName,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div className={classes.overflowSet}>
            {params.row?.customer ? `${params.row?.customer?.firstName} ${params.row?.customer?.lastName}` : ''}
          </div>
        </div>
      ),
    },
    {
      field: 'pickUp',
      headerName: 'Pick-up & drop-off',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 260,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupGrid}>
          <img src={`/images/pickupIcon.png`} alt='' className={classes.pickUpIcon} />
          <div className={classes.pickupDetail}>
            <div className={classes.overflowSet}>
              {params.row?.pickupRefIdLocation ? params.row?.pickupRefIdLocation?.locationName : 'No Data'}
            </div>
            <div className={classes.overflowSet}>
              {params.row?.dropoffRefIdLocation ? params.row?.dropoffRefIdLocation?.locationName : 'No Data'}
            </div>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      field: 'pickupTime',
      headerName: 'Time',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 160,
      valueGetter: (params: { row: any }) => params.row?.actualPickupTime ?? params.row?.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div className={classes.overflowSet}>
            {params.row?.actualPickupTime
              ? moment(params.row?.actualPickupTime).format('hh:mm A')
              : moment(params.row?.pickupTime).format('hh:mm A')}
          </div>
          <div className={classes.overflowSet}>
            {params.row?.actualDropOffTime
              ? moment(params.row?.actualDropOffTime).format('hh:mm A')
              : moment(params.row?.estimatedDroppoff).format('hh:mm A')}
          </div>
        </div>
      ),
    },
    {
      field: 'refIdModes',
      headerName: 'Mode',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: any) => params.row.refIdModes.modeName,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className={classes.overflowSet}>{params?.row?.refIdModes?.modeName ?? ''}</div>
      ),
    },
    {
      field: 'addons',
      headerName: 'Addons',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className={classes.overflowSet}>
          {params.row?.addons &&
            params.row?.addons
              .filter((addon: any) => addon.quantity)
              .map((addon: any) => (
                <div key={addon?.refIdAddonItem}>
                  {addon?.itemDetails?.itemName} x {addon?.quantity}
                </div>
              ))}
        </div>
      ),
      sortable: false,
    },
    {
      field: 'pricing',
      headerName: 'Ride Fare',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: any) => params.row.pricing.total,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div className={classes.overflowSet}>{`${
            params.row?.pricing?.currency ? params.row?.pricing?.currency : 'AED'
          }  ${params.row.pricing?.total ? params.row.pricing.total : 0}`}</div>
        );
      },
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      sortable: false,
    },
    {
      field: 'area',
      headerName: 'Area',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className={classes.overflowSet}>{params.row.pickupRefIdLocation.locationName}</div>
      ),
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      renderCell: (param: GridRenderCellParams<string>) => (
        <div className={classes.actionCell}>
          <Chip label='Re-Schedule' variant={'outlined'} onClick={() => onReScheduleClick(param.row)} />
        </div>
      ),
      sortable: false,
    },
  ];

  if (process.env.REACT_APP_DISPLAY_DEBUG_FIELDS) {
    column.splice(0, 0, {
      field: 'statusID',
      headerName: 'Id / Status',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.idJourney,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div className={classes.overflowSet}>
            <div>{params.row?.idJourney}</div>
            <div>{params.row?.status}</div>
          </div>
        </div>
      ),
    });
  }

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Reschedule Rides '}
        buttonText={' '}
        handleOpen={handleOpen}
        showButton={false}
        searchListKeys={searchListKeys}
        setSearchInput={setSearchInput}
        subject={SUBJECTS.BOOKING_MANAGEMENT.toString()}
      />
      <Box className={classes.tableHeader}>
        <DataGrid
          rows={bookingData}
          columns={column}
          rowHeight={100}
          disableColumnFilter
          disableColumnMenu
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationMode='server'
          onPageChange={(newPage) => {
            setPage(newPage);
            setOffset(newPage);
          }}
          rowsPerPageOptions={[10, 20, 100]}
          rowCount={totalRows}
        />
      </Box>
    </div>
  );
}

export default BookingRescheduled;
