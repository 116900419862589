import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { ReactElement, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import {
  GetAllPromotions,
  useCreatePromoCodeMutation,
  useUpdatePromoCodeMutation,
  useGetAllPromotionsQueryResult,
  useGetAreasLazyQuery,
} from 'gql/generated/query.graphql';
import { PromoCodeProps } from './constants/typing';
import { RegEx } from 'utils/Regex';
import { schema } from '../promo-code-management/constants/yupSchema';
import Strings from '../promo-code-management/constants/strings';
import {
  promoApplications,
  promoSpecification,
  promoTypes,
  statusTypes,
  typesOfUsers,
  unitTypes,
} from '../promo-code-management/constants/constants';

const AddPromoCode = ({ open, handleClose, title, params }: PromoCodeProps): ReactElement => {
  const [promoCodeVal, setPromoCodeVal] = useState('');
  const [promoDescriptionVal, setPromoDescriptionVal] = useState('');
  const [promoTitleVal, setPromoTitleVal] = useState('');
  const [promoUnitVal, setPromoUnitVal] = useState('');
  const [promoTypesVal, setPromoTypesVal] = useState('');
  const [promoDiscountVal, setPromoDiscountVal] = useState<null | number | string>();
  const [promoMinBaseFareVal, setPromoMinBaseFareVal] = useState<null | number>();
  const [promoTotalUsageLimitVal, setPromoTotalUsageLimitVal] = useState<null | number>();
  const [promoPerCustomerUsageLimitVal, setPromoPerCustomerUsageLimitVal] = useState<null | number>();
  const [promoActiveFromDateVal, setPromoActiveFromDateVal] = useState<null | string>(null);
  const [promoActiveTillDateVal, setPromoActiveTillDateVal] = useState<null | string>(null);
  const [promoIsActiveVal, setPromoIsActiveVal] = useState(true);
  const [promoMaxDiscountVal, setPromoMaxDiscountVal] = useState<null | number | string>();
  const [promoUsageLimitPerMonthVal, setPromoUsageLimitPerMonthVal] = useState<null | number | string>('');
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState('');
  const [promoTitleErrorMessage, setPromoTitleErrorMessage] = useState('');
  const [promoDescriptionErrorMessage, setPromoDescriptionErrorMessage] = useState('');
  const [promoDateErrorMessage, setPromoDateErrorMessage] = useState('');
  const [promoDiscountErrorMessage, setPromoDiscountErrorMessage] = useState<string>('');
  const [promoTierWiseDiscountErrorMessage, setPromoTierWiseDiscountErrorMessage] = useState<string>('');
  const [promoTotalUsageLimitErrorMessage, setPromoTotalUsageLimitErrorMessage] = useState<string>('');
  const [promoPerCustomerUsageLimitErrorMessage, setPromoPerCustomerUsageLimitErrorMessage] = useState<string>('');
  const [promoMaxDiscountErrorMessage, setPromoMaxDiscountErrorMessage] = useState<string>('');
  const [promoTierWiseError, setPromoTierWiseError] = useState<string>('');
  const [tierDiscountErrorIndex, setTierDiscountErrorIndex] = useState<number[]>([]);
  const [tierDiscountError, setTierDiscountError] = useState<number[]>([]);
  const [promoUsageLimitPerMonthErrorMessage, setPromoUsageLimitPerMonthErrorMessage] = useState('');
  const [promoMinBaseFareErrorMessage, setPromoMinBaseFareErrorMessage] = useState('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [areaList, setAreaList] = useState<any | []>([]);
  const [promoUserTypesVal, setPromoUserTypesVal] = useState('');
  const [promoLocationVal, setPromoLocationVal] = useState<null | number | string>();
  const [promoTierWiseDiscount, setPromoTierWiseDiscount] = useState<number[][]>([[]]);
  const [promoApplicationVal, setPromoApplicationVal] = useState(promoApplications[0].value);
  const [promoSpecificationVal, setPromoSpecificationVal] = useState(promoSpecification[0].value);
  const [promoMinDate, setPromoMinDate] = useState<string | null>(null);
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const minDate = new Date(Date.now());
  const { data } = useGetAllPromotionsQueryResult({});
  let promoDetails = data?.getAllPromotions || [];
  const [getAreas, { data: areasData }] = useGetAreasLazyQuery();

  const [createPromoCode] = useCreatePromoCodeMutation({
    onError: () => setError(true),
    onCompleted: () => handleClose(),
  });

  const [updatePromoCode] = useUpdatePromoCodeMutation({
    onError: () => setError(true),
    onCompleted: () => handleClose(),
  });

  const isFormCompleted = () => {
    const requiredFields = [
      promoCodeVal,
      promoDescriptionVal,
      promoTitleVal,
      promoUnitVal,
      promoSpecificationVal,
      promoApplicationVal,
      promoTypesVal,
      promoMinBaseFareVal,
      promoTotalUsageLimitVal,
      promoPerCustomerUsageLimitVal,
      promoActiveFromDateVal,
      promoActiveTillDateVal,
      promoMaxDiscountVal,
      promoUsageLimitPerMonthVal,
    ];

    if (promoTierWiseDiscountErrorMessage !== '') return false;

    if (promoDiscountErrorMessage !== '') return false;

    if (promoCodeErrorMessage !== '') return false;

    if (promoTitleErrorMessage !== '') return false;

    if (promoDescriptionErrorMessage !== '') return false;

    if (promoTierWiseError !== '') return false;

    if (promoMinBaseFareErrorMessage !== '') return false;

    if (promoUsageLimitPerMonthErrorMessage !== '') return false;

    if (promoTotalUsageLimitErrorMessage !== '') return false;

    if (promoPerCustomerUsageLimitErrorMessage !== '') return false;

    if (promoMaxDiscountErrorMessage !== '') return false;

    const isSimplePromo = promoApplicationVal.toLowerCase() === promoApplications[0].value.toLocaleLowerCase();
    const isSpecificPromo = promoSpecificationVal.toLowerCase() === promoSpecification[1].value.toLowerCase();
    const isLocationValid = promoLocationVal !== '';
    const isLocationRequired = isSpecificPromo && !isLocationValid;

    if (!requiredFields.every((field) => field && !promoDateErrorMessage)) return false;

    if (isLocationRequired) return false;

    if (isSimplePromo) return promoDiscountVal && promoUserTypesVal;
    else return promoTierWiseDiscount.flat().length === promoTierWiseDiscount.length;
  };

  const updateFormCompleted = () => (isFormCompleted() ? setFormCompleted(true) : setFormCompleted(false));

  const eventCheck = (pressedButton: string) => ['-', '+', 'e'].includes(pressedButton);

  const getAllData = (input: string | null) =>
    getAreas({
      variables: {
        filter: {
          name: input || '',
        },
      },
    });

  const handleAddTierDiscountField = () => setPromoTierWiseDiscount([...promoTierWiseDiscount, []]);

  const handleRemoveTierDiscountField = (index: number) => {
    const newPromoTierWiseDiscountArray = [...promoTierWiseDiscount];
    newPromoTierWiseDiscountArray.length > 1 && newPromoTierWiseDiscountArray.splice(index, 1);
    setPromoTierWiseDiscount([...newPromoTierWiseDiscountArray]);
  };

  useEffect(updateFormCompleted, [
    promoCodeVal,
    promoDescriptionVal,
    promoTitleVal,
    promoUnitVal,
    promoTypesVal,
    promoDiscountVal,
    promoMinBaseFareVal,
    promoTotalUsageLimitVal,
    promoPerCustomerUsageLimitVal,
    promoActiveFromDateVal,
    promoActiveTillDateVal,
    promoMaxDiscountVal,
    promoUsageLimitPerMonthVal,
    promoDateErrorMessage,
    promoLocationVal,
    promoUserTypesVal,
    promoApplicationVal,
    promoSpecificationVal,
    promoTierWiseDiscount,
  ]);

  const settingPromoStates = () => {
    setPromoCodeVal(params.promoCode);
    setPromoDescriptionVal(params.promoDescription);
    setPromoTitleVal(params.promoTitle);
    setPromoUnitVal(params.unit);
    setPromoTypesVal(params.promoType);
    setPromoDiscountVal(params.discount);
    setPromoMinBaseFareVal(params.minimumBaseFare);
    setPromoTotalUsageLimitVal(params.totalUsageLimit);
    setPromoPerCustomerUsageLimitVal(params.perCustomerUsageLimit);
    setPromoActiveFromDateVal(params.activeFromDate);
    setPromoActiveTillDateVal(params.activeTillDate);
    setPromoMaxDiscountVal(params.maxDiscount);
    setPromoUsageLimitPerMonthVal(params.promoUsageLimitPerMonth);
    setPromoLocationVal(params.refIdZedGeometry);
    setPromoUserTypesVal(params.typesOfUsers);
    setPromoMinDate(params.activeFromDate);
    setPromoApplicationVal(params.promoApplication);
    setPromoSpecificationVal(params.promoSpecification);
    setPromoTierWiseDiscount(params.tierDiscounts);
  };

  useEffect(() => {
    if (params !== '') {
      settingPromoStates();
    }
  }, [params]);

  useEffect(() => {
    updateFormCompleted();
  }, [
    promoCodeErrorMessage,
    promoTitleErrorMessage,
    promoDescriptionErrorMessage,
    promoDiscountErrorMessage,
    promoTierWiseDiscountErrorMessage,
    promoTierWiseError,
    promoMinBaseFareErrorMessage,
    promoUsageLimitPerMonthErrorMessage,
    promoTotalUsageLimitErrorMessage,
    promoPerCustomerUsageLimitErrorMessage,
    promoMaxDiscountErrorMessage,
  ]);

  useEffect(() => {
    if (RegEx.PROMO_CODE.test(promoCodeVal)) setPromoCodeErrorMessage('');
    else setPromoCodeErrorMessage(Strings.promo.promoCodeError);

    if (RegEx.PROMO_TITLE.test(promoTitleVal)) setPromoTitleErrorMessage('');
    else setPromoTitleErrorMessage(Strings.promo.promoCodeTitleError);

    if (RegEx.PROMO_DESCRIPTION.test(promoDescriptionVal)) setPromoDescriptionErrorMessage('');
    else setPromoDescriptionErrorMessage(Strings.promo.promoCodeDescriptionError);
  }, [promoCodeVal, promoTitleVal, promoDescriptionVal]);

  useEffect(() => {
    if (promoActiveFromDateVal && promoActiveTillDateVal) {
      const fromDateObj = new Date(promoActiveFromDateVal);
      const fromTillObj = new Date(promoActiveTillDateVal);

      if (fromDateObj > fromTillObj) setPromoDateErrorMessage(Strings.date.dateError);
      else setPromoDateErrorMessage('');
    } else setPromoDateErrorMessage('');
  }, [promoActiveFromDateVal, promoActiveTillDateVal]);

  useEffect(() => {
    areasData && typeof areasData === 'object' && setAreaList(areasData?.getAreas);
  }, [areasData]);

  useEffect(() => {
    getAllData('');
  }, []);

  useEffect(() => {
    if (promoApplicationVal.toLowerCase() === promoApplications[1].value.toLowerCase()) {
      setPromoUserTypesVal(typesOfUsers[1].value);
      setPromoDiscountVal('');
    } else {
      if (params === '') {
        setPromoUserTypesVal('');
        setPromoDiscountVal('');
      }
    }
  }, [promoApplicationVal]);

  useEffect(() => {
    promoSpecificationVal.toLowerCase() === promoSpecification[1].value.toLowerCase() &&
      !promoLocationVal &&
      setPromoLocationVal('');
  }, [promoSpecificationVal]);

  const checkingPromoUsageLimit = () =>
    promoTierWiseDiscount.length
      ? setPromoUsageLimitPerMonthVal(promoTierWiseDiscount.length)
      : setPromoUsageLimitPerMonthVal('');

  useEffect(() => {
    promoApplicationVal.toLowerCase() === promoApplications[1].value.toLowerCase()
      ? checkingPromoUsageLimit()
      : params === '' && setPromoUsageLimitPerMonthVal('');
  }, [promoTierWiseDiscount, promoApplicationVal]);

  const setMaxDiscountEmpty = () => params === '' && setPromoMaxDiscountVal('');

  const maxDiscountCheck = () =>
    promoDiscountVal === 0 ? setPromoMaxDiscountVal('') : setPromoMaxDiscountVal(promoDiscountVal);

  const setMaxDiscount = () => (promoUnitVal === unitTypes[0].value ? maxDiscountCheck() : setMaxDiscountEmpty());

  useEffect(() => {
    if (promoDiscountVal && +promoDiscountVal !== 0) setMaxDiscount();
  }, [promoUnitVal, promoDiscountVal, promoTypes, promoApplicationVal]);

  const checkingMaxOfAllTiers = () =>
    Math.max(...promoTierWiseDiscount.flat())
      ? setPromoMaxDiscountVal(Math.max(...promoTierWiseDiscount.flat()))
      : setPromoMaxDiscountVal('');

  useEffect(() => {
    if (promoApplicationVal.toLowerCase() === promoApplications[1].value.toLowerCase()) {
      if (promoUnitVal.toLowerCase() === unitTypes[0].value.toLowerCase()) checkingMaxOfAllTiers();
      else if (promoUnitVal.toLowerCase() === unitTypes[1].value.toLowerCase()) {
        !params && setPromoMaxDiscountVal('');
      }
    }
  }, [promoApplicationVal, promoTierWiseDiscount, promoUnitVal]);

  const isInvalidDiscount = (unitValue: string, discount: number | null | undefined | string) =>
    unitValue.toLowerCase() === unitTypes[1].value.toLowerCase() && discount && +discount > 100;

  useEffect(() => {
    if (promoDiscountVal && promoDiscountVal !== '0') {
      const errorMessage = isInvalidDiscount(promoUnitVal, +promoDiscountVal)
        ? `${Strings.promo.percentageDiscountValueError}`
        : '';

      setPromoDiscountErrorMessage(errorMessage);
    }
  }, [promoDiscountVal, promoUnitVal]);

  const isInvalidTierDiscount = (unitValue: string, tierWiseDiscount: number[][]) => {
    const tierErrorIndexes =
      unitValue.toLowerCase() === unitTypes[1].value.toLowerCase() &&
      tierWiseDiscount
        .map((value, index) => {
          const discountValue = Array.isArray(value) ? value[0] : value;
          if (discountValue > 100) return index;
          else return -1;
        })
        .filter((index) => index !== -1);

    Array.isArray(tierErrorIndexes) && tierErrorIndexes.length > 0 && setTierDiscountErrorIndex(tierErrorIndexes);
    return Array.isArray(tierErrorIndexes) && tierErrorIndexes.length > 0;
  };

  useEffect(() => {
    if (promoTierWiseDiscount) {
      const errorMessage = isInvalidTierDiscount(promoUnitVal, promoTierWiseDiscount)
        ? `${Strings.promo.percentageDiscountValueError}`
        : '';

      setPromoTierWiseDiscountErrorMessage(errorMessage);
    }
  }, [promoTierWiseDiscount, promoUnitVal]);

  const isInvalidTierDiscountCheck = (tierWiseDiscount: number[][]) => {
    const tierErrorIndexes = tierWiseDiscount
      .map((value, index) => {
        const discountValue = Array.isArray(value) ? value[0] : value;
        if (discountValue <= 0) return index;
        else return -1;
      })
      .filter((index) => index !== -1);

    Array.isArray(tierErrorIndexes) && tierErrorIndexes.length > 0 && setTierDiscountError(tierErrorIndexes);
    return Array.isArray(tierErrorIndexes) && tierErrorIndexes.length > 0;
  };

  useEffect(() => {
    if (promoTierWiseDiscount) {
      const errorMessage = isInvalidTierDiscountCheck(promoTierWiseDiscount)
        ? `${Strings.promo.discountValueError}`
        : '';

      setPromoTierWiseError(errorMessage);
    }
  }, [promoTierWiseDiscount, promoUnitVal]);

  useEffect(() => {
    if (promoDiscountVal && +promoDiscountVal <= 0) setPromoDiscountErrorMessage(Strings.promo.discountValueError);
  }, [promoDiscountVal]);

  useEffect(() => {
    setPromoDiscountErrorMessage('');
    setPromoTitleErrorMessage('');
  }, [promoApplicationVal]);

  useEffect(() => {
    if (promoUsageLimitPerMonthVal !== undefined) {
      const errorMessage =
        promoUsageLimitPerMonthVal === null || (+promoUsageLimitPerMonthVal <= 0 && promoUsageLimitPerMonthVal !== '')
          ? `${Strings.promo.promoUsagelimitErrorMessage}`
          : '';

      setPromoUsageLimitPerMonthErrorMessage(errorMessage);
    }
  }, [promoUsageLimitPerMonthVal]);

  useEffect(() => {
    if (promoMinBaseFareVal !== undefined) {
      const errorMessage =
        promoMinBaseFareVal === null || +promoMinBaseFareVal <= 0 ? `${Strings.promo.minimumBaseFareErrorMessage}` : '';

      setPromoMinBaseFareErrorMessage(errorMessage);
    }
  }, [promoMinBaseFareVal]);

  useEffect(() => {
    if (promoTotalUsageLimitVal !== undefined) {
      const errorMessage =
        promoTotalUsageLimitVal === null || +promoTotalUsageLimitVal <= 0
          ? `${Strings.promo.totalUsageLimitMinimumValErrorMessage}`
          : '';

      setPromoTotalUsageLimitErrorMessage(errorMessage);
    }
  }, [promoTotalUsageLimitVal]);

  useEffect(() => {
    if (promoPerCustomerUsageLimitVal !== undefined) {
      const errorMessage =
        promoPerCustomerUsageLimitVal === null || +promoPerCustomerUsageLimitVal <= 0
          ? `${Strings.promo.perCustomerUsageLimitMinimumValErrorMessage}`
          : '';

      setPromoPerCustomerUsageLimitErrorMessage(errorMessage);
    }
  }, [promoPerCustomerUsageLimitVal]);

  useEffect(() => {
    if (promoMaxDiscountVal !== undefined) {
      const errorMessage =
        promoMaxDiscountVal === null || +promoMaxDiscountVal <= 0
          ? `${Strings.promo.maxDisountMinimumValErrorMessage}`
          : '';

      setPromoMaxDiscountErrorMessage(errorMessage);
    }
  }, [promoMaxDiscountVal]);

  useEffect(() => {
    if (promoTotalUsageLimitVal && promoPerCustomerUsageLimitVal) {
      if (+promoTotalUsageLimitVal < +promoPerCustomerUsageLimitVal) {
        if (promoMaxDiscountVal) {
          if (+promoTotalUsageLimitVal < +promoMaxDiscountVal)
            setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForBoth);
          else setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForPerCustomerUsageLimit);
        } else setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForPerCustomerUsageLimit);
      } else setPromoTotalUsageLimitErrorMessage('');
    }

    if (promoPerCustomerUsageLimitVal && promoMaxDiscountVal) {
      if (+promoPerCustomerUsageLimitVal < +promoMaxDiscountVal)
        setPromoPerCustomerUsageLimitErrorMessage(Strings.promo.perCustomerUsageLimitErrorForMaxDiscount);
      else setPromoPerCustomerUsageLimitErrorMessage('');
    }

    if (promoTotalUsageLimitVal && promoMaxDiscountVal) {
      if (+promoTotalUsageLimitVal < +promoMaxDiscountVal) {
        if (promoPerCustomerUsageLimitVal) {
          if (+promoTotalUsageLimitVal < +promoPerCustomerUsageLimitVal)
            setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForBoth);
          else setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForMaxDiscount);
        } else setPromoTotalUsageLimitErrorMessage(Strings.promo.totalUsageLimitErrorForMaxDiscount);
      }
    }

    if (promoTotalUsageLimitVal && promoPerCustomerUsageLimitVal && promoMaxDiscountVal) {
      if (
        promoTotalUsageLimitVal >= promoPerCustomerUsageLimitVal &&
        promoPerCustomerUsageLimitVal >= +promoMaxDiscountVal
      ) {
        if (+promoTotalUsageLimitVal !== 0 && +promoPerCustomerUsageLimitVal !== 0 && +promoMaxDiscountVal !== 0) {
          setPromoTotalUsageLimitErrorMessage('');
          setPromoPerCustomerUsageLimitErrorMessage('');
          setPromoMaxDiscountErrorMessage('');
        }
      }
    }
  }, [promoTotalUsageLimitVal, promoPerCustomerUsageLimitVal, promoMaxDiscountVal]);

  const promoInputData = (input: string) => {
    let mutationName = input === 'create' ? createPromoCode : updatePromoCode;
    mutationName({
      variables: {
        input: {
          idPromotion: params.idPromotion,
          promoCode: promoCodeVal,
          promoDescription: promoDescriptionVal,
          promoTitle: promoTitleVal,
          promoType: promoTypesVal,
          unit: promoUnitVal,
          discount: +promoDiscountVal! || 0,
          minimumBaseFare: +promoMinBaseFareVal!,
          totalUsageLimit: +promoTotalUsageLimitVal!,
          perCustomerUsageLimit: +promoPerCustomerUsageLimitVal!,
          activeFromDate: promoActiveFromDateVal,
          activeTillDate: promoActiveTillDateVal,
          isActive: promoIsActiveVal,
          maxDiscount: +promoMaxDiscountVal!,
          promoUsageLimitPerMonth: +promoUsageLimitPerMonthVal!,
          typesOfUsers: promoUserTypesVal,
          promoApplication: promoApplicationVal,
          refIdZedGeometry: +promoLocationVal! || 0,
          promoSpecification: promoSpecificationVal,
          promoTierWiseDiscount: promoTierWiseDiscount,
        },
      },
      refetchQueries: [GetAllPromotions, 'getAllPromotion'],
    });
  };

  const onSave = () => {
    if (params !== '') promoInputData('update');
    else {
      const isPromoCodeExists = promoDetails?.some(
        (promotion) => promotion.promoCode.toLowerCase() === promoCodeVal.toLowerCase()
      );

      if (isPromoCodeExists) setPromoCodeErrorMessage(Strings.promo.promoAlreadyExists);
      else promoInputData('create');
    }
  };

  const closingModal = () => {
    handleClose();
    settingPromoStates();
  };

  const renderDropdown = (array: any) =>
    array.map((type: any, index: any) => (
      <MenuItem className={classes.transformText} key={index} value={type.value}>
        {type.label}
      </MenuItem>
    ));

  const renderAreaList = areaList.map(
    (area: any) =>
      area?.isEnable && (
        <MenuItem className={classes.transformText} key={area.idArea} value={area.idArea}>
          {area.areaName}
        </MenuItem>
      )
  );

  const locationSelection = (promoLocationVal: string | number | undefined | null) => {
    return typeof promoLocationVal === 'string'
      ? (() => {
          if (promoLocationVal !== '') {
            return areasData?.getAreas.filter((area) => area.areaName === promoLocationVal)[0].idArea;
          } else {
            return promoLocationVal;
          }
        })()
      : promoLocationVal === 0
      ? ''
      : promoLocationVal;
  };

  const renderPromoSpecificationVal = promoSpecificationVal.toLowerCase() ===
    promoSpecification[1].value.toLocaleLowerCase() && (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>Select Location</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        name='promoLocationVal'
        value={locationSelection(promoLocationVal)}
        label='promoLocationVal'
        onChange={(event) => setPromoLocationVal(+event!.target!.value!)}
      >
        {renderAreaList}
      </Select>
    </FormControl>
  );

  const renderPromoApplicationValOrTierWiseDiscount =
    promoApplicationVal.toLowerCase() === promoApplications[0].value.toLowerCase() ? (
      <TextField
        margin='normal'
        fullWidth
        id='discount'
        placeholder='Discount'
        name='discount'
        type='number'
        inputProps={{ min: 1, max: 100 }}
        inputMode='decimal'
        autoComplete='minimumBaseFare'
        value={promoDiscountVal}
        onChange={(event) => setPromoDiscountVal(event.target.value)}
        onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
        error={promoDiscountErrorMessage ? true : false}
        helperText={promoDiscountErrorMessage}
      />
    ) : (
      <>
        {promoTierWiseDiscount.map((_tierDiscount, index) => (
          <div key={index} className={classes.discountField}>
            <TextField
              margin='normal'
              fullWidth
              id='discount'
              placeholder='Tier Discount'
              name='discount'
              type='number'
              inputProps={{ min: 1, max: 100 }}
              inputMode='decimal'
              autoComplete='minimumBaseFare'
              value={promoTierWiseDiscount[index]}
              onChange={(event: any) => {
                const newPromoTierWiseDiscountArray = [...promoTierWiseDiscount];
                newPromoTierWiseDiscountArray[index] = [+event.target.value];
                setPromoTierWiseDiscount(newPromoTierWiseDiscountArray);
              }}
              onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
              error={
                (tierDiscountErrorIndex.includes(index) && promoTierWiseDiscountErrorMessage ? true : false) ||
                (tierDiscountError.includes(index) && promoTierWiseError ? true : false)
              }
              helperText={
                (tierDiscountErrorIndex.includes(index) && promoTierWiseDiscountErrorMessage) ||
                (tierDiscountError.includes(index) && promoTierWiseError)
              }
            />
            <button className={classes.crossBtn} onClick={() => handleRemoveTierDiscountField(index)}>
              x
            </button>
          </div>
        ))}
        <Button
          className={classes.plusBtn}
          sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }}
          onClick={handleAddTierDiscountField}
        >
          +
        </Button>
      </>
    );

  const renderPromoApplicationVal = promoApplicationVal.toLowerCase() !==
    promoApplications[1].value.toLocaleLowerCase() && (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>User Type</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        name='typesOfUsers'
        value={promoUserTypesVal}
        label='typesOfUsers'
        onChange={(event) => setPromoUserTypesVal(event.target.value)}
      >
        {renderDropdown(typesOfUsers)}
      </Select>
    </FormControl>
  );

  const handleMaxDiscountChange = (event: any) => setPromoMaxDiscountVal(event.target.value);

  const checkForCustomerMaxDiscount = () => {
    if (promoUnitVal !== unitTypes[0].value) {
      return (
        <TextField
          margin='normal'
          fullWidth
          id='maxDiscount'
          placeholder='Max Discount'
          name='maxDiscount'
          type='number'
          inputProps={{ min: 1 }}
          inputMode='decimal'
          autoComplete='maxDiscount'
          value={promoMaxDiscountVal}
          onChange={handleMaxDiscountChange}
          error={promoMaxDiscountErrorMessage ? true : false}
          helperText={promoMaxDiscountErrorMessage}
        />
      );
    }
  };

  const checkForCustomPerCustomerUsageLimit = promoApplicationVal !== promoApplications[1].value && (
    <TextField
      margin='normal'
      fullWidth
      id='promoUsageLimitPerMonth'
      placeholder='Promo usage limit customer per month'
      name='promoUsageLimitPerMonth'
      type='number'
      inputProps={{ min: 1 }}
      inputMode='decimal'
      autoComplete='promoUsageLimitPerMonth'
      value={promoUsageLimitPerMonthVal}
      onChange={(event: any) => setPromoUsageLimitPerMonthVal(event.target.value)}
      onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
      error={promoUsageLimitPerMonthErrorMessage ? true : false}
      helperText={promoUsageLimitPerMonthErrorMessage}
    />
  );

  const minimumDateCalculation = () => {
    if (params !== '') {
      if (promoMinDate) {
        if (new Date(promoMinDate) > minDate) return minDate;
        else return promoMinDate;
      }
    } else return minDate;
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <div className={classes.formGrid}>
            <TextField
              margin='normal'
              fullWidth
              id='promoCode'
              placeholder='Promo Code'
              name='promoCode'
              autoComplete='promoCode'
              autoFocus
              value={promoCodeVal}
              onChange={(event) => setPromoCodeVal(event.target.value)}
              error={promoCodeErrorMessage ? true : false}
              helperText={promoCodeErrorMessage}
            />
            <TextField
              margin='normal'
              fullWidth
              id='promoDescription'
              placeholder='Promo Description'
              name='promoDescription'
              autoComplete='promoDescription'
              value={promoDescriptionVal}
              onChange={(event) => setPromoDescriptionVal(event.target.value)}
              error={promoDescriptionErrorMessage ? true : false}
              helperText={promoDescriptionErrorMessage}
            />
            <TextField
              margin='normal'
              fullWidth
              id='promoTitle'
              placeholder='Promo Title'
              name='promoTitle'
              autoComplete='promoTitle'
              value={promoTitleVal}
              onChange={(event) => setPromoTitleVal(event.target.value)}
              error={promoTitleErrorMessage ? true : false}
              helperText={promoTitleErrorMessage}
            />
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Promo Application</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='promoApplicationVal'
                value={promoApplicationVal}
                label='promoApplicationVal'
                onChange={(event) => setPromoApplicationVal(event.target.value)}
              >
                {renderDropdown(promoApplications)}
              </Select>
            </FormControl>
            {renderPromoApplicationValOrTierWiseDiscount}
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Promo Location</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='promoApplicationVal'
                value={promoSpecificationVal}
                label='promoApplicationVal'
                onChange={(event) => setPromoSpecificationVal(event.target.value)}
              >
                {renderDropdown(promoSpecification)}
              </Select>
            </FormControl>
            {renderPromoSpecificationVal}
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Promo Type</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='promoType'
                value={promoTypesVal}
                label='promoType'
                onChange={(event) => setPromoTypesVal(event.target.value)}
              >
                {renderDropdown(promoTypes)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Unit</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='unitType'
                value={promoUnitVal}
                label='Unit'
                onChange={(event) => setPromoUnitVal(event.target.value)}
              >
                {renderDropdown(unitTypes)}
              </Select>
            </FormControl>
            {renderPromoApplicationVal}
            <TextField
              margin='normal'
              fullWidth
              id='minimumBaseFare'
              placeholder='Minimum Base Fare'
              name='minimumBaseFare'
              type='number'
              inputProps={{ min: 1 }}
              inputMode='decimal'
              autoComplete='minimumBaseFare'
              value={promoMinBaseFareVal}
              onChange={(event: any) => setPromoMinBaseFareVal(event.target.value)}
              onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
              error={promoMinBaseFareErrorMessage ? true : false}
              helperText={promoMinBaseFareErrorMessage}
            />
            <TextField
              margin='normal'
              fullWidth
              id='totalUsageLimit'
              placeholder='Total usage limit'
              name='totalUsageLimit'
              type='number'
              inputProps={{ min: 1 }}
              inputMode='decimal'
              autoComplete='totalUsageLimit'
              value={promoTotalUsageLimitVal}
              onChange={(event: any) => setPromoTotalUsageLimitVal(event.target.value)}
              onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
              error={promoTotalUsageLimitErrorMessage ? true : false}
              helperText={promoTotalUsageLimitErrorMessage}
            />
            <TextField
              margin='normal'
              fullWidth
              id='perCustomerUsageLimit'
              placeholder='Per customer overall amount limit'
              name='perCustomerUsageLimit'
              type='number'
              inputProps={{ min: 0 }}
              inputMode='decimal'
              autoComplete='perCustomerUsageLimit'
              value={promoPerCustomerUsageLimitVal}
              onChange={(event: any) => setPromoPerCustomerUsageLimitVal(event.target.value)}
              onKeyPress={(event) => eventCheck(event.key) && event.preventDefault()}
              error={promoPerCustomerUsageLimitErrorMessage ? true : false}
              helperText={promoPerCustomerUsageLimitErrorMessage}
            />
            <div className={classes.dateError}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Active From Date'
                  value={promoActiveFromDateVal}
                  minDate={minimumDateCalculation()}
                  onChange={(newValue: any) => {
                    const dateObj = new Date(newValue);
                    const momentObj = moment(dateObj).format('YYYY-MM-DD');
                    setPromoActiveFromDateVal(momentObj);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <span className={classes.dateErrorMessage}>{promoDateErrorMessage}</span>
              </LocalizationProvider>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Active Till Date'
                value={promoActiveTillDateVal && new Date(promoActiveTillDateVal).getTime() - 300 * 60 * 1000}
                minDate={minDate}
                onChange={(newValue: any) => {
                  const dateObj = new Date(newValue);
                  const momentObj = moment(dateObj).format('YYYY-MM-DD');
                  setPromoActiveTillDateVal(momentObj);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Status</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='statusTypes'
                value={promoIsActiveVal}
                label='statusTypes'
                onChange={(event) => setPromoIsActiveVal(`${event.target.value}`.toLowerCase() === 'true')}
              >
                {renderDropdown(statusTypes)}
              </Select>
            </FormControl>
            {checkForCustomerMaxDiscount()}
            {checkForCustomPerCustomerUsageLimit}
            <div className={classes.actionBtn}>
              <Button size='medium' onClick={onSave} disabled={!formCompleted}>
                Save
              </Button>
              <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={closingModal}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddPromoCode;

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 750,
  },
  formGrid: {
    alignItems: 'center',
    display: 'Grid',
    gap: 10,
    gridTemplateColumns: '350px 350px',
  },
  actionBtn: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: 'auto auto',
  },
  transformText: {
    textTransform: 'capitalize',
  },
  dateErrorMessage: {
    color: 'red',
    display: 'flex',
    fontSize: 'small',
    position: 'relative',
  },
  dateError: {
    display: 'Grid',
  },
  discountField: {
    alignItems: 'baseline',
    display: 'flex',
  },
  crossBtn: {
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    color: '#949494',
    cursor: 'pointer',
    height: 53,
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 2,
    width: 50,
    '&:hover': {
      backgroundColor: 'rgb(148 148 148 / 50%)',
    },
  },
  plusBtn: {
    border: '1px solid #c4c4c4',
    height: 53,
  },
});
