import moment from 'moment';
import * as Yup from 'yup';

export const modeFeatures = [
  { key: 0, value: 'AC-Temperature', label: 'AC-Temperature' },
  { key: 1, value: 'Ambient Lightings', label: 'Ambient Lightings' },
  {
    key: 2,
    value: 'Music System - Bluetooth Connectivity',
    label: 'Music System - Bluetooth Connectivity',
  },
  {
    key: 2,
    value: 'Music System - Audio cable Connectivity',
    label: 'Music System - Audio cable Connectivity',
  },
  { key: 5, value: 'Hoodie Customisation', label: 'Hoodie Customisation' },
  { key: 6, value: 'Freezer Availability', label: 'Freezer Availability' },
  { key: 7, value: 'Charging Point', label: 'Charging Point' },
  { key: 8, value: 'WIFI Availability', label: 'WIFI Availability' },
  { key: 9, value: 'Water', label: 'Water' },
  { key: 10, value: 'Coffee', label: 'Coffee' },
];

export const schema = Yup.object().shape({
  vehicleNumber: Yup.string().typeError('Vehicle Number is required').required('Vehicle Number is required'),
  vehicleChasisNumber: Yup.string().typeError('Vehicle chasis number is required').required('Vehicle chasis number is required'),
  vehicleRegistration: Yup.string().typeError('Vehicle registration emirate is required').required('Vehicle registration emirate is required'),
  vehicleClassification: Yup.string().typeError('Vehicle classification is required').required('Vehicle classification is required'),
  vehicleBrand: Yup.string().typeError('Vehicle Brand is required').required('Vehicle Brand is required'),
  vehicleCategory: Yup.string().typeError('Vehicle category is required').required('Vehicle category is required'),
  carType: Yup.string().typeError('Car type is required').required('Car type is required'),
  vehicleSeating: Yup.string().typeError('Vehicle seating capacity is required').required('Vehicle seating capacity is required'),
  supplierName: Yup.string().typeError('Supplier name is required').required('Supplier name is required'),
  kidSeatsAvailableInVehicle: Yup.string().typeError('Kids seats selection is required').required('Kids seats selection is required'),
  vehicleColour: Yup.string().typeError('Vehicle Colour is required').required('Vehicle Colour is required'),
  vehicleFuel: Yup.string().typeError('Vehicle fuel type is required').required('Vehicle fuel type is required'),
  vehicleModel: Yup.string().typeError('Vehicle model name is required').required('Vehicle model name is required'),
  manufacturingYear: Yup.string().typeError('Vehicle Manufacturing Year is required').required('Vehicle Manufacturing Year is required'),
  vehicleLicenseNumber: Yup.number()
    .typeError('Vehicle is required & must be a number')
    .required('Vehicle License Number is required'),
  licenseExpiryDate: Yup.mixed()
    .required('License date is required')
    .test('License date', 'License date is invalid', (date) => date && date > moment(new Date()).format('YYYY-MM-DD')),
  vehicleInsuranceValidity: Yup.mixed()
    .required('Vehicle Insurance date is required')
    .test(
      'Vehicle Insurance date',
      'Vehicle Insurance date is invalid',
      (date) => date && date > moment(new Date()).format('YYYY-MM-DD')
    ),
});

export const vehicleFields = [
  {
    name: 'vehicleNumber',
    placeHolder: 'Vehicle Number',
  },
  {
    name: 'vehicleChasisNumber',
    placeHolder: 'Vehicle Chasis Number',
  },
  {
    name: 'vehicleLicenseNumber',
    placeHolder: 'Vehicle License Number',
  },
];

export const vehicleLabels = [
  {
    name: 'vehicleClassification',
    placeHolder: 'Vehicle Classification',
  },
  {
    name: 'vehicleRegistration',
    placeHolder: 'Vehicle Registration Emirate',
  },
  {
    name: 'vehicleSeating',
    placeHolder: 'Vehicle Seating Capacity',
  },
  {
    name: 'vehicleCategory',
    placeHolder: 'Vehicle Category',
  },
  {
    name: 'kidSeatsAvailableInVehicle',
    placeHolder: 'Kid Seats Available in Vehicle',
  },
  {
    name: 'vehicleBrand',
    placeHolder: 'Vehicle Brand',
  },
  {
    name: 'carType',
    placeHolder: 'Car Type',
  },
  {
    name: 'vehicleColour',
    placeHolder: 'Vehicle Colour',
  },
  {
    name: 'vehicleFuel',
    placeHolder: 'Vehicle Fuel Type',
  },
  {
    name: 'vehicleModel',
    placeHolder: 'Vehicle Model Name',
  },
];

export const licenseNumberValidate = (number: string, emirate: string) => {
  if (emirate === 'Abu Dhabi') {
    return /^\d{1,5}$/.test(number);
  } else if (emirate === 'Ajman') {
    return /^[ABCDEH]\d{1,5}$/.test(number);
  } else if (emirate === 'Dubai') {
    return /^[A-Z]{1,2}\d{1,5}$/.test(number);
  } else if (emirate === 'Fujairah') {
    return /^[A-GKMPRST]\d{1,5}$/.test(number);
  } else if (emirate === 'Ras Al Khaimah') {
    return /^[ACDIKMNSVY]\d{1,5}$/.test(number);
  } else if (emirate === 'Sharjah') {
    return /^[123]\d{1,5}$/.test(number);
  } else if (emirate === 'Umm Al Quwain') {
    return /^[ABCDEFGHIX]\d{1,5}$/.test(number);
  }
};

export const idKidsCategory = (name: string) =>
  name === 'Zed Kids' || name === 'Zed Elite Kids';

export const isTaxi = (name: string) =>
  name === 'Zed Elite' ||
  name === 'Zed Elite Max' ||
  name === 'Zed Elite Kids';

export const vehicleColors: any = {
  limo: ['Black', 'White', 'Silver', 'Grey'],
  taxi: ['Arabia Taxi - Green', 'Cars Taxi - Blue', 'Dubai Taxi Corporation - Red', 'Dubai Taxi Corporation - Pink', 'Metro Taxi - Orange', 'National Taxi - Yellow']
};
