import { makeStyles } from '@mui/styles';

export default makeStyles({
  modalDialog: {
    '& .MuiDialogActions-root': {
      justifyContent: 'flex-start',
      padding: '8px 24px 20px 24px',

      '& .MuiButton-root': {
        padding: '2px 20px',
        height: '30px',
        fontSize: '13px',
      }
    },
  },
  deleteModal: {
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
});
