import { useState } from 'react';
import { Box, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddFare from '../add-fare';
import FareGridLayout from '../fareGridLayout';
import { FareTabs } from 'pages/fare-management';
import DeleteModal from 'common/deleteModal';

interface ControlProps {
  fare: any;
  fareList: any;
  updateFare: (data: UpdateFareInterface) => void;
  deleteFare: (fare: number) => void;
}

export interface UpdateFareInterface {
  baseMorningShift?: string;
  baseNightShift?: string;
  bookingFee?: string;
  farePerKm?: string;
  farePerMin?: string;
  idFare: number;
}

const FareControl: React.FC<ControlProps> = ({ fare, fareList, updateFare, deleteFare }: ControlProps) => {
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);

  const updateData = (data: UpdateFareInterface) => {
    setOpenEdit(false);
    updateFare({
      baseMorningShift: data?.baseMorningShift,
      baseNightShift: data?.baseNightShift,
      bookingFee: data?.bookingFee,
      farePerKm: data?.farePerKm,
      farePerMin: data?.farePerMin,
      idFare: fare.fare.idFare,
    });
  };

  const deleteSelectedFare = (area: any) => {
    setOpenDelete(false);
    deleteFare(area.fare.idFare);
  };

  return (
    <FareGridLayout
      type={FareTabs.BASE}
      multipleOf='1x'
      minFare={fare?.fare?.bookingFee}
      onEdit={() => setOpenEdit(true)}
      onDelete={() => setOpenDelete(true)}
    >
      {isOpenEdit && (
        <AddFare
          type='edit'
          isOpen={isOpenEdit}
          fare={fare}
          fareList={fareList}
          onSubmit={updateData}
          onClose={() => setOpenEdit(false)}
        />
      )}
      {isOpenDelete && (
        <DeleteModal
          open={isOpenDelete}
          onCancel={() => setOpenDelete(false)}
          onSubmit={() => deleteSelectedFare(fare)}
        />
      )}
      <ListItem sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h6' component='div'>
          {fare?.areaName}
        </Typography>
        <Typography display='flex' alignItems='center' gap='5px' variant='subtitle1' component='div'>
          Area Type:
          <Typography mb='0' variant='subtitle2' component='div'>
            {fare?.refIdAreaType?.areaTypeName}
          </Typography>
        </Typography>
      </ListItem>
      <ListItem sx={{ justifyContent: 'space-between', pt: '0', pb: '0' }}>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle2' fontWeight='100' component='div'>
            AED
          </Typography>
          <Typography ml='3px' mr='3px' variant='subtitle2' component='div'>
            {fare?.fare?.farePerMin}
          </Typography>
          <Typography variant='subtitle2' fontWeight='100' display='block'>
            Fare/Minute
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle2' fontWeight='100' component='div'>
            AED
          </Typography>
          <Typography ml='3px' mr='3px' variant='subtitle2' component='div'>
            {fare?.fare?.farePerKm}
          </Typography>
          <Typography variant='subtitle2' fontWeight='100' display='block'>
            Fare/Km
          </Typography>
        </Box>
      </ListItem>
      <ListItem sx={{ pt: '0', pb: '0' }}>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle2' fontWeight='100' component='div'>
            AED
          </Typography>
          <Typography ml='3px' mr='3px' variant='subtitle2' component='div'>
            {fare?.fare?.farePerKm}
          </Typography>
          <Typography variant='subtitle2' fontWeight='100' display='block'>
            Waiting Fare/Minute
          </Typography>
        </Box>
      </ListItem>
    </FareGridLayout>
  );
};

export default FareControl;
