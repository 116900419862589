import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { makeStyles } from '@mui/styles';
import { GRAY_8A } from 'common/theme/colors';

const useStyles = makeStyles({
  containerGrid: {
    display: 'grid',
    gridRowGap: 20,
    padding: 10,
  },
  profileGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    gridColumnGap: 10,
    alignItems: 'center',
  },
  driverImage: {
    width: 50,
  },
  nameGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  RatingGrid: {
    display: 'grid',
    gridTemplateColumns: '30px auto',
  },
});

type driverProps = {
  driver: {
    name: string;
    rating: number;
    distance: string;
    rides: string;
    miles: string;
    accidents: string;
  };
};

function DriverInfoCard(props: driverProps) {
  const classes = useStyles();
  return (
    <div className={classes.containerGrid}>
      <div className={classes.profileGrid}>
        <img src={`/images/userimg.png`} alt='' className={classes.driverImage} />
        <div className={classes.nameGrid}>
          <Typography fontWeight='600'>{props.driver.name}</Typography>
          <Typography>{`~${props.driver.distance}Km Away`}</Typography>
          <div className={classes.RatingGrid}>
            <Typography fontWeight='600'>{props.driver.rating}</Typography>
            <Rating value={props.driver.rating} size='small' readOnly precision={0.5} className={classes.ratingStyle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverInfoCard;
