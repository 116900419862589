import { components } from 'react-select';

const VehicleOptions = (props: any) => (
  <div>
    <components.Option {...props}>
      <input type='checkbox' checked={props.isSelected} /> <label>{props.label}</label>

    </components.Option>
  </div>
);

export default VehicleOptions;
