import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Paper, Dialog, Autocomplete, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GRAY_86, GRAY_8A, LIGHT_BLACK } from 'common/theme/colors';
import { AssignDriver, useGetAvailableDriversForLocationLazyQuery, useGetDriversAdminLazyQuery } from 'gql/generated/query.graphql';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/system';
import { GridRenderCellParams } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutation } from '@apollo/client';
import DriverInfo from './DriverInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import { BOOKING } from 'routers/config';
import { toastMessage } from 'gql/cache';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 475px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 400,
      fontSize: 18,
      color: GRAY_8A,
    },
    '& .super-app-theme--dark-cell': {
      fontWeight: 400,
      fontSize: 18,
      color: LIGHT_BLACK,
    },
  },
  pickUpIcon: {
    height: 50,
  },
  pickupGrid: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
    color: LIGHT_BLACK,
  },
  userImage: {
    width: 40,
  },
  actionCell: {
    display: 'grid',
    alignItems: 'center',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  bookingCardGrid: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    height: 475,
    width: 550,
    gridRowGap: 10,
  },
  bookingCardGrid1: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    height: 195,
    width: 410,
    gridRowGap: 10,
    alignContent: 'center',
    justifyItems: 'center',
  },
  rideGrid: {
    // backgroundImage: `url(${'/images/rideBg.png'})`,
    position: 'relative',
    height: 'calc(100vh - 130px)',
  },
  createBookingCard: {
    width: 500,
    position: 'absolute',
    top: '0',
    left: '0',
    margin: 20,
    padding: 20,
  },
  rescheduleGrid: {
    display: 'grid',
    gridRowGap: 20,
  },
  modeGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  infoGrid: {
    display: 'grid',
    gridRowGap: 10,
  }
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

type AssignDriverProps = {
  open: boolean;
  journey: string;
  closeValue: (newValue: boolean) => void;
};

interface LocationStateType {
  state: {
    params: any;
  };
}

export default function AssignDriverModal({ open, closeValue, journey }: AssignDriverProps) {
  const classes = useStyles();
  const [searchInput, setSearchInput] = React.useState({ name: '' });
  const [driversData, setDriversData] = useState([] as any);
  const [openModal, setOpenModal] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalRows, setTotalRows] = useState(0 as any);
  const history = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(70);
  const [selectedDriver, setSelectedDriver] = useState<any>({
    firstName: '',
    lastName: '',
    emailId: '',
    distance: '',
    duration: '',
    rides: '',
    miles: '',
    accidents: '',
  });
  const [getDriversAdmin, { data: driverAdminData, loading: isLoading }] = useGetDriversAdminLazyQuery({});
  const [assignDriver, { data: successData, loading, error }] = useMutation(AssignDriver);


  const closeSuccessModal = () => {
    setOpenModal(false);
  };

  const closeErrorModal = () => {
    setOpenFailedModal(false);
  };

  const openSuccessModal = () => {
    setOpenModal(true);
    setTimeout(() => {
      closeSuccessModal();
      closeValue(false);
    }, 2000);
  };

  const openErrorModal = () => {
    setOpenFailedModal(true);
    setTimeout(() => {
      closeErrorModal();
      closeValue(false);
      setIsError(false);
    }, 5000);
  };

  const handleClick = () => {
    if (!selectedDriver.emailId) {
      toastMessage({ type: 'error', message: 'Please choose a driver to assign.' });
      return;
    }
    const temp = assignDriver({
      variables: {
        driverEmail: selectedDriver.emailId,
        journeyId: parseFloat(journey)
      }
    });
    temp
    .then(() => openSuccessModal() )
    .catch((error) => {
      setErrorMessage(error.message);
      openErrorModal();
      setIsError(true);
    });
  };

  useEffect(() => {
    getDriversAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!isLoading && driverAdminData && driverAdminData.getDriversAdmin) {
      let arrData: any = [];

      driverAdminData?.getDriversAdmin.results.forEach((element: any) => {
        arrData.push({ ...element, id: element['idDriverUser'] });
      });
      setDriversData(arrData);
      setTotalRows(driverAdminData?.getDriversAdmin.totalRows);
    }
  }, [driverAdminData]);


  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
    },
  ];

  return (
    <Dialog onClose={closeValue} open={open} >
      <Paper className={classes.bookingCardGrid}>
        <div style={{ marginLeft: "92%" }}>
          <IconButton onClick={() => closeValue(false)}>
            <CancelIcon />
          </IconButton>
        </div>
        <Typography fontWeight={800} fontSize={25} style={{ marginLeft: "37%", marginTop: "-25%"  }}>Assign driver</Typography>
        <Box>
          <div style={{ marginLeft: "3%", marginRight: "3%", marginBottom: "3%", marginTop: "-28%" }}>
            <Autocomplete
              disablePortal
              id='select-city-textbox'
              size='small'
              onChange={(event: any, value: any) => {
                if (value) {
                  setSelectedDriver(value);
                  // TODO: hit api resolver to bind background map arrx. - time - km
                } else setSelectedDriver({});
              }}
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={driversData}
              autoHighlight
              getOptionLabel={(option) => option.firstName}
              renderOption={(props, option) => (
                <Box component='li' {...props}>
                  <DriverInfo driver={option} />
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Select Near By Driver'
                />
              )}
            />
          </div>
          <Button onClick={handleClick} style={{ margin: "5%", marginLeft: "38%" }}> Assign</Button>
        </Box>
      </Paper>
      <SuccessLeaveModal open={openModal} onClose={closeSuccessModal} />
      <ErrorModal open={openFailedModal} onClose={closeErrorModal} message={errorMessage} />
    </Dialog>

  );
}
type SuccessLeaveProps = {
  open: boolean;
  onClose: (event: MouseEvent) => void;
};

type ErrorModalProps = {
  open: boolean;
  onClose: (event: MouseEvent) => void;
  message: string;
};
const SuccessLeaveModal = ({ open, onClose }: SuccessLeaveProps) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper className={classes.bookingCardGrid1}>
        <img src={`/images/successIcon.svg`} alt='success-logo' />
        <Typography fontWeight={600}>{'Driver Assigned Successfully'}</Typography>
      </Paper>
    </Dialog>
  );
};

const ErrorModal = ({ open, onClose, message }: ErrorModalProps) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper className={classes.bookingCardGrid1}>
        <div>
        <img src={`/images/cross.png`} alt='error-logo' width={30} height={30}/>
        </div>
        <Typography fontWeight={600} style={{ padding: 10 }}>{message}</Typography>
      </Paper>
    </Dialog>
  );
};
