import { useEffect } from 'react';

const DrawPolygon = (props: any) => {
  const drew = (polygon: any) => {
    let coords = getPolygons(polygon);
    props.drawPolygonData(coords);
    google.maps.event.addListener(polygon, 'drag', () => {
      let coords = getPolygons(polygon);
      props.drawPolygonData(coords);
    });
  };

  const getPolygons = (polygon: any) => {
    let coords: [string, string][] = [];
    const polygonArray = polygon.getPath().getArray();
    polygonArray.forEach((xy: any, i: number) => {
      coords.push([xy.lat(), xy.lng()]);
      /* Google map dynamically handles the polygon last [lat, lang], to store it in DB we are pushing the first point manually */
      if (polygonArray?.length - 1 === i && polygonArray[0]) {
        coords.push([polygonArray[0].lat(), polygonArray[0].lng()]);
      }
    });
    return coords;
  };

  useEffect(() => {
    let drawingManager: object | null = null;
    if (props.map) {
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          fillColor: '#ee4736',
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: true,
          editable: true,
          zIndex: 1,
          strokeColor: '#ee4736',
          draggable: true,
        },
        map: props.map,
      });
      google.maps.event.addListener(drawingManager, 'polygoncomplete', drew);
    }
    return () => {
      if (drawingManager) google.maps.event.clearListeners(drawingManager, 'overlaycomplete');
    };
  }, []);
  return null;
};

export { DrawPolygon };
