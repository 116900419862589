import React from 'react';
import SearchHeader from 'common/search-header';
import CustomerListTable from 'components/customer-management/CustomerListTable';
import { makeStyles } from '@mui/styles';
import AddUser from 'components/customer-management/Add-user';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const searchListKeys = [
  {
    value: 'emailId',
    label: 'Email',
  },
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'mobileNumber',
    label: 'Phone',
  },
];

function CustomerList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Customers List'}
        buttonText={'Add Customer'}
        handleOpen={handleOpen}
        showButton={true}
        disabledButton={true}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <CustomerListTable searchInput={searchInput} />
      {open && <AddUser open={open} handleClose={handleClose} title={'Add New User'} />}
    </div>
  );
}

export default CustomerList;
