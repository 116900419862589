import { DARK_GREY, WHITE } from "../colors";

const muiAlert = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '&.MuiAlert-standardError': {
          background: DARK_GREY,
          color: WHITE,
          '& svg': {
            color: WHITE
          }
        }
      },
    },
  },
};

export default muiAlert;
