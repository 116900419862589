import * as Yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Dialog, FormControl, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import {
  GetCompanySecretCodesAdminDocument,
  useCreateCompanySecretCodeMutation,
  useGetCompaniesLazyQuery,
  useGetCompanySecretCodesLazyQuery,
} from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  uploadButton: {
    background: LIGHT_GREY,
    borderRadius: 5,
    height: 52,
    display: 'grid',
    alignItems: 'center',
    paddingLeft: 10,
    marginTop: 10,
    color: DARK_GREY,
  },
  transformText: { textTransform: 'capitalize' },
});

const schema = Yup.object().shape({
  secretCode: Yup.string().required('Secret Code is required'),
  companyName: Yup.string().required('Company Name is required'),
  expiryDate: Yup.string().required('Expiry Date is required'),
});

const AddSecretCode = ({
  open,
  handleClose,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
}): React.ReactElement => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [secretCodeVal, setSecretCodeVal] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companies, setCompanies] = useState([] as any);
  const [companySecretCodes, setCompanySecretCodes] = useState([] as any);
  const [expiryDate, setExpiryDate] = useState<null | string>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const minDate = new Date(Date.now());

  const [createCompanySecretCode] = useCreateCompanySecretCodeMutation({
    onError: (error) => {
      setError(true);
    },
    onCompleted() {
      handleClose();
    },
  });

  const [getAllCompanies, { data, loading }] = useGetCompaniesLazyQuery();
  const [getAllCompanySecretCodes, { data: companySecretCodesData, loading: companySecretCodesLoading }] =
    useGetCompanySecretCodesLazyQuery();

  const handleChange = (event: SelectChangeEvent) => {
    setCompanyName(event.target.value as string);
  };

  const onSave = () => {
    const isSecretCodeExists = companySecretCodes.find((code: string) => code === secretCodeVal);

    if (isSecretCodeExists) {
      setErrorMessage('Secret Code Already Exists.');
    } else {
      createCompanySecretCode({
        variables: {
          input: {
            companySecretCode: secretCodeVal,
            refCompanyName: companyName,
            codeExpiryDate: expiryDate,
          },
        },
        refetchQueries: [GetCompanySecretCodesAdminDocument, 'getAllCompanies'],
      });
    }
  };

  useEffect(() => {
    getAllCompanies();
    getAllCompanySecretCodes();
  }, []);

  useEffect(() => {
    if (!loading && data && data.getAllCompanies) {
      const arrData = data?.getAllCompanies.map((element) => ({
        label: element.companyName,
        value: element.companyName,
      }));
      setCompanies(arrData);
    }

    if (!companySecretCodesLoading && companySecretCodesData && companySecretCodesData.getAllCompanySecretCodes) {
      const secretCodes = companySecretCodesData?.getAllCompanySecretCodes.map((element) => element.companySecretCode);
      setCompanySecretCodes(secretCodes);
    }
  }, [data, companySecretCodesData]);

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <div className={classes.formGrid}>
            <TextField
              margin='normal'
              fullWidth
              id='secretCode'
              placeholder='Secret Code'
              name='secretCode'
              InputProps={{ disableUnderline: true }}
              autoComplete='secretCode'
              autoFocus
              value={secretCodeVal}
              onChange={(event: any) => {
                const { value: e } = event.target;
                setSecretCodeVal(e);
              }}
              error={errorMessage ? true : false}
              helperText={errorMessage}
            />
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Company Name</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='companyName'
                value={companyName}
                label='Company Name'
                onChange={handleChange}
              >
                {companies.map((obj: any, i: any) => (
                  <MenuItem className={classes.transformText} key={i} value={obj.value}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Expiry Date'
                value={expiryDate}
                minDate={minDate}
                onChange={(newValue: any) => {
                  const dateObj = new Date(newValue);
                  const momentObj = moment(dateObj).format('YYYY-MM-DD');
                  setExpiryDate(momentObj);
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <div className={classes.actionBtn}>
              <Button size='medium' onClick={onSave}>
                Save
              </Button>
              <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddSecretCode;
