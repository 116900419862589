import { createContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import SearchHeader from 'common/search-header';
import RoleListTable from 'components/role-management/RoleListTable';
import AddRole from 'components/role-management/RoleModal';
import { RoleSubjectsEntity, useGetRoleSubjectsLazyQuery } from 'gql/generated/query.graphql';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const searchListKeys = [
  {
    value: 'name',
    label: 'Role Name',
  },
];

export type RoleContextType = {
  subjects: RoleSubjectsEntity[];
  searchInput: { name: string };
};

export const RoleContext = createContext<RoleContextType | null>(null);

function RoleList() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState({ name: '' });
  const [subjects, setSubjects] = useState<RoleSubjectsEntity[]>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [getRoleSubjects, { data: roleSubjectsData }] = useGetRoleSubjectsLazyQuery({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
  });

  useEffect(() => {
    getRoleSubjects();
  }, []);

  useEffect(() => {
    if (roleSubjectsData) {
      setSubjects(roleSubjectsData?.getRoleSubjects);
    }
  }, [roleSubjectsData]);

  return (
    <RoleContext.Provider value={{ subjects, searchInput }}>
      <div className={classes.tableGrid}>
        <SearchHeader
          title={'All Roles'}
          buttonText={'Add Role'}
          showButton={true}
          handleOpen={handleOpen}
          setSearchInput={setSearchInput}
          searchListKeys={searchListKeys}
          subject={SUBJECTS.USER_ROLE_MANAGEMENT.toString()}
        />
        <Can I='read' a={SUBJECTS.USER_ROLE_MANAGEMENT.toString()}>
          <RoleListTable />
        </Can>
        {open && <AddRole open={open} handleClose={handleClose} title={'Add New Role'} />}
      </div>
    </RoleContext.Provider>
  );
}

export default RoleList;
