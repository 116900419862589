import { useEffect, useState } from 'react';

const Polygon = (props: any) => {
  const [polygon, setPolygon] = useState<google.maps.Polygon>();

  useEffect(() => {
    if (typeof google !== "undefined") {
      if (google?.maps?.Polygon) {
        setPolygon(new google.maps.Polygon());
        return () => {
          if (polygon) {
            polygon.setMap(null);
          }
        };
      }
    }
  }, [typeof google !== "undefined"]);

  const drew = () => {
    let coords = getPolygons(polygon);
    props.drawPolygonData(coords);
  };

  const getPolygons = (polygon: any) => {
    let coords: [string, string][] = [];
    const polygonArray = polygon.getPath().getArray();
    polygonArray.forEach((xy: any, i: number) => {
      coords.push([xy.lat(), xy.lng()]);
      /* Google map dynamically handles the polygon last [lat, lang], to store it in DB we are pushing the first point manually */
      if (polygonArray?.length - 1 === i && polygonArray[0]) {
        coords.push([polygonArray[0].lat(), polygonArray[0].lng()]);
      }
    });
    return coords;
  };

  useEffect(() => {
    if (polygon && props?.coordinates) {
      let polyPath = props.coordinates.map((l: any) => {
        return { lat: l[0], lng: l[1] };
      });
      const polygonOptions = {
        paths: polyPath,
        strokeColor: '#ee4736',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ee4736',
        fillOpacity: 0.35,
        map: props.map,
        draggable: props.type == 'viewEdit' ? true : false,
        editable: props.type == 'viewEdit' ? true : false,
      };
      polygon.setOptions(polygonOptions);
      google.maps.event.addListener(polygon, 'dragend', drew);
      polygon.getPaths().forEach((path) => {
        google.maps.event.addListener(path, 'set_at', drew);
      });
    }
    return () => {
      if (polygon) {
        google.maps.event.clearListeners(polygon, 'dragend');
      }
    };
  }, [polygon, props.coordinates]);
  return null;
};

export { Polygon };
