import { makeStyles } from '@mui/styles';

import { GRAY_86 } from 'common/theme/colors';

export default makeStyles({
  userHeading: {
    fontWeight: 'bolder',
    fontSize: 16,
    marginTop: 0.5,
  },

  textStyle: {
    fontWeight: 400,
    fontSize: 16,
  },

  flexProperty: {
    display: 'flex',
    marginBottom: 20,
  },
});

export const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};
