import React, { useState, MouseEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useNavigate } from 'react-router-dom';
import { CUSTOMERPROFILE } from 'routers/config';
import AddUser from './Add-user';
import DeleteModal from 'common/deleteModal';
import PromoCodeModal from 'common/promocodeModal';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';
import AssignmentToUser from './AssignmentToUser';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px 30px 30px',
    gridColumnGap: 10,
  },
  actionImage: {
    width: 22,
  },
});

function TableActionComponent({
  params,
  disabledShowButton = false,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: any;
  disabledShowButton?: boolean;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) {
  const classes = useStyles();
  const history = useNavigate();
  const [editOpen, setEditOpen] = React.useState(false);
  const [isOpenDelete, setOpenDelete] = React.useState(false);
  const [isOpenPromo, setOpenPromo] = useState(false);
  const [openAssignPromo, setOpenAssignPromo] = useState(false);
  const deleteUser = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

   const setPromoCode = (event: MouseEvent) => {
     event.stopPropagation();
     setOpenPromo(true);
   };

  const goToProfile = (event: React.MouseEvent) => {
    event.stopPropagation();
    history(CUSTOMERPROFILE, { state: { customerId: params.idCustomerUser } });
  };

  const openEditDialog = () => setEditOpen(true);

  const handleEditClose = () => setEditOpen(false);

  const deleteSelectedUser = () => {
    //on delete user
    setOpenDelete(false);
  };

  const openAssignModal = () => setOpenAssignPromo(true);

  const handleAssignModalClose = () => setOpenAssignPromo(false);

  return (
    <div className={classes.ActionGrid}>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={(e) => setPromoCode(e)}>
          <LocalOfferIcon/>
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='show' size='small' onClick={(e) => goToProfile(e)} disabled={disabledShowButton}>
          <VisibilityIcon />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog} disabled={disabledEditButton}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteUser(e)} disabled={disabledDeleteButton}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='add-remove-promo' size='small' onClick={openAssignModal}>
          <img src={`/images/paragraph.png`} alt='add-remove-promotion' className={classes.actionImage} />
        </IconButton>
      </Can>
      <DeleteModal open={isOpenDelete} onCancel={() => setOpenDelete(false)} onSubmit={deleteSelectedUser} />
      <PromoCodeModal
        open={isOpenPromo}
        onCancel={() => setOpenPromo(false)}
        onSubmit={() => setOpenPromo(false)}
        email={params.emailId}
        personUuid={params.idCustomerUser}
      />
      <AddUser open={editOpen} handleClose={handleEditClose} title={'Edit User'} />
      {openAssignPromo && (
        <AssignmentToUser
          open={openAssignPromo}
          handleAssignModalClose={handleAssignModalClose}
          title={'Assign or Unassign promotion'}
          params={params}
        />
      )}
    </div>
  );
}

export default TableActionComponent;
