import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, TextField, InputAdornment, Select, MenuItem } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Chip from '@mui/material/Chip';
import { GRAY_86 } from 'common/theme/colors';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridTemplateRows: '50px 50px',
  },
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '120px auto',
    height: 125,
  },
  searchGrid: {
    display: 'grid',
    gridTemplateColumns: '350px 50px',
  },
  searchDropIcon: {
    background: GRAY_86,
    borderRadius: '0px 6px 6px 0px',
    height: 80,
  },
  chipStyle: {
    fontSize: 18,
    height: 40,
    width: 150,
    fontWeight: 500,
  },
  tabGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chipGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: 10,
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function DriverSearch(props: any) {
  const classes = useStyles();
  const [tab, setTab] = useState('upcoming');
  const [searchKey, setSearchKey] = useState(props.searchListKeys[0]?.value);

  const handleChange = (event: any) => {
    props.setSearchInput({ [`${searchKey}`]: event.target.value });
  };

  const tabChange = (tabValue: string, status: boolean) => {
    setTab(tabValue);
    props.setRideStatus(status);
  };
  return (
    <div className={classes.mainGrid}>
      <Typography fontWeight={500} variant='h5'>
        {'Rides'}
      </Typography>
      <div className={classes.tabGrid}>
        <div className={classes.chipGrid}>
          <Chip
            label='UPCOMING'
            variant={tab === 'upcoming' ? 'outlined' : 'filled'}
            className={classes.chipStyle}
            onClick={() => tabChange('upcoming', true)}
          />
          <Chip
            label='COMPLETED'
            variant={tab === 'completed' ? 'outlined' : 'filled'}
            className={classes.chipStyle}
            onClick={() => tabChange('completed', false)}
          />
        </div>
        <div className={classes.searchWrapper}>
          <TextField
            id='search-box'
            variant='outlined'
            placeholder='Search'
            size='small'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            style={{ height: 37 }}
            className={classes.searchDropIcon}
          >
            {props.searchListKeys.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}

export default DriverSearch;
