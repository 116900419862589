import { WHITE, LIGHT_GREY } from '../colors';

const muiOutlinedInput = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& input': {
          background: WHITE,
          borderRadius: '5px',
          border: 'none',
          height: 20,
        },
        // '&:hover': {
        //   '& .MuiOutlinedInput-notchedOutline': {
        //     borderColor: WHITE
        //   }
        // },
        // "&.Mui-focused": {
        //   '& .MuiOutlinedInput-notchedOutline': {
        //     borderColor: WHITE
        //   }
        // },
      },
    },
  },
};

export default muiOutlinedInput;
