import { FC, useRef, useEffect, useState } from 'react';
import { DrawPolygon } from './drawPolygon';
import { Polygon } from './polygon';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  type: string;
  currArea?: any;
  polygonDataFn?: any;
  zoom?: number;
}

const Map: FC<MapProps> = ({ type, currArea, polygonDataFn, children, style, zoom = 12 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [coordinate, setCoordinate] = useState<object>();

  const initializeMap = () => {
    if (ref.current && !map) {
      const mapOptions = {
        zoom,
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        center: { lat: 25.188449, lng: 55.275651 },
        mapTypeControlOptions: {
          position: 8,
        },
      };
      if (window?.google?.maps) {
        setMap(new window.google.maps.Map(ref.current, mapOptions));
      }
    }
  };

  useEffect(() => {
    initializeMap();
  }, [ref, map]);

  useEffect(() => {
    if (currArea?.geometry?.geom?.length) {
      let coordinateVal = JSON.parse(currArea.geometry.geom) || [[]];
      setCoordinate(coordinateVal.coordinates[0]);
      let [lat, lng] = coordinateVal.coordinates[0][0];
      map?.panTo({ lat: lat, lng: lng });
    }
    if (!currArea?.idArea) {
      setMap(undefined);
      initializeMap();
    }
  }, [currArea]);

  const drawPolygonData = (coordinates: any) => {
    let polyData = {
      type: 'Polygon',
      coordinates: [coordinates],
      zoom: map?.getZoom() || 1,
      center: map?.getCenter()!.toJSON() || coordinates[0],
    };
    polygonDataFn(polyData);
  };

  return (
    <>
      <div ref={ref} style={style} />
      {children}
      {(type === 'viewEdit' || type === 'view') && (
        <Polygon drawPolygonData={drawPolygonData} type={type} map={map} coordinates={coordinate} />
      )}
      {type == 'add' && map && <DrawPolygon drawPolygonData={drawPolygonData} map={map} />}
    </>
  );
};

export default Map;
