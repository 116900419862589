import { createContext, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

type DrawerContextValue = {
  isDrawerOpen: boolean;
  routerState: any;
};

type DrawerProviderType = {
  isDrawerOpen: boolean;
  children: ReactElement;
};

export const DrawerContext = createContext<DrawerContextValue>({ isDrawerOpen: false, routerState: {} });

const DrawerProvider = ({ children, isDrawerOpen }: DrawerProviderType) => {
  const { state } = useLocation();
  return (
    <DrawerContext.Provider value={{ isDrawerOpen: isDrawerOpen, routerState: state }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
