import { useEffect } from 'react';
import { Button, Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD } from 'routers/config';
import { BLACK } from 'common/theme/colors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from 'common/forms/InputField';
import PreLoginLayout from 'common/layouts/preLoginLayout';
import { isLoggedInVar } from 'gql/cache';
import { useLoginAsAdminMutation } from 'gql/generated/query.graphql';

interface IFormInput {
  username: string;
  password: string;
}

const schema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
});

const LogIn = () => {
  const history = useNavigate();
  const [loginAsAdminMutation, { data, loading, error }] = useLoginAsAdminMutation({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { username: usernameErrorMsg, password: pwdErrorMsg } = errors || {};

  const onLoginSubmit = (data: any) => {
    loginAsAdminMutation({
      variables: {
        input: {
          emailId: data.username,
          password: btoa(data.password),
        },
      },
    });
  };

  useEffect(() => {
    if (data?.loginAsAdmin?.access_token) {
      localStorage.setItem('token', data.loginAsAdmin.access_token);
      localStorage.setItem('admin', JSON.stringify(data.loginAsAdmin.user));
      localStorage.setItem('SUBJECTS', JSON.stringify(data.loginAsAdmin.subjects));
      isLoggedInVar(true);
      history('/admin');
    }
  }, [data]);

  return (
    <>
      <PreLoginLayout title='Login' desc=''>
        <form onSubmit={handleSubmit(onLoginSubmit)}>
          <InputField
            control={control}
            name='username'
            margin='normal'
            fullWidth
            placeholder='Username'
            helperText={usernameErrorMsg?.message}
            error={usernameErrorMsg ? true : false}
          />
          <InputField
            control={control}
            name='password'
            margin='normal'
            fullWidth
            type='password'
            placeholder='Password'
            helperText={pwdErrorMsg?.message}
            error={pwdErrorMsg ? true : false}
          />
          <Grid container>
            <Grid item xs>
              <Link
                href='#'
                variant='body2'
                sx={{ color: BLACK, textDecoration: 'none' }}
                onClick={() => {
                  history(FORGOT_PASSWORD);
                }}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button type='submit' fullWidth variant='contained' size='small' sx={{ mt: 2 }}>
            Login
          </Button>
        </form>
      </PreLoginLayout>
    </>
  );
};

export default LogIn;
