import Box from '@mui/system/Box';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { useGetVehiclesAndPartnersLazyQuery } from 'gql/generated/query.graphql';
import TableActionComponent from './TableActionComponent';
import moment from 'moment';

const VehicleListTable = ({ searchInput }: { searchInput: any }) => {
  const [vehicleData, setVehicleData] = useState([] as any);

  const [getVehiclesAndPartners, { data: vehicleAdminData, loading: isLoading }] = useGetVehiclesAndPartnersLazyQuery();
  const classes = useStyles();
  const column = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'idDocument',
      headerName: 'License Number',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'licensePlate',
      headerName: 'Vehicle Number',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'manufacturingYear',
      headerName: 'Manufacturing Year',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'chasisNo',
      headerName: 'Chasis No',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'fuelType',
      headerName: 'Fuel Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'seats',
      headerName: 'Seats',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'kidsSeats',
      headerName: 'Kids Seats',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'expiryDate',
      headerName: 'License Expiry Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) =>  params.row?.expiryDate ? moment(params.row.expiryDate).format('D MMM, YYYY') : null,
    },
    {
      field: 'insuranceExpDate',
      headerName: 'Insurance Validity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) =>  params.row?.insuranceExpDate ? moment(params.row.insuranceExpDate).format('D MMM, YYYY') : null,
    },
    {
      field: 'vehicleType',
      headerName: 'Vehicle Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'regCity',
      headerName: 'Reg City',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 300,
    },
    {
      field: 'frontImageUrl',
      headerName: 'license Plate Front',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.frontImageUrl)}
          src={params.row?.frontImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Profile image'
          loading='lazy'
        />
      ),
    },
    {
      field: 'backImageUrl',
      headerName: 'License Plate Back',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.backImageUrl)}
          src={params.row?.backImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Profile image'
          loading='lazy'
        />
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TableActionComponent params={params.row} />
      ),
      sortable: false,
    }
  ];

  useEffect(() => {
    getVehiclesAndPartners();
  }, []);

  useEffect(() => {
    if (!isLoading && vehicleAdminData && vehicleAdminData.getVehiclesAndPartners) {
      let arrData: any = [];

      vehicleAdminData?.getVehiclesAndPartners.vehicles.forEach((element: any) => {
        arrData.push({ ...element, id: element['idVehicle'] });
      });
      setVehicleData(arrData);
    }
  }, [vehicleAdminData]);

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={vehicleData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={isLoading}
        pagination
        rowsPerPageOptions={[10, 20, 100]}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
  },
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

export default VehicleListTable;
