import React, { useEffect, useState } from 'react';
import CustomerDetails from 'components/customer-management/CustomerDetails';
import CustomerSearch from 'components/customer-management/CustomerSearch';
import { makeStyles } from '@mui/styles';
import CustomerUpComingTable from 'components/customer-management/CustomerUpcomingTable';
import CustomerCompletedRideTable from 'components/customer-management/CustomerCompletedRideTable';
import {
  Customer,
  RideSummaryResponse,
  Ride_Listing_Classification,
  useCustomerRideSummaryLazyQuery,
  useGetAdminCustomerRidesQuery,
  useGetCustomerQuery,
} from 'gql/generated/query.graphql';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridRowGap: 30,
  },
});

interface LocationStateType {
  state: {
    customerId: string;
  };
}

const DEFAULT_LIMIT = 5;
const DEFAULT_PAGE = 0;
const searchListKeys = [
  {
    value: 'journeyId',
    label: 'ID',
  },
];

function CustomerProfile() {
  const classes = useStyles();
  const [customerData, setCustomerData] = useState<Customer | any>({});
  const [rideSummary, setRideSummary] = useState<RideSummaryResponse | any>({
    totalHours: 0,
    totalMiles: 0,
    totalRides: 0,
  });
  const [isUpComingRide, setIsUpComingRide] = useState<Boolean>(true);
  const [upComingRides, setUpComingRides] = useState<any>([]);
  const [totalUpcomingRows, setTotalUpcomingRows] = useState<number>(0);
  const [totalCompletedRows, setTotalCompletedRows] = useState<number>(0);
  const [completedRides, setCompletedRides] = useState<any>([]);
  const [searchInput, setSearchInput] = useState({ journeyId: null });
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const {
    state: { customerId },
  } = useLocation() as unknown as LocationStateType;
  const getRideStatus = (rideStatus: boolean) => {
    if (!rideStatus) {
      setPage(DEFAULT_PAGE);
    } else setLimit(DEFAULT_LIMIT);
    setIsUpComingRide(rideStatus);
  };

  const { loading, data }: { loading: boolean; data: any } = useGetCustomerQuery({
    variables: {
      customerId,
    },
  });

  const { loading: rideLoading, data: ridesData }: { loading: boolean; data: any } = useGetAdminCustomerRidesQuery({
    variables: {
      input: {
        userId: customerId,
        category: isUpComingRide ? Ride_Listing_Classification.Upcoming : Ride_Listing_Classification.Completed,
        limit,
        offset: page * limit,
        journeyId: searchInput.journeyId ? parseFloat(searchInput.journeyId) : null,
      },
    },
  });

  useEffect(() => {
    if (!loading && data && data.getCustomer) {
      setCustomerData(data.getCustomer);
    }
    return () => {};
  }, [data, loading]);
  
  useEffect(() => {
    if (!rideLoading && ridesData && ridesData?.getAdminCustomerRides) {
      if (isUpComingRide) {
        setUpComingRides(ridesData.getAdminCustomerRides.results);
        setTotalUpcomingRows(ridesData.getAdminCustomerRides.totalRows);
      } else {
        setCompletedRides(ridesData.getAdminCustomerRides.results);
        setTotalCompletedRows(ridesData.getAdminCustomerRides.totalRows);
      }
    }
    return () => {};
  }, [ridesData, rideLoading, limit, offset]);

  useEffect(() => {
  !isUpComingRide && setLimit(totalCompletedRows);
  }, [isUpComingRide, totalCompletedRows]);

  const [getCustomerRideSummary, { data: customerSummaryData }] = useCustomerRideSummaryLazyQuery();

  useEffect(() => {
    if (customerId) {
      getCustomerRideSummary({
        variables: {
          customerId: customerId,
        },
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (customerSummaryData) {
      setRideSummary({
        totalHours: customerSummaryData.customerRideSummary.totalHours,
        totalMiles: customerSummaryData.customerRideSummary.totalMiles,
        totalRides: customerSummaryData.customerRideSummary.totalRides,
      });
    }
  }, [customerSummaryData]);

  return (
    <div className={classes.mainGrid}>
      <CustomerDetails data={customerData} rideSummary={rideSummary} />
      <CustomerSearch setRideStatus={getRideStatus} searchListKeys={searchListKeys} setSearchInput={setSearchInput} />
      {isUpComingRide ? (
        <CustomerUpComingTable
          data={upComingRides}
          totalRows={totalUpcomingRows}
          pageSize={limit}
          setPageSize={setLimit}
          setOffset={setOffset}
          setPage={setPage}
        />
      ) : (
        <CustomerCompletedRideTable completedRides={completedRides}  rideLoading={rideLoading}/>
      )}
    </div>
  );
}

export default CustomerProfile;
