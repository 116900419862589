import * as Yup from 'yup';
import { Button, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import InputField from 'common/forms/InputField';
import { GetCompaniesDocument, useCreateCompanyMutation, useGetCompaniesLazyQuery } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
});

const schema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
});

const AddCompany = ({
  open,
  handleClose,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
}): React.ReactElement => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [companies, setCompanies] = useState([] as string[]);

  const [getAllCompanies, { data: companiesData, loading: companiesLoading }] = useGetCompaniesLazyQuery();
  const [createCompany] = useCreateCompanyMutation({
    onError: (error) => {
      setError(true);
    },
    onCompleted() {
      handleClose();
    },
  });

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (!companiesLoading && companiesData && companiesData.getAllCompanies) {
      const data = companiesData?.getAllCompanies.map((company) => company.companyName);
      setCompanies(data);
    }
  }, [companiesData]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { companyName: companyNameErrorMsg } = errors;
  const onSave = (data: any) => {
    const isCompanyExists = companies.find((companyName) => companyName === data.companyName);

    if (isCompanyExists) {
      setErrorMessage('Company name already exists.');
    } else {
      createCompany({
        variables: {
          input: {
            companyName: data.companyName,
          },
        },
        refetchQueries: [GetCompaniesDocument, 'getCompaniesAdmin'],
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <div className={classes.formGrid}>
            <InputField
              control={control}
              margin='normal'
              fullWidth
              id='companyName'
              placeholder='Company Name'
              name='companyName'
              variant='filled'
              InputProps={{ disableUnderline: true }}
              autoComplete='companyName'
              autoFocus
              error={!error || errorMessage ? true : false}
              helperText={errorMessage ? errorMessage : companyNameErrorMsg?.message}
            />
            <div className={classes.actionBtn}>
              <Button size='medium' type='submit'>
                Save
              </Button>
              <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddCompany;
