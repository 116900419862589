import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Dialog } from '@mui/material';

const useStyles = makeStyles({
  bookingCardGrid: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    height: 175,
    width: 450,
    gridRowGap: 10,
    alignContent: 'center',
    justifyItems: 'center',
  },
});

type BookingSuccessProps = {
  open: boolean;
  onClose: (event: MouseEvent) => void;
};

function BookingSuccessModal({ open, onClose }: BookingSuccessProps) {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper className={classes.bookingCardGrid}>
        <img src={`/images/successIcon.svg`} alt='success-logo'/>
        <Typography fontWeight={400}>{'Ride has been booked successfully'}</Typography>
      </Paper>
    </Dialog>
  );
}

export default BookingSuccessModal;
