import { makeStyles } from '@mui/styles';

export default makeStyles({
  controlContent: {
    '& .MuiFormControl-root': {
      '& .MuiFilledInput-root': {
        '& .MuiFilledInput-input.MuiInputBase-input': {
          paddingTop: '15px',
        },
      },
    },
  },
  controlGroup: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    minWidth: '500px',
    '& .MuiButton-root': {
      fontSize: '13px',
    },
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    marginTop: '15px',
    width: '200px',
  },
});
