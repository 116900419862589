import { Card, Button, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { FareProps } from '../fare-list';
import SurgeFare from '../surge-fare';
import { useState } from 'react';

interface ControlProps {
  fare: FareProps;
  addSurgeFare: (data: CreateSurgeFareInput) => void;
}

export interface CreateSurgeFareInput {
  areaId?: number;
  surgeEndTime: string;
  surgeFare: string;
  surgeStartTime: string;
}

const useStyles = makeStyles({
  controlPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '22px 0 0 20px',
    width: '350px',
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiButton-root': {
        color: 'inherit',
        backgroundColor: 'inherit',
        border: '1px solid',
        borderRadius: '30px',
        height: '35px',
        fontSize: '13px',
      },
    },
  },
});

const AddSurgeControl: React.FC<ControlProps> = ({ fare, addSurgeFare }: ControlProps) => {
  const { controlPanel } = useStyles();
  const [isOpenSurge, setOpenSurge] = useState(!1);

  const addingSurgeFee = (data: CreateSurgeFareInput) => {
    setOpenSurge(!1);
    addSurgeFare({
      surgeEndTime: data.surgeEndTime,
      surgeFare: data.surgeFare,
      surgeStartTime: data.surgeStartTime,
    });
  };

  return (
    <Card className={controlPanel}>
      <CardContent>
        <Typography variant='subtitle1' gutterBottom component='div'>
          No surge fare has been set up till now.
        </Typography>
        <Button onClick={() => setOpenSurge(!0)} size='small'>
          Add Surge fare
        </Button>
      </CardContent>
      {isOpenSurge && (
        <SurgeFare fare={fare} isOpen={isOpenSurge} onClose={() => setOpenSurge(!1)} onSubmit={addingSurgeFee} />
      )}
    </Card>
  );
};

export default AddSurgeControl;
