import { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { RoleContext, RoleContextType } from 'pages/role-management';

interface Column {
  id: 'title' | 'add' | 'delete' | 'view_only' | 'edit';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'title', label: 'Permissions', minWidth: 270 },
  { id: 'add', label: 'ADD' },
  { id: 'delete', label: 'DELETE' },
  { id: 'view_only', label: 'VIEW ONLY' },
  { id: 'edit', label: 'EDIT' },
];

function PermissionTable({ showOnly = false }: { showOnly?: boolean }) {
  const { subjects } = useContext(RoleContext) as RoleContextType;
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'rolePermissions',
  });

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label='sticky table' size='small'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                <Typography variant='h6' gutterBottom component='div'>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, index) => {
            return (
              <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                <TableCell>
                  <Controller
                    name={`rolePermissions.${index}[0]`}
                    control={control}
                    render={({ field }) => (
                      <Typography variant='subtitle1' gutterBottom component='div'>
                        <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                        {` ${subjects.find((subject) => subject.idRoleSubjects == field.value)?.subjectLabel}`}
                      </Typography>
                    )}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Controller
                    name={`rolePermissions.${index}[1][0]`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                        checked={!!field.value}
                        disabled={showOnly}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Controller
                    name={`rolePermissions.${index}[1][3]`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                        checked={!!field.value}
                        disabled={showOnly}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Controller
                    name={`rolePermissions.${index}[1][1]`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                        checked={!!field.value}
                        disabled={showOnly}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Controller
                    name={`rolePermissions.${index}[1][2]`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                        checked={!!field.value}
                        disabled={showOnly}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PermissionTable;
