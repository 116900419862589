import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MainLayout from 'common/layouts/main';

const DashboardPage = () => {
  return (
    <MainLayout>
      <Box>
        <Outlet />
      </Box>
    </MainLayout>
  );
};

export default DashboardPage;
