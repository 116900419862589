import React, { useState, useEffect } from 'react';
import Map from 'common/map/map';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Autocomplete, TextField, Fab, Divider, Box } from '@mui/material';
import { DARK_GREY, GRAY_86 } from 'common/theme/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BookingSuccessModal from 'components/booking-management/BookingSuccessModal';
import DriverInfoCard from 'components/booking-management/DriverInfoCard';
import DriverProfile from 'components/booking-management/DriverProfile';

import {
  GetAdminBookingSummaryDocument,
  GetAdminDistanceTimeEstimationsDocument,
  useAdminRescheduleBookingMutation,
  useGetAvailableDriversForLocationLazyQuery,
  useGetDistanceTimeEstimationsLazyQuery,
} from 'gql/generated/query.graphql';
import moment from 'moment';
import { BOOK_RESCHEDULE } from 'routers/config';

const useStyles = makeStyles({
  rideGrid: {
    // backgroundImage: `url(${'/images/rideBg.png'})`,
    position: 'relative',
    height: 'calc(100vh - 130px)',
  },
  createBookingCard: {
    width: 500,
    position: 'absolute',
    top: '0',
    left: '0',
    margin: 20,
    padding: 20,
  },
  rescheduleGrid: {
    display: 'grid',
    gridRowGap: 20,
  },
  modeGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
  },
  infoGrid: {
    display: 'grid',
    gridRowGap: 10,
  },
  pickupGrid: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
  pickUpIcon: {
    height: 50,
  },
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

interface LocationStateType {
  state: {
    params: any;
  };
}

function RescheduleRide() {
  const classes = useStyles();
  const {
    state: { params },
  } = useLocation() as unknown as LocationStateType;
  const history = useNavigate();
  const [mapData, setMapData] = useState<object | null>(null);
  const [currArea, setCurrArea] = useState<any | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [driversData, setDriversData] = useState([] as any);
  const [selectedDriver, setSelectedDriver] = useState<any>({
    name: '',
    rating: 0,
    distance: '',
    duration: '',
    rides: '',
    miles: '',
    accidents: '',
  });

  const [getAvailableDriversForLocation, { data: driverAdminData, loading: isLoading }] =
    useGetAvailableDriversForLocationLazyQuery({
      onError: (error) => {
        console.log(error.networkError?.message);
      },
    });

  const [getDistanceTimeEstimations] = useLazyQuery(GetAdminDistanceTimeEstimationsDocument, {
    onError: (error) => {
      console.log(error.networkError?.message);
    },
  });

  const [adminRescheduleBookingMutation, { data: adminData, loading: adminLoading }] =
    useAdminRescheduleBookingMutation({
      onError: (error) => {
        console.log(error.networkError?.message);
      },
    });
  useEffect(() => {
    getAvailableDriversForLocation({
      variables: {
        journeyId: parseFloat(params.idJourney),
      },
    });
  }, []);

  useEffect(() => {
    let avbDrivers: any = [];
    const getDistanceTimeEstimationsDriver = async (drivers: any = []) => {
      return await getDistanceTimeEstimations({
        variables: {
          input: drivers,
        },
      });
    };

    if (driverAdminData && driverAdminData?.getAvailableDriversForLocation?.results) {
      let arrData: any = [];
      const results = driverAdminData?.getAvailableDriversForLocation?.results;
      (async () => {
        for (const driver of results) {
          avbDrivers.push({
            driverId: driver['idDriverUser'],
            lat: params.pickupRefIdLocation.lat,
            long: params.pickupRefIdLocation.long,
          });
        }
        const estimations = await getDistanceTimeEstimationsDriver(avbDrivers);
        for (let i = 0; i < results.length; i++) {
          const driver = results[i];
          arrData.push({
            ...driver,
            id: driver['idDriverUser'],
            name: `${driver.firstName} ${driver.lastName}`,
            rating: driver?.driverDetails?.rating || 0,
            distance: estimations?.data?.getAdminDistanceTimeEstimations[i].distance
              ? (estimations.data.getAdminDistanceTimeEstimations[i].distance / 1000).toFixed(1)
              : '',
            duration: estimations?.data?.getAdminDistanceTimeEstimations[i].duration
              ? (estimations.data.getAdminDistanceTimeEstimations[i].duration / 60).toFixed(1)
              : '',
            rides: driver.rideSummary?.totalRides,
            miles: driver.rideSummary?.totalMiles ? (driver.rideSummary.totalMiles * 0.62137).toFixed(1) : 0,
            accidents: '0',
          });
        }
      })();
      setDriversData(arrData);
    }
  }, [driverAdminData]);

  const handleBookRide = () => {
    const payload = {
      cancelledJourneyId: Number(params?.idJourney),
      refIdDriverUser: selectedDriver?.idDriverUser,
    };

    adminRescheduleBookingMutation({
      variables: {
        input: payload,
      },
      onCompleted: (res) => {
        if (res.adminRescheduleBooking.success) {
          openSuccessModal();
          history(BOOK_RESCHEDULE);
        }
      },
      refetchQueries: [GetAdminBookingSummaryDocument, 'getAdminBookingSummary'],
    });
  };

  const openSuccessModal = () => {
    setOpenModal(true);
  };

  const closeSuccessModal = () => {
    setOpenModal(false);
  };

  const polygonDataFn = (coordinates: any) => {
    setMapData(coordinates);
  };

  return (
    <>
      <div className={classes.rideGrid}>
        <Map
          type='view'
          currArea={currArea}
          polygonDataFn={polygonDataFn}
          style={{ width: '100%', height: '100%' }}
        ></Map>

        <Paper className={classes.createBookingCard}>
          <div className={classes.rescheduleGrid}>
            <Typography variant='h4' fontWeight='500'>
              Reschedule Ride
            </Typography>

            <Autocomplete
              disablePortal
              id='select-city-textbox'
              size='small'
              onChange={(event: any, value: any) => {
                if (value) {
                  setSelectedDriver(value);
                  // TODO: hit api resolver to bind background map arrx. - time - km
                }
              }}
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={driversData}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component='li' {...props}>
                  <DriverInfoCard driver={option} />
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Select Near By Driver'
                />
              )}
            />

            {selectedDriver.name && <DriverProfile driver={selectedDriver} />}

            <Divider />

            <div className={classes.infoGrid}>
              <Typography fontWeight='500'>Ride information</Typography>
              <div className={classes.modeGrid}>
                <Typography color={DARK_GREY}>{moment(params.pickupTime).format('D MMM')}</Typography>
                <Typography>
                  Mode: <b>{params.refIdModes.modeName}</b>
                </Typography>
              </div>
              <div>
                <div className={classes.pickupGrid}>
                  <img src={`/images/pickupIcon.png`} alt='' className={classes.pickUpIcon} />
                  <div className={classes.pickupDetail}>
                    <div className={classes.modeGrid}>
                      <Typography noWrap fontWeight={600}>
                        {params.pickupRefIdLocation.locationName}
                      </Typography>
                      <Typography color={DARK_GREY}>{moment(new Date(params.pickupTime)).format('hh:mm A')}</Typography>
                    </div>
                    <div className={classes.modeGrid}>
                      <Typography noWrap fontWeight={600}>
                        {params.dropoffRefIdLocation.locationName}
                      </Typography>
                      <Typography color={DARK_GREY}>
                        {moment(new Date(params.estimatedDroppoff)).format('hh:mm A')}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selectedDriver.name && (
              <div>
                <Fab
                  variant='extended'
                  size='medium'
                  color='primary'
                  onClick={handleBookRide}
                  sx={{ textTransform: 'none' }}
                >
                  {'Book This Ride'}
                </Fab>
              </div>
            )}
          </div>
        </Paper>

        <BookingSuccessModal open={openModal} onClose={closeSuccessModal} />
      </div>
    </>
  );
}

export default RescheduleRide;
