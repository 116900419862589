import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import AddVehicle from 'components/vehicle-managment/AddVehicle';
import SearchHeader from 'common/search-header';
import { SUBJECTS } from 'common/casl/ability';
import VehicleListTable from 'components/vehicle-managment/VehicleListTable';
import { searchListKeys } from './constants';

const VehicleList = () => {
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState({});

  const classes = useStyles();

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Vehicle List'}
        buttonText={'Add Vehicle'}
        showButton={true}
        handleOpen={() => setOpen(true)}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <VehicleListTable searchInput={searchInput} />
      {open && <AddVehicle open={open} handleClose={() => setOpen(false)} title={'Add New Vehicle'} action='add' />}
    </div>
  );
};

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

export default VehicleList;
