import { useState, useEffect } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useQuery } from '@apollo/client';

import { AutoCompleteStyle } from 'common/promocodeModal/styled';
import InputField from 'common/forms/InputField';
import { schema, supplierPartnerTypesData } from './constants';
import { GetTaxiesAndColorsDocument } from 'gql/generated/query.graphql';

interface SupplierPartnerType {
  idSupplier?: string;
  supplierPartnerType?: string;
}

const SupplierTypeSelector = ({ control, error, params = {} }: { control: Control<any>; error: Record<string, any>, params?: any }) => {
  const [supplierPartnerTypes, setsupplierPartnerTypes] = useState<SupplierPartnerType[]>([]);
  const [supplierTaxiColor, setSupplierTaxiColor] = useState([] as any);
  const [selectedType, setSelectedType] = useState<string | undefined>();

  const { loading, error: taxiColorError, data: taxiColorData } = useQuery(GetTaxiesAndColorsDocument);  

  useEffect(() => {    
    setsupplierPartnerTypes(supplierPartnerTypesData);
    setSupplierTaxiColor(taxiColorData?.getTaxiesAndColors);
    params ? setSelectedType(params?.type) : null;
  }, [taxiColorData?.getTaxiesAndColors]);

  const { supplierPartnerType: supplierPartnerTypeErrorMsg, supplierName: supplierNameErrorMsg } = error;

  if (!supplierTaxiColor || loading) return <p>Loading...</p>;  
  
  return (
    <>
      <Controller
        control={control}
        name='supplierPartnerType'
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disablePortal
            id='supplierPartnerType'
            size='small'
            fullWidth
            popupIcon={<KeyboardArrowDownIcon />}
            options={supplierPartnerTypes}
            getOptionLabel={(option: any) => option.supplierPartnerType}
            onChange={(_event, item) => {
              onChange(item?.idSupplier);
              setSelectedType(item?.idSupplier);
            }}
            defaultValue={{supplierPartnerType: supplierPartnerTypes.find(obj => obj.idSupplier === value)?.supplierPartnerType || ''}}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='filled'
                margin='normal'
                required
                InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                label='Supplier Partner Type'
                helperText={supplierPartnerTypeErrorMsg?.message}
                error={supplierPartnerTypeErrorMsg ? true : false}
              />
            )}
          />
        )}
      />
      {selectedType == 'LIMO' ? (
        <InputField
          control={control}
          margin='normal'
          fullWidth
          name='supplierName'
          placeholder='Supplier Name'
          id='supplierName'
          variant='filled'
          required
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 20 }}
          autoComplete='supplierName'
          helperText={supplierNameErrorMsg?.message}
          error={supplierNameErrorMsg ? true : false}
        />
      ) : (
        <Controller
          control={control}
          name='refIdTaxiSupplierColor'
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disablePortal
              id='refIdTaxiSupplierColor'
              size='small'
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              options={supplierTaxiColor}
              getOptionLabel={(option: any) => `${option.type}(${option.color})`}
              onChange={(_event, item) => {
                onChange(item?.idColor);
              }}
              defaultValue={params ? value : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='filled'
                  margin='normal'
                  required
                  InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                  label='Supplier Taxi Name'
                  helperText={supplierNameErrorMsg?.message}
                  error={supplierNameErrorMsg ? true : false}
                />
              )}
            />
          )}
        />
      )}
    </>
  );
};

export default SupplierTypeSelector;
