import React from 'react';
import SearchHeader from 'common/search-header';
import DriverListTable from 'components/driver-management/DriverListTable';
import { makeStyles } from '@mui/styles';
import AddUser from 'components/driver-management/Add-user';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const searchListKeys = [
  {
    value: 'emailId',
    label: 'Email',
  },
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'mobileNumber',
    label: 'Phone',
  },
];

function DriverList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.tableGrid}>
      <SearchHeader
        title={'Drivers List'}
        buttonText={'Add Driver'}
        showButton={true}
        handleOpen={handleOpen}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <DriverListTable searchInput={searchInput} />
      {open && <AddUser open={open} handleClose={handleClose} title='Add New Driver' action='add' />}
    </div>
  );
}

export default DriverList;
