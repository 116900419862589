import { Card, Button, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { FareProps } from '../fare-list';
import SurgeFare from '../surge-fare';
import { useState } from 'react';
import SalikFare from '../salik-fare';

interface ControlProps {
  fare?: FareProps;
  addSalikFare?: ( data: FareProps | null) => void;
}

const useStyles = makeStyles({
  controlPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '22px 0 0 20px',
    width: '350px',
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiButton-root': {
        color: 'inherit',
        backgroundColor: 'inherit',
        border: '1px solid',
        borderRadius: '30px',
        height: '35px',
        fontSize: '13px',
      },
    },
  },
});

const AddSalikControl: React.FC<ControlProps> = ({ fare, addSalikFare }: ControlProps) => {
  const { controlPanel } = useStyles();
  const [isSalikModal, setSalikModal] = useState(!1);


  return (
    <Card className={controlPanel}>
      <CardContent>
        <Typography variant='subtitle1' gutterBottom component='div'>
          No Salik fare has been set up till now.
        </Typography>
        <Button onClick={() => setSalikModal(!0)} size='small'>
          Add Salik Fare
        </Button>
      </CardContent>
      {isSalikModal && <SalikFare isOpen={isSalikModal} fare={fare} onSubmit={addSalikFare} onClose={() => setSalikModal(!1)} />}
    </Card>
  );
};

export default AddSalikControl;