import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};

export type AcTempOptions = {
  __typename?: 'AcTempOptions';
  idAcTempOptions: Scalars['Int'];
  value: Scalars['String'];
};

export type AddItemToJourneyInput = {
  items: Array<Item>;
  journeyId: Scalars['Float'];
};

export type AddRoleInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  roleDescription?: InputMaybe<Scalars['String']>;
  roleName: Scalars['String'];
  rolePermissions: Scalars['String'];
};

export type AddonDriverInventory = {
  __typename?: 'AddonDriverInventory';
  idAddonDriverInventory: Scalars['Int'];
  inStock: Scalars['Float'];
  inventoryItem: AddonInventory;
  itemDetails: ExposedItem;
  refIdAddonItem: Scalars['Float'];
  refIdDriverUser: Scalars['String'];
};

export type AddonInventory = {
  __typename?: 'AddonInventory';
  idAddonInventory: Scalars['Float'];
  inStock: Scalars['Float'];
  isActive: Scalars['Boolean'];
  item: AddonItem;
  refIdAddonItem: Scalars['Float'];
  refIdAddonVendor: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type AddonItem = {
  __typename?: 'AddonItem';
  idAddonItem: Scalars['Int'];
  itemName: Scalars['String'];
  mediaUrl: Scalars['String'];
  refIdAddonCategory: Scalars['Float'];
};

export type Address = {
  __typename?: 'Address';
  addressNo: Scalars['String'];
  area: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  idAddress: Scalars['ID'];
  ownerType: Scalars['String'];
  refId: SupplierEntity;
  street: Scalars['String'];
};

export type AddressInput = {
  addressNo: Scalars['String'];
  area: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  ownerType: Scalars['String'];
  street: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  details?: Maybe<AdminUserDetail>;
  emailId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  idAdminUser: Scalars['ID'];
  lastName: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<AdminRolesEntity>;
  status: Scalars['String'];
};

export type AdminDepartment = {
  __typename?: 'AdminDepartment';
  departmentDescription?: Maybe<Scalars['String']>;
  departmentName: Scalars['String'];
  idAdminDepartment: Scalars['ID'];
};

export type AdminEditJourneyInput = {
  dropOffLocation?: InputMaybe<UpdateLocationInput>;
  pickUpLocation?: InputMaybe<UpdateLocationInput>;
  pickupTime?: InputMaybe<Scalars['DateTime']>;
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdDriverUser?: InputMaybe<Scalars['String']>;
  refIdModes?: InputMaybe<Scalars['Float']>;
};

export type AdminForgotPasswordInput = {
  mobileNumber: Scalars['String'];
};

export type AdminLoginResponse = {
  __typename?: 'AdminLoginResponse';
  access_token: Scalars['String'];
  subjects: Scalars['JSON'];
  user: Admin;
};

export type AdminOrganization = {
  __typename?: 'AdminOrganization';
  idAdminOrganization: Scalars['ID'];
  organizationDescription?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
};

export type AdminRideInputs = {
  category?: InputMaybe<Ride_Listing_Classification>;
  journeyId?: InputMaybe<Scalars['Float']>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export type AdminRolesEntity = {
  __typename?: 'AdminRolesEntity';
  idAdminUserRoles: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  roleDescription?: Maybe<Scalars['String']>;
  roleName: Scalars['String'];
  rolePermissions: Scalars['JSON'];
};

export type AdminUpdateResponse = {
  __typename?: 'AdminUpdateResponse';
  success: Scalars['Boolean'];
  user: Admin;
};

export type AdminUserDetail = {
  __typename?: 'AdminUserDetail';
  adminUserDescription?: Maybe<Scalars['String']>;
  department?: Maybe<AdminDepartment>;
  experience?: Maybe<Scalars['String']>;
  idAdminUserDtl: Scalars['ID'];
  isActive: Scalars['Boolean'];
  organization?: Maybe<AdminOrganization>;
  refIdAdminUser: Scalars['String'];
};

export type AreaEntity = {
  __typename?: 'AreaEntity';
  areaName: Scalars['String'];
  fare?: Maybe<FareEntity>;
  fareEnt: FareEntity;
  geometry: GeoMetryLocationEntity;
  idArea: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  refIdAreaType: AreaTypeEntity;
  refIdZedGeometry?: Maybe<Scalars['Float']>;
  salik?: Maybe<SalikFareEntity>;
  salikEnt: SalikFareEntity;
  surgEnt: SurgeFareEntity;
  surge?: Maybe<SurgeFareEntity>;
};

export type AreaTypeEntity = {
  __typename?: 'AreaTypeEntity';
  areaTypeName: Scalars['String'];
  idAreaType: Scalars['Int'];
};

export type ArtOptions = {
  __typename?: 'ArtOptions';
  artLink: Scalars['String'];
  artName: Scalars['String'];
  idArtOptions: Scalars['Int'];
};

export type AssignAddonsToDriverInput = {
  addonInventoryId: Scalars['Float'];
  driverId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type AvailableDriverResponse = {
  __typename?: 'AvailableDriverResponse';
  results?: Maybe<Array<DriverEntity>>;
  success: Scalars['Boolean'];
};

export type Badge = {
  __typename?: 'Badge';
  badgeName: Scalars['String'];
  idBadge: Scalars['Float'];
};

export type BankDetails = {
  __typename?: 'BankDetails';
  IBAN: Scalars['String'];
  accName: Scalars['String'];
  bankAccNo: Scalars['String'];
  bankName: Scalars['String'];
  cancelledChequeUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  idAccount: Scalars['ID'];
  ownerType: Scalars['String'];
  refOwner: SupplierEntity;
  updatedAt: Scalars['DateTime'];
};

export type BankDetailsInput = {
  IBAN: Scalars['String'];
  accName: Scalars['String'];
  bankAccNo: Scalars['String'];
  bankName: Scalars['String'];
  cancelledChequeUrl?: InputMaybe<Scalars['String']>;
  ownerType: OwnerTypes;
};

export type BookingFilterInputs = {
  endDate?: InputMaybe<Scalars['String']>;
  journeyId?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Float']>;
};

export type BookingFilterResponse = {
  __typename?: 'BookingFilterResponse';
  results: Array<Journey>;
  statistics?: Maybe<StatisticResponse>;
  success: Scalars['Boolean'];
  totalRows?: Maybe<Scalars['Float']>;
};

export type ChallengeResponse = {
  __typename?: 'ChallengeResponse';
  amount?: Maybe<Scalars['Float']>;
  token?: Maybe<Scalars['String']>;
};

export type ChatOptions = {
  __typename?: 'ChatOptions';
  idChatOptions: Scalars['Int'];
  value: Scalars['String'];
};

export type ColourOptions = {
  __typename?: 'ColourOptions';
  colourName?: Maybe<Scalars['String']>;
  idColourOptions: Scalars['Int'];
  value: Scalars['String'];
};

export type CreateAdminUserInput = {
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  pwd: Scalars['String'];
  refIdAdminDepartment?: InputMaybe<Scalars['Int']>;
  refIdAdminUserRoles: Scalars['Int'];
};

export type CreateAreaInput = {
  areaName: Scalars['String'];
  areaTypeRefId: Scalars['Float'];
  geom: Scalars['String'];
};

export type CreateCustomerAdminInput = {
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  password: Scalars['String'];
};

export type DriverLeaveInput = {
  emailId?: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  availabilityStatus: InputMaybe<Boolean>;
  driverLeavesTime: InputMaybe<Array<SomeArray>>;
};

export type SomeArray = {
    id:Scalars['String'],
    name: Scalars['String'],
    selected:InputMaybe<Boolean>,
    startTime: Scalars['String'],
    endTime:Scalars['String'],
}

export type CreateCustomerInput = {
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  idColourOptions?: InputMaybe<Scalars['Float']>;
  lastName: Scalars['String'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  otp: Scalars['String'];
  password: Scalars['String'];
  sid: Scalars['String'];
  zedName?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  access_token: Scalars['String'];
  user: Customer;
};

export type IdentityDocument = {
  __typename?: 'IdentityDocument';
  idDocument?: Scalars['String'];
  frontImageUrl?: Scalars['String'];
  backImageUrl?: Scalars['String'];
  documentType?: Scalars['String'];
  expiryDate?: Scalars['String'];
};

export type AssignDriverInput = {
  driverEmail: Scalars['String'];
  journeyId?: Scalars['String'];
}

export type CreateDriverAdminInput = {
  emailId: Scalars['String'];
  empId?: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  partner: Scalars['Float'];
  pwd?: Scalars['String'];
  id?: Scalars['String'];
  vehicle?: Scalars['Float'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  idMeter?: Scalars['String'];
  refIdSupplier?: Scalars['String'];
  gender?: Scalars['String'];
  identityDocuments?: Maybe<Array<IdentityDocument>>;
  startDate?: Scalars['String'];
};

export type CreateDriverInput = {
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  pwd: Scalars['String'];
  userName: Scalars['String'];
  userType: Scalars['String'];
};

export type CreateFareInput = {
  areaId: Scalars['Float'];
  baseMorningShift: Scalars['String'];
  baseNightShift: Scalars['String'];
  bookingFee: Scalars['String'];
  farePerKm: Scalars['String'];
  farePerMin: Scalars['String'];
};

export type CreateGlobalLocInput = {
  lat: Scalars['String'];
  locationCustomName: Scalars['String'];
  locationName: Scalars['String'];
  locationType: Scalars['String'];
  long: Scalars['String'];
  placeId: Scalars['String'];
};

export type CreateJourneyInput = {
  dropOffLocation: CreateLocationInput;
  journeyType: Scalars['String'];
  paymentMethodId: Scalars['String'];
  pickUpLocation: CreateLocationInput;
  pickupTime: Scalars['DateTime'];
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdCustomerUser: Scalars['String'];
  refIdModes: Scalars['Float'];
  returnJourney?: InputMaybe<CreateReturnJourneyInput>;
  stops?: InputMaybe<Array<CreateLocationInput>>;
};

export type CreateLocationInput = {
  lat: Scalars['String'];
  locationCustomName: Scalars['String'];
  locationName: Scalars['String'];
  locationType: Scalars['String'];
  long: Scalars['String'];
  placeId?: InputMaybe<Scalars['String']>;
};

export type CreateModeAdminInput = {
  acTempRefId: Scalars['Float'];
  lightRefId: Scalars['Float'];
  modeName: Scalars['String'];
  musicVolumeRefId: Scalars['Float'];
};

export type CreateModeInput = {
  acTempRefId: Scalars['Float'];
  artRefId: Scalars['Float'];
  chatRefId: Scalars['Float'];
  colourId: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  lightRefId: Scalars['Float'];
  mediaUrl?: InputMaybe<Scalars['String']>;
  modeName: Scalars['String'];
  musicGenereRefId: Scalars['Float'];
  musicVolumeRefId: Scalars['Float'];
};

export type CreatePromotionInputs = {
  activeFromDate: Scalars['DateTime'];
  activeTillDate: Scalars['DateTime'];
  discount: Scalars['Float'];
  isActive: Scalars['Boolean'];
  minimumBaseFare: Scalars['Float'];
  perCustomerUsageLimit: Scalars['Float'];
  promoCode: Scalars['String'];
  promoDescription: Scalars['String'];
  promoTitle: Scalars['String'];
  totalUsageLimit: Scalars['Float'];
  unit: Scalars['String'];
  typesOfUsers: Scalars['String'];
  promoSpecification: Scalars['String'];
  promoApplication: Scalars['String'];
  refIdZedGeometry: Scalars['Float'];
  promoTierWiseDiscount: Scalars['JSON'];
  promoType: Scalars['String'];
  promoUsageLimitPerMonth: Scalars['Float'];
  maxDiscount: Scalars['Float'];
};

export type UpdatePromotionInputs = {
  idPromotion: Scalars['Float'];
  activeFromDate: Scalars['DateTime'];
  activeTillDate: Scalars['DateTime'];
  discount: Scalars['Float'];
  isActive: Scalars['Boolean'];
  minimumBaseFare: Scalars['Float'];
  perCustomerUsageLimit: Scalars['Float'];
  promoCode: Scalars['String'];
  promoDescription: Scalars['String'];
  promoTitle: Scalars['String'];
  totalUsageLimit: Scalars['Float'];
  unit: Scalars['String'];
  typesOfUsers: Scalars['String'];
  promoSpecification: Scalars['String'];
  promoApplication: Scalars['String'];
  refIdZedGeometry: Scalars['Float'];
  promoTierWiseDiscount: Scalars['JSON'];
  promoType: Scalars['String'];
  promoUsageLimitPerMonth: Scalars['Float'];
  maxDiscount: Scalars['Float'];
};

export type CustomerUserPromotionInputs = {
  refIdCustomerUser: Scalars['String'];
  refIdIdPromotion:  Scalars['Float'];
  isSelfAdded: Scalars['Boolean'];
  promotionStatus: Scalars['Boolean'];
};

export type CreateRegularJourneyInput = {
  days?: InputMaybe<Daystype>;
  dropOffLocation: CreateLocationInput;
  endDate: Scalars['String'];
  journeyType: Scalars['String'];
  nameJourney: Scalars['String'];
  paymentMethodId: Scalars['String'];
  pickUpLocation: CreateLocationInput;
  refIdCustomerUser: Scalars['String'];
  startDate: Scalars['String'];
};

export type CreateReturnJourneyInput = {
  paymentMethodId?: InputMaybe<Scalars['String']>;
  pickupTime: Scalars['DateTime'];
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdModes: Scalars['Float'];
};

export type CreateSalikFareInput = {
  areaId: Scalars['Float'];
  salikFee: Scalars['String'];
};

export type CreateSupplierInputs = {
  address: Array<AddressInput>;
  bankDetails: Array<BankDetailsInput>;
  email: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  identityDocuments?: InputMaybe<Array<IdentityDocumentAdminInput>>;
  licenceNo: Scalars['String'];
  mobileNumber: Scalars['String'];
  name?: Scalars['String'];
  refIdTaxiSupplierColor?: Scalars['Float'];
  startDate: Scalars['DateTime'];
  type: Scalars['String'];
};

export type CreateSurgeFareInput = {
  areaId: Scalars['Float'];
  surgeEndTime: Scalars['String'];
  surgeFare: Scalars['String'];
  surgeStartTime: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  createdDate: Scalars['DateTime'];
  devices?: Maybe<Array<Devices>>;
  emailId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  idCustomerUser: Scalars['ID'];
  lastName: Scalars['String'];
  location?: Maybe<Array<CustomerSavedLocEntity>>;
  mediaUrl?: Maybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  modes?: Maybe<Array<Modes>>;
  preferences?: Maybe<Array<UserPreferenceResponse>>;
  profilePicture?: Maybe<Scalars['String']>;
  promotions?: Maybe<Array<CustomerUserPromotions>>;
  rating?: Maybe<Scalars['Float']>;
  referralCode: string;
  refereePromoCode: string;
  totalReferralBonus: number;
  paidReferralBonus: number;
  refereePromoUsedCount: number;
  refIdColourOptions?: Maybe<ColourOptions>;
  status: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  zedName?: Maybe<Scalars['String']>;
};

export type CustomerFeedbackInput = {
  customerComments?: InputMaybe<Scalars['String']>;
  driverRating: Scalars['Float'];
  tip?: InputMaybe<Scalars['Float']>;
};

export type CustomerFeedbackOptions = {
  __typename?: 'CustomerFeedbackOptions';
  customerFeedbackOptionsId: Scalars['Float'];
  feedbackOptionsLabel: Scalars['String'];
  feedbackOptionsName: Scalars['String'];
  isActive: Scalars['Boolean'];
  mediaUrl?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CustomerLoginResponse = {
  __typename?: 'CustomerLoginResponse';
  access_token: Scalars['String'];
  user: Customer;
};

export type CustomerRidesResponse = {
  __typename?: 'CustomerRidesResponse';
  results?: Maybe<Array<Journey>>;
  totalRows: Scalars['Float'];
};

export type CustomerSavedLocEntity = {
  __typename?: 'CustomerSavedLocEntity';
  idCustomerSavedLoc: Scalars['ID'];
  locCustomName: Scalars['String'];
  locType: Scalars['String'];
  refIdCustomerUser: Scalars['String'];
  refIdLocation: LocationsEntity;
};

export enum CustomerSavedLocTypes {
  Airport = 'AIRPORT',
  Custom = 'CUSTOM',
  Gym = 'GYM',
  Home = 'HOME',
  Work = 'WORK',
}

export type CustomerUserPromotions = {
  __typename?: 'CustomerUserPromotions';
  description?: Maybe<Scalars['String']>;
  isSelfAdded: Scalars['Boolean'];
  promocode: Scalars['String'];
  promotionId: Scalars['Float'];
  refIdCustomerUser: Scalars['String'];
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type DayTime = {
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdModes: Scalars['Float'];
  return?: InputMaybe<DayTimeReturn>;
  time: Scalars['String'];
};

export type DayTimeArgs = {
  __typename?: 'DayTimeArgs';
  refIdAddons?: Maybe<Scalars['Float']>;
  refIdModes?: Maybe<Scalars['Float']>;
  return?: Maybe<DayTimeReturnArgs>;
  time?: Maybe<Scalars['String']>;
};

export type DayTimeReturn = {
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdModes: Scalars['Float'];
  time: Scalars['String'];
};

export type DayTimeReturnArgs = {
  __typename?: 'DayTimeReturnArgs';
  refIdAddons?: Maybe<Scalars['Float']>;
  refIdModes?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
};

export type Daystype = {
  FRI?: InputMaybe<DayTime>;
  MON?: InputMaybe<DayTime>;
  SAT?: InputMaybe<DayTime>;
  SUN?: InputMaybe<DayTime>;
  THU?: InputMaybe<DayTime>;
  TUE?: InputMaybe<DayTime>;
  WED?: InputMaybe<DayTime>;
};

export type DaystypeArgs = {
  __typename?: 'DaystypeArgs';
  FRI?: Maybe<DayTimeArgs>;
  MON?: Maybe<DayTimeArgs>;
  SAT?: Maybe<DayTimeArgs>;
  SUN?: Maybe<DayTimeArgs>;
  THU?: Maybe<DayTimeArgs>;
  TUE?: Maybe<DayTimeArgs>;
  WED?: Maybe<DayTimeArgs>;
};

export type DeleteCardInput = {
  paymentMethodId: Scalars['String'];
};

export type DeleteJourney = {
  journeyType?: InputMaybe<Scalars['String']>;
  rythmid: Scalars['Float'];
};

export type Devices = {
  __typename?: 'Devices';
  deviceName: Scalars['String'];
  deviceToken: Scalars['String'];
  idDevices: Scalars['ID'];
  refIdAnyUser: Scalars['String'];
};

export type DistanceTimeEstimationsInput = {
  driverId: Scalars['String'];
  lat: Scalars['String'];
  long: Scalars['String'];
};

export type DistanceTimeEstimationsResponse = {
  __typename?: 'DistanceTimeEstimationsResponse';
  distance: Scalars['Float'];
  duration: Scalars['Float'];
};

export type DriverEntity = {
  __typename?: 'DriverEntity';
  badges?: Maybe<Array<DriverUserBadges>>;
  createdDate: Scalars['DateTime'];
  devices?: Maybe<Array<Devices>>;
  driverDetails?: Maybe<DriverUserDtlEntity>;
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  idDriverUser: Scalars['ID'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  notifications: DriverNotificationResponse;
  online: Scalars['Boolean'];
  ride?: Maybe<RideDriver>;
  rideSummary?: Maybe<RideSummaryResponse>;
  rides?: Maybe<Array<RideDriver>>;
  status: Scalars['String'];
};

export type VehiclesBrandsEntity = {
  __typename?: 'getVehiclesBrands';
  manufacturer?: string[];
  type?: string[];
};

export type VehiclesModelEntity = {
  __typename?: 'getVehiclesModel';
  id?: string[];
  model?: string[];
  type?: string[];
  seatsNo?: string[];
  doorsNo?: string[];
};

export type RegCitiesEntity = {
  __typename?: 'getRegCitiesModel';
  id?: string[];
  name?: string[];
  country?: string[];
};

export type VehicleColorEntity = {
  __typename?: 'getVehicleColour';
  idColor?: string[];
  type?: string[];
  color?: string[];
};

export type VehicleTypeEntity = {
  __typename?: 'getVehicleType';
  id?: string[];
  name?: string[];
};

export type DriverEntityRideArgs = {
  journeyId?: InputMaybe<Scalars['Float']>;
  rideId?: InputMaybe<Scalars['Float']>;
};

export type DriverEntityRidesArgs = {
  category: Ride_Listing_Classification;
};

export type DriverFeedbackInput = {
  customerRating: Scalars['Float'];
  driverComments?: InputMaybe<Scalars['String']>;
};

export type DriverLoginResponse = {
  __typename?: 'DriverLoginResponse';
  access_token: Scalars['String'];
  user: DriverEntity;
};

export type DriverLoginWithMobileNumberInput = {
  mobileNumber: Scalars['String'];
  otp: Scalars['String'];
  sid: Scalars['String'];
};

export type DriverNotfications = {
  __typename?: 'DriverNotfications';
  createdDate: Scalars['String'];
  message: Scalars['String'];
  title: Scalars['String'];
};

export type DriverNotificationResponse = {
  __typename?: 'DriverNotificationResponse';
  existing: Array<DriverNotfications>;
  new: Array<DriverNotfications>;
};

export type DriverRidesResponse = {
  __typename?: 'DriverRidesResponse';
  results?: Maybe<Array<RideDriver>>;
  totalRows: Scalars['Float'];
};

export type DriverUserBadges = {
  __typename?: 'DriverUserBadges';
  idDriverUserBadges: Scalars['Float'];
  refIdBadgeUser: Badge;
  refIdDriverUser: Scalars['ID'];
};

export type DriverUserDtlEntity = {
  __typename?: 'DriverUserDtlEntity';
  about?: Maybe<Scalars['String']>;
  empId?: Scalars['String'];
  experience?: Maybe<Scalars['String']>;
  idDriverUserDtl: Scalars['ID'];
  isActive: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  refIdDriverUser: Scalars['ID'];
  refIdPartner?: Maybe<Partner>;
  refIdVehicle?: Maybe<Vehicle>;
};

export type EditJourneyInput = {
  dropOffLocation?: InputMaybe<UpdateLocationInput>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  pickUpLocation?: InputMaybe<UpdateLocationInput>;
  pickupTime?: InputMaybe<Scalars['DateTime']>;
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdModes?: InputMaybe<Scalars['Float']>;
  returnJourney?: InputMaybe<EditReturnJourneyInput>;
  stops?: InputMaybe<Array<UpdateLocationInput>>;
};

export type EditRegularJourneyInput = {
  days?: InputMaybe<Daystype>;
  dropOffLocation?: InputMaybe<CreateLocationInput>;
  endDate?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  pickUpLocation?: InputMaybe<CreateLocationInput>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type EditReturnJourneyInput = {
  paymentMethodId?: InputMaybe<Scalars['String']>;
  pickupTime?: InputMaybe<Scalars['DateTime']>;
  refIdAddons?: InputMaybe<Scalars['Float']>;
  refIdModes?: InputMaybe<Scalars['Float']>;
};

export type ExposedItem = {
  __typename?: 'ExposedItem';
  idAddonInventory: Scalars['Float'];
  inStock: Scalars['Float'];
  isActive: Scalars['Boolean'];
  itemName: Scalars['String'];
  mediaUrl: Scalars['String'];
  unitPrice: Scalars['Float'];
};

export type FaqCategory = {
  __typename?: 'FaqCategory';
  categoryDescription: Scalars['String'];
  categoryName: Scalars['String'];
  faqCategoryId: Scalars['ID'];
};

export type FaqDtls = {
  __typename?: 'FaqDtls';
  answer: Scalars['String'];
  faqCategoryId: Scalars['Float'];
  faqId: Scalars['ID'];
  question: Scalars['String'];
};

export type FareEntity = {
  __typename?: 'FareEntity';
  baseMorningShift?: Maybe<Scalars['String']>;
  baseNightShift?: Maybe<Scalars['String']>;
  bookingFee?: Maybe<Scalars['String']>;
  farePerKm?: Maybe<Scalars['String']>;
  farePerMin?: Maybe<Scalars['String']>;
  idFare: Scalars['Int'];
  refIdArea: Scalars['Int'];
};

export type FilterDriverInput = {
  emailId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FilterInput = {
  emailId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  differenceInReferralBonus?: InputMaybe<Scalars['Int']>;
};

export type FilterRoleInput = {
  limit: Scalars['Float'];
  name?: InputMaybe<Scalars['String']>;
  offset: Scalars['Float'];
};

export type FilterRoleResponse = {
  __typename?: 'FilterRoleResponse';
  results: Array<AdminRolesEntity>;
  totalRows: Scalars['Float'];
};

export type Filters = {
  modeName?: InputMaybe<Scalars['String']>;
  modeType: Mode_Type;
};

export type FindAreaResponse = {
  __typename?: 'FindAreaResponse';
  areaName: Scalars['String'];
  fare?: Maybe<FareEntity>;
  fareEnt: FareEntity;
  geometry: GeoMetryLocationEntity;
  idArea: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  refIdAreaType: AreaTypeEntity;
  salik?: Maybe<SalikFareEntity>;
  salikEnt: SalikFareEntity;
  surgEnt: SurgeFareEntity;
  surge?: Maybe<SurgeFareEntity>;
};

export type GeoMetryLocationEntity = {
  __typename?: 'GeoMetryLocationEntity';
  geom: Scalars['String'];
  idGeometryLocations: Scalars['Int'];
};

export type GetSaveCardIntentResponse = {
  __typename?: 'GetSaveCardIntentResponse';
  customer: Scalars['String'];
  ephemeralKey: Scalars['String'];
  publishableKey: Scalars['String'];
  setupIntent: Scalars['String'];
};

export type GlobalLocationsEntities = {
  __typename?: 'GlobalLocationsEntities';
  idGlobalLoc: Scalars['ID'];
  isActive: Scalars['Boolean'];
  locType: Scalars['String'];
  location: LocationsEntity;
};

export type IdentityDocumentEntity = {
  __typename?: 'IdentityDocumentEntity';
  backImageUrl: Scalars['String'];
  documentOwner?: Maybe<Scalars['String']>;
  documentType: Scalars['String'];
  expiryDate?: Maybe<Scalars['DateTime']>;
  frontImageUrl: Scalars['String'];
  id: Scalars['ID'];
  idDocument?: Maybe<Scalars['String']>;
  refId: SupplierEntity;
};

export type Item = {
  id: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type Journey = {
  __typename?: 'Journey';
  actualDropOffTime?: Maybe<Scalars['DateTime']>;
  actualPickupTime?: Maybe<Scalars['DateTime']>;
  addons?: Maybe<Array<JourneyAddon>>;
  allowList: Scalars['JSONObject'];
  bookingRef: Scalars['String'];
  customer: Customer;
  dispatchRetryCount: Scalars['Float'];
  driver?: Maybe<DriverEntity>;
  driverName?: Maybe<Scalars['String']>;
  driverRejectRetryCount: Scalars['Float'];
  dropoffRefIdLocation: LocationsEntity;
  estimatedCost?: Maybe<Scalars['String']>;
  estimatedDroppoff?: Maybe<Scalars['DateTime']>;
  idJourney: Scalars['ID'];
  journeyType: Scalars['String'];
  pickupRefIdLocation: LocationsEntity;
  pickupTime: Scalars['DateTime'];
  pricing?: Maybe<PriceBreakdown>;
  refIdCustomerUser: Scalars['String'];
  refIdModes?: Maybe<Modes>;
  refIdRhythm?: Maybe<Scalars['Float']>;
  retryCount: Scalars['Float'];
  returnRefIdJourney?: Maybe<Journey>;
  rhythm?: Maybe<Rhythm>;
  snapshot?: Maybe<RideSnapshotEntity>;
  status: Scalars['String'];
  stops?: Maybe<Array<Stops>>;
  stripePaymentMethod: Scalars['String'];
};

export type ReferralJourney = {
  __typename?: 'Journey';
  firstName: string;
  lastName: string;
  refereeUsedPromoCodeCount: number;
};

export type JourneyAddon = {
  __typename?: 'JourneyAddon';
  inventoryItem: AddonInventory;
  itemDetails: ExposedItem;
  quantity: Scalars['Float'];
  refIdAddonItem: Scalars['Float'];
};

export type JourneyCustomerFeedback = {
  __typename?: 'JourneyCustomerFeedback';
  journeyCustomerFeedbackId: Scalars['Float'];
  refIdCustomerFeedbackOptions: CustomerFeedbackOptions;
  refIdJourney: Journey;
};

export type JourneyEstimationWithPricing = {
  __typename?: 'JourneyEstimationWithPricing';
  distance: Scalars['Float'];
  dropOffTime: Scalars['DateTime'];
  pricing: PriceBreakdown;
  routePolyline: Scalars['String'];
  stopsEstimatedDropOff?: Maybe<Array<StopsEstimatedDropOff>>;
};

export type JourneyEstimationWithReturn = {
  __typename?: 'JourneyEstimationWithReturn';
  distance: Scalars['Float'];
  dropOffTime: Scalars['DateTime'];
  pricing: PriceBreakdown;
  return?: Maybe<JourneyEstimationWithPricing>;
  routePolyline: Scalars['String'];
  stopsEstimatedDropOff?: Maybe<Array<StopsEstimatedDropOff>>;
};

export type JourneyEstimationsInput = {
  dropOffLocation: CreateLocationInput;
  pickUpLocation: CreateLocationInput;
  pickupTime: Scalars['DateTime'];
  stops?: InputMaybe<Array<CreateLocationInput>>;
};

export type JourneyMedia = {
  __typename?: 'JourneyMedia';
  mediaUrl: Scalars['String'];
};

export type JourneyPriceBreakdown = {
  __typename?: 'JourneyPriceBreakdown';
  addonFee: Scalars['Float'];
  baseFare: Scalars['Float'];
  bookingFee: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  discount: Scalars['Float'];
  farePerKm: Scalars['Float'];
  farePerMin: Scalars['Float'];
  idJourneyPriceBreakdown: Scalars['Float'];
  isEstimated: Scalars['Boolean'];
  refIdJourney: Scalars['Float'];
  rideModifiedFee: Scalars['Float'];
  salikFee: Scalars['Float'];
  surgeCharges: Scalars['Float'];
  total: Scalars['Float'];
  updatedDate: Scalars['DateTime'];
  vat: Scalars['Float'];
  voucher: Scalars['Float'];
  waitingFee: Scalars['Float'];
};

export type LatLong = {
  __typename?: 'LatLong';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type LatLongInput = {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type LightOptions = {
  __typename?: 'LightOptions';
  idLightOptions: Scalars['Int'];
  value: Scalars['String'];
};

export type ListAdminUsersInput = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type ListAdminUsersResponse = {
  __typename?: 'ListAdminUsersResponse';
  results: Array<Admin>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListCompaniesInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type ListCompaniesResponse = {
  __typename?: 'ListCompaniesResponse';
  results: Array<Company>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListCompanySecretCodesInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type ListCompanySecretCodesResponse = {
  __typename?: 'ListCompanySecretCodesResponse';
  results: Array<CompanySecretCodes>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListCustomersInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type ListCustomersResponse = {
  __typename?: 'ListCustomersResponse';
  results: Array<Customer>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type WaitingListInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type WaitingListResponse = {
  __typename?: 'WaitingListResponse';
  results: Array<Customer>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListDriversInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export type ListDriversResponse = {
  __typename?: 'ListDriversResponse';
  results: Array<DriverEntity>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListModesInput = {
  filters?: InputMaybe<Filters>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type ListSupplierResponse = {
  __typename?: 'ListSupplierResponse';
  results: Array<SupplierEntity>;
  totalRows?: Maybe<Scalars['Float']>;
};

export type ListSuppliersInputs = {
  filter?: InputMaybe<FilterInput>;
  limit: Scalars['Float'];
  offset?: InputMaybe<Scalars['Float']>;
};

export enum LocationTypes {
  Custom = 'CUSTOM',
  Global = 'GLOBAL',
  Journey = 'JOURNEY',
}

export type LocationsEntity = {
  __typename?: 'LocationsEntity';
  idLocation: Scalars['ID'];
  lat: Scalars['String'];
  locationCustomName: Scalars['String'];
  locationName: Scalars['String'];
  locationType: Scalars['String'];
  long: Scalars['String'];
  placeId?: Maybe<Scalars['String']>;
};

export type LoginAdminInput = {
  emailId: Scalars['String'];
  password: Scalars['String'];
};

export type LoginCustomerInput = {
  emailId: Scalars['String'];
  password: Scalars['String'];
};

export type LoginDriverInput = {
  emailId: Scalars['String'];
  password: Scalars['String'];
};

export type LoginWithMobileNumberInput = {
  mobileNumber: Scalars['String'];
  otp: Scalars['String'];
  sid: Scalars['String'];
};

export enum Mode_Type {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Global = 'GLOBAL',
}

export type ModeOptions = {
  __typename?: 'ModeOptions';
  acTemp?: Maybe<Array<AcTempOptions>>;
  art?: Maybe<Array<ArtOptions>>;
  chat?: Maybe<Array<ChatOptions>>;
  colour?: Maybe<Array<ColourOptions>>;
  light?: Maybe<Array<LightOptions>>;
  musicGenere?: Maybe<Array<MusicGenereOptions>>;
  musicVolume?: Maybe<Array<MusicVolumeOptionsEntity>>;
};

export type Modes = {
  __typename?: 'Modes';
  deletedAt?: Maybe<Scalars['String']>;
  idModes: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isOnetimeUse?: Maybe<Scalars['Boolean']>;
  mediaUrl?: Maybe<Scalars['String']>;
  modeDescription?: Maybe<Scalars['String']>;
  modeName: Scalars['String'];
  modeType: Scalars['String'];
  refIdArtOptions: ArtOptions;
  refIdChatOptions: ChatOptions;
  refIdColourOptions: ColourOptions;
  refIdIdAcTempOptions: AcTempOptions;
  refIdLightOptions: LightOptions;
  refIdMusicGenereOptions: MusicGenereOptions;
  refIdMusicVolumeOptions: MusicVolumeOptionsEntity;
};

export type MusicGenereOptions = {
  __typename?: 'MusicGenereOptions';
  idMusicGenereOptions: Scalars['Int'];
  value: Scalars['String'];
};

export type MusicVolumeOptionsEntity = {
  __typename?: 'MusicVolumeOptionsEntity';
  idMusicVolumeOptions: Scalars['Int'];
  value: Scalars['String'];
};
export type Company = {
  __typename?: 'Company';
  idCompany: Scalars['ID'];
  companyName: Scalars['String'];
};

export type CompanySecretCodes = {
  __typename?: 'CompanySecretCodes';
  idCompanySecretCode: Scalars['Int'];
  companySecretCode: Scalars['String'];
  refIdCompany: Scalars['String'];
  expiry_date: Scalars['DateTime'];
};

// export type AddCompanyInput = {
//   __typename?: 'AddCompanyInput';
//   companyName: Scalars['String'];
// };
export type Mutation = {
  __typename?: 'Mutation';
  addAddonToJourney: SuccessResponse;
  /** Admin - Add new Fare */
  addFare: FareEntity;
  /** Admin - Add new SalikFare */
  addSalikFare: SalikFareEntity;
  /** Admin - Add new SurgeFare */
  addSurgeFare: SurgeFareEntity;
  addUserRole: SuccessResponse;
  adminEditBooking: Journey;
  adminRescheduleBooking: SuccessResponse;
  applyLocationToDriver: Scalars['Boolean'];
  applyPromotion: SuccessResponse;
  assignAddonsToDriverAdmin: SuccessResponse;
  cancelJourneyCustomer: ChallengeResponse;
  cancelRideByAdmin: SuccessResponse;
  confirmCancelJourneyCustomer: Scalars['Boolean'];
  confirmEditJourneyCustomer: Scalars['Boolean'];
  confirmRemoveCustomerAddonsJourney: Scalars['Boolean'];
  /** Create new admin user */
  createAdminUser: SuccessResponse;
  /** Admin - Add new Area */
  createArea: AreaEntity;
  /** Admin - Add new Area Type */
  createAreaType: AreaTypeEntity;
  /** This will create new `Companies */
  createCompany: Company;
  /** This will create new SecretCode */
  createCompanySecretCode: Company;
  /** This will signup new `Customers */
  createCustomer: CreateCustomerResponse;
  /** Admin - This will signup new Customers */
  createCustomerAdmin: Customer;
  /** This will signup new `Drivers */
  createDriver: DriverEntity;
  /** This will signup new `Drivers */
  createDriverAdmin: DriverEntity;
  /** This will create new `GlobalLocations */
  createGlobalLocation: GlobalLocationsEntities;
  createJourney: Journey;
  createJourneyV2: Journey;
  /** This will create new `Mode */
  createMode: Modes;
  /** Admin will create a new Mode */
  createModeAdmin: Modes;
  createModeByAdmin: SuccessResponse;
  createRythm: Rhythm;
  createRythmV2: Rhythm;
  /** This will create new Supplier */
  createSupplierAdmin: SupplierEntity;
  updateSupplier: SupplierEntity;
  deleteAdminUser: SuccessResponse;
  deleteWaitingList: SuccessResponse;
  /** Admin - Delete area */
  deleteArea: SuccessResponse;
  deleteCard: SuccessResponse;
  /** Delete customer account */
  deleteCustomerAccount: SuccessResponse;
  /** Admin - Delete customer account */
  deleteCustomerAccountAdmin: SuccessResponse;
  addDriverLeave: SuccessResponse;
  /** Admin - Delete Driver account */
  deleteDriverAccountAdmin: SuccessResponse;
  /** Admin - Delete Fare */
  deleteFare: SuccessResponse;
  /** This will delete `GlobalLocation */
  deleteGlobalLocation: Scalars['Float'];
  /** This will delete `Mode */
  deleteMode: SuccessResponse;
  deleteRole: SuccessResponse;
  deleteRythm: Scalars['Boolean'];
  /** Admin - Delete Salik */
  deleteSalik: SuccessResponse;
  /** Admin - Delete Surge */
  deleteSurge: SuccessResponse;
  denyRide: SuccessResponse;
  driverAcceptRide: SuccessResponse;
  driverOnRoute: SuccessResponse;
  driverRejectRide: SuccessResponse;
  editJourney: Journey;
  editJourneyCustomer: ChallengeResponse;
  editRythm: Rhythm;
  extendWaitingTime: Scalars['Boolean'];
  /** Admin Login */
  loginAsAdmin: AdminLoginResponse;
  /** Customer Login */
  loginAsCustomer: CustomerLoginResponse;
  /** Driver Login */
  loginAsDriver: DriverLoginResponse;
  loginWithAdminMobileNumber: AdminLoginResponse;
  /** Customer Login via Mobile Number */
  loginWithCustomerMobileNumber: CustomerLoginResponse;
  pauseRythm: Rhythm;
  /** Mutation to register a Customer Device for Push Notification */
  registerCustomerDevice: Devices;
  /** Mutation to register a Driver Device for Push Notification */
  registerDriverDevice: Devices;
  removeCustomerAddonsJourney: ChallengeResponse;
  removePromotion: SuccessResponse;
  rideArrived: SuccessResponse;
  rideEnded: SuccessResponse;
  rideStarted: SuccessResponse;
  saveAdminMediaUrl: Scalars['String'];
  saveJourneyMedia: Array<Scalars['String']>;
  /** This will save new locatiion */
  saveLocation: CustomerSavedLocEntity;
  saveUserMediaUrl: Scalars['String'];
  sendPushNotification: Scalars['Boolean'];
  /** Verify OTP for Email and Phone */
  sendSupplierOTP: OtpVerificationResponse;
  setDefaultPaymentMethod: SuccessResponse;
  simulateRide: Scalars['String'];
  submitRideFeedbackCustomer: RideSnapshotEntity;
  submitRideFeedbackDriver: RideSnapshotEntity;
  swapMoveMode: SuccessResponse;
  testAddPromotionToCustomer: SuccessResponse;
  testAssignJourneyToDriver: Scalars['Boolean'];
  testChangeZedConfig: SuccessResponse;
  testGeometry: RideSnapshotEntity;
  /** To update the drivers current location */
  testNotificationsUpdate: Scalars['Boolean'];
  testRidePayment: Scalars['String'];
  testSetConfigJourneyPickUpTimeInterval: Scalars['Boolean'];
  /** Update Admin's Password */
  updateAdminPassword: SuccessResponse;
  updateAdminUser: AdminUpdateResponse;
  /** Admin - Update Area */
  updateArea: SuccessResponse;
  /** This will update profile color of a user */
  updateColorOption: Customer;
  /** This will update `Customer */
  updateCustomer: Customer;
  /** This will update `Referral Customer */
  updateReferralCustomer: Customer;
  /** Admin - This will update `Customer */
  updateCustomerAdmin: Customer;
  updateCustomerFeedbackForJourney: Array<JourneyCustomerFeedback>;
  /** This will update Customer Password */
  updateCustomerPassword: SuccessResponse;
  /** This will update `Driver */
  updateDriver: DriverEntity;
  /** To update the drivers current location */
  updateDriverCurrentLocation: SuccessResponse;
  /** To update the drivers current location */
  updateDriverCurrentLocationV2: SuccessResponse;
  /** To update the drivers current location */
  updateDriverCurrentLocationV3: SuccessResponse;
  /** This will update `Driver password */
  updateDriverPassword: SuccessResponse;
  /** Admin - Update Fare */
  updateFare: FareEntity;
  /** This will update `GlobalLocation */
  updateGlobalLocation: GlobalLocationsEntities;
  /** Profile - Update customer mobile number */
  updateMobileNumber: SuccessResponse;
  /** This will update `Mode */
  updateMode: Modes;
  updateModeAdmin: Modes;
  updateMoveMode: SuccessResponse;
  /** update customer mediaUrl */
  updateProfilePic: SuccessResponse;
  updateRole: SuccessResponse;
  /** Admin - Update SalikFare */
  updateSalikFare: SalikFareEntity;
  /** Admin - Update SurgeFare */
  updateSurgeFare: SurgeFareEntity;
  /** Update User Preferences */
  updateUserPreferences: SuccessResponse;
  updatedDriversStatus: Scalars['Boolean'];
  /** Admin Forgot Password */
  verifyAdminMobileNumber: OtpVerificationResponse;
  /** This will verify `Driver MobileNumber And OTP */
  verifyDriverMobileNumberAndOTP: DriverLoginResponse;
  /** Login - Validate Customer Mobile Number and send otp */
  verifyMobileNumberForLogin: OtpVerificationResponse;
  /** Profile - Verify customer mobile number and send OTP */
  verifyMobileNumberForMobileUpdate: OtpVerificationResponse;
  /** Signup - Validate Customer Mobile Number and send otp */
  verifyMobileNumberForSignUp: OtpVerificationResponse;
  verifyOTPOnCreateSupplier: SuccessResponse;
};

export type MutationAddAddonToJourneyArgs = {
  inputs: AddItemToJourneyInput;
};

export type MutationAddFareArgs = {
  input: CreateFareInput;
};

export type MutationAddSalikFareArgs = {
  input: CreateSalikFareInput;
};

export type MutationAddSurgeFareArgs = {
  input: CreateSurgeFareInput;
};

export type MutationAddUserRoleArgs = {
  inputs: AddRoleInput;
};

export type MutationAdminEditBookingArgs = {
  idJourney: Scalars['Float'];
  input: AdminEditJourneyInput;
};

export type MutationAdminRescheduleBookingArgs = {
  input: RescheduleJourneyInput;
};

export type MutationApplyLocationToDriverArgs = {
  driverId: Scalars['String'];
  polyline: Scalars['String'];
};

export type MutationApplyPromotionArgs = {
  journeyId: Scalars['Float'];
  promoCode: Scalars['String'];
  promotionId: Scalars['Float'];
};

export type MutationAssignAddonsToDriverAdminArgs = {
  inputs: AssignAddonsToDriverInput;
};

export type MutationCancelJourneyCustomerArgs = {
  journeyId: Scalars['Float'];
};

export type MutationCancelRideByAdminArgs = {
  journeyId: Scalars['Float'];
};

export type MutationConfirmCancelJourneyCustomerArgs = {
  token: Scalars['String'];
};

export type MutationConfirmEditJourneyCustomerArgs = {
  data: EditJourneyInput;
  token: Scalars['String'];
};

export type MutationConfirmRemoveCustomerAddonsJourneyArgs = {
  token: Scalars['String'];
};

export type MutationCreateAdminUserArgs = {
  input: CreateAdminUserInput;
};

export type MutationCreateAreaArgs = {
  input: CreateAreaInput;
};

export type MutationCreateAreaTypeArgs = {
  name: Scalars['String'];
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerAdminArgs = {
  input: CreateCustomerAdminInput;
};

export type MutationCreateDriverArgs = {
  input: CreateDriverInput;
};

export type MutationCreateCompanyArgs = {
  input: AddCompanyInput;
};

export type MutationCreatePromoCodeArgs = {
  input: CreatePromotionInputs;
};

export type MutationUpdatePromoCodeArgs = {
  input: UpdatePromotionInputs;
};

export type MutationCustomerUserPromoCodeArgs = {
  input: CustomerUserPromotionInputs;
};

export type MutationCreateCompanySecretCodeArgs = {
  input: CompanySecretCodesInput;
};

export type MutationCreateDriverAdminArgs = {
  input: CreateDriverAdminInput;
};

export type MutationCreateGlobalLocationArgs = {
  input: CreateGlobalLocInput;
};

export type MutationCreateJourneyArgs = {
  input: CreateJourneyInput;
};

export type MutationCreateJourneyV2Args = {
  input: CreateJourneyInput;
};

export type MutationCreateModeArgs = {
  input: CreateModeInput;
};

export type MutationCreateModeAdminArgs = {
  input: CreateModeAdminInput;
};

export type MutationCreateModeByAdminArgs = {
  inputs: CreateModeInput;
};

export type MutationCreateRythmArgs = {
  input: CreateRegularJourneyInput;
};

export type MutationCreateRythmV2Args = {
  input: CreateRegularJourneyInput;
};

export type MutationCreateSupplierAdminArgs = {
  input: CreateSupplierInputs;
};

export type MutationDeleteAdminUserArgs = {
  userId: Scalars['String'];
};

export type MutationDeleteAreaArgs = {
  areaId: Scalars['Float'];
};

export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
};

export type MutationDeleteCustomerAccountArgs = {
  pwd: Scalars['String'];
};

export type MutationDeleteCustomerAccountAdminArgs = {
  id: Scalars['String'];
};

export type MutationDeleteDriverAccountAdminArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFareArgs = {
  fareId: Scalars['Float'];
};

export type MutationDeleteGlobalLocationArgs = {
  globalLocId: Scalars['Float'];
};

export type MutationDeleteModeArgs = {
  modeId: Scalars['Float'];
};

export type MutationDeleteRoleArgs = {
  roleId: Scalars['Float'];
};

export type MutationDeleteRythmArgs = {
  input: DeleteJourney;
};

export type MutationDeleteSalikArgs = {
  salikId: Scalars['Float'];
};

export type MutationDeleteSurgeArgs = {
  surgeId: Scalars['Float'];
};

export type MutationDenyRideArgs = {
  entryId: Scalars['Float'];
  journeyId: Scalars['Float'];
};

export type MutationDriverAcceptRideArgs = {
  journeyId: Scalars['Float'];
};

export type MutationDriverOnRouteArgs = {
  journeyId: Scalars['Float'];
};

export type MutationDriverRejectRideArgs = {
  journeyId: Scalars['Float'];
};

export type MutationEditJourneyArgs = {
  idJourney: Scalars['Float'];
  input: EditJourneyInput;
};

export type MutationEditJourneyCustomerArgs = {
  journeyId: Scalars['Float'];
};

export type MutationEditRythmArgs = {
  input: EditRegularJourneyInput;
  rythmID: Scalars['Float'];
};

export type MutationExtendWaitingTimeArgs = {
  journeyId: Scalars['Float'];
};

export type MutationLoginAsAdminArgs = {
  loginAdminInput: LoginAdminInput;
};

export type MutationLoginAsCustomerArgs = {
  loginCustomerInput: LoginCustomerInput;
};

export type MutationLoginAsDriverArgs = {
  loginDriverInput: LoginDriverInput;
};

export type MutationLoginWithAdminMobileNumberArgs = {
  loginWithMobileNumberInput: LoginWithMobileNumberInput;
};

export type MutationLoginWithCustomerMobileNumberArgs = {
  loginWithMobileNumberInput: LoginWithMobileNumberInput;
};

export type MutationPauseRythmArgs = {
  input: PauseRegularJourneyInput;
  rythmID: Scalars['Float'];
};

export type MutationRegisterCustomerDeviceArgs = {
  deviceToken: Scalars['String'];
};

export type MutationRegisterDriverDeviceArgs = {
  deviceToken: Scalars['String'];
};

export type MutationRemoveCustomerAddonsJourneyArgs = {
  journeyId: Scalars['Float'];
};

export type MutationRemovePromotionArgs = {
  journeyId: Scalars['Float'];
  promotionId: Scalars['Float'];
};

export type MutationRideArrivedArgs = {
  journeyId: Scalars['Float'];
};

export type MutationRideEndedArgs = {
  inputs: RideEndedInputs;
};

export type MutationRideStartedArgs = {
  inputs: RideStartedInputs;
};

export type MutationSaveAdminMediaUrlArgs = {
  fileName: Scalars['String'];
};

export type MutationSaveJourneyMediaArgs = {
  fileNames: Array<Scalars['String']>;
  journeyId: Scalars['Float'];
};

export type MutationSaveLocationArgs = {
  customerId: Scalars['String'];
  input: SaveLocationInput;
};

export type MutationSaveUserMediaUrlArgs = {
  fileName: Scalars['String'];
};

export type MutationSendPushNotificationArgs = {
  input: SendPushInput;
};

export type MutationSendSupplierOtpArgs = {
  input: VerifyMobileNumberInput;
};

export type MutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultCardInput;
};

export type MutationSimulateRideArgs = {
  input: RideSimulation;
};

export type MutationSubmitRideFeedbackCustomerArgs = {
  input: CustomerFeedbackInput;
  journeyId: Scalars['Float'];
};

export type MutationSubmitRideFeedbackDriverArgs = {
  input: DriverFeedbackInput;
  journeyId: Scalars['Float'];
};

export type MutationSwapMoveModeArgs = {
  journeyId: Scalars['Float'];
  modeId: Scalars['Float'];
  onetimeUse: Scalars['Boolean'];
};

export type MutationTestAddPromotionToCustomerArgs = {
  inputs: CreatePromotionInputs;
};

export type MutationTestAssignJourneyToDriverArgs = {
  journeyId: Scalars['Float'];
};

export type MutationTestChangeZedConfigArgs = {
  inputs: ZedConfigEditInputs;
};

export type MutationTestGeometryArgs = {
  journeyId: Scalars['Float'];
  ridePolyline: Scalars['String'];
};

export type MutationTestNotificationsUpdateArgs = {
  message: Scalars['String'];
  title: Scalars['String'];
};

export type MutationTestRidePaymentArgs = {
  journeyId: Scalars['Float'];
};

export type MutationTestSetConfigJourneyPickUpTimeIntervalArgs = {
  inMin: Scalars['Float'];
};

export type MutationUpdateAdminPasswordArgs = {
  password: Scalars['String'];
};

export type MutationUpdateAdminUserArgs = {
  inputs: UpdateAdminUserInput;
};

export type MutationUpdateWaitingListArgs = {
  inputs: UpdateWaitingListInput;
};

export type MutationUpdateAreaArgs = {
  input: UpdateAreaInput;
};

export type MutationUpdateColorOptionArgs = {
  colorId: Scalars['Int'];
};

export type MutationUpdateCustomerArgs = {
  input: UpdateReferralCustomerInput;
};

export type MutationUpdateCustomerAdminArgs = {
  input: UpdateCustomerAdminInput;
};

export type MutationUpdateCustomerFeedbackForJourneyArgs = {
  journeyId: Scalars['Float'];
  options: Array<Scalars['Float']>;
};

export type MutationUpdateCustomerPasswordArgs = {
  password: Scalars['String'];
};

export type MutationUpdateDriverArgs = {
  driverId: Scalars['String'];
  input: UpdateDriverInput;
};

export type MutationUpdateDriverCurrentLocationArgs = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type MutationUpdateDriverCurrentLocationV2Args = {
  coordsSet: Array<LatLongInput>;
};

export type MutationUpdateDriverCurrentLocationV3Args = {
  coordsSet: Array<DriverLocUpdateInput>;
};

export type MutationUpdateDriverPasswordArgs = {
  password: Scalars['String'];
};

export type MutationUpdateFareArgs = {
  input: UpdateFareInput;
};

export type MutationUpdateGlobalLocationArgs = {
  globalLocId: Scalars['Float'];
  input: UpdateGlobalLocInput;
};

export type MutationUpdateMobileNumberArgs = {
  input: UpdateMobileNumberInput;
};

export type MutationUpdateModeArgs = {
  input: UpdateModeInput;
  modeId: Scalars['Float'];
};

export type MutationUpdateModeAdminArgs = {
  inputs: UpdateModeInput;
  modeId: Scalars['Float'];
};

export type MutationUpdateMoveModeArgs = {
  inputs: UpdateMoveModeInput;
};

export type MutationUpdateProfilePicArgs = {
  url: Scalars['String'];
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateSalikFareArgs = {
  input: UpdateSalikFareInput;
};

export type MutationUpdateSurgeFareArgs = {
  input: UpdateSurgeFareInput;
};

export type MutationUpdateUserPreferencesArgs = {
  input: UserPreferenceInput;
};

export type MutationUpdatedDriversStatusArgs = {
  isOnline: Scalars['Boolean'];
};

export type MutationVerifyAdminMobileNumberArgs = {
  input: AdminForgotPasswordInput;
};

export type MutationVerifyDriverMobileNumberAndOtpArgs = {
  input: DriverLoginWithMobileNumberInput;
};

export type MutationVerifyMobileNumberForLoginArgs = {
  input: VerifyMobileNumberInput;
};

export type MutationVerifyMobileNumberForMobileUpdateArgs = {
  input: VerifyMobileNumberInput;
};

export type MutationVerifyMobileNumberForSignUpArgs = {
  input: VerifyMobileNumberInput;
};

export type MutationVerifyOtpOnCreateSupplierArgs = {
  input: OtpVerificationInput;
};

export type OtpVerificationResponse = {
  __typename?: 'OTPVerificationResponse';
  sid: Scalars['String'];
};

export type OtpVerificationInput = {
  email: Scalars['String'];
  emailOtp: Scalars['String'];
  mobileNumber: Scalars['String'];
  mobileOtp: Scalars['String'];
};

export enum OwnerTypes {
  Customer = 'CUSTOMER',
  Driver = 'DRIVER',
  Supplier = 'SUPPLIER',
}

export type PageData = {
  __typename?: 'PageData';
  count: Scalars['Float'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedModes = {
  __typename?: 'PaginatedModes';
  results: Array<Modes>;
  totalRows: Scalars['Float'];
};

export type Partner = {
  __typename?: 'Partner';
  idPartner: Scalars['Float'];
  partnerName?: Maybe<Scalars['String']>;
};

export type PauseRegularJourneyInput = {
  journeyType?: InputMaybe<Scalars['String']>;
  pausedFrom: Scalars['DateTime'];
  pausedUntil: Scalars['DateTime'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand: Scalars['String'];
  default: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  last4: Scalars['String'];
  paymentMethodId: Scalars['String'];
  type: Scalars['String'];
};

export type PriceBreakdown = {
  __typename?: 'PriceBreakdown';
  currency: Scalars['String'];
  isEstimation: Scalars['Boolean'];
  pricingGroups: Array<PriceBreakdownGroup>;
  total: Scalars['Float'];
};

export type PriceBreakdownGroup = {
  __typename?: 'PriceBreakdownGroup';
  amount: Scalars['Float'];
  group_type: PriceGroup;
  label: Scalars['String'];
};

export enum PriceGroup {
  Addons = 'ADDONS',
  ChargesFees = 'CHARGES_FEES',
  Discount = 'DISCOUNT',
  Fare = 'FARE',
  Taxes = 'TAXES',
  Toll = 'TOLL',
}

export type PromotionEntity = {
  __typename?: 'PromotionEntity';
  activeFromDate: Scalars['DateTime'];
  activeTillDate: Scalars['DateTime'];
  discount: Scalars['Float'];
  isActive: Scalars['Boolean'];
  minimumBaseFare: Scalars['Float'];
  perCustomerUsageLimit: Scalars['Float'];
  promoCode: Scalars['String'];
  promoDescription: Scalars['String'];
  promoTitle: Scalars['String'];
  totalUsageLimit: Scalars['Float'];
  typesOfUsers: Scalars['String'];
  promoSpecification: Scalars['String'];
  promoApplication: Scalars['String'];
  unit: Scalars['String'];
  idPromotion: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  addongetjourney: Scalars['Float'];
  /** Check Customer Validity */
  checkDeleteValidity: Scalars['String'];
  customerRideSummary: RideSummaryResponse;
  /** Get Registered devices for driver */
  devices: Scalars['String'];
  /** Driver Logout */
  faq: FaqDtls;
  /** Driver Logout */
  faqCategory: Array<FaqCategory>;
  /** Driver Logout */
  faqs: Array<FaqDtls>;
  getAdminBookingSummary: BookingFilterResponse;
  getAdminCancelledBookings: BookingFilterResponse;
  /** Get all Customer Rides for Admin */
  getAdminCustomerRides: CustomerRidesResponse;
  /** Get all Driver Rides for Admin */
  getAdminDriverRides: DriverRidesResponse;
  getAdminSignedUrl: S3SignedUrlResponse;
  getAdminUserByID: Admin;
  /** List of admin users with pagination and search */
  getAdminUsersList: ListAdminUsersResponse;
  /** This will return all the company*/
  getAllCompanies: Array<Company>;
  getAllSuppliers: Array<CreateSupplierInputs>;
  /** Get all `GlobalLocations */
  getAllGlobalLocations: Array<GlobalLocationsEntities>;
  /** Get all journies */
  getAllJournies: Array<Journey>;
  /** Get all promotions */
  getAllPromotions: Array<PromotionEntity>;
  /** Admin - Find area by id */
  getArea: FindAreaResponse;
  /** Admin - Get all area Types */
  getAreaType: Array<AreaTypeEntity>;
  /** Admin - Get all areas */
  getAreas: Array<AreaEntity>;
  getAvailableAddonForDriver: Array<AddonDriverInventory>;
  getAvailableDriversForLocation: AvailableDriverResponse;
  /** Get Session Token for Chat SDK */
  getChatToken: Scalars['String'];
  getConfigJourneyPickUpTimeInterval: Scalars['Float'];
  getCurrentDriverLocationDuringRide?: Maybe<LatLong>;
  /** The List of Companies with Pagination and filters */
  getCompaniesAdmin: ListCompaniesResponse;
  /** The List of Companies with Pagination and filters */
  getCompanySecretCodesAdmin: ListCompanySecretCodesResponse;
  /** Get the `Customer */
  /** This will return all the company*/
  getAllCompanySecretCodes: Array<CompanySecretCodes>;
  getCustomer: Customer;
  getCustomerFeedbackOptions: Array<CustomerFeedbackOptions>;
  /** The List of Customers with filters */
  getCustomers: Array<Customer>;
  /** The List of Customers with Pagination and filters */
  getCustomersAdmin: ListCustomersResponse;
  getDepartmentsList: Array<AdminDepartment>;
  /** Get Distance and Time Estimations */
  getDistanceTimeEstimations: DistanceTimeEstimationsResponse;
  /** Get the `Drivers */
  getDriver: DriverEntity;
  getVehiclesBrands: VehiclesBrandsEntity;
  /** Get the `Driver for Admin */
  getDriverAdmin: DriverEntity;
  /** Get Drivers location */
  getDriverCurrentLocation: LatLong;
  /** The List of Drivers with Filters */
  getDrivers: Array<DriverEntity>;
  /** The List of Drivers with Pagination and filters */
  getDriversAdmin: ListDriversResponse;
  /** Get all Gloabl Modes with values */
  getGlobalModeDetails: Array<Modes>;
  getJourneyActualPricing: JourneyPriceBreakdown;
  getJourneyEstimations: JourneyEstimationWithReturn;
  getJourneyMediaUploadUrls: Array<S3SignedUrlResponse>;
  getJourneyPriceBreakDown: JourneyPriceBreakdown;
  /** Get journy details based on id */
  getJourny: Journey;
  getReferralJourney: ReferralJourney;
  /** Get all Options */
  getModeOptions: ModeOptions;
  /** Get S3 profile picture path */
  getProfilePicPath: Scalars['String'];
  getRegisteredDeviceIds: Array<Scalars['String']>;
  getRoleByID: AdminRolesEntity;
  getRoleSubjects: Array<RoleSubjectsEntity>;
  /** Get RhythmDetailsArgs details based on id */
  getRythm: RhythmDetailsArgs;
  getSaveCardIntent: GetSaveCardIntentResponse;
  getSelectedAddonsForJourney: Array<JourneyAddon>;
  /** The List of Suppliers */
  getSuppliers: Array<SupplierEntity>;
  /** The List of Suppliers with Pagination and filters */
  getSuppliersAdmin: ListSupplierResponse;
  getUserRoleList: FilterRoleResponse;
  getUserUploadUrl: S3SignedUrlResponse;
  /** This will return all the vehicles and partners */
  getVehiclesAndPartners: VehiclePartnerResponse;
  /** check if email already exist */
  isValidEmail: Scalars['Boolean'];
  listCards: Array<PaymentMethod>;
  listModesAdmin: PaginatedModes;
  /** Customer Logout */
  logoutAsCustomer: SuccessResponse;
  /** Driver Logout */
  logoutAsDriver: SuccessResponse;
  root: Scalars['String'];
  testGetSnappedCoordinatesForDriver: Scalars['String'];
  /** verify `Driver MobileNumber And Send OTP */
  verifyDriverMobileNumberAndSendOTP: OtpVerificationResponse;
};

export type QueryCustomerRideSummaryArgs = {
  customerId: Scalars['String'];
};

export type QueryFaqArgs = {
  faqId: Scalars['Float'];
};

export type QueryFaqsArgs = {
  faqCategoryId: Scalars['Float'];
};

export type QueryGetAdminBookingSummaryArgs = {
  input: BookingFilterInputs;
};

export type QueryGetAdminCancelledBookingsArgs = {
  input: BookingFilterInputs;
};

export type QueryGetAdminCustomerRidesArgs = {
  input: AdminRideInputs;
};

export type QueryGetAdminDriverRidesArgs = {
  input: AdminRideInputs;
};

export type QueryGetAdminUserByIdArgs = {
  userId: Scalars['String'];
};

export type QueryGetAdminUsersListArgs = {
  input: ListAdminUsersInput;
};

export type QueryGetAllJourniesArgs = {
  category: Ride_Listing_Classification;
  customerId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
};

export type QueryGetAreaArgs = {
  areaId: Scalars['Float'];
};

export type QueryGetAreasArgs = {
  filter?: InputMaybe<FilterInput>;
};

export type QueryGetAvailableAddonForDriverArgs = {
  driverId: Scalars['String'];
};

export type QueryGetAvailableDriversForLocationArgs = {
  journeyId: Scalars['Float'];
};

export type QueryGetCurrentDriverLocationDuringRideArgs = {
  driverId: Scalars['String'];
  jounrneyId: Scalars['Float'];
};

export type QueryGetCustomerArgs = {
  customerId: Scalars['String'];
};

export type QueryGetCustomerFeedbackOptionsArgs = {
  tag: Scalars['String'];
};

export type QueryGetCustomersArgs = {
  filter?: InputMaybe<FilterInput>;
};

export type QueryGetCustomersAdminArgsi = {
  input: ListCompaniesInputs;
};

export type QueryCompanySecretCodesAdminArgs = {
  input: ListCompanySecretCodesInputs;
};

export type QueryGetCustomersAdminArgs = {
  input: ListCustomersInputs;
};

export type QueryGetDistanceTimeEstimationsArgs = {
  input: DistanceTimeEstimationsInput;
};

export type QueryGetDriverAdminArgs = {
  driverId: Scalars['String'];
};

export type QueryGetDriversArgs = {
  filter?: InputMaybe<FilterDriverInput>;
};

export type QueryGetDriversAdminArgs = {
  input: ListDriversInputs;
};

export type QueryGetJourneyActualPricingArgs = {
  journeyId: Scalars['Float'];
};

export type QueryGetJourneyEstimationsArgs = {
  input: JourneyEstimationsInput;
  returnPickUpTime?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGetJourneyMediaUploadUrlsArgs = {
  count: Scalars['Float'];
};

export type QueryGetJourneyPriceBreakDownArgs = {
  journeyId: Scalars['Float'];
};

export type QueryGetJournyArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  journeyId: Scalars['Float'];
};

export type QueryGetReferralJournyArgs = {
  referralCode?: InputMaybe<Scalars['String']>;
  totalReferralBonus: number;
  paidReferralBonus: number;
};

export type QueryGetRegisteredDeviceIdsArgs = {
  userId: Scalars['String'];
};

export type QueryGetRoleByIdArgs = {
  roleId: Scalars['Float'];
};

export type QueryGetRythmArgs = {
  rythmId: Scalars['Float'];
};

export type QueryGetSelectedAddonsForJourneyArgs = {
  journeyId: Scalars['Float'];
};

export type QueryGetSuppliersAdminArgs = {
  input: ListSuppliersInputs;
};

export type QueryGetUserRoleListArgs = {
  input: FilterRoleInput;
};

export type QueryIsValidEmailArgs = {
  input: Scalars['String'];
};

export type QueryListModesAdminArgs = {
  inputs: ListModesInput;
};

export type QueryTestGetSnappedCoordinatesForDriverArgs = {
  driverId: Array<Scalars['String']>;
};

export type QueryVerifyDriverMobileNumberAndSendOtpArgs = {
  mobileNumber: Scalars['String'];
};

export enum Ride_Listing_Classification {
  Completed = 'COMPLETED',
  Upcoming = 'UPCOMING',
}

export type RescheduleJourneyInput = {
  cancelledJourneyId: Scalars['Float'];
  refIdDriverUser: Scalars['String'];
};

export type Rhythm = {
  __typename?: 'Rhythm';
  dropoffRefIdLocation: LocationsEntity;
  endDate: Scalars['String'];
  idRhythm: Scalars['ID'];
  isActive: Scalars['Boolean'];
  pausedFrom: Scalars['String'];
  pausedUntil: Scalars['String'];
  pickupRefIdLocation: LocationsEntity;
  refIdCustomerUser: Scalars['String'];
  rhythmName: Scalars['String'];
  startDate: Scalars['String'];
  stripePaymentMethod: Scalars['String'];
};

export type RhythmDays = {
  __typename?: 'RhythmDays';
  idRhythmDays: Scalars['ID'];
  journeyType: Scalars['String'];
  pickupTime: Scalars['String'];
  refIdModes: Scalars['Float'];
  refIdRhythm?: Maybe<Scalars['Float']>;
  refIdRhythmDays?: Maybe<RhythmDays>;
  repeatOnDay: Scalars['String'];
};

export type RhythmDetailsArgs = {
  __typename?: 'RhythmDetailsArgs';
  days?: Maybe<DaystypeArgs>;
  dropOffLocation: LocationsEntity;
  endDate?: Maybe<Scalars['String']>;
  idRhythm: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  journeyType: Scalars['String'];
  pausedFrom?: Maybe<Scalars['String']>;
  pausedUntil?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  pickUpLocation: LocationsEntity;
  rhythmName: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type RideDriver = {
  __typename?: 'RideDriver';
  actualDropOffTime?: Maybe<Scalars['DateTime']>;
  actualPickupTime?: Maybe<Scalars['DateTime']>;
  addons?: Maybe<Array<JourneyAddon>>;
  arrivedDuration?: Maybe<Scalars['Float']>;
  bookingRef: Scalars['String'];
  customerMobile: Scalars['String'];
  customerName: Scalars['String'];
  customerNickname: Scalars['String'];
  dropOffLocation: LocationsEntity;
  estimatedCost: Scalars['String'];
  estimatedDroppoff: Scalars['DateTime'];
  idJourney: Scalars['ID'];
  journeyType: Scalars['String'];
  media?: Maybe<Array<JourneyMedia>>;
  mode?: Maybe<Modes>;
  pickUpLocation: LocationsEntity;
  pickupTime: Scalars['DateTime'];
  pricing?: Maybe<PriceBreakdown>;
  rideId?: Maybe<Scalars['Float']>;
  snapshot?: Maybe<RideSnapshotEntity>;
  status: Scalars['String'];
  stops?: Maybe<Array<Stops>>;
};

export type RideEndedInputs = {
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  journeyId: Scalars['Float'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type RideSimulation = {
  cancelRideAfter?: InputMaybe<Scalars['Float']>;
  currentLat: Scalars['Float'];
  currentLong: Scalars['Float'];
  driverArrivedLateDuration?: InputMaybe<Scalars['Float']>;
  journeyId: Scalars['Float'];
  onRouteDuration?: InputMaybe<Scalars['Float']>;
  rideDuration?: InputMaybe<Scalars['Float']>;
  rideRoutePolyline?: InputMaybe<Scalars['String']>;
  waitingTime?: InputMaybe<Scalars['Float']>;
};

export type RideSnapshotEntity = {
  __typename?: 'RideSnapshotEntity';
  customerComments?: Maybe<Scalars['String']>;
  customerRating?: Maybe<Scalars['Float']>;
  driverComments?: Maybe<Scalars['String']>;
  driverRating?: Maybe<Scalars['Float']>;
  journeyRating?: Maybe<Scalars['Float']>;
  refIdJourney: Scalars['Float'];
  rideID: Scalars['ID'];
  ridePolyline?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
  /** Total time is in seconds */
  totalTime?: Maybe<Scalars['Float']>;
};

export type RideStartedInputs = {
  journeyId: Scalars['Float'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type RideSummaryResponse = {
  __typename?: 'RideSummaryResponse';
  totalHours: Scalars['Float'];
  totalMiles: Scalars['Float'];
  totalRides: Scalars['Float'];
};

export type RoleFields = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  roleDescription?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Scalars['String']>;
  rolePermissions?: InputMaybe<Scalars['String']>;
};

export type RoleSubjectsEntity = {
  __typename?: 'RoleSubjectsEntity';
  idRoleSubjects: Scalars['ID'];
  subjectKey: Scalars['String'];
  subjectLabel: Scalars['String'];
};

export type S3SignedUrlResponse = {
  __typename?: 'S3SignedUrlResponse';
  fileName: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type SalikFareEntity = {
  __typename?: 'SalikFareEntity';
  idSalik: Scalars['Int'];
  refIdArea: Scalars['Int'];
  salikFee?: Maybe<Scalars['String']>;
};

export type SaveLocationInput = {
  latitude: Scalars['String'];
  locType: LocationTypes;
  longitude: Scalars['String'];
  placeId?: InputMaybe<Scalars['String']>;
  savedLocCustomName: Scalars['String'];
  savedLocName: Scalars['String'];
  savedLocType?: InputMaybe<CustomerSavedLocTypes>;
};

export type SendPushInput = {
  link?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['String'];
  user_type: Scalars['String'];
};

export type SetDefaultCardInput = {
  paymentMethodId: Scalars['String'];
};

export type StatisticResponse = {
  __typename?: 'StatisticResponse';
  cancelled?: Maybe<Scalars['Float']>;
  completed?: Maybe<Scalars['Float']>;
  nexthour?: Maybe<Scalars['Float']>;
};

export type Stops = {
  __typename?: 'Stops';
  estimatedDropOff?: Maybe<Scalars['DateTime']>;
  locations?: Maybe<LocationsEntity>;
  stopOrder?: Maybe<Scalars['Float']>;
};

export type StopsEstimatedDropOff = {
  __typename?: 'StopsEstimatedDropOff';
  distance: Scalars['Float'];
  dropOffTime: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SupplierEntity = {
  __typename?: 'SupplierEntity';
  address?: Maybe<Array<Address>>;
  bankDetails?: Maybe<Array<BankDetails>>;
  email: Scalars['String'];
  endDate: Scalars['DateTime'];
  idSupplier: Scalars['ID'];
  identityDocuments?: Maybe<Array<IdentityDocumentEntity>>;
  licenceNo: Scalars['String'];
  mobileNumber: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  refIdTaxiSupplierColor?: Scalars['Float'];
  startDate: Scalars['DateTime'];
  type: Scalars['String'];
};

export type SurgeFareEntity = {
  __typename?: 'SurgeFareEntity';
  idSurge: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  refIdArea: Scalars['Int'];
  surgeEndTime?: Maybe<Scalars['String']>;
  surgeFare?: Maybe<Scalars['String']>;
  surgeStartTime?: Maybe<Scalars['String']>;
};

export type UpdateAdminFields = {
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  pwd?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  refIdAdminDepartment?: InputMaybe<Scalars['Float']>;
  refIdAdminOrganization?: InputMaybe<Scalars['Float']>;
  refIdAdminUserRoles?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateWaitingListFields = {
  emailId?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  idWaitingList?: InputMaybe<Scalars['String']>;
};

export type UpdateAdminUserInput = {
  data: UpdateAdminFields;
  userId: Scalars['String'];
};

export type UpdateWaitingListInput = {
  data: UpdateWaitingListFields;
  idWaitingList: Scalars['String'];
};

export type UpdateAreaInput = {
  areaId: Scalars['Float'];
  areaName?: InputMaybe<Scalars['String']>;
  areaTypeRefId?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCustomerAdminInput = {
  customerId: Scalars['String'];
  data: UpdateCustomerFields;
};

export type UpdateCustomerFields = {
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  zedName?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  zedName?: InputMaybe<Scalars['String']>;
};

export type UpdateReferralCustomerInput = {
  referralData?: string;
};

export type getReferralJourneyInput = {
  referralCode?: string;
  totalReferralBonus?: number;
  paidReferralBonus?: number;
};

export type UpdateDriverDtlInput = {
  about?: InputMaybe<Scalars['String']>;
  empId?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  partnerRefId?: InputMaybe<Scalars['Float']>;
  vehicleRefId?: InputMaybe<Scalars['Float']>;
};

export type UpdateDriverInput = {
  driverDtl?: InputMaybe<UpdateDriverDtlInput>;
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  pwd?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateVehicleInput = {
  licensePlate: string;
  carType: string;
  regCity: string;
  vehicleLicensePlateNo: string;
  brand: string;
  model: string;
  category: string;
  chasisNo: string;
  fuelType: string;
  manufacturingYear: number;
  color: string;
  kidsSeats: number;
  modes: string;
  seats: number;
  refIdSupplier?: string;
  vehicleType?: string;
  vehicleLicenseNumber?: string;
  licenseExpiryDate?: string;
  vehicleInsuranceValidity?: string;
  licensePlateFront?: string;
};

export type UpdateFareInput = {
  baseMorningShift?: InputMaybe<Scalars['String']>;
  baseNightShift?: InputMaybe<Scalars['String']>;
  bookingFee?: InputMaybe<Scalars['String']>;
  farePerKm?: InputMaybe<Scalars['String']>;
  farePerMin?: InputMaybe<Scalars['String']>;
  idFare: Scalars['Float'];
};

export type UpdateGlobalLocInput = {
  loc?: InputMaybe<Scalars['String']>;
  locName?: InputMaybe<Scalars['String']>;
  locType?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationInput = {
  lat?: InputMaybe<Scalars['String']>;
  locationCustomName?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
};

export type UpdateMobileNumberInput = {
  mobileNumber: Scalars['String'];
  otp: Scalars['String'];
  sid: Scalars['String'];
};

export type UpdateModeInput = {
  acTempRefId?: InputMaybe<Scalars['Float']>;
  artRefId?: InputMaybe<Scalars['Float']>;
  chatRefId?: InputMaybe<Scalars['Float']>;
  colourId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  lightRefId?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  modeName?: InputMaybe<Scalars['String']>;
  musicGenereRefId?: InputMaybe<Scalars['Float']>;
  musicVolumeRefId?: InputMaybe<Scalars['Float']>;
};

export type UpdateMoveModeInput = {
  acTempRefId: Scalars['Float'];
  artRefId: Scalars['Float'];
  chatRefId: Scalars['Float'];
  colourId: Scalars['Float'];
  journeyId: Scalars['Float'];
  lightRefId: Scalars['Float'];
  modeId: Scalars['Float'];
  modeName: Scalars['String'];
  musicGenereRefId: Scalars['Float'];
  musicVolumeRefId: Scalars['Float'];
  onetimeUse: Scalars['Boolean'];
};

export type UpdateRoleInput = {
  data: RoleFields;
  roleId: Scalars['Float'];
};

export type UpdateSalikFareInput = {
  idSalik: Scalars['Float'];
  salikFee?: InputMaybe<Scalars['String']>;
};

export type UpdateSurgeFareInput = {
  idSurge: Scalars['Float'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  surgeEndTime?: InputMaybe<Scalars['String']>;
  surgeFare?: InputMaybe<Scalars['String']>;
  surgeStartTime?: InputMaybe<Scalars['String']>;
};

export type UserPreferenceCategory = {
  __typename?: 'UserPreferenceCategory';
  idPreferences: Scalars['Float'];
  preferenceCategory?: Maybe<Scalars['String']>;
  preferenceDescription?: Maybe<Scalars['String']>;
  preferenceName?: Maybe<Scalars['String']>;
};

export type UserPreferenceInput = {
  idPreferences: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export type UserPreferenceResponse = {
  __typename?: 'UserPreferenceResponse';
  idPreferences: Scalars['Float'];
  isActive: Scalars['Boolean'];
  preferenceCategory?: Maybe<Scalars['String']>;
  preferenceDescription?: Maybe<Scalars['String']>;
  preferenceName?: Maybe<Scalars['String']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  idVehicle: Scalars['Float'];
  licensePlate?: Maybe<Scalars['String']>;
};

export type VehiclePartnerResponse = {
  __typename?: 'VehiclePartnerResponse';
  partners: Array<Partner>;
  vehicles: Array<Vehicle>;
};

export type VerifyMobileNumberInput = {
  mobileNumber: Scalars['String'];
};

export type OtpParams = {
  mobileNumber: Scalars['String'];
  emailId: Scalars['String'];
  licenceNo: Scalars['String'];
  emirateId: Scalars['String'];
};

export type SendOtpInput = {
  mobileNumber: Scalars['String'];
  emailId: Scalars['String'];
  licenceNo: Scalars['String'];
  emirateId: Scalars['String'];
  editSupplierParams?: OtpParams;
};

export type ZedConfigEditInputs = {
  addonAllowedFromPickupTime?: InputMaybe<Scalars['Float']>;
  addonNotificationInterval?: InputMaybe<Scalars['Float']>;
  dispatchNearestInterval?: InputMaybe<Scalars['Float']>;
};

export type DriverLocUpdateInput = {
  heading: Scalars['Float'];
  isMoving: Scalars['Boolean'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  odometer: Scalars['Float'];
  speed: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type IdentityDocumentAdminInput = {
  backImageUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  documentOwner?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['String']>;
  frontImageUrl?: InputMaybe<Scalars['String']>;
  idDocument?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type LoginAsAdminMutationVariables = Exact<{
  input: LoginAdminInput;
}>;

export type LoginAsAdminMutation = {
  __typename?: 'Mutation';
  loginAsAdmin: {
    __typename?: 'AdminLoginResponse';
    access_token: string;
    subjects: any;
    user: {
      __typename?: 'Admin';
      idAdminUser: string;
      mediaUrl?: string | null;
      status: string;
      firstName: string;
      lastName: string;
      emailId?: string | null;
      mobileNumber: string;
    };
  };
};

export type CreateModeAdminMutationVariables = Exact<{
  input: CreateModeAdminInput;
}>;

export type CreateModeAdminMutation = {
  __typename?: 'Mutation';
  createModeAdmin: {
    __typename?: 'Modes';
    idModes: number;
    modeName: string;
    modeType: string;
    refIdArtOptions: { __typename?: 'ArtOptions'; idArtOptions: number; artName: string; artLink: string };
    refIdChatOptions: { __typename?: 'ChatOptions'; idChatOptions: number; value: string };
    refIdColourOptions: { __typename?: 'ColourOptions'; idColourOptions: number; value: string };
    refIdIdAcTempOptions: { __typename?: 'AcTempOptions'; idAcTempOptions: number; value: string };
    refIdLightOptions: { __typename?: 'LightOptions'; idLightOptions: number; value: string };
    refIdMusicGenereOptions: { __typename?: 'MusicGenereOptions'; idMusicGenereOptions: number; value: string };
    refIdMusicVolumeOptions: { __typename?: 'MusicVolumeOptionsEntity'; idMusicVolumeOptions: number; value: string };
  };
};

export type GetCustomersQueryVariables = Exact<{
  filter: FilterInput;
}>;

export type GetCustomersQuery = {
  __typename?: 'Query';
  getCustomers: Array<{
    __typename?: 'Customer';
    idCustomerUser: string;
    firstName: string;
    lastName: string;
    emailId?: string | null;
    mobileNumber: string;
  }>;
};

export type GetDriversQueryVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetVehicleBrandsQueryVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetVehicleCategoryQueryVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetVehicleModelQueryVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetRegCitiesQueryVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetVehicleColorVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetVehicleTypeVariables = Exact<{
  filter: FilterDriverInput;
}>;

export type GetDriversQuery = {
  __typename?: 'Query';
  getDrivers: Array<{
    __typename?: 'DriverEntity';
    idDriverUser: string;
    status: string;
    firstName: string;
    lastName: string;
    emailId: string;
    mobileNumber: string;
    driverDetails?: {
      __typename?: 'DriverUserDtlEntity';
      about?: string | null;
      empId: string;
      experience?: string | null;
      idDriverUserDtl: string;
      isActive: boolean;
      refIdDriverUser: string;
      refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
      refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
    } | null;
  }>;
};

export type GetVehicleBrandsQuery = {
  __typename?: 'Query';
  getVehiclesBrands: Array<{
    __typename?: 'VehiclesBrandsEntity';
    manufacturer: string;
    type: string;
  }>;
};

export type GetVehicleCategoryQuery = {
  __typename?: 'Query';
  getVehiclesCategory: Array<{
    __typename?: 'VehiclesBrandsEntity';
    idVehicleCategory: number;
    categoryName: string;
    type: string;
    category: string;
    description: string;
    seating: number;
  }>;
};

export type GetVehicleModelQuery = {
  __typename?: 'Query';
  getVehiclesModel: Array<{
    __typename?: 'VehiclesModelEntity';
    id: number;
    model: string;
    type: string;
    seatsNo: string;
    doorsNo: string;
  }>;
};

export type GetRegCitiesQuery = {
  __typename?: 'Query';
  getRegCities: Array<{
    __typename?: 'RegCitiesEntity';
    id: number;
    name: string;
    country: string;
  }>;
};

export type GetVehicleColorQuery = {
  __typename?: 'Query';
  getVehicleColour: Array<{
    __typename?: 'vehicleColorEntity';
    idColor: number;
    type: string;
    color: string;
  }>;
};

export type GetVehicleTypeQuery = {
  __typename?: 'Query';
  getVehicleType: Array<{
    __typename?: 'vehicleTypeEntity';
    id: number;
    name: string;
  }>;
};

export type CreateCustomerAdminMutationVariables = Exact<{
  input: CreateCustomerAdminInput;
}>;

export type CreateCustomerAdminMutation = {
  __typename?: 'Mutation';
  createCustomerAdmin: {
    __typename?: 'Customer';
    idCustomerUser: string;
    firstName: string;
    lastName: string;
    emailId?: string | null;
    mediaUrl?: string | null;
    mobileNumber: string;
    status: string;
  };
};

export type AddDriverLeaveMutationVariables = {
  input: DriverLeaveInput;
};

export type AddDriverLeaveMutation = {
  __typename?: 'Mutation';
  addDriverLeave:  { __typename?: 'addDriverLeave'; success: boolean;  idDriverLeave: number};
};

export type DeleteCustomerAccountAdminMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteCustomerAccountAdminMutation = {
  __typename?: 'Mutation';
  deleteCustomerAccountAdmin: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteDriverAccountAdminMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteDriverLeaveAdminMutationVariables = Exact<{
  emailId: Scalars['String'];
}>;

export type DeleteDriverAccountAdminMutation = {
  __typename?: 'Mutation';
  deleteDriverAccountAdmin: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteDriverLeaveAdminMutation = {
  __typename?: 'Mutation';
  deleteDriverLeave: boolean;
};

export type CreateAreaMutationVariables = Exact<{
  input: CreateAreaInput;
}>;

export type CreateAreaMutation = {
  __typename?: 'Mutation';
  createArea: {
    __typename?: 'AreaEntity';
    idArea: number;
    areaName: string;
    isActive?: boolean | null;
    refIdZedGeometry?: number | null;
  };
};

export type GetAreasQueryVariables = Exact<{
  filter: FilterInput;
}>;

export type GetAreasQuery = {
  __typename?: 'Query';
  getAreas: Array<{
    __typename?: 'AreaEntity';
    idArea: number;
    areaName: string;
    isActive?: boolean | null;
    refIdZedGeometry?: number | null;
    refIdAreaType: { __typename?: 'AreaTypeEntity'; idAreaType: number; areaTypeName: string };
    fare?: {
      __typename?: 'FareEntity';
      idFare: number;
      bookingFee?: string | null;
      baseNightShift?: string | null;
      baseMorningShift?: string | null;
      farePerMin?: string | null;
      farePerKm?: string | null;
    } | null;
    surge?: {
      __typename?: 'SurgeFareEntity';
      idSurge: number;
      surgeFare?: string | null;
      surgeEndTime?: string | null;
      surgeStartTime?: string | null;
      isActive?: boolean | null;
    } | null;
    salik?: { __typename?: 'SalikFareEntity'; idSalik: number; salikFee?: string | null } | null;
  }>;
};

export type GetAreaQueryVariables = Exact<{
  areaId: Scalars['Float'];
}>;

export type GetAreaQuery = {
  __typename?: 'Query';
  getArea: {
    __typename?: 'FindAreaResponse';
    idArea: number;
    areaName: string;
    isActive?: boolean | null;
    isEnable?: boolean;
    refIdAreaType: { __typename?: 'AreaTypeEntity'; idAreaType: number; areaTypeName: string };
    geometry: { __typename?: 'GeoMetryLocationEntity'; idGeometryLocations: number; geom: string };
  };
};

export type DeleteAreaMutationVariables = Exact<{
  areaId: Scalars['Float'];
}>;

export type DeleteAreaMutation = {
  __typename?: 'Mutation';
  deleteArea: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UpdateAreaMutationVariables = Exact<{
  input: UpdateAreaInput;
}>;

export type UpdateAreaMutation = {
  __typename?: 'Mutation';
  updateArea: { __typename?: 'SuccessResponse'; success: boolean };
};

export type CreateAreaTypeMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateAreaTypeMutation = {
  __typename?: 'Mutation';
  createAreaType: { __typename?: 'AreaTypeEntity'; idAreaType: number; areaTypeName: string };
};

export type AddFareMutationVariables = Exact<{
  input: CreateFareInput;
}>;

export type AddFareMutation = {
  __typename?: 'Mutation';
  addFare: {
    __typename?: 'FareEntity';
    idFare: number;
    refIdArea: number;
    bookingFee?: string | null;
    baseNightShift?: string | null;
    baseMorningShift?: string | null;
    farePerMin?: string | null;
    farePerKm?: string | null;
  };
};

export type UpdateFareMutationVariables = Exact<{
  input: UpdateFareInput;
}>;

export type UpdateFareMutation = {
  __typename?: 'Mutation';
  updateFare: {
    __typename?: 'FareEntity';
    idFare: number;
    refIdArea: number;
    bookingFee?: string | null;
    baseNightShift?: string | null;
    baseMorningShift?: string | null;
    farePerMin?: string | null;
    farePerKm?: string | null;
  };
};

export type AddSurgeFareMutationVariables = Exact<{
  input: CreateSurgeFareInput;
}>;

export type AddSurgeFareMutation = {
  __typename?: 'Mutation';
  addSurgeFare: {
    __typename?: 'SurgeFareEntity';
    idSurge: number;
    refIdArea: number;
    surgeFare?: string | null;
    surgeEndTime?: string | null;
    surgeStartTime?: string | null;
    isActive?: boolean | null;
  };
};

export type UpdateSurgeFareMutationVariables = Exact<{
  input: UpdateSurgeFareInput;
}>;

export type UpdateSurgeFareMutation = {
  __typename?: 'Mutation';
  updateSurgeFare: {
    __typename?: 'SurgeFareEntity';
    idSurge: number;
    refIdArea: number;
    surgeFare?: string | null;
    surgeEndTime?: string | null;
    surgeStartTime?: string | null;
    isActive?: boolean | null;
  };
};

export type AddSalikFareMutationVariables = Exact<{
  input: CreateSalikFareInput;
}>;

export type AddSalikFareMutation = {
  __typename?: 'Mutation';
  addSalikFare: { __typename?: 'SalikFareEntity'; idSalik: number; salikFee?: string | null };
};

export type UpdateSalikFareMutationVariables = Exact<{
  input: UpdateSalikFareInput;
}>;

export type UpdateSalikFareMutation = {
  __typename?: 'Mutation';
  updateSalikFare: { __typename?: 'SalikFareEntity'; idSalik: number; salikFee?: string | null };
};

export type GetAreaTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetAreaTypeQuery = {
  __typename?: 'Query';
  getAreaType: Array<{ __typename?: 'AreaTypeEntity'; idAreaType: number; areaTypeName: string }>;
};

export type GetVehiclesAndPartnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetVehiclesAndPartnersQuery = {
  __typename?: 'Query';
  getVehiclesAndPartners: {
    __typename?: 'VehiclePartnerResponse';
    partners: Array<{ __typename?: 'Partner'; idPartner: number; partnerName?: string | null }>;
    vehicles: Array<{
      __typename?: 'Vehicle';
      idVehicle: number;
      licensePlate?: string | null
    }>;
  };
};

export type CreateDriverAdminMutationVariables = Exact<{
  input: CreateDriverAdminInput;
}>;

export type UpdateDriverAdminMutationVariables = Exact<{
  input: CreateDriverAdminInput;
}>;

export type CreateSupplierAdminMutationVariables = Exact<{
  input: CreateSupplierInputs;
}>;

export type CreateDriverAdminMutation = {
  __typename?: 'Mutation';
  createDriverAdmin: {
    __typename?: 'DriverEntity';
    idDriverUser: string;
    emailId: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    status: string;
    driverDetails?: {
      __typename?: 'DriverUserDtlEntity';
      idDriverUserDtl: string;
      empId?: string | null;
      experience?: string | null;
      about?: string | null;
      refIdDriverUser: string;
      refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
      refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
    } | null;
  };
};

export type UpdateDriverAdminMutation = {
  __typename?: 'Mutation';
  createDriverAdmin: {
    __typename?: 'DriverEntity';
    idDriverUser: string;
    emailId: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    status: string;
    driverDetails?: {
      __typename?: 'DriverUserDtlEntity';
      idDriverUserDtl: string;
      empId?: string | null;
      experience?: string | null;
      about?: string | null;
      refIdDriverUser: string;
      refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
      refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
    } | null;
  };
};

export type UpdateDriverMutationVariables = Exact<{
  driverId: Scalars['String'];
  input: UpdateDriverInput;
}>;

export type UpdateVehicleMutationVariables = Exact<{
  idVehicle: number;
  input: CreateVehicleAdminInput;
}>;

export type UpdateVehicleMutation = {
  __typename?: 'Mutation';
  updateVehicle: {
    __typename?: 'VehicleEntity';
    licensePlate: string;
    carType: string;
    regCity: string;
    vehicleLicensePlateNo: string;
    brand: string;
    model: string;
    category: string;
    chasisNo: string;
    fuelType: string;
    manufacturingYear: number;
    color: string;
    kidsSeats: number;
    modes: string;
    seats: number;
    refIdSupplier?: string;
    vehicleType?: string;
    vehicleLicenseNumber?: string;
    licenseExpiryDate?: string;
    vehicleInsuranceValidity?: string;
    licensePlateFront?: string;
  };
};

export type UpdateDriverMutation = {
  __typename?: 'Mutation';
  updateDriver: {
    __typename?: 'DriverEntity';
    emailId: string;
    firstName: string;
    idDriverUser: string;
    lastName: string;
    mobileNumber: string;
    status: string;
    badges?: Array<{
      __typename?: 'DriverUserBadges';
      idDriverUserBadges: number;
      refIdDriverUser: string;
      refIdBadgeUser: { __typename?: 'Badge'; badgeName: string; idBadge: number };
    }> | null;
    devices?: Array<{
      __typename?: 'Devices';
      deviceName: string;
      deviceToken: string;
      idDevices: string;
      refIdAnyUser: string;
    }> | null;
    driverDetails?: {
      __typename?: 'DriverUserDtlEntity';
      about?: string | null;
      empId?: string | null;
      experience?: string | null;
      idDriverUserDtl: string;
      isActive: boolean;
      refIdDriverUser: string;
      refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
      refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
    } | null;
  };
};

export type GetDriversAdminQueryVariables = Exact<{
  input: ListDriversInputs;
}>;

export type GetDriversAdminQuery = {
  __typename?: 'Query';
  getDriversAdmin: {
    __typename?: 'ListDriversResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'DriverEntity';
      emailId: string;
      firstName: string;
      idDriverUser: string;
      lastName: string;
      mobileNumber: string;
      status: string;
      createdDate: any;

      badges?: Array<{
        __typename?: 'DriverUserBadges';
        idDriverUserBadges: number;
        refIdDriverUser: string;
        refIdBadgeUser: { __typename?: 'Badge'; idBadge: number; badgeName: string };
      }> | null;
      devices?: Array<{
        __typename?: 'Devices';
        deviceName: string;
        deviceToken: string;
        idDevices: string;
        refIdAnyUser: string;
      }> | null;
      driverDetails?: {
        __typename?: 'DriverUserDtlEntity';
        about?: string | null;
        empId?: string | null;
        experience?: string | null;
        idDriverUserDtl: string;
        isActive: boolean;
        rating?: number | null;
        refIdDriverUser: string;
        refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
        refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
      } | null;
    }>;
  };
};

export type GetDriverAdminQueryVariables = Exact<{
  driverId: Scalars['String'];
}>;

export type GetDriverAdminQuery = {
  __typename?: 'Query';
  getDriverAdmin: {
    __typename?: 'DriverEntity';
    emailId: string;
    firstName: string;
    idDriverUser: string;
    lastName: string;
    mobileNumber: string;
    status: string;
    createdDate: any;
    badges?: Array<{
      __typename?: 'DriverUserBadges';
      idDriverUserBadges: number;
      refIdDriverUser: string;
      refIdBadgeUser: { __typename?: 'Badge'; badgeName: string; idBadge: number };
    }> | null;
    devices?: Array<{
      __typename?: 'Devices';
      deviceName: string;
      deviceToken: string;
      idDevices: string;
      refIdAnyUser: string;
    }> | null;
    driverDetails?: {
      __typename?: 'DriverUserDtlEntity';
      about?: string | null;
      empId?: string | null;
      experience?: string | null;
      idDriverUserDtl: string;
      isActive: boolean;
      refIdDriverUser: string;
      rating?: number | null;
      refIdPartner?: { __typename?: 'Partner'; idPartner: number; partnerName?: string | null } | null;
      refIdVehicle?: { __typename?: 'Vehicle'; idVehicle: number; licensePlate?: string | null } | null;
    } | null;
    rideSummary?: {
      __typename?: 'RideSummaryResponse';
      totalRides: number;
      totalHours: number;
      totalMiles: number;
    } | null;
  };
};

export type GetAdminBookingSummaryQueryVariables = Exact<{
  input: BookingFilterInputs;
}>;

export type GetAdminBookingSummaryQuery = {
  __typename?: 'Query';
  getAdminBookingSummary: {
    __typename?: 'BookingFilterResponse';
    success: boolean;
    totalRows?: number | null;
    results: Array<{
      __typename?: 'Journey';
      idJourney: string;
      bookingRef: string;
      journeyType: string;
      pickupTime: any;
      actualPickupTime?: any | null;
      actualDropOffTime?: any | null;
      estimatedCost?: string | null;
      estimatedDroppoff?: any | null;
      driverName?: string | null;
      refIdCustomerUser: string;
      refIdRhythm?: number | null;
      status: string;
      dropoffRefIdLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      pickupRefIdLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      customer: { __typename?: 'Customer'; firstName: string; lastName: string; mobileNumber: string };
      addons?: Array<{
        __typename?: 'JourneyAddon';
        refIdAddonItem: number;
        quantity: number;
        itemDetails: { __typename?: 'ExposedItem'; idAddonInventory: number; itemName: string };
      }> | null;
      refIdModes?: {
        __typename?: 'Modes';
        idModes: number;
        isActive: boolean;
        modeName: string;
        modeType: string;
      } | null;
      pricing?: { __typename?: 'PriceBreakdown'; total: number; isEstimation: boolean; currency: string } | null;
    }>;
    statistics?: {
      __typename?: 'StatisticResponse';
      cancelled?: number | null;
      completed?: number | null;
      nexthour?: number | null;
    } | null;
  };
};

export type GetAdminDriverRidesQueryVariables = Exact<{
  input: AdminRideInputs;
}>;

export type GetAdminDriverRidesQuery = {
  __typename?: 'Query';
  getAdminDriverRides: {
    __typename?: 'DriverRidesResponse';
    totalRows: number;
    results?: Array<{
      __typename?: 'RideDriver';
      idJourney: string;
      bookingRef: string;
      customerName: string;
      status: string;
      pickupTime: any;
      estimatedCost: string;
      estimatedDroppoff: any;
      pickUpLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      dropOffLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      mode?: { __typename?: 'Modes'; idModes: number; modeName: string; modeType: string } | null;
      addons?: Array<{
        __typename?: 'JourneyAddon';
        refIdAddonItem: number;
        quantity: number;
        itemDetails: { __typename?: 'ExposedItem'; idAddonInventory: number; itemName: string };
      }> | null;
      pricing?: { __typename?: 'PriceBreakdown'; total: number; isEstimation: boolean; currency: string } | null;
      snapshot?: {
        __typename?: 'RideSnapshotEntity';
        customerComments?: string | null;
        customerRating?: number | null;
        journeyRating?: number | null;
      } | null;
    }> | null;
  };
};

export type GetAdminCustomerRidesQueryVariables = Exact<{
  input: AdminRideInputs;
}>;

export type GetAdminCustomerRidesQuery = {
  __typename?: 'Query';
  getAdminCustomerRides: {
    __typename?: 'CustomerRidesResponse';
    totalRows: number;
    results?: Array<{
      __typename?: 'Journey';
      idJourney: string;
      bookingRef: string;
      estimatedCost?: string | null;
      estimatedDroppoff?: any | null;
      journeyType: string;
      pickupTime: any;
      refIdCustomerUser: string;
      status: string;
      driver?: {
        __typename?: 'DriverEntity';
        idDriverUser: string;
        firstName: string;
        lastName: string;
        createdDate: any;
        badges?: Array<{
          __typename?: 'DriverUserBadges';
          idDriverUserBadges: number;
          refIdDriverUser: string;
          refIdBadgeUser: { __typename?: 'Badge'; badgeName: string; idBadge: number };
        }> | null;
        driverDetails?: { __typename?: 'DriverUserDtlEntity'; rating?: number | null } | null;
      } | null;
      dropoffRefIdLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      pickupRefIdLocation: {
        __typename?: 'LocationsEntity';
        idLocation: string;
        lat: string;
        locationCustomName: string;
        locationName: string;
        locationType: string;
        long: string;
        placeId?: string | null;
      };
      addons?: Array<{
        __typename?: 'JourneyAddon';
        refIdAddonItem: number;
        quantity: number;
        itemDetails: { __typename?: 'ExposedItem'; idAddonInventory: number; itemName: string };
      }> | null;
      refIdModes?: {
        __typename?: 'Modes';
        idModes: number;
        isActive: boolean;
        modeName: string;
        modeType: string;
      } | null;
      snapshot?: {
        __typename?: 'RideSnapshotEntity';
        customerComments?: string | null;
        customerRating?: number | null;
        driverComments?: string | null;
        driverRating?: number | null;
        journeyRating?: number | null;
        refIdJourney: number;
        rideID: string;
        ridePolyline?: string | null;
        totalCost?: number | null;
        totalDistance?: number | null;
        totalTime?: number | null;
      } | null;
      pricing?: { __typename?: 'PriceBreakdown'; total: number; isEstimation: boolean; currency: string } | null;
    }> | null;
  };
};

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'Customer';
    emailId?: string | null;
    firstName: string;
    idCustomerUser: string;
    lastName: string;
    mediaUrl?: string | null;
    mobileNumber: string;
    rating?: number | null;
    status: string;
    stripeCustomerId?: string | null;
    zedName?: string | null;
    createdDate: any;
  };
};

export type GetRideEventQueryVariables = Exact<{
  journeyId: number;
}>;

export type GetRideEventQuery = {
  __typename?: 'Query';
  getRideEvents: {
    __typename?: any;
    rideStatusID?: number | null;
    createdDate: any;
  };
};

export type AdminRescheduleBookingMutationVariables = Exact<{
  input: RescheduleJourneyInput;
}>;

export type AdminRescheduleBookingMutation = {
  __typename?: 'Mutation';
  adminRescheduleBooking: { __typename?: 'SuccessResponse'; success: boolean };
};

export type CancelRideByAdminMutationVariables = Exact<{
  journeyId: Scalars['Float'];
}>;

export type CancelRideByAdminMutation = {
  __typename?: 'Mutation';
  cancelRideByAdmin: { __typename?: 'SuccessResponse'; success: boolean };
};

export type GetCustomersAdminQueryVariables = Exact<{
  input: ListCustomersInputs;
}>;

export type GetWaitingListAdminQueryVariables = Exact<{
  input: WaitingListInputs;
}>;

export type GetCompaniesAdminQueryVariables = Exact<{
  input: ListCompaniesInputs;
}>;

export type GetReferralJourneyQueryVariables = Exact<{
  referralCode?: string;
  totalReferralBonus?: number;
  paidReferralBonus?: number;
}>;

export type GetCompanySecretCodesAdminQueryVariables = Exact<{
  input: ListCompanySecretCodesInputs;
}>;

export type GetCompaniesAdminQuery = {
  __typename?: 'Query';
  getCompaniesAdmin: {
    __typename?: 'ListCompaniesResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'Company';
      companyName: string;
      idCompany: string;
    }>;
  };
};

export type GetReferralJourneyQuery = {
  __typename?: 'Query';
  GetReferralJourney: {
    __typename?: 'ReferralJourneyResponse';
    results: Array<{
      __typename?: 'Journey';
      firstName: string;
      lastname: string;
      refreePromoUsedCount: number;
    }>;
  };
};

export type GetCompanySecretCodesAdminQuery = {
  __typename?: 'Query';
  getCompanySecretCodesAdmin: {
    __typename?: 'ListCompanySecretCodesResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'CompanySecretCodes';
      idCompanySecretCode: number;
      companySecretCode: string;
      expiryDate: any;
      refCompanyName: string;
    }>;
  };
};
export type GetCustomersAdminQuery = {
  __typename?: 'Query';
  getCustomersAdmin: {
    __typename?: 'ListCustomersResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'Customer';
      emailId?: string | null;
      referralCode: string;
      refereePromoCode: string;
      totalReferralBonus: number;
      paidReferralBonus: number;
      refereePromoUsedCount: number;
      firstName: string;
      idCustomerUser: string;
      lastName: string;
      mediaUrl?: string | null;
      mobileNumber: string;
      rating?: number | null;
      status: string;
      stripeCustomerId?: string | null;
      zedName?: string | null;
      createdDate: any;
      modes?: Array<{ __typename?: 'Modes'; idModes: number; modeName: string; modeType: string }> | null;
    }>;
  };
};

export type GetWaitingListAdminQuery = {
  __typename?: 'Query';
  getWaitingListAdmin: {
    __typename?: 'WaitingListResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'WaitingList';
      emailId?: string | null;
      mobileNumber: string;
      idWaitingList: number;
      createdDate: string;
    }>;
  };
};

export type GetAvailableDriversForLocationQueryVariables = Exact<{
  journeyId: Scalars['Float'];
}>;

export type GetAvailableDriversForLocationQuery = {
  __typename?: 'Query';
  getAvailableDriversForLocation: {
    __typename?: 'AvailableDriverResponse';
    success: boolean;
    results?: Array<{
      __typename?: 'DriverEntity';
      idDriverUser: string;
      firstName: string;
      lastName: string;
      driverDetails?: { __typename?: 'DriverUserDtlEntity'; rating?: number | null } | null;
      badges?: Array<{
        __typename?: 'DriverUserBadges';
        idDriverUserBadges: number;
        refIdDriverUser: string;
        refIdBadgeUser: { __typename?: 'Badge'; idBadge: number; badgeName: string };
      }> | null;
      rideSummary?: {
        __typename?: 'RideSummaryResponse';
        totalRides: number;
        totalHours: number;
        totalMiles: number;
      } | null;
    }> | null;
  };
};

export type GetDistanceTimeEstimationsQueryVariables = Exact<{
  input: DistanceTimeEstimationsInput;
}>;

export type GetDistanceTimeEstimationsQuery = {
  __typename?: 'Query';
  getDistanceTimeEstimations: { __typename?: 'DistanceTimeEstimationsResponse'; distance: number; duration: number };
};

export type GetGlobalModeDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPromotionsQuery = {
  __typename?: 'Query';
  getAllPromotions: Array<{
    __typename?: 'PromotionEntity';
    idPromotion: number;
    promoCode: string;
    promoDescription: string;
    promoTitle: string;
    discount: number | number[];
    tierDiscounts: number[];
    refIdZedGeometry: number | string;
  }>;
};

export type GetGlobalModeDetailsQuery = {
  __typename?: 'Query';
  getGlobalModeDetails: Array<{ __typename?: 'Modes'; idModes: number; modeName: string }>;
};

export type AdminEditBookingMutationVariables = Exact<{
  idJourney: Scalars['Float'];
  input: AdminEditJourneyInput;
}>;

export type AdminEditBookingMutation = {
  __typename?: 'Mutation';
  adminEditBooking: {
    __typename?: 'Journey';
    idJourney: string;
    bookingRef: string;
    journeyType: string;
    pickupTime: any;
    estimatedCost?: string | null;
    estimatedDroppoff?: any | null;
    refIdCustomerUser: string;
    refIdRhythm?: number | null;
    status: string;
    dropoffRefIdLocation: {
      __typename?: 'LocationsEntity';
      idLocation: string;
      lat: string;
      locationCustomName: string;
      locationName: string;
      locationType: string;
      long: string;
      placeId?: string | null;
    };
    pickupRefIdLocation: {
      __typename?: 'LocationsEntity';
      idLocation: string;
      lat: string;
      locationCustomName: string;
      locationName: string;
      locationType: string;
      long: string;
      placeId?: string | null;
    };
    addons?: Array<{
      __typename?: 'JourneyAddon';
      refIdAddonItem: number;
      quantity: number;
      itemDetails: { __typename?: 'ExposedItem'; idAddonInventory: number; itemName: string };
    }> | null;
    refIdModes?: {
      __typename?: 'Modes';
      idModes: number;
      isActive: boolean;
      modeName: string;
      modeType: string;
    } | null;
    pricing?: { __typename?: 'PriceBreakdown'; total: number; isEstimation: boolean; currency: string } | null;
  };
};

export type CustomerRideSummaryQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;

export type CustomerRideSummaryQuery = {
  __typename?: 'Query';
  customerRideSummary: {
    __typename?: 'RideSummaryResponse';
    totalHours: number;
    totalMiles: number;
    totalRides: number;
  };
};

export type UpdateAdminUserMutationVariables = Exact<{
  inputs: UpdateAdminUserInput;
}>;

export type UpdateAdminUserMutation = {
  __typename?: 'Mutation';
  updateAdminUser: {
    __typename?: 'AdminUpdateResponse';
    success: boolean;
    user: {
      __typename?: 'Admin';
      emailId?: string | null;
      firstName: string;
      mediaUrl?: string | null;
      mobileNumber: string;
      lastName: string;
      status: string;
      idAdminUser: string;
      details?: {
        __typename?: 'AdminUserDetail';
        adminUserDescription?: string | null;
        experience?: string | null;
        idAdminUserDtl: string;
        isActive: boolean;
        refIdAdminUser: string;
        department?: {
          __typename?: 'AdminDepartment';
          departmentDescription?: string | null;
          departmentName: string;
          idAdminDepartment: string;
        } | null;
        organization?: {
          __typename?: 'AdminOrganization';
          idAdminOrganization: string;
          organizationDescription?: string | null;
          organizationName: string;
        } | null;
      } | null;
      role?: { __typename?: 'AdminRolesEntity'; roleName: string; idAdminUserRoles: string } | null;
    };
  };
};

export type UpdateWaitingListMutationVariables = Exact<{
  inputs: UpdateWaitingListInput;
}>;

export type UpdateWaitingListMutation = {
  __typename?: 'Mutation';
  updateWaitingList: {
    __typename?: 'WaitingListUpdateResponse';
    success: boolean;
    user: {
      __typename?: 'WaitingList';
      emailId?: string | null;
      mobileNumber: string;
      idWaitingList: number;
    };
  };
};

export type GetAdminUserByIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetAdminUserByIdQuery = {
  __typename?: 'Query';
  getAdminUserByID: {
    __typename?: 'Admin';
    emailId?: string | null;
    firstName: string;
    idAdminUser: string;
    lastName: string;
    mediaUrl?: string | null;
    middleName?: string | null;
    mobileNumber: string;
    status: string;
    role?: {
      __typename?: 'AdminRolesEntity';
      idAdminUserRoles: string;
      isActive?: boolean | null;
      roleDescription?: string | null;
      roleName: string;
      rolePermissions: any;
    } | null;
    details?: {
      __typename?: 'AdminUserDetail';
      adminUserDescription?: string | null;
      experience?: string | null;
      idAdminUserDtl: string;
      isActive: boolean;
      refIdAdminUser: string;
      department?: {
        __typename?: 'AdminDepartment';
        departmentDescription?: string | null;
        departmentName: string;
        idAdminDepartment: string;
      } | null;
      organization?: {
        __typename?: 'AdminOrganization';
        idAdminOrganization: string;
        organizationDescription?: string | null;
        organizationName: string;
      } | null;
    } | null;
  };
};

export type DeleteFareMutationVariables = Exact<{
  fareId: Scalars['Float'];
}>;

export type DeleteFareMutation = {
  __typename?: 'Mutation';
  deleteFare: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteSurgeMutationVariables = Exact<{
  surgeId: Scalars['Float'];
}>;

export type DeleteSurgeMutation = {
  __typename?: 'Mutation';
  deleteSurge: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteSalikMutationVariables = Exact<{
  salikId: Scalars['Float'];
}>;

export type DeleteSalikMutation = {
  __typename?: 'Mutation';
  deleteSalik: { __typename?: 'SuccessResponse'; success: boolean };
};

export type ApplyPromotionMutationVariables = Exact<{
  promotionId: Scalars['Float'];
  userId: Scalars['String'];
}>;

export type ApplyPromotionMutation = {
  __typename?: 'Mutation';
  applyPromo: { __typename?: 'SuccessResponse'; success: boolean };
};

export type GetAdminUsersListQueryVariables = Exact<{
  input: ListAdminUsersInput;
}>;

export type GetAdminUsersListQuery = {
  __typename?: 'Query';
  getAdminUsersList: {
    __typename?: 'ListAdminUsersResponse';
    totalRows?: number | null;
    results: Array<{
      __typename?: 'Admin';
      emailId?: string | null;
      firstName: string;
      mediaUrl?: string | null;
      mobileNumber: string;
      lastName: string;
      status: string;
      idAdminUser: string;
      role?: {
        __typename?: 'AdminRolesEntity';
        idAdminUserRoles: string;
        roleName: string;
        roleDescription?: string | null;
        rolePermissions: any;
        isActive?: boolean | null;
      } | null;
      details?: {
        __typename?: 'AdminUserDetail';
        idAdminUserDtl: string;
        experience?: string | null;
        adminUserDescription?: string | null;
        isActive: boolean;
        refIdAdminUser: string;
        department?: {
          __typename?: 'AdminDepartment';
          idAdminDepartment: string;
          departmentName: string;
          departmentDescription?: string | null;
        } | null;
        organization?: {
          __typename?: 'AdminOrganization';
          idAdminOrganization: string;
          organizationName: string;
          organizationDescription?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type DeleteAdminUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DeleteAdminUserMutation = {
  __typename?: 'Mutation';
  deleteAdminUser: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type DeleteWaitingListMutationVariables = Exact<{
  idWaitingList: Scalars['String'];
}>;

export type DeleteVehicleMutationVariables = Exact<{
  idVehicle: Scalars['String'];
}>;

export type DeleteWaitingListMutation = {
  __typename?: 'Mutation';
  deleteWaitingList: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type DeleteVehicleMutation = {
  __typename?: 'Mutation';
  deleteVehicle: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type VerifyAdminMobileNumberMutationVariables = Exact<{
  input: AdminForgotPasswordInput;
}>;

export type VerifyAdminMobileNumberMutation = {
  __typename?: 'Mutation';
  verifyAdminMobileNumber: { __typename?: 'OTPVerificationResponse'; sid: string };
};

export type LoginWithAdminMobileNumberMutationVariables = Exact<{
  loginWithMobileNumberInput: LoginWithMobileNumberInput;
}>;

export type LoginWithAdminMobileNumberMutation = {
  __typename?: 'Mutation';
  loginWithAdminMobileNumber: {
    __typename?: 'AdminLoginResponse';
    access_token: string;
    user: {
      __typename?: 'Admin';
      idAdminUser: string;
      mediaUrl?: string | null;
      status: string;
      firstName: string;
      lastName: string;
      emailId?: string | null;
      mobileNumber: string;
    };
  };
};

export type UpdateAdminPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type UpdateAdminPasswordMutation = {
  __typename?: 'Mutation';
  updateAdminPassword: { __typename?: 'SuccessResponse'; success: boolean };
};

export type CreateAdminUserMutationVariables = Exact<{
  input: CreateAdminUserInput;
}>;

export type CreateAdminUserMutation = {
  __typename?: 'Mutation';
  createAdminUser: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type GetDepartmentsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentsListQuery = {
  __typename?: 'Query';
  getDepartmentsList: Array<{
    __typename?: 'AdminDepartment';
    idAdminDepartment: string;
    departmentDescription?: string | null;
    departmentName: string;
  }>;
};

export type GetUserRoleListQueryVariables = Exact<{
  input: FilterRoleInput;
}>;

export type GetUserRoleListQuery = {
  __typename?: 'Query';
  getUserRoleList: {
    __typename?: 'FilterRoleResponse';
    totalRows: number;
    results: Array<{
      __typename?: 'AdminRolesEntity';
      idAdminUserRoles: string;
      roleName: string;
      roleDescription?: string | null;
      rolePermissions: any;
      isActive?: boolean | null;
    }>;
  };
};

export type AddUserRoleMutationVariables = Exact<{
  inputs: AddRoleInput;
}>;

export type AddUserRoleMutation = {
  __typename?: 'Mutation';
  addUserRole: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: { __typename?: 'SuccessResponse'; success: boolean; message?: string | null };
};

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['Float'];
}>;

export type DeleteRoleMutation = {
  __typename?: 'Mutation';
  deleteRole: { __typename?: 'SuccessResponse'; message?: string | null; success: boolean };
};

export type GetRoleSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoleSubjectsQuery = {
  __typename?: 'Query';
  getRoleSubjects: Array<{
    __typename?: 'RoleSubjectsEntity';
    idRoleSubjects: string;
    subjectKey: string;
    subjectLabel: string;
  }>;
};

export const LoginAsAdminDocument = gql`
  mutation loginAsAdmin($input: LoginAdminInput!) {
    loginAsAdmin(loginAdminInput: $input) {
      access_token
      user {
        idAdminUser
        mediaUrl
        status
        firstName
        lastName
        emailId
        mobileNumber
      }
      subjects
    }
  }
`;
export type LoginAsAdminMutationFn = Apollo.MutationFunction<LoginAsAdminMutation, LoginAsAdminMutationVariables>;

/**
 * __useLoginAsAdminMutation__
 *
 * To run a mutation, you first call `useLoginAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsAdminMutation, { data, loading, error }] = useLoginAsAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginAsAdminMutation, LoginAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginAsAdminMutation, LoginAsAdminMutationVariables>(LoginAsAdminDocument, options);
}
export type LoginAsAdminMutationHookResult = ReturnType<typeof useLoginAsAdminMutation>;
export type LoginAsAdminMutationResult = Apollo.MutationResult<LoginAsAdminMutation>;
export type LoginAsAdminMutationOptions = Apollo.BaseMutationOptions<
  LoginAsAdminMutation,
  LoginAsAdminMutationVariables
>;
export const CreateModeAdminDocument = gql`
  mutation createModeAdmin($input: CreateModeAdminInput!) {
    createModeAdmin(input: $input) {
      idModes
      modeName
      modeType
      refIdArtOptions {
        idArtOptions
        artName
        artLink
      }
      refIdChatOptions {
        idChatOptions
        value
      }
      refIdColourOptions {
        idColourOptions
        value
      }
      refIdIdAcTempOptions {
        idAcTempOptions
        value
      }
      refIdLightOptions {
        idLightOptions
        value
      }
      refIdMusicGenereOptions {
        idMusicGenereOptions
        value
      }
      refIdMusicVolumeOptions {
        idMusicVolumeOptions
        value
      }
    }
  }
`;
export type CreateModeAdminMutationFn = Apollo.MutationFunction<
  CreateModeAdminMutation,
  CreateModeAdminMutationVariables
>;

/**
 * __useCreateModeAdminMutation__
 *
 * To run a mutation, you first call `useCreateModeAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModeAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModeAdminMutation, { data, loading, error }] = useCreateModeAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModeAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateModeAdminMutation, CreateModeAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateModeAdminMutation, CreateModeAdminMutationVariables>(
    CreateModeAdminDocument,
    options
  );
}
export type CreateModeAdminMutationHookResult = ReturnType<typeof useCreateModeAdminMutation>;
export type CreateModeAdminMutationResult = Apollo.MutationResult<CreateModeAdminMutation>;
export type CreateModeAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateModeAdminMutation,
  CreateModeAdminMutationVariables
>;
export const GetCustomersDocument = gql`
  query getCustomers($filter: FilterInput!) {
    getCustomers(filter: $filter) {
      idCustomerUser
      firstName
      lastName
      emailId
      mobileNumber
    }
  }
`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const getVehicleTypeDocument = gql`
  query getVehicleType {
    getVehicleType {
      id
      name
    }
  }
`;

export function useGetVehicleTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleTypeQuery, GetVehicleTypeVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleTypeQuery, GetVehicleTypeVariables>(getVehicleTypeDocument, options);
}
export function useGetVehicleTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleTypeQuery, GetVehicleTypeVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleTypeQuery, GetVehicleTypeVariables>(getVehicleTypeDocument, options);
}
export type GetVehicleTypeQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleTypeLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleTypeQueryResult = Apollo.QueryResult<GetVehicleTypeQuery, GetVehicleTypeVariables>;

export const getVehicleColourDocument = gql`
  query getVehicleColour {
    getVehicleColour {
      idColor
      type
      color
    }
  }
`;

export function useGetVehicleColorQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleColorQuery, GetRegCitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleColorQuery, GetVehicleColorVariables>(getVehicleColourDocument, options);
}
export function useGetVehicleColorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleColorQuery, GetVehicleColorVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleColorQuery, GetVehicleColorVariables>(getVehicleColourDocument, options);
}
export type GetVehicleColorQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleColorLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleColorQueryResult = Apollo.QueryResult<GetVehicleColorQuery, GetVehicleColorVariables>;

export const GetRegCitiesDocument = gql`
  query getRegCities {
    getRegCities {
      id
      name
      country
    }
  }
`;

export function useGetRegCitiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegCitiesQuery, GetRegCitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegCitiesQuery, GetRegCitiesQueryVariables>(GetRegCitiesDocument, options);
}
export function useGetRegCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegCitiesQuery, GetRegCitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegCitiesQuery, GetRegCitiesQueryVariables>(GetRegCitiesDocument, options);
}
export type GetRegCitiesQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetRegCitiesLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetRegCitiesQueryResult = Apollo.QueryResult<GetRegCitiesQuery, GetRegCitiesQueryVariables>;

export const GetVehicleModelDocument = gql`
  query getVehiclesModel {
    getVehiclesModel {
      id
      model
      type
      seatsNo
      doorsNo
    }
  }
`;

export function useGetVehicleModelQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleModelQuery, GetVehicleModelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleModelQuery, GetVehicleModelQueryVariables>(GetVehicleModelDocument, options);
}
export function useGetVehicleModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleModelQuery, GetVehicleModelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleModelQuery, GetVehicleModelQueryVariables>(GetVehicleModelDocument, options);
}
export type GetVehicleModelQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleModelLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleModelQueryResult = Apollo.QueryResult<GetVehicleModelQuery, GetVehicleModelQueryVariables>;

export const GetVehicleCategoryDocument = gql`
  query getVehiclesCategory {
    getVehiclesCategory {
      idVehicleCategory
      categoryName
      type
      category
      description
      seating
    }
  }
`;

export function useGetVehicleCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleCategoryQuery, GetVehicleCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleCategoryQuery, GetVehicleCategoryQueryVariables>(
    GetVehicleCategoryDocument,
    options
  );
}
export function useGetVehicleCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleCategoryQuery, GetVehicleCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleCategoryQuery, GetVehicleCategoryQueryVariables>(
    GetVehicleCategoryDocument,
    options
  );
}
export type GetVehicleCategoryQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleCategoryLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleCategoryQueryResult = Apollo.QueryResult<
  GetVehicleCategoryQuery,
  GetVehicleCategoryQueryVariables
>;

export const GetVehicleBrandsDocument = gql`
  query getVehiclesBrands {
    getVehiclesBrands {
      manufacturer
    }
  }
`;

export function useGetVehicleBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>(GetVehicleBrandsDocument, options);
}
export function useGetVehicleBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>(GetVehicleBrandsDocument, options);
}
export type GetVehicleBrandsQueryHookResult = ReturnType<typeof useGetVehicleBrandsQuery>;
export type GetVehicleBrandsLazyQueryHookResult = ReturnType<typeof useGetVehicleBrandsLazyQuery>;
export type GetVehicleBrandsQueryResult = Apollo.QueryResult<GetVehicleBrandsQuery, GetVehicleBrandsQueryVariables>;

export const GetDriversDocument = gql`
  query getDrivers($filter: FilterDriverInput!) {
    getDrivers(filter: $filter) {
      idDriverUser
      status
      firstName
      lastName
      emailId
      mobileNumber
      driverDetails {
        about
        empId
        experience
        idDriverUserDtl
        isActive
        refIdDriverUser
        refIdPartner {
          idPartner
          partnerName
        }
        refIdVehicle {
          idVehicle
          licensePlate
        }
      }
    }
  }
`;

/**
 * __useGetDriversQuery__
 *
 * To run a query within a React component, call `useGetDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDriversQuery(baseOptions: Apollo.QueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
}
export function useGetDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriversQuery, GetDriversQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
}
export type GetDriversQueryHookResult = ReturnType<typeof useGetDriversQuery>;
export type GetDriversLazyQueryHookResult = ReturnType<typeof useGetDriversLazyQuery>;
export type GetDriversQueryResult = Apollo.QueryResult<GetDriversQuery, GetDriversQueryVariables>;
export const CreateCustomerAdminDocument = gql`
  mutation createCustomerAdmin($input: CreateCustomerAdminInput!) {
    createCustomerAdmin(input: $input) {
      idCustomerUser
      firstName
      lastName
      emailId
      mediaUrl
      mobileNumber
      status
    }
  }
`;
export type CreateCustomerAdminMutationFn = Apollo.MutationFunction<
  CreateCustomerAdminMutation,
  CreateCustomerAdminMutationVariables
>;

/**
 * __useCreateCustomerAdminMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAdminMutation, { data, loading, error }] = useCreateCustomerAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCustomerAdminMutation, CreateCustomerAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomerAdminMutation, CreateCustomerAdminMutationVariables>(
    CreateCustomerAdminDocument,
    options
  );
}
export type CreateCustomerAdminMutationHookResult = ReturnType<typeof useCreateCustomerAdminMutation>;
export type CreateCustomerAdminMutationResult = Apollo.MutationResult<CreateCustomerAdminMutation>;
export type CreateCustomerAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerAdminMutation,
  CreateCustomerAdminMutationVariables
>;

export const AddDriverLeave = gql`
  mutation addDriverLeave($input: DriverLeaveInput!) {
    addDriverLeave(input: $input) {
      idDriverLeave
    }
}
`;

export const UpdateDriverLeave = gql`
  mutation updateDriverLeave($input: DriverLeaveInput!) {
    updateDriverLeave(input: $input) {
      idDriverLeave
    }
}
`;

export const DeleteDriverLeaveAdmin = gql`
  mutation deleteDriverLeave($emailId: String!) {
    deleteDriverLeave(emailId: $emailId)
  }
`;

export const GetDriverLeave = gql`
query getDriversLeavesAdmin($input: ListDriversInputs!) {
  getDriversLeavesAdmin(input: $input) {
    totalRows
    results {
      availabilityStatus
      idDriverLeave
      startDate
      endDate
      status
      refIdDriverUser{
				emailId
        firstName
        lastName
      }
      driverLeaveScheduleDays{
        idUnavailabilityDays
        days
        startTime
        endTime
        status
      }
    }
    __typename
  }
}
`;

export function useAddDriverLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDriverLeaveMutation, AddDriverLeaveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDriverLeaveMutation, AddDriverLeaveMutationVariables>(
    AddDriverLeave,
    options
  );
}

export function useUpdateDriverLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDriverLeaveMutation, AddDriverLeaveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDriverLeaveMutation, AddDriverLeaveMutationVariables>(
    UpdateDriverLeave,
    options
  );
}

export const DeleteCustomerAccountAdminDocument = gql`
  mutation deleteCustomerAccountAdmin($id: String!) {
    deleteCustomerAccountAdmin(id: $id) {
      success
    }
  }
`;
export type DeleteCustomerAccountAdminMutationFn = Apollo.MutationFunction<
  DeleteCustomerAccountAdminMutation,
  DeleteCustomerAccountAdminMutationVariables
>;

/**
 * __useDeleteCustomerAccountAdminMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAccountAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAccountAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAccountAdminMutation, { data, loading, error }] = useDeleteCustomerAccountAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerAccountAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerAccountAdminMutation,
    DeleteCustomerAccountAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCustomerAccountAdminMutation, DeleteCustomerAccountAdminMutationVariables>(
    DeleteCustomerAccountAdminDocument,
    options
  );
}
export type DeleteCustomerAccountAdminMutationHookResult = ReturnType<typeof useDeleteCustomerAccountAdminMutation>;
export type DeleteCustomerAccountAdminMutationResult = Apollo.MutationResult<DeleteCustomerAccountAdminMutation>;
export type DeleteCustomerAccountAdminMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerAccountAdminMutation,
  DeleteCustomerAccountAdminMutationVariables
>;
export const DeleteDriverAccountAdminDocument = gql`
  mutation deleteDriverAccountAdmin($id: String!) {
    deleteDriverAccountAdmin(id: $id) {
      success
    }
  }
`;
export type DeleteDriverAccountAdminMutationFn = Apollo.MutationFunction<
  DeleteDriverAccountAdminMutation,
  DeleteDriverAccountAdminMutationVariables
>;

/**
 * __useDeleteDriverAccountAdminMutation__
 *
 * To run a mutation, you first call `useDeleteDriverAccountAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverAccountAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverAccountAdminMutation, { data, loading, error }] = useDeleteDriverAccountAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
 export function useDeleteDriverLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDriverLeaveAdminMutation, DeleteDriverLeaveAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDriverLeaveAdminMutation, DeleteDriverLeaveAdminMutationVariables>(
    DeleteDriverLeaveAdmin,
    options
  );
}
export function useDeleteDriverAccountAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDriverAccountAdminMutation, DeleteDriverAccountAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDriverAccountAdminMutation, DeleteDriverAccountAdminMutationVariables>(
    DeleteDriverAccountAdminDocument,
    options
  );
}
export type DeleteDriverAccountAdminMutationHookResult = ReturnType<typeof useDeleteDriverAccountAdminMutation>;
export type DeleteDriverAccountAdminMutationResult = Apollo.MutationResult<DeleteDriverAccountAdminMutation>;
export type DeleteDriverAccountAdminMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverAccountAdminMutation,
  DeleteDriverAccountAdminMutationVariables
>;
export const CreateAreaDocument = gql`
  mutation createArea($input: CreateAreaInput!) {
    createArea(input: $input) {
      idArea
      areaName
      isActive
      refIdZedGeometry
    }
  }
`;
export type CreateAreaMutationFn = Apollo.MutationFunction<CreateAreaMutation, CreateAreaMutationVariables>;

/**
 * __useCreateAreaMutation__
 *
 * To run a mutation, you first call `useCreateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaMutation, { data, loading, error }] = useCreateAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAreaMutation, CreateAreaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAreaMutation, CreateAreaMutationVariables>(CreateAreaDocument, options);
}
export type CreateAreaMutationHookResult = ReturnType<typeof useCreateAreaMutation>;
export type CreateAreaMutationResult = Apollo.MutationResult<CreateAreaMutation>;
export type CreateAreaMutationOptions = Apollo.BaseMutationOptions<CreateAreaMutation, CreateAreaMutationVariables>;
export const GetAreasDocument = gql`
  query getAreas($filter: FilterInput!) {
    getAreas(filter: $filter) {
      idArea
      areaName
      isActive
      isEnable
      refIdZedGeometry
      refIdAreaType {
        idAreaType
        areaTypeName
      }
      fare {
        idFare
        bookingFee
        baseNightShift
        baseMorningShift
        farePerMin
        farePerKm
      }
      surge {
        idSurge
        surgeFare
        surgeEndTime
        surgeStartTime
        isActive
      }
      salik {
        idSalik
        salikFee
      }
    }
  }
`;

/**
 * __useGetAreasQuery__
 *
 * To run a query within a React component, call `useGetAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAreasQuery(baseOptions: Apollo.QueryHookOptions<GetAreasQuery, GetAreasQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
}
export function useGetAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAreasQuery, GetAreasQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
}
export type GetAreasQueryHookResult = ReturnType<typeof useGetAreasQuery>;
export type GetAreasLazyQueryHookResult = ReturnType<typeof useGetAreasLazyQuery>;
export type GetAreasQueryResult = Apollo.QueryResult<GetAreasQuery, GetAreasQueryVariables>;
export const GetAreaDocument = gql`
  query getArea($areaId: Float!) {
    getArea(areaId: $areaId) {
      idArea
      areaName
      isEnable
      refIdAreaType {
        idAreaType
        areaTypeName
      }
      isActive
      geometry {
        idGeometryLocations
        geom
      }
    }
  }
`;

export function useGetSaveAdminSignedUrl(
  baseOptions?: Apollo.QueryHookOptions<GetAdminSignedUrlQuery, GetAdminSignedUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminSignedUrlQuery, GetAdminSignedUrlQueryVariables>(GetAdminSignedUrlDocument, options);
}
export function useGetSaveAdminSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSignedUrlQuery, GetAdminSignedUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminSignedUrlQuery, GetAdminSignedUrlQueryVariables>(
    GetAdminSignedUrlDocument,
    options
  );
}
export type GetAdminSignedUrlQueryHookResult = ReturnType<typeof useGetSaveAdminSignedUrl>;
export type GetAdminSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSaveAdminSignedUrlLazyQuery>;
export type GetAdminSignedUrlQueryResult = Apollo.QueryResult<GetAdminSignedUrlQuery, GetAdminSignedUrlQueryVariables>;
export type GetAdminSignedUrlQuery = {
  __typename?: 'Query';
  getUserUploadUrl: { __typename?: 'getUserUploadUrl'; fileName: string; signedUrl: string };
};
export type GetAdminSignedUrlQueryVariables = Exact<{ [key: string]: never }>;

export const GetAdminSignedUrlDocument = gql`
  query getAdminSignedUrl {
    getAdminSignedUrl {
      fileName
      signedUrl
    }
  }
`;

/**
 * __useGetAreaQuery__
 *
 * To run a query within a React component, call `useGetAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetAreaQuery(baseOptions: Apollo.QueryHookOptions<GetAreaQuery, GetAreaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
}
export function useGetAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAreaQuery, GetAreaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
}
export type GetAreaQueryHookResult = ReturnType<typeof useGetAreaQuery>;
export type GetAreaLazyQueryHookResult = ReturnType<typeof useGetAreaLazyQuery>;
export type GetAreaQueryResult = Apollo.QueryResult<GetAreaQuery, GetAreaQueryVariables>;
export const DeleteAreaDocument = gql`
  mutation deleteArea($areaId: Float!) {
    deleteArea(areaId: $areaId) {
      success
    }
  }
`;
export type DeleteAreaMutationFn = Apollo.MutationFunction<DeleteAreaMutation, DeleteAreaMutationVariables>;

/**
 * __useDeleteAreaMutation__
 *
 * To run a mutation, you first call `useDeleteAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAreaMutation, { data, loading, error }] = useDeleteAreaMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useDeleteAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAreaMutation, DeleteAreaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAreaMutation, DeleteAreaMutationVariables>(DeleteAreaDocument, options);
}
export type DeleteAreaMutationHookResult = ReturnType<typeof useDeleteAreaMutation>;
export type DeleteAreaMutationResult = Apollo.MutationResult<DeleteAreaMutation>;
export type DeleteAreaMutationOptions = Apollo.BaseMutationOptions<DeleteAreaMutation, DeleteAreaMutationVariables>;
export const UpdateAreaDocument = gql`
  mutation updateArea($input: UpdateAreaInput!) {
    updateArea(input: $input) {
      success
    }
  }
`;
export type UpdateAreaMutationFn = Apollo.MutationFunction<UpdateAreaMutation, UpdateAreaMutationVariables>;

/**
 * __useUpdateAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaMutation, { data, loading, error }] = useUpdateAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAreaMutation, UpdateAreaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAreaMutation, UpdateAreaMutationVariables>(UpdateAreaDocument, options);
}
export type UpdateAreaMutationHookResult = ReturnType<typeof useUpdateAreaMutation>;
export type UpdateAreaMutationResult = Apollo.MutationResult<UpdateAreaMutation>;
export type UpdateAreaMutationOptions = Apollo.BaseMutationOptions<UpdateAreaMutation, UpdateAreaMutationVariables>;

export const UpdateAreaStateDocument = gql`
  mutation updateAreaState($idArea: Float!) {
    updateAreaState(idArea: $idArea) {
      success
      message
    }
  }
`;

export type UpdateAreaStateMutation = {
  __typename?: 'Mutation';
  updateAreaState: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UpdateAreaStateMutationVariables = Exact<{
  idArea?: Scalars['Float'];
}>;

export function useUpdateAreaStateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAreaStateMutation, UpdateAreaStateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAreaStateMutation, UpdateAreaStateMutationVariables>(
    UpdateAreaStateDocument,
    options
  );
}
export type UpdateAreaStateMutationHookResult = ReturnType<typeof useUpdateAreaStateMutation>;
export type UpdateAreaStateMutationResult = Apollo.MutationResult<UpdateAreaStateMutation>;
export type UpdateAreaStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAreaStateMutation,
  UpdateAreaStateMutationVariables
>;

export const CreateAreaTypeDocument = gql`
  mutation createAreaType($name: String!) {
    createAreaType(name: $name) {
      idAreaType
      areaTypeName
    }
  }
`;
export type CreateAreaTypeMutationFn = Apollo.MutationFunction<CreateAreaTypeMutation, CreateAreaTypeMutationVariables>;

/**
 * __useCreateAreaTypeMutation__
 *
 * To run a mutation, you first call `useCreateAreaTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaTypeMutation, { data, loading, error }] = useCreateAreaTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAreaTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAreaTypeMutation, CreateAreaTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAreaTypeMutation, CreateAreaTypeMutationVariables>(CreateAreaTypeDocument, options);
}
export type CreateAreaTypeMutationHookResult = ReturnType<typeof useCreateAreaTypeMutation>;
export type CreateAreaTypeMutationResult = Apollo.MutationResult<CreateAreaTypeMutation>;
export type CreateAreaTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateAreaTypeMutation,
  CreateAreaTypeMutationVariables
>;
export const AddFareDocument = gql`
  mutation addFare($input: CreateFareInput!) {
    addFare(input: $input) {
      idFare
      refIdArea
      bookingFee
      baseNightShift
      baseMorningShift
      farePerMin
      farePerKm
    }
  }
`;
export type AddFareMutationFn = Apollo.MutationFunction<AddFareMutation, AddFareMutationVariables>;

/**
 * __useAddFareMutation__
 *
 * To run a mutation, you first call `useAddFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFareMutation, { data, loading, error }] = useAddFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFareMutation(
  baseOptions?: Apollo.MutationHookOptions<AddFareMutation, AddFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFareMutation, AddFareMutationVariables>(AddFareDocument, options);
}
export type AddFareMutationHookResult = ReturnType<typeof useAddFareMutation>;
export type AddFareMutationResult = Apollo.MutationResult<AddFareMutation>;
export type AddFareMutationOptions = Apollo.BaseMutationOptions<AddFareMutation, AddFareMutationVariables>;
export const UpdateFareDocument = gql`
  mutation updateFare($input: UpdateFareInput!) {
    updateFare(input: $input) {
      idFare
      refIdArea
      bookingFee
      baseNightShift
      baseMorningShift
      farePerMin
      farePerKm
    }
  }
`;
export type UpdateFareMutationFn = Apollo.MutationFunction<UpdateFareMutation, UpdateFareMutationVariables>;

/**
 * __useUpdateFareMutation__
 *
 * To run a mutation, you first call `useUpdateFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFareMutation, { data, loading, error }] = useUpdateFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFareMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFareMutation, UpdateFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFareMutation, UpdateFareMutationVariables>(UpdateFareDocument, options);
}
export type UpdateFareMutationHookResult = ReturnType<typeof useUpdateFareMutation>;
export type UpdateFareMutationResult = Apollo.MutationResult<UpdateFareMutation>;
export type UpdateFareMutationOptions = Apollo.BaseMutationOptions<UpdateFareMutation, UpdateFareMutationVariables>;
export const AddSurgeFareDocument = gql`
  mutation addSurgeFare($input: CreateSurgeFareInput!) {
    addSurgeFare(input: $input) {
      idSurge
      refIdArea
      surgeFare
      surgeEndTime
      surgeStartTime
      isActive
    }
  }
`;
export type AddSurgeFareMutationFn = Apollo.MutationFunction<AddSurgeFareMutation, AddSurgeFareMutationVariables>;

export type UpdateCustomerReferralMutationVariables = Exact<{
  input: UpdateReferralCustomerInput;
}>;

export type UpdateCustomerReferralMutation = {
  __typename?: 'Mutation';
  updateReferralCustomer: { __typename?: 'Customer'; referralData?: string };
};

export function useUpdateCustomerReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerReferralMutation, UpdateCustomerReferralMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerReferralMutation, UpdateCustomerReferralMutationVariables>(
    UpdateCustomerReferralDocument,
    options
  );
}

export const UpdateCustomerReferralDocument = gql`
  mutation updateReferralCustomer($input: UpdateReferralCustomerInput!) {
    updateReferralCustomer(input: $input) {
      referralData
    }
  }
`;

/**
 * __useAddSurgeFareMutation__
 *
 * To run a mutation, you first call `useAddSurgeFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurgeFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurgeFareMutation, { data, loading, error }] = useAddSurgeFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSurgeFareMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSurgeFareMutation, AddSurgeFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSurgeFareMutation, AddSurgeFareMutationVariables>(AddSurgeFareDocument, options);
}
export type AddSurgeFareMutationHookResult = ReturnType<typeof useAddSurgeFareMutation>;
export type AddSurgeFareMutationResult = Apollo.MutationResult<AddSurgeFareMutation>;
export type AddSurgeFareMutationOptions = Apollo.BaseMutationOptions<
  AddSurgeFareMutation,
  AddSurgeFareMutationVariables
>;
export const UpdateSurgeFareDocument = gql`
  mutation updateSurgeFare($input: UpdateSurgeFareInput!) {
    updateSurgeFare(input: $input) {
      idSurge
      refIdArea
      surgeFare
      surgeEndTime
      surgeStartTime
      isActive
    }
  }
`;
export type UpdateSurgeFareMutationFn = Apollo.MutationFunction<
  UpdateSurgeFareMutation,
  UpdateSurgeFareMutationVariables
>;

/**
 * __useUpdateSurgeFareMutation__
 *
 * To run a mutation, you first call `useUpdateSurgeFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurgeFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurgeFareMutation, { data, loading, error }] = useUpdateSurgeFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurgeFareMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSurgeFareMutation, UpdateSurgeFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSurgeFareMutation, UpdateSurgeFareMutationVariables>(
    UpdateSurgeFareDocument,
    options
  );
}
export type UpdateSurgeFareMutationHookResult = ReturnType<typeof useUpdateSurgeFareMutation>;
export type UpdateSurgeFareMutationResult = Apollo.MutationResult<UpdateSurgeFareMutation>;
export type UpdateSurgeFareMutationOptions = Apollo.BaseMutationOptions<
  UpdateSurgeFareMutation,
  UpdateSurgeFareMutationVariables
>;
export const AddSalikFareDocument = gql`
  mutation addSalikFare($input: CreateSalikFareInput!) {
    addSalikFare(input: $input) {
      idSalik
      salikFee
    }
  }
`;
export type AddSalikFareMutationFn = Apollo.MutationFunction<AddSalikFareMutation, AddSalikFareMutationVariables>;

/**
 * __useAddSalikFareMutation__
 *
 * To run a mutation, you first call `useAddSalikFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalikFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalikFareMutation, { data, loading, error }] = useAddSalikFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSalikFareMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSalikFareMutation, AddSalikFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSalikFareMutation, AddSalikFareMutationVariables>(AddSalikFareDocument, options);
}
export type AddSalikFareMutationHookResult = ReturnType<typeof useAddSalikFareMutation>;
export type AddSalikFareMutationResult = Apollo.MutationResult<AddSalikFareMutation>;
export type AddSalikFareMutationOptions = Apollo.BaseMutationOptions<
  AddSalikFareMutation,
  AddSalikFareMutationVariables
>;
export const UpdateSalikFareDocument = gql`
  mutation updateSalikFare($input: UpdateSalikFareInput!) {
    updateSalikFare(input: $input) {
      idSalik
      salikFee
    }
  }
`;
export type UpdateSalikFareMutationFn = Apollo.MutationFunction<
  UpdateSalikFareMutation,
  UpdateSalikFareMutationVariables
>;

/**
 * __useUpdateSalikFareMutation__
 *
 * To run a mutation, you first call `useUpdateSalikFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalikFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalikFareMutation, { data, loading, error }] = useUpdateSalikFareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalikFareMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSalikFareMutation, UpdateSalikFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSalikFareMutation, UpdateSalikFareMutationVariables>(
    UpdateSalikFareDocument,
    options
  );
}
export type UpdateSalikFareMutationHookResult = ReturnType<typeof useUpdateSalikFareMutation>;
export type UpdateSalikFareMutationResult = Apollo.MutationResult<UpdateSalikFareMutation>;
export type UpdateSalikFareMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalikFareMutation,
  UpdateSalikFareMutationVariables
>;
export const GetAreaTypeDocument = gql`
  query getAreaType {
    getAreaType {
      idAreaType
      areaTypeName
    }
  }
`;

/**
 * __useGetAreaTypeQuery__
 *
 * To run a query within a React component, call `useGetAreaTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreaTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAreaTypeQuery, GetAreaTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreaTypeQuery, GetAreaTypeQueryVariables>(GetAreaTypeDocument, options);
}
export function useGetAreaTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAreaTypeQuery, GetAreaTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreaTypeQuery, GetAreaTypeQueryVariables>(GetAreaTypeDocument, options);
}
export type GetAreaTypeQueryHookResult = ReturnType<typeof useGetAreaTypeQuery>;
export type GetAreaTypeLazyQueryHookResult = ReturnType<typeof useGetAreaTypeLazyQuery>;
export type GetAreaTypeQueryResult = Apollo.QueryResult<GetAreaTypeQuery, GetAreaTypeQueryVariables>;
export const GetVehiclesAndPartnersDocument = gql`
  query getVehiclesAndPartners {
    getVehiclesAndPartners {
      partners {
        idPartner
        partnerName
      }
      vehicles {
        idVehicle
        licensePlate
        carType
        regCity
        refIdSupplier
        brand
        model
        category
        chasisNo
        fuelType
        manufacturingYear
        color
        kidsSeats
        modes
        seats
        vehicleType
        type
        insuranceExpDate
        idDocument
        frontImageUrl
        backImageUrl
        documentType
        expiryDate
      }
    }
  }
`;

/**
 * __useGetVehiclesAndPartnersQuery__
 *
 * To run a query within a React component, call `useGetVehiclesAndPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesAndPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesAndPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehiclesAndPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVehiclesAndPartnersQuery, GetVehiclesAndPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehiclesAndPartnersQuery, GetVehiclesAndPartnersQueryVariables>(
    GetVehiclesAndPartnersDocument,
    options
  );
}
export function useGetVehiclesAndPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesAndPartnersQuery, GetVehiclesAndPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehiclesAndPartnersQuery, GetVehiclesAndPartnersQueryVariables>(
    GetVehiclesAndPartnersDocument,
    options
  );
}
export type GetVehiclesAndPartnersQueryHookResult = ReturnType<typeof useGetVehiclesAndPartnersQuery>;
export type GetVehiclesAndPartnersLazyQueryHookResult = ReturnType<typeof useGetVehiclesAndPartnersLazyQuery>;
export type GetVehiclesAndPartnersQueryResult = Apollo.QueryResult<
  GetVehiclesAndPartnersQuery,
  GetVehiclesAndPartnersQueryVariables
>;
export const CreateDriverAdminDocument = gql`
  mutation createDriverAdmin($input: CreateDriverAdminInput!) {
    createDriverAdmin(input: $input) {
      idDriverUser
      emailId
      firstName
      lastName
      mobileNumber
      status
      mediaUrl
      idMeter
      refIdSupplier
      gender
      identityDocuments {
        idDocument
        frontImageUrl
        backImageUrl
        documentType
        expiryDate
      }
      startDate
      driverDetails {
        idDriverUserDtl
        empId
        experience
        about
        refIdDriverUser
        refIdPartner {
          idPartner
          partnerName
        }
        refIdVehicle {
          idVehicle
          licensePlate
        }
      }
    }
  }
`;
export type CreateDriverAdminMutationFn = Apollo.MutationFunction<
  CreateDriverAdminMutation,
  CreateDriverAdminMutationVariables
>;
export const UpdateDriverAdminDocument = gql`
  mutation updateDriverAdmin($input: CreateDriverAdminInput!) {
    updateDriverAdmin(input: $input) {
      idDriverUser
      emailId
      firstName
      lastName
      mobileNumber
      status
      mediaUrl
      idMeter
      refIdSupplier
      gender
      identityDocuments {
        idDocument
        frontImageUrl
        backImageUrl
        documentType
        expiryDate
      }
      startDate
      driverDetails {
        idDriverUserDtl
        empId
        experience
        about
        refIdDriverUser
        refIdPartner {
          idPartner
          partnerName
        }
        refIdVehicle {
          idVehicle
          licensePlate
        }
      }
    }
  }
`;

export const AssignDriver = gql`
  mutation($driverEmail: String!, $journeyId: Float!) {
    assignJourneyToDriverByAdmin(driverEmail: $driverEmail, journeyId: $journeyId)
  }
`;

export type UpdateDriverAdminMutationFn = Apollo.MutationFunction<
  UpdateDriverAdminMutation,
  UpdateDriverAdminMutationVariables
>;


/**
 * __useCreateDriverAdminMutation__
 *
 * To run a mutation, you first call `useCreateDriverAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverAdminMutation, { data, loading, error }] = useCreateDriverAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDriverAdminMutation, CreateDriverAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDriverAdminMutation, CreateDriverAdminMutationVariables>(
    CreateDriverAdminDocument,
    options
  );
}
export type CreateDriverAdminMutationHookResult = ReturnType<typeof useCreateDriverAdminMutation>;
export type CreateDriverAdminMutationResult = Apollo.MutationResult<CreateDriverAdminMutation>;
export type CreateDriverAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverAdminMutation,
  CreateDriverAdminMutationVariables
>;

export function useUpdateDriverAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDriverAdminMutation, UpdateDriverAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDriverAdminMutation, UpdateDriverAdminMutationVariables>(
    UpdateDriverAdminDocument,
    options
  );
}
export type UpdateDriverAdminMutationHookResult = ReturnType<typeof useUpdateDriverAdminMutation>;
export type UpdateDriverAdminMutationResult = Apollo.MutationResult<UpdateDriverAdminMutation>;
export type UpdateDriverAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverAdminMutation,
  UpdateDriverAdminMutationVariables
>;
export const UpdateDriverDocument = gql`
  mutation updateDriver($driverId: String!, $input: UpdateDriverInput!) {
    updateDriver(driverId: $driverId, input: $input) {
      badges {
        idDriverUserBadges
        refIdBadgeUser {
          badgeName
          idBadge
        }
        refIdDriverUser
      }
      devices {
        deviceName
        deviceToken
        idDevices
        refIdAnyUser
      }
      driverDetails {
        about
        empId
        experience
        idDriverUserDtl
        isActive
        refIdDriverUser
        refIdPartner {
          idPartner
          partnerName
        }
        refIdVehicle {
          idVehicle
          licensePlate
        }
      }
      emailId
      firstName
      idDriverUser
      lastName
      mobileNumber
      status
    }
  }
`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
}

export function useUpdateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
}


export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;

export const UpdateVehicleDocument = gql`
  mutation updateVehicle($idVehicle: Float!, $input: CreateVehicleAdminInput!) {
    updateVehicle(idVehicle: $idVehicle, input: $input) {
      licensePlate,
      carType,
      regCity,
      vehicleLicensePlateNo,
      brand,
      model,
      category,
      chasisNo,
      fuelType,
      manufacturingYear,
      color,
      kidsSeats,
      modes,
      seats,
      refIdSupplier,
      vehicleType,
      vehicleLicenseNumber,
      licenseExpiryDate,
      vehicleInsuranceValidity,
      licensePlateFront,
    }
  }
`;

export const GetDriversAdminDocument = gql`
  query getDriversAdmin($input: ListDriversInputs!) {
    getDriversAdmin(input: $input) {
      totalRows
      results {
        badges {
          idDriverUserBadges
          refIdBadgeUser {
            idBadge
            badgeName
          }
          refIdDriverUser
        }
        devices {
          deviceName
          deviceToken
          idDevices
          refIdAnyUser
        }
        driverDetails {
          about
          empId
          experience
          idDriverUserDtl
          isActive
          rating
          refIdDriverUser
          refIdPartner {
            idPartner
            partnerName
          }
          refIdVehicle {
            idVehicle
            licensePlate
          }
        }
        emailId
        firstName
        idDriverUser
        lastName
        mobileNumber
        status
        createdDate
        mediaUrl
        idMeter
        refIdSupplier
        gender
        startDate
        idDriverEmp
        identityDocuments {
          idDocument
          frontImageUrl
          backImageUrl
          documentType
          expiryDate
        }
      }
    }
  }
`;

/**
 * __useGetDriversAdminQuery__
 *
 * To run a query within a React component, call `useGetDriversAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDriversAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriversAdminQuery, GetDriversAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriversAdminQuery, GetDriversAdminQueryVariables>(GetDriversAdminDocument, options);
}
export function useGetDriversAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriversAdminQuery, GetDriversAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriversAdminQuery, GetDriversAdminQueryVariables>(GetDriversAdminDocument, options);
}
export type GetDriversAdminQueryHookResult = ReturnType<typeof useGetDriversAdminQuery>;
export type GetDriversAdminLazyQueryHookResult = ReturnType<typeof useGetDriversAdminLazyQuery>;
export type GetDriversAdminQueryResult = Apollo.QueryResult<GetDriversAdminQuery, GetDriversAdminQueryVariables>;
export const GetDriverAdminDocument = gql`
  query getDriverAdmin($driverId: String!) {
    getDriverAdmin(driverId: $driverId) {
      badges {
        idDriverUserBadges
        refIdBadgeUser {
          badgeName
          idBadge
        }
        refIdDriverUser
      }
      devices {
        deviceName
        deviceToken
        idDevices
        refIdAnyUser
      }
      driverDetails {
        about
        empId
        experience
        idDriverUserDtl
        isActive
        refIdDriverUser
        refIdPartner {
          idPartner
          partnerName
        }
        refIdVehicle {
          idVehicle
          licensePlate
        }
        rating
      }
      emailId
      firstName
      idDriverUser
      lastName
      mobileNumber
      status
      createdDate
      rideSummary {
        totalRides
        totalHours
        totalMiles
      }
    }
  }
`;

/**
 * __useGetDriverAdminQuery__
 *
 * To run a query within a React component, call `useGetDriverAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAdminQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriverAdminQuery, GetDriverAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriverAdminQuery, GetDriverAdminQueryVariables>(GetDriverAdminDocument, options);
}
export function useGetDriverAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriverAdminQuery, GetDriverAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriverAdminQuery, GetDriverAdminQueryVariables>(GetDriverAdminDocument, options);
}
export type GetDriverAdminQueryHookResult = ReturnType<typeof useGetDriverAdminQuery>;
export type GetDriverAdminLazyQueryHookResult = ReturnType<typeof useGetDriverAdminLazyQuery>;
export type GetDriverAdminQueryResult = Apollo.QueryResult<GetDriverAdminQuery, GetDriverAdminQueryVariables>;
export const GetAdminBookingSummaryDocument = gql`
  query getAdminBookingSummary($input: BookingFilterInputs!) {
    getAdminBookingSummary(input: $input) {
      results {
        idJourney
        bookingRef
        journeyType
        tripId
        promoCode
        dropoffRefIdLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        pickupRefIdLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        pickupTime
        actualPickupTime
        actualDropOffTime
        estimatedCost
        estimatedDroppoff
        customer {
          firstName
          lastName
          mobileNumber
        }
        driverName
        addons {
          refIdAddonItem
          quantity
          itemDetails {
            idAddonInventory
            itemName
          }
        }
        refIdCustomerUser
        refIdModes {
          idModes
          isActive
          modeName
          modeType
        }
        refIdRhythm
        status
        pricing {
          total
          isEstimation
          currency
        }
        stops {
          estimatedDropOff
          locations {
            idLocation
            locationName
            locationCustomName
          }
        }
      }
      success
      totalRows
      statistics {
        cancelled
        completed
        nexthour
      }
    }
  }
`;

/**
 * __useGetAdminBookingSummaryQuery__
 *
 * To run a query within a React component, call `useGetAdminBookingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminBookingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminBookingSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminBookingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminBookingSummaryQuery, GetAdminBookingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminBookingSummaryQuery, GetAdminBookingSummaryQueryVariables>(
    GetAdminBookingSummaryDocument,
    options
  );
}
export function useGetAdminBookingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminBookingSummaryQuery, GetAdminBookingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminBookingSummaryQuery, GetAdminBookingSummaryQueryVariables>(
    GetAdminBookingSummaryDocument,
    options
  );
}
export type GetAdminBookingSummaryQueryHookResult = ReturnType<typeof useGetAdminBookingSummaryQuery>;
export type GetAdminBookingSummaryLazyQueryHookResult = ReturnType<typeof useGetAdminBookingSummaryLazyQuery>;
export type GetAdminBookingSummaryQueryResult = Apollo.QueryResult<
  GetAdminBookingSummaryQuery,
  GetAdminBookingSummaryQueryVariables
>;
export const GetAdminDriverRidesDocument = gql`
  query getAdminDriverRides($input: AdminRideInputs!) {
    getAdminDriverRides(input: $input) {
      totalRows
      results {
        idJourney
        bookingRef
        customerName
        status
        pickUpLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        dropOffLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        pickupTime
        estimatedCost
        estimatedDroppoff
        mode {
          idModes
          modeName
          modeType
        }
        addons {
          refIdAddonItem
          quantity
          itemDetails {
            idAddonInventory
            itemName
          }
        }
        pricing {
          total
          isEstimation
          currency
        }
        snapshot {
          customerComments
          customerRating
          journeyRating
        }
      }
    }
  }
`;

/**
 * __useGetAdminDriverRidesQuery__
 *
 * To run a query within a React component, call `useGetAdminDriverRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDriverRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDriverRidesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminDriverRidesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminDriverRidesQuery, GetAdminDriverRidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminDriverRidesQuery, GetAdminDriverRidesQueryVariables>(
    GetAdminDriverRidesDocument,
    options
  );
}
export function useGetAdminDriverRidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDriverRidesQuery, GetAdminDriverRidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminDriverRidesQuery, GetAdminDriverRidesQueryVariables>(
    GetAdminDriverRidesDocument,
    options
  );
}
export type GetAdminDriverRidesQueryHookResult = ReturnType<typeof useGetAdminDriverRidesQuery>;
export type GetAdminDriverRidesLazyQueryHookResult = ReturnType<typeof useGetAdminDriverRidesLazyQuery>;
export type GetAdminDriverRidesQueryResult = Apollo.QueryResult<
  GetAdminDriverRidesQuery,
  GetAdminDriverRidesQueryVariables
>;
export const GetAdminCustomerRidesDocument = gql`
  query getAdminCustomerRides($input: AdminRideInputs!) {
    getAdminCustomerRides(input: $input) {
      totalRows
      results {
        idJourney
        bookingRef
        driver {
          idDriverUser
          firstName
          lastName
          badges {
            idDriverUserBadges
            refIdBadgeUser {
              badgeName
              idBadge
            }
            refIdDriverUser
          }
          createdDate
          driverDetails {
            rating
          }
        }
        dropoffRefIdLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        estimatedCost
        estimatedDroppoff
        journeyType
        pickupRefIdLocation {
          idLocation
          lat
          locationCustomName
          locationName
          locationType
          long
          placeId
        }
        pickupTime
        addons {
          refIdAddonItem
          quantity
          itemDetails {
            idAddonInventory
            itemName
          }
        }
        refIdCustomerUser
        refIdModes {
          idModes
          isActive
          modeName
          modeType
        }
        snapshot {
          customerComments
          customerRating
          driverComments
          driverRating
          journeyRating
          refIdJourney
          rideID
          ridePolyline
          totalCost
          totalDistance
          totalTime
        }
        status
        pricing {
          total
          isEstimation
          currency
        }
      }
    }
  }
`;

/**
 * __useGetAdminCustomerRidesQuery__
 *
 * To run a query within a React component, call `useGetAdminCustomerRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCustomerRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCustomerRidesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminCustomerRidesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminCustomerRidesQuery, GetAdminCustomerRidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminCustomerRidesQuery, GetAdminCustomerRidesQueryVariables>(
    GetAdminCustomerRidesDocument,
    options
  );
}
export function useGetAdminCustomerRidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminCustomerRidesQuery, GetAdminCustomerRidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminCustomerRidesQuery, GetAdminCustomerRidesQueryVariables>(
    GetAdminCustomerRidesDocument,
    options
  );
}
export type GetAdminCustomerRidesQueryHookResult = ReturnType<typeof useGetAdminCustomerRidesQuery>;
export type GetAdminCustomerRidesLazyQueryHookResult = ReturnType<typeof useGetAdminCustomerRidesLazyQuery>;
export type GetAdminCustomerRidesQueryResult = Apollo.QueryResult<
  GetAdminCustomerRidesQuery,
  GetAdminCustomerRidesQueryVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      emailId
      firstName
      idCustomerUser
      lastName
      mediaUrl
      mobileNumber
      rating
      status
      stripeCustomerId
      zedName
      createdDate
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const AdminRescheduleBookingDocument = gql`
  mutation adminRescheduleBooking($input: RescheduleJourneyInput!) {
    adminRescheduleBooking(input: $input) {
      success
    }
  }
`;
export type AdminRescheduleBookingMutationFn = Apollo.MutationFunction<
  AdminRescheduleBookingMutation,
  AdminRescheduleBookingMutationVariables
>;

export const GetRideEvents = gql`
  query getRideEvents($journeyId: Float!) {
    getRideEvents(journeyId: $journeyId) {
      createdDate
      rideStatusID
    }
  }
`;

/**
 * __useGetRideEventQuery__
 *
 * To run a query within a React component, call `useGetRideEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRideEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRideEventQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */

export function useGetRideEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetRideEventQuery, GetRideEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRideEventQuery, GetRideEventQueryVariables>(GetRideEvents, options);
}

/**
 * __useAdminRescheduleBookingMutation__
 *
 * To run a mutation, you first call `useAdminRescheduleBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRescheduleBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRescheduleBookingMutation, { data, loading, error }] = useAdminRescheduleBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminRescheduleBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminRescheduleBookingMutation, AdminRescheduleBookingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRescheduleBookingMutation, AdminRescheduleBookingMutationVariables>(
    AdminRescheduleBookingDocument,
    options
  );
}
export type AdminRescheduleBookingMutationHookResult = ReturnType<typeof useAdminRescheduleBookingMutation>;
export type AdminRescheduleBookingMutationResult = Apollo.MutationResult<AdminRescheduleBookingMutation>;
export type AdminRescheduleBookingMutationOptions = Apollo.BaseMutationOptions<
  AdminRescheduleBookingMutation,
  AdminRescheduleBookingMutationVariables
>;
export const CancelRideByAdminDocument = gql`
  mutation cancelRideByAdmin($journeyId: Float!) {
    cancelRideByAdmin(journeyId: $journeyId) {
      success
    }
  }
`;
export type CancelRideByAdminMutationFn = Apollo.MutationFunction<
  CancelRideByAdminMutation,
  CancelRideByAdminMutationVariables
>;

/**
 * __useCancelRideByAdminMutation__
 *
 * To run a mutation, you first call `useCancelRideByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRideByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRideByAdminMutation, { data, loading, error }] = useCancelRideByAdminMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useCancelRideByAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelRideByAdminMutation, CancelRideByAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelRideByAdminMutation, CancelRideByAdminMutationVariables>(
    CancelRideByAdminDocument,
    options
  );
}
export type CancelRideByAdminMutationHookResult = ReturnType<typeof useCancelRideByAdminMutation>;
export type CancelRideByAdminMutationResult = Apollo.MutationResult<CancelRideByAdminMutation>;
export type CancelRideByAdminMutationOptions = Apollo.BaseMutationOptions<
  CancelRideByAdminMutation,
  CancelRideByAdminMutationVariables
>;
export const GetCompaniesAdminDocument = gql`
  query getCompaniesAdmin($input: ListCompaniesInputs!) {
    getCompaniesAdmin(input: $input) {
      totalRows
      results {
        idCompany
        companyName
      }
    }
  }
`;

/**
 * __useGetCustomersAdminQuery__
 *
 * To run a query within a React component, call `useGetCompaniesAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompaniesAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>(GetCustomersAdminDocument, options);
}
export function useGetCompaniesAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>(
    GetCompaniesAdminDocument,
    options
  );
}
export type GetCompaniesAdminQueryHookResult = ReturnType<typeof useGetCompaniesAdminQuery>;
export type GetCompaniesAdminLazyQueryHookResult = ReturnType<typeof useGetCompaniesAdminLazyQuery>;
export type GetCompaniesAdminQueryResult = Apollo.QueryResult<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>;

export const GetCustomersAdminDocument = gql`
  query getCustomersAdmin($input: ListCustomersInputs!) {
    getCustomersAdmin(input: $input) {
      totalRows
      results {
        emailId
        firstName
        idCustomerUser
        lastName
        mediaUrl
        mobileNumber
        referralCode
        refereePromoCode
        totalReferralBonus
        paidReferralBonus
        refereePromoUsedCount
        modes {
          idModes
          modeName
          modeType
        }
        rating
        status
        stripeCustomerId
        zedName
        createdDate
      }
    }
  }
`;

export function useGetReferralJourneyQuery(
  baseOptions: Apollo.QueryHookOptions<GetReferralJourneyQuery, GetReferralJourneyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferralJourneyQuery, GetReferralJourneyQueryVariables>(
    GetReferralJourneyDocument,
    options
  );
}
export function useGetReferralJourneyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferralJourneyQuery, GetReferralJourneyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferralJourneyQuery, GetReferralJourneyQueryVariables>(
    GetReferralJourneyDocument,
    options
  );
}
export type GetReferralJourneyQueryHookResult = ReturnType<typeof useGetCompaniesAdminQuery>;
export type GetReferralJourneyLazyQueryHookResult = ReturnType<typeof useGetCompaniesAdminLazyQuery>;
export type GetReferralJourneyQueryResult = Apollo.QueryResult<GetCompaniesAdminQuery, GetCompaniesAdminQueryVariables>;

export const GetReferralJourneyDocument = gql`
  query GetReferralJourney($referralCode: String!, $totalReferralBonus: Float!, $paidReferralBonus: Float!) {
    GetReferralJourney(
      referralCode: $referralCode
      totalReferralBonus: $totalReferralBonus
      paidReferralBonus: $paidReferralBonus
    ) {
      firstName
      lastName
      refereePromoUsedCount
    }
  }
`;

export const GetWaitingListAdminDocument = gql`
  query getWaitingListAdmin($input: WaitingListInputs!) {
    getWaitingListAdmin(input: $input) {
      totalRows
      results {
        emailId
        mobileNumber
        idWaitingList
        createdDate
      }
    }
  }
`;

/**
 * __useCustomersAdminQuery__
 *
 * To run a query within a React component, call `useCustomersAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomersAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomersAdminQuery, GetCustomersAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersAdminQuery, GetCustomersAdminQueryVariables>(GetCustomersAdminDocument, options);
}
export function useGetCustomersAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersAdminQuery, GetCustomersAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersAdminQuery, GetCustomersAdminQueryVariables>(
    GetCustomersAdminDocument,
    options
  );
}
export function useGetWaitingListAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingListAdminQuery, GetWaitingListAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWaitingListAdminQuery, GetWaitingListAdminQueryVariables>(
    GetWaitingListAdminDocument,
    options
  );
}

export type GetWaitingListAdminLazyQueryHookResult = ReturnType<typeof useGetWaitingListAdminLazyQuery>;
export type GetCustomersAdminQueryHookResult = ReturnType<typeof useCustomersAdminQuery>;
export type GetCustomersAdminLazyQueryHookResult = ReturnType<typeof useGetCustomersAdminLazyQuery>;
export type GetCustomersAdminQueryResult = Apollo.QueryResult<GetCustomersAdminQuery, GetCustomersAdminQueryVariables>;

export const GetCompanySecretCodesAdminDocument = gql`
  query getCompanySecretCodesAdmin($input: ListCompanySecretCodesInputs!) {
    getCompanySecretCodesAdmin(input: $input) {
      totalRows
      results {
        idCompanySecretCode
        companySecretCode
        expiry_date
        refCompanyName
        codeRedemptions
      }
    }
  }
`;

/**
 * __useCompanySecretCodesAdminQuery__
 *
 * To run a query within a React component, call `useCompanySecretCodesAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySecretCodesAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySecretCodesAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanySecretCodesAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanySecretCodesAdminQuery, GetCompanySecretCodesAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanySecretCodesAdminQuery, GetCompanySecretCodesAdminQueryVariables>(
    GetCustomersAdminDocument,
    options
  );
}
export function useGetCompanySecretCodesAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySecretCodesAdminQuery, GetCompanySecretCodesAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanySecretCodesAdminQuery, GetCompanySecretCodesAdminQueryVariables>(
    GetCompanySecretCodesAdminDocument,
    options
  );
}
export type GetCompanySecretCodesAdminQueryHookResult = ReturnType<typeof useCompanySecretCodesAdminQuery>;
export type GetCompanySecretCodesAdminLazyQueryHookResult = ReturnType<typeof useGetCompanySecretCodesAdminLazyQuery>;
export type GetCompanySecretCodesAdminQueryResult = Apollo.QueryResult<
  GetCompanySecretCodesAdminQuery,
  GetCompanySecretCodesAdminQueryVariables
>;

export const GetCompanySecretCodesDocument = gql`
  query getAllCompanySecretCodes {
    getAllCompanySecretCodes {
      idCompanySecretCode
      companySecretCode
      refCompanyName
      expiry_date
    }
  }
`;

export type GetCompanySecretCodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCompanySecretCodesQuery = {
  __typename?: 'Query';
  getAllCompanySecretCodes: Array<{
    __typename?: 'CompanySecretCodes';
    idCompanySecretCode: number;
    companySecretCode: string;
    refCompanyName: string;
    expiry_date: any;
  }>;
};

export function useGetCompanySecretCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompanySecretCodesQuery, GetCompanySecretCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCompanySecretCodesQuery, GetCompanySecretCodesQueryVariables>(
    GetCompanySecretCodesDocument,
    options
  );
}

export type GetCompanySecretCodesLazyQueryHookResult = ReturnType<typeof useGetCompanySecretCodesLazyQuery>;
export type GetCompanySecretCodesQueryResult = Apollo.QueryResult<
  GetAllCompanySecretCodesQuery,
  GetCompanySecretCodesQueryVariables
>;

export const GetAvailableDriversForLocationDocument = gql`
  query getAvailableDriversForLocation($journeyId: Float!) {
    getAvailableDriversForLocation(journeyId: $journeyId) {
      results {
        idDriverUser
        firstName
        lastName
        driverDetails {
          rating
        }
        badges {
          idDriverUserBadges
          refIdDriverUser
          refIdBadgeUser {
            idBadge
            badgeName
          }
        }
        rideSummary {
          totalRides
          totalHours
          totalMiles
        }
      }
      success
    }
  }
`;

/**
 * __useGetAvailableDriversForLocationQuery__
 *
 * To run a query within a React component, call `useGetAvailableDriversForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDriversForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDriversForLocationQuery({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useGetAvailableDriversForLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableDriversForLocationQuery,
    GetAvailableDriversForLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvailableDriversForLocationQuery, GetAvailableDriversForLocationQueryVariables>(
    GetAvailableDriversForLocationDocument,
    options
  );
}
export function useGetAvailableDriversForLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableDriversForLocationQuery,
    GetAvailableDriversForLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAvailableDriversForLocationQuery, GetAvailableDriversForLocationQueryVariables>(
    GetAvailableDriversForLocationDocument,
    options
  );
}
export type GetAvailableDriversForLocationQueryHookResult = ReturnType<typeof useGetAvailableDriversForLocationQuery>;
export type GetAvailableDriversForLocationLazyQueryHookResult = ReturnType<
  typeof useGetAvailableDriversForLocationLazyQuery
>;
export type GetAvailableDriversForLocationQueryResult = Apollo.QueryResult<
  GetAvailableDriversForLocationQuery,
  GetAvailableDriversForLocationQueryVariables
>;

export const GetAllPromotions = gql`
  query getAllPromotions {
    getAllPromotions {
      idPromotion
      promoCode
      promoDescription
      promoTitle
      promoType
      unit
      discount
      minimumBaseFare
      totalUsageLimit
      typesOfUsers
      promoSpecification
      promoApplication
      perCustomerUsageLimit
      activeFromDate
      activeTillDate
      isActive
      maxDiscount
      promoUsageLimitPerMonth
      totalAwailedDiscount
      promoApplication
      promoSpecification
      typesOfUsers
      refIdZedGeometry
      tierDiscounts
    }
  }
`;

export function useGetAllPromotionsQueryResult(baseOptions: Apollo.QueryHookOptions<GetAllPromotionsQuery>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPromotionsQuery>(GetAllPromotions, options);
}

export const GetDistanceTimeEstimationsDocument = gql`
  query getDistanceTimeEstimations($input: DistanceTimeEstimationsInput!) {
    getDistanceTimeEstimations(input: $input) {
      distance
      duration
    }
  }
`;

/**
 * __useGetDistanceTimeEstimationsQuery__
 *
 * To run a query within a React component, call `useGetDistanceTimeEstimationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistanceTimeEstimationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistanceTimeEstimationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDistanceTimeEstimationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDistanceTimeEstimationsQuery, GetDistanceTimeEstimationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDistanceTimeEstimationsQuery, GetDistanceTimeEstimationsQueryVariables>(
    GetDistanceTimeEstimationsDocument,
    options
  );
}
export function useGetDistanceTimeEstimationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDistanceTimeEstimationsQuery, GetDistanceTimeEstimationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDistanceTimeEstimationsQuery, GetDistanceTimeEstimationsQueryVariables>(
    GetDistanceTimeEstimationsDocument,
    options
  );
}
export type GetDistanceTimeEstimationsQueryHookResult = ReturnType<typeof useGetDistanceTimeEstimationsQuery>;
export type GetDistanceTimeEstimationsLazyQueryHookResult = ReturnType<typeof useGetDistanceTimeEstimationsLazyQuery>;
export type GetDistanceTimeEstimationsQueryResult = Apollo.QueryResult<
  GetDistanceTimeEstimationsQuery,
  GetDistanceTimeEstimationsQueryVariables
>;
export const GetGlobalModeDetailsDocument = gql`
  query getGlobalModeDetails {
    getGlobalModeDetails {
      idModes
      modeName
    }
  }
`;

/**
 * __useGetGlobalModeDetailsQuery__
 *
 * To run a query within a React component, call `useGetGlobalModeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalModeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalModeDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalModeDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGlobalModeDetailsQuery, GetGlobalModeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGlobalModeDetailsQuery, GetGlobalModeDetailsQueryVariables>(
    GetGlobalModeDetailsDocument,
    options
  );
}
export function useGetGlobalModeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalModeDetailsQuery, GetGlobalModeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGlobalModeDetailsQuery, GetGlobalModeDetailsQueryVariables>(
    GetGlobalModeDetailsDocument,
    options
  );
}
export type GetGlobalModeDetailsQueryHookResult = ReturnType<typeof useGetGlobalModeDetailsQuery>;
export type GetGlobalModeDetailsLazyQueryHookResult = ReturnType<typeof useGetGlobalModeDetailsLazyQuery>;
export type GetGlobalModeDetailsQueryResult = Apollo.QueryResult<
  GetGlobalModeDetailsQuery,
  GetGlobalModeDetailsQueryVariables
>;
export const AdminEditBookingDocument = gql`
  mutation adminEditBooking($idJourney: Float!, $input: AdminEditJourneyInput!) {
    adminEditBooking(idJourney: $idJourney, input: $input) {
      idJourney
      bookingRef
      journeyType
      dropoffRefIdLocation {
        idLocation
        lat
        locationCustomName
        locationName
        locationType
        long
        placeId
      }
      pickupRefIdLocation {
        idLocation
        lat
        locationCustomName
        locationName
        locationType
        long
        placeId
      }
      pickupTime
      addons {
        refIdAddonItem
        quantity
        itemDetails {
          idAddonInventory
          itemName
        }
      }
      estimatedCost
      estimatedDroppoff
      refIdCustomerUser
      refIdModes {
        idModes
        isActive
        modeName
        modeType
      }
      refIdRhythm
      status
      pricing {
        total
        isEstimation
        currency
      }
    }
  }
`;
export type AdminEditBookingMutationFn = Apollo.MutationFunction<
  AdminEditBookingMutation,
  AdminEditBookingMutationVariables
>;

/**
 * __useAdminEditBookingMutation__
 *
 * To run a mutation, you first call `useAdminEditBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditBookingMutation, { data, loading, error }] = useAdminEditBookingMutation({
 *   variables: {
 *      idJourney: // value for 'idJourney'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEditBookingMutation, AdminEditBookingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditBookingMutation, AdminEditBookingMutationVariables>(
    AdminEditBookingDocument,
    options
  );
}
export type AdminEditBookingMutationHookResult = ReturnType<typeof useAdminEditBookingMutation>;
export type AdminEditBookingMutationResult = Apollo.MutationResult<AdminEditBookingMutation>;
export type AdminEditBookingMutationOptions = Apollo.BaseMutationOptions<
  AdminEditBookingMutation,
  AdminEditBookingMutationVariables
>;
export const CustomerRideSummaryDocument = gql`
  query customerRideSummary($customerId: String!) {
    customerRideSummary(customerId: $customerId) {
      totalHours
      totalMiles
      totalRides
    }
  }
`;

/**
 * __useCustomerRideSummaryQuery__
 *
 * To run a query within a React component, call `useCustomerRideSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRideSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRideSummaryQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerRideSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerRideSummaryQuery, CustomerRideSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerRideSummaryQuery, CustomerRideSummaryQueryVariables>(
    CustomerRideSummaryDocument,
    options
  );
}
export function useCustomerRideSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerRideSummaryQuery, CustomerRideSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerRideSummaryQuery, CustomerRideSummaryQueryVariables>(
    CustomerRideSummaryDocument,
    options
  );
}
export type CustomerRideSummaryQueryHookResult = ReturnType<typeof useCustomerRideSummaryQuery>;
export type CustomerRideSummaryLazyQueryHookResult = ReturnType<typeof useCustomerRideSummaryLazyQuery>;
export type CustomerRideSummaryQueryResult = Apollo.QueryResult<
  CustomerRideSummaryQuery,
  CustomerRideSummaryQueryVariables
>;
export const UpdateAdminUserDocument = gql`
  mutation UpdateAdminUser($inputs: UpdateAdminUserInput!) {
    updateAdminUser(inputs: $inputs) {
      success
      user {
        emailId
        firstName
        mediaUrl
        mobileNumber
        lastName
        status
        idAdminUser
        details {
          adminUserDescription
          department {
            departmentDescription
            departmentName
            idAdminDepartment
          }
          experience
          idAdminUserDtl
          isActive
          organization {
            idAdminOrganization
            organizationDescription
            organizationName
          }
          refIdAdminUser
        }
        role {
          roleName
          idAdminUserRoles
        }
      }
    }
  }
`;
export const UpdateWaitingListDocument = gql`
  mutation UpdateWaitingList($inputs: UpdateWaitingListInput!) {
    updateWaitingList(inputs: $inputs) {
      success
      user {
        emailId
        mobileNumber
        idWaitingList
      }
    }
  }
`;
export type UpdateAdminUserMutationFn = Apollo.MutationFunction<
  UpdateAdminUserMutation,
  UpdateAdminUserMutationVariables
>;

/**
 * __useUpdateAdminUserMutation__
 *
 * To run a mutation, you first call `useUpdateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminUserMutation, { data, loading, error }] = useUpdateAdminUserMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>(
    UpdateAdminUserDocument,
    options
  );
}

export function useUpdateWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWaitingListMutation, UpdateWaitingListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWaitingListMutation, UpdateWaitingListMutationVariables>(
    UpdateWaitingListDocument,
    options
  );
}

export type UpdateWaitingListMutationHookResult = ReturnType<typeof useUpdateWaitingListMutation>;
export type UpdateAdminUserMutationHookResult = ReturnType<typeof useUpdateAdminUserMutation>;
export type UpdateAdminUserMutationResult = Apollo.MutationResult<UpdateAdminUserMutation>;
export type UpdateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminUserMutation,
  UpdateAdminUserMutationVariables
>;
export const GetAdminUserByIdDocument = gql`
  query getAdminUserByID($userId: String!) {
    getAdminUserByID(userId: $userId) {
      emailId
      firstName
      idAdminUser
      lastName
      mediaUrl
      middleName
      mobileNumber
      role {
        idAdminUserRoles
        isActive
        roleDescription
        roleName
        rolePermissions
      }
      details {
        adminUserDescription
        department {
          departmentDescription
          departmentName
          idAdminDepartment
        }
        experience
        idAdminUserDtl
        isActive
        organization {
          idAdminOrganization
          organizationDescription
          organizationName
        }
        refIdAdminUser
      }
      status
    }
  }
`;

/**
 * __useGetAdminUserByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAdminUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>(GetAdminUserByIdDocument, options);
}
export function useGetAdminUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>(GetAdminUserByIdDocument, options);
}
export type GetAdminUserByIdQueryHookResult = ReturnType<typeof useGetAdminUserByIdQuery>;
export type GetAdminUserByIdLazyQueryHookResult = ReturnType<typeof useGetAdminUserByIdLazyQuery>;
export type GetAdminUserByIdQueryResult = Apollo.QueryResult<GetAdminUserByIdQuery, GetAdminUserByIdQueryVariables>;
export const DeleteFareDocument = gql`
  mutation deleteFare($fareId: Float!) {
    deleteFare(fareId: $fareId) {
      success
    }
  }
`;
export type DeleteFareMutationFn = Apollo.MutationFunction<DeleteFareMutation, DeleteFareMutationVariables>;

/**
 * __useDeleteFareMutation__
 *
 * To run a mutation, you first call `useDeleteFareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFareMutation, { data, loading, error }] = useDeleteFareMutation({
 *   variables: {
 *      fareId: // value for 'fareId'
 *   },
 * });
 */
export function useDeleteFareMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFareMutation, DeleteFareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFareMutation, DeleteFareMutationVariables>(DeleteFareDocument, options);
}
export type DeleteFareMutationHookResult = ReturnType<typeof useDeleteFareMutation>;
export type DeleteFareMutationResult = Apollo.MutationResult<DeleteFareMutation>;
export type DeleteFareMutationOptions = Apollo.BaseMutationOptions<DeleteFareMutation, DeleteFareMutationVariables>;
export const DeleteSurgeDocument = gql`
  mutation deleteSurge($surgeId: Float!) {
    deleteSurge(surgeId: $surgeId) {
      success
    }
  }
`;
export type DeleteSurgeMutationFn = Apollo.MutationFunction<DeleteSurgeMutation, DeleteSurgeMutationVariables>;

/**
 * __useDeleteSurgeMutation__
 *
 * To run a mutation, you first call `useDeleteSurgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurgeMutation, { data, loading, error }] = useDeleteSurgeMutation({
 *   variables: {
 *      surgeId: // value for 'surgeId'
 *   },
 * });
 */
export function useDeleteSurgeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSurgeMutation, DeleteSurgeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSurgeMutation, DeleteSurgeMutationVariables>(DeleteSurgeDocument, options);
}
export type DeleteSurgeMutationHookResult = ReturnType<typeof useDeleteSurgeMutation>;
export type DeleteSurgeMutationResult = Apollo.MutationResult<DeleteSurgeMutation>;
export type DeleteSurgeMutationOptions = Apollo.BaseMutationOptions<DeleteSurgeMutation, DeleteSurgeMutationVariables>;

export const ApplyPromoMutation = gql`
  mutation applyPromo($promotionId: Float!, $userId: String!) {
    applyPromo(promotionId: $promotionId, userId: $userId) {
      promotionId
      refIdCustomerUser
    }
  }
`;

export function useApplyPromoMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyPromotionMutation, ApplyPromotionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyPromotionMutation, ApplyPromotionMutationVariables>(ApplyPromoMutation, options);
}

export const DeleteSalikDocument = gql`
  mutation deleteSalik($salikId: Float!) {
    deleteSalik(salikId: $salikId) {
      success
    }
  }
`;
export type DeleteSalikMutationFn = Apollo.MutationFunction<DeleteSalikMutation, DeleteSalikMutationVariables>;

/**
 * __useDeleteSalikMutation__
 *
 * To run a mutation, you first call `useDeleteSalikMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalikMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalikMutation, { data, loading, error }] = useDeleteSalikMutation({
 *   variables: {
 *      salikId: // value for 'salikId'
 *   },
 * });
 */
export function useDeleteSalikMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSalikMutation, DeleteSalikMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSalikMutation, DeleteSalikMutationVariables>(DeleteSalikDocument, options);
}
export type DeleteSalikMutationHookResult = ReturnType<typeof useDeleteSalikMutation>;
export type DeleteSalikMutationResult = Apollo.MutationResult<DeleteSalikMutation>;
export type DeleteSalikMutationOptions = Apollo.BaseMutationOptions<DeleteSalikMutation, DeleteSalikMutationVariables>;
export const GetAdminUsersListDocument = gql`
  query getAdminUsersList($input: ListAdminUsersInput!) {
    getAdminUsersList(input: $input) {
      totalRows
      results {
        emailId
        firstName
        mediaUrl
        mobileNumber
        lastName
        status
        idAdminUser
        role {
          idAdminUserRoles
          roleName
          roleDescription
          rolePermissions
          isActive
        }
        details {
          idAdminUserDtl
          experience
          adminUserDescription
          department {
            idAdminDepartment
            departmentName
            departmentDescription
          }
          organization {
            idAdminOrganization
            organizationName
            organizationDescription
          }
          isActive
          refIdAdminUser
        }
      }
    }
  }
`;

/**
 * __useGetAdminUsersListQuery__
 *
 * To run a query within a React component, call `useGetAdminUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUsersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAdminUsersListQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminUsersListQuery, GetAdminUsersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUsersListQuery, GetAdminUsersListQueryVariables>(GetAdminUsersListDocument, options);
}
export function useGetAdminUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUsersListQuery, GetAdminUsersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUsersListQuery, GetAdminUsersListQueryVariables>(
    GetAdminUsersListDocument,
    options
  );
}
export type GetAdminUsersListQueryHookResult = ReturnType<typeof useGetAdminUsersListQuery>;
export type GetAdminUsersListLazyQueryHookResult = ReturnType<typeof useGetAdminUsersListLazyQuery>;
export type GetAdminUsersListQueryResult = Apollo.QueryResult<GetAdminUsersListQuery, GetAdminUsersListQueryVariables>;
export const DeleteAdminUserDocument = gql`
  mutation deleteAdminUser($userId: String!) {
    deleteAdminUser(userId: $userId) {
      success
      message
    }
  }
`;
export const DeleteWaitingListDocument = gql`
  mutation deleteWaitingList($idWaitingList: String!) {
    deleteWaitingList(idWaitingList: $idWaitingList) {
      success
      message
    }
  }
`;
export const DeleteVehicleDocument = gql`
  mutation deleteVehicle($idVehicle: String!) {
    deleteVehicle(idVehicle: $idVehicle) {
      success
      message
    }
  }
`;
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>;

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(
    DeleteAdminUserDocument,
    options
  );
}
export function useDeleteWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWaitingListMutation, DeleteWaitingListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWaitingListMutation, DeleteWaitingListMutationVariables>(
    DeleteWaitingListDocument,
    options
  );
}
export type DeleteWaitingListMutationHookResult = ReturnType<typeof useDeleteWaitingListMutation>;
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>;

export function useDeleteVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVehicleMutation, DeleteVehicleMutationVariables>(
    DeleteVehicleDocument,
    options
  );
}
export type DeleteVehicleMutationHookResult = ReturnType<typeof useDeleteVehicleMutation>;
export type DeleteVehicleMutationResult = Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<
DeleteVehicleMutation,
  DeleteVehicleMutationVariables
>;

export const VerifyAdminMobileNumberDocument = gql`
  mutation verifyAdminMobileNumber($input: AdminForgotPasswordInput!) {
    verifyAdminMobileNumber(input: $input) {
      sid
    }
  }
`;
export type VerifyAdminMobileNumberMutationFn = Apollo.MutationFunction<
  VerifyAdminMobileNumberMutation,
  VerifyAdminMobileNumberMutationVariables
>;

/**
 * __useVerifyAdminMobileNumberMutation__
 *
 * To run a mutation, you first call `useVerifyAdminMobileNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAdminMobileNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAdminMobileNumberMutation, { data, loading, error }] = useVerifyAdminMobileNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyAdminMobileNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyAdminMobileNumberMutation, VerifyAdminMobileNumberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyAdminMobileNumberMutation, VerifyAdminMobileNumberMutationVariables>(
    VerifyAdminMobileNumberDocument,
    options
  );
}
export type VerifyAdminMobileNumberMutationHookResult = ReturnType<typeof useVerifyAdminMobileNumberMutation>;
export type VerifyAdminMobileNumberMutationResult = Apollo.MutationResult<VerifyAdminMobileNumberMutation>;
export type VerifyAdminMobileNumberMutationOptions = Apollo.BaseMutationOptions<
  VerifyAdminMobileNumberMutation,
  VerifyAdminMobileNumberMutationVariables
>;
export const LoginWithAdminMobileNumberDocument = gql`
  mutation loginWithAdminMobileNumber($loginWithMobileNumberInput: LoginWithMobileNumberInput!) {
    loginWithAdminMobileNumber(loginWithMobileNumberInput: $loginWithMobileNumberInput) {
      access_token
      user {
        idAdminUser
        mediaUrl
        status
        firstName
        lastName
        emailId
        mobileNumber
      }
    }
  }
`;
export type LoginWithAdminMobileNumberMutationFn = Apollo.MutationFunction<
  LoginWithAdminMobileNumberMutation,
  LoginWithAdminMobileNumberMutationVariables
>;

/**
 * __useLoginWithAdminMobileNumberMutation__
 *
 * To run a mutation, you first call `useLoginWithAdminMobileNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAdminMobileNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAdminMobileNumberMutation, { data, loading, error }] = useLoginWithAdminMobileNumberMutation({
 *   variables: {
 *      loginWithMobileNumberInput: // value for 'loginWithMobileNumberInput'
 *   },
 * });
 */
export function useLoginWithAdminMobileNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithAdminMobileNumberMutation,
    LoginWithAdminMobileNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithAdminMobileNumberMutation, LoginWithAdminMobileNumberMutationVariables>(
    LoginWithAdminMobileNumberDocument,
    options
  );
}
export type LoginWithAdminMobileNumberMutationHookResult = ReturnType<typeof useLoginWithAdminMobileNumberMutation>;
export type LoginWithAdminMobileNumberMutationResult = Apollo.MutationResult<LoginWithAdminMobileNumberMutation>;
export type LoginWithAdminMobileNumberMutationOptions = Apollo.BaseMutationOptions<
  LoginWithAdminMobileNumberMutation,
  LoginWithAdminMobileNumberMutationVariables
>;
export const UpdateAdminPasswordDocument = gql`
  mutation updateAdminPassword($password: String!) {
    updateAdminPassword(password: $password) {
      success
    }
  }
`;
export type UpdateAdminPasswordMutationFn = Apollo.MutationFunction<
  UpdateAdminPasswordMutation,
  UpdateAdminPasswordMutationVariables
>;

/**
 * __useUpdateAdminPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminPasswordMutation, { data, loading, error }] = useUpdateAdminPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateAdminPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdminPasswordMutation, UpdateAdminPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminPasswordMutation, UpdateAdminPasswordMutationVariables>(
    UpdateAdminPasswordDocument,
    options
  );
}
export type UpdateAdminPasswordMutationHookResult = ReturnType<typeof useUpdateAdminPasswordMutation>;
export type UpdateAdminPasswordMutationResult = Apollo.MutationResult<UpdateAdminPasswordMutation>;
export type UpdateAdminPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminPasswordMutation,
  UpdateAdminPasswordMutationVariables
>;
export const CreateAdminUserDocument = gql`
  mutation createAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      success
      message
    }
  }
`;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(
    CreateAdminUserDocument,
    options
  );
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;
export const GetDepartmentsListDocument = gql`
  query getDepartmentsList {
    getDepartmentsList {
      idAdminDepartment
      departmentDescription
      departmentName
    }
  }
`;

/**
 * __useGetDepartmentsListQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDepartmentsListQuery, GetDepartmentsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepartmentsListQuery, GetDepartmentsListQueryVariables>(
    GetDepartmentsListDocument,
    options
  );
}
export function useGetDepartmentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentsListQuery, GetDepartmentsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepartmentsListQuery, GetDepartmentsListQueryVariables>(
    GetDepartmentsListDocument,
    options
  );
}
export type GetDepartmentsListQueryHookResult = ReturnType<typeof useGetDepartmentsListQuery>;
export type GetDepartmentsListLazyQueryHookResult = ReturnType<typeof useGetDepartmentsListLazyQuery>;
export type GetDepartmentsListQueryResult = Apollo.QueryResult<
  GetDepartmentsListQuery,
  GetDepartmentsListQueryVariables
>;
export const GetUserRoleListDocument = gql`
  query getUserRoleList($input: FilterRoleInput!) {
    getUserRoleList(input: $input) {
      totalRows
      results {
        idAdminUserRoles
        roleName
        roleDescription
        rolePermissions
        isActive
      }
    }
  }
`;

/**
 * __useGetUserRoleListQuery__
 *
 * To run a query within a React component, call `useGetUserRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserRoleListQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserRoleListQuery, GetUserRoleListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserRoleListQuery, GetUserRoleListQueryVariables>(GetUserRoleListDocument, options);
}
export function useGetUserRoleListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserRoleListQuery, GetUserRoleListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserRoleListQuery, GetUserRoleListQueryVariables>(GetUserRoleListDocument, options);
}
export type GetUserRoleListQueryHookResult = ReturnType<typeof useGetUserRoleListQuery>;
export type GetUserRoleListLazyQueryHookResult = ReturnType<typeof useGetUserRoleListLazyQuery>;
export type GetUserRoleListQueryResult = Apollo.QueryResult<GetUserRoleListQuery, GetUserRoleListQueryVariables>;
export const AddUserRoleDocument = gql`
  mutation addUserRole($inputs: AddRoleInput!) {
    addUserRole(inputs: $inputs) {
      success
      message
    }
  }
`;
export type AddUserRoleMutationFn = Apollo.MutationFunction<AddUserRoleMutation, AddUserRoleMutationVariables>;

/**
 * __useAddUserRoleMutation__
 *
 * To run a mutation, you first call `useAddUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserRoleMutation, { data, loading, error }] = useAddUserRoleMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useAddUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUserRoleMutation, AddUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserRoleMutation, AddUserRoleMutationVariables>(AddUserRoleDocument, options);
}
export type AddUserRoleMutationHookResult = ReturnType<typeof useAddUserRoleMutation>;
export type AddUserRoleMutationResult = Apollo.MutationResult<AddUserRoleMutation>;
export type AddUserRoleMutationOptions = Apollo.BaseMutationOptions<AddUserRoleMutation, AddUserRoleMutationVariables>;
export const UpdateRoleDocument = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      success
      message
    }
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($roleId: Float!) {
    deleteRole(roleId: $roleId) {
      message
      success
    }
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetRoleSubjectsDocument = gql`
  query getRoleSubjects {
    getRoleSubjects {
      idRoleSubjects
      subjectKey
      subjectLabel
    }
  }
`;

/**
 * __useGetRoleSubjectsQuery__
 *
 * To run a query within a React component, call `useGetRoleSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleSubjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoleSubjectsQuery, GetRoleSubjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleSubjectsQuery, GetRoleSubjectsQueryVariables>(GetRoleSubjectsDocument, options);
}
export function useGetRoleSubjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleSubjectsQuery, GetRoleSubjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleSubjectsQuery, GetRoleSubjectsQueryVariables>(GetRoleSubjectsDocument, options);
}
export type GetRoleSubjectsQueryHookResult = ReturnType<typeof useGetRoleSubjectsQuery>;
export type GetRoleSubjectsLazyQueryHookResult = ReturnType<typeof useGetRoleSubjectsLazyQuery>;
export type GetRoleSubjectsQueryResult = Apollo.QueryResult<GetRoleSubjectsQuery, GetRoleSubjectsQueryVariables>;

export type CreatCompanyMutationVariables = Exact<{
  input: AddCompanyInput;
}>;

export type CreatCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'Company';
    idCompany: string;
    companyName: string;
  };
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCompaniesQuery = {
  __typename?: 'Query';
  getAllCompanies: Array<{
    __typename?: 'Company';
    idCompany: string;
    companyName: string;
  }>;
};

export const CreatCompanyDocument = gql`
  mutation creatCompany($addCompanyInput: AddCompanyInput!) {
    createCompany(addCompanyInput: $addCompanyInput) {
      idCompany
      companyName
    }
  }
`;

// export type CreateCompanyMutation = Apollo.MutationFunction<CreatCompanyMutation, CreatCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export type CreateVehicleAdminInput = {
  licensePlate: string;
  carType: string;
  regCity: string;
  vehicleLicensePlateNo: string;
  brand: string;
  model: string;
  category: string;
  chasisNo: string;
  fuelType: string;
  manufacturingYear: number;
  color: string;
  kidsSeats: number;
  modes: string;
  seats: number;
  refIdSupplier?: string;
  vehicleType?: string;
  vehicleLicenseNumber?: string;
  licenseExpiryDate?: string;
  vehicleInsuranceValidity?: string;
  licensePlateFront?: string;
};

export const createVehicleAdminDocument = gql`
  mutation createVehicleAdmin($input: CreateVehicleAdminInput!) {
    createVehicleAdmin(input: $input) {
      licensePlate
      carType
      regCity
      vehicleLicensePlateNo
      brand
      model
      category
      chasisNo
      fuelType
      manufacturingYear
      color
      kidsSeats
      modes
      seats
      vehicleType
      type
      vehicleLicenseNumber
      licenseExpiryDate
      vehicleInsuranceValidity
      licensePlateFront
    }
  }
`;

export type CreateVehicleAdminMutation = {
  __typename?: 'Mutation';
  createVehicleAdmin: {
    __typename?: 'Vehicle';
    licensePlate: string;
    carType: string;
    regCity: string;
    vehicleLicensePlateNo: string;
    brand: string;
    model: string;
    category: string;
    chasisNo: string;
    fuelType: string;
    manufacturingYear: number;
    color: string;
    kidsSeats: number;
    modes: string;
    seats: number;
    refIdSupplier?: string;
    vehicleType?: string;
    vehicleLicenseNumber?: string;
    licenseExpiryDate?: string;
    vehicleInsuranceValidity?: string;
    licensePlateFront?: string;
  };
};

export type CreateVehicleMutationVariables = Exact<{
  input: CreateVehicleAdminInput;
}>;

export function useCreateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVehicleAdminMutation, CreateVehicleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVehicleAdminMutation, CreateVehicleMutationVariables>(
    createVehicleAdminDocument,
    options
  );
}
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleAdminMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<
  CreateVehicleAdminMutation,
  CreateVehicleMutationVariables
>;

export type AddCompanyInput = {
  companyName: Scalars['String'];
};

export const CreateCompanyDocument = gql`
  mutation createCompany($input: AddCompanyInput!) {
    createCompany(input: $input) {
      idCompany
      companyName
    }
  }
`;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'Company';
    idCompany: string;
    companyName: string;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  input: AddCompanyInput;
}>;

export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreatCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreatCompanyMutation,
  CreatCompanyMutationVariables
>;

export const GetCompaniesDocument = gql`
  query getAllCompanies {
    getAllCompanies {
      idCompany
      companyName
    }
  }
`;

/**
 * __useGetCompaniessQuery__
 *
 * To run a query within a React component, call `useGetCompaniessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniessQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllCompaniesQuery, GetCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompaniesQuery, GetCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
}
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetAllCompaniesQuery, GetCompaniesQueryVariables>;

export type CompanySecretCodesInput = {
  companySecretCode: Scalars['String'];
  refCompanyName: Scalars['String'];
  codeExpiryDate: Scalars['DateTime'];
};

export const CreateCompanySecretCodeDocument = gql`
  mutation createCompanySecretCode($input: CompanySecretCodesInput!) {
    createCompanySecretCode(Input: $input) {
      idCompanySecretCode
      companySecretCode
      expiry_date
      refCompanyName
    }
  }
`;

export type CreateCompanySecretCodeMutation = {
  __typename?: 'Mutation';
  createCompanySecretCode: {
    __typename?: 'CompanySecretCodes';
    idCompanySecretCode: number;
    companySecretCode: string;
    refCompanyName: string;
    expiry_date: any;
  };
};

export type CreateCompanySecretCodeMutationVariables = Exact<{
  input: CompanySecretCodesInput;
}>;

export function useCreateCompanySecretCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanySecretCodeMutation, CreateCompanySecretCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanySecretCodeMutation, CreateCompanySecretCodeMutationVariables>(
    CreateCompanySecretCodeDocument,
    options
  );
}
export type CreateCompanySecretCodeMutationHookResult = ReturnType<typeof useCreateCompanySecretCodeMutation>;
export type CreateCompanySecretCodeMutationResult = Apollo.MutationResult<CreateCompanySecretCodeMutation>;
export type CreateSecretCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanySecretCodeMutation,
  CreateCompanySecretCodeMutationVariables
>;

export const CreatePromoCodeDocument = gql`
  mutation createPromoCode($input: CreatePromotionInputs!) {
    createPromoCode(data: $input) {
      success
      message
    }
  }
`;

export type CreatePromoCodeMutation = {
  __typename?: 'Mutation';
  createPromoCode: { __typename?: 'SuccessResponse'; success: boolean };
};

export type CreatePromoCodeMutationVariables = Exact<{
  input: CreatePromotionInputs;
}>;

export function useCreatePromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(
    CreatePromoCodeDocument,
    options
  );
}
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = Apollo.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = Apollo.BaseMutationOptions<
  CreatePromoCodeMutation,
  CreatePromoCodeMutationVariables
>;

export const UpdatePromoCodeDocument = gql`
  mutation updatePromoCode($input: UpdatePromotionInputs!) {
    updatePromoCode(data: $input) {
      success
      message
    }
  }
`;

export type UpdatePromoCodeMutation = {
  __typename?: 'Mutation';
  updatePromoCode: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UpdatePromoCodeMutationVariables = Exact<{
  input: UpdatePromotionInputs;
}>;

export function useUpdatePromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>(
    UpdatePromoCodeDocument,
    options
  );
}
export type UpdatePromoCodeMutationHookResult = ReturnType<typeof useUpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationResult = Apollo.MutationResult<UpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdatePromoCodeMutation,
  UpdatePromoCodeMutationVariables
>;

export const AssignCustomerUserPromoDocument = gql`
  mutation assignCustomerPromo($input: CustomerUserPromotionInputs!) {
    assignCustomerPromo(input: $input) {
      success
      message
    }
  }
`;

export type AssignCustomerUserPromoMutation = {
  __typename?: 'Mutation';
  assignCustomerPromo: { __typename?: 'SuccessResponse'; success: boolean };
};

export type AssignCustomerUserPromoMutationVariables = Exact<{
  input: CustomerUserPromotionInputs;
}>;

export function useAssignCustomerUserPromoMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignCustomerUserPromoMutation, AssignCustomerUserPromoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignCustomerUserPromoMutation, AssignCustomerUserPromoMutationVariables>(
    AssignCustomerUserPromoDocument,
    options
  );
}
export type AssignCustomerUserPromoMutationHookResult = ReturnType<typeof useAssignCustomerUserPromoMutation>;
export type AssignCustomerUserPromoMutationResult = Apollo.MutationResult<AssignCustomerUserPromoMutation>;
export type AssignCustomerUserPromoMutationOptions = Apollo.BaseMutationOptions<
  AssignCustomerUserPromoMutation,
  AssignCustomerUserPromoMutationVariables
>;


export const UnAssignCustomerUserPromoDocument = gql`
  mutation unAssignCustomerPromo($input: CustomerUserPromotionInputs!) {
    unAssignCustomerPromo(inputs: $input) {
      success
      message
    }
  }
`;

export type UnAssignCustomerUserPromoMutation = {
  __typename?: 'Mutation';
  unAssignCustomerPromo: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UnAssignCustomerUserPromoMutationVariables = Exact<{
  input: CustomerUserPromotionInputs;
}>;

export function useUnAssignCustomerUserPromoMutation(
  baseOptions?: Apollo.MutationHookOptions<UnAssignCustomerUserPromoMutation, UnAssignCustomerUserPromoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnAssignCustomerUserPromoMutation, UnAssignCustomerUserPromoMutationVariables>(
    UnAssignCustomerUserPromoDocument,
    options
  );
}
export type UnAssignCustomerUserPromoMutationHookResult = ReturnType<typeof useAssignCustomerUserPromoMutation>;
export type UnAssignCustomerUserPromoMutationResult = Apollo.MutationResult<UnAssignCustomerUserPromoMutation>;
export type UnAssignCustomerUserPromoMutationOptions = Apollo.BaseMutationOptions<
  UnAssignCustomerUserPromoMutation,
  UnAssignCustomerUserPromoMutationVariables
>;


export const GetCustomerAssignedPromotions = gql`
  query getCustomerAssignedPromotion($customerId: String!) {
    getCustomerAssignedPromotion(refIdCustomerUser: $customerId) {
      promotionId
    }
  }
`;

export const DeletePromoCodeDocument = gql`
  mutation deletePromoCode($idPromotion: Float!) {
    deletePromoCode(idPromotion: $idPromotion) {
      success
      message
    }
  }
`;

export type DeletePromoCodeMutation = {
  __typename?: 'Mutation';
  deletePromoCode: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeletePromoCodeMutationVariables = Exact<{
  idPromotion?: Scalars['Float'];
}>;

export function useDeletePromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>(
    DeletePromoCodeDocument,
    options
  );
}
export type DeletePromoCodeMutationHookResult = ReturnType<typeof useDeletePromoCodeMutation>;
export type DeletePromoCodeMutationResult = Apollo.MutationResult<DeletePromoCodeMutation>;
export type DeletePromoCodeMutationOptions = Apollo.BaseMutationOptions<
  DeletePromoCodeMutation,
  DeletePromoCodeMutationVariables
>;
export const CreateSupplierAdminDocument = gql`
  mutation createSupplier($input: CreateSupplierInputs!) {
    createSupplierAdmin(input: $input) {
      idSupplier
      email
      name
      type
      mobileNumber
      taxiSupplierColor {
        idColor
        color
        type
      }
    }
  }
`;
export type CreateSupplierAdminMutationFn = Apollo.MutationFunction<
  SupplierEntity,
  CreateSupplierAdminMutationVariables
>;

/**
 * __useCreateSupplierAdminMutation__
 *
 * To run a mutation, you first call `useCreateSupplierAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierAdminMutation, { data, loading, error }] = useCreateSupplierAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<SupplierEntity, CreateSupplierAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SupplierEntity, CreateSupplierAdminMutationVariables>(CreateSupplierAdminDocument, options);
}
export type CreateSupplierAdminMutationHookResult = ReturnType<typeof useCreateSupplierAdminMutation>;
export type CreateSupplierAdminMutationResult = Apollo.MutationResult<SupplierEntity>;
export type CreateSupplierAdminMutationOptions = Apollo.BaseMutationOptions<
  SupplierEntity,
  CreateSupplierAdminMutationVariables
>;

export const GetAllEmiratesDocument = gql`
  {
    getAllEmirates {
      id
      name
      country
    }
  }
`;

export const GetTaxiesAndColorsDocument = gql`
  {
    getTaxiesAndColors {
      idColor
      color
      type
    }
  }
`;

export type GetSuppliersQueryVariables = Exact<{
  input: ListSuppliersInputs;
}>;

export type GetAllSuppliersQueryVariables = Exact<{
  input: ListSuppliersInputs;
}>;

export type GetAllSuppliersQuery = {
  __typename?: 'Query';
  getSuppliersAdmin: {
    results: Array<{
      __typename?: 'supplier';
    }>;
    totalRows: Scalars['Int'];
  };
};

export type GetSuppliersQuery = {
  __typename?: 'Query';
  getSuppliers: {
      __typename?: 'supplier';
  };
};

export const GetAllSuppliersDocument = gql`
  query getSuppliers {
    getSuppliers {
      idSupplier
      email
      name
      type
      mobileNumber
      bankDetails {
        accName
        IBAN
      }
      taxiSupplierColor {
        type
        color
        idColor
      }
      address {
        area
        idAddress
      }
      identityDocuments {
        frontImageUrl
        backImageUrl
      }
    }
  }
`;

export function useGetAllSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetAllSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSuppliersQuery, GetAllSuppliersQueryVariables>(GetAllSuppliersDocument, options);
}

export const GetSuppliersDocument = gql`
  query getSuppliersAdmin($input: ListSuppliersInputs!) {
    getSuppliersAdmin(input: $input) {
      results {
        idSupplier
        email
        name
        type
        mobileNumber
        licenceNo
        startDate
        taxiSupplierColor {
          color
          type
          idColor
        }
        bankDetails {
          accName
          bankName
          IBAN
          bankAccNo
        }
        address {
          area
          idAddress
          addressNo
          city
          street
        }
        identityDocuments {
          idDocument
          frontImageUrl
          backImageUrl
        }
      }
      totalRows
    }
  }
`;
export function useGetSuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllSuppliersQuery, GetSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
}
export function useGetSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllSuppliersQuery, GetSuppliersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
}
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<GetAllSuppliersQuery, GetSuppliersQueryVariables>;

export const UseSendSupplierOTPDocument = gql`
  mutation sentOTP($input: SendOtpInput!) {
    sendSupplierOTP(input: $input) {
      sid
      otpCreationDate
    }
  }
`;

export const UseVerifyOTPOnCreateSupplierDocument = gql`
  mutation verifyOTP($input: OtpVerificationInput!) {
    verifyOTPOnCreateSupplier(input: $input) {
      success
    }
  }
`;

export const DeleteSupplierDocument = gql`
  mutation deleteSupplier($idSupplier: String!){
    deleteSupplier(idSupplier: $idSupplier){
      success
      message
    }
  }
`;

export type DeleteSupplierMutation = {
  __typename?: 'Mutation';
  deleteSupplier: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteSupplierMutationVariables = Exact<{
  idSupplier?: Scalars['String'];
}>;

export function useDeleteSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(
    DeleteSupplierDocument,
    options
  );
}
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = Apollo.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
>;

export const UpdateSupplierDocument = gql`
  mutation updateSupplier($input: CreateSupplierInputs!, $supplierId: String!) {
    updateSupplier(input: $input, supplierId: $supplierId) {
      name
      email
    }
  }
`;

export type UpdateSupplierMutation = {
  __typename?: 'Mutation';
  updateSupplier: { __typename?: 'SupplierEntity'; SupplierEntity: SupplierEntity };
};

export type UpdateSupplierMutationVariables = Exact<{
  supplierId?: Scalars['String'];
  input: CreateSupplierInputs;
}>;

export function useUpdateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(
    UpdateSupplierDocument,
    options
  );
}
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;

export const GetAdminDistanceTimeEstimationsDocument = gql`
  query getAdminDistanceTimeEstimations($input: [DistanceTimeEstimationsInput!]!) {
    getAdminDistanceTimeEstimations(input: $input) {
      distance
      duration
    }
  }
`;
