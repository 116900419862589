export default {
  addFare: {
    numberFieldError: 'Only positive value is allowed',
    baseNightShiftError: 'Night Shift is required',
    baseMorningShiftError: 'Morning Shift is required',
    bookingFeeError: 'Booking fare is required',
    farePerMinError: 'Min fare is required',
    farePerKmError: 'MK fare is required',
    areaIdError: 'Area Or location is required',
    surgeFareError: 'Surge Fare is required',
  },
};
