import { createContext, useEffect, useState } from 'react';
import SearchHeader from 'common/search-header';
import UserListTable from 'components/user-management/userListTable';
import { makeStyles } from '@mui/styles';
import AddAdmin from 'components/user-management/addAdmin';
import { useGetDepartmentsListQuery, useGetUserRoleListQuery } from 'gql/generated/query.graphql';
import { SUBJECTS } from 'common/casl/ability';

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

const searchListKeys = [
  {
    value: 'emailId',
    label: 'Email',
  },
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'mobileNumber',
    label: 'Phone',
  },
];

export type UserAdminContextType = {
  roleList: any[];
  departmentList: any[];
};

export const UserAdminContext = createContext<UserAdminContextType | null>(null);

function UserAdminList() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState({});
  const [roleList, setRoleList] = useState([] as any);
  const [departmentList, setDepartmentList] = useState([] as any);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: roleData, loading: roleLoading } = useGetUserRoleListQuery({
    variables: {
      input: {
        limit: 100,
        offset: 0,
      },
    },
  });

  useEffect(() => {
    if (!roleLoading && roleData && roleData?.getUserRoleList) {
      setRoleList(roleData?.getUserRoleList?.results);
    }
  }, [roleData]);

  const { data: departmentData, loading: deptLoading } = useGetDepartmentsListQuery();
  useEffect(() => {
    if (!deptLoading && departmentData && departmentData?.getDepartmentsList) {
      setDepartmentList(departmentData?.getDepartmentsList);
    }
  }, [departmentData]);

  return (
    <UserAdminContext.Provider value={{ roleList, departmentList }}>
      <div className={classes.tableGrid}>
        <SearchHeader
          title={'All Users / Admin Users'}
          buttonText={'Add User'}
          handleOpen={handleOpen}
          showButton={true}
          disabledButton={false}
          setSearchInput={setSearchInput}
          searchListKeys={searchListKeys}
          subject={SUBJECTS.USER_MANAGEMENT.toString()}
        />
        <UserListTable searchInput={searchInput} />
        {open && <AddAdmin open={open} handleClose={handleClose} title={'Add New User'} />}
      </div>
    </UserAdminContext.Provider>
  );
}

export default UserAdminList;
