import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Dialog, TextField } from '@mui/material';
import { DARK_GREY, LIGHT_GREY, GRAY_86 } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import InputField from 'common/forms/InputField';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCreateAdminUserMutation, GetAdminUsersListDocument } from 'gql/generated/query.graphql';
import { UserAdminContext, UserAdminContextType } from 'pages/users-management';
import { RegEx } from 'utils/Regex';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  uploadButton: {
    background: LIGHT_GREY,
    borderRadius: 5,
    height: 52,
    display: 'grid',
    alignItems: 'center',
    paddingLeft: 10,
    marginTop: 10,
    color: DARK_GREY,
  },
});

interface IFormInput {
  firstName: string;
  lastName: string;
  emailId: string;
  mobileNumber: number;
  pwd: string;
  mediaUrl: string;
}

const FILE_SIZE = '5000000';
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  emailId: Yup.string().email().required('Email is required'),
  mobileNumber: Yup.string()
    .required('Phone Number is required*')
    .matches(RegEx.UAE_MOBILE_NUMBER, 'Enter a valid phone number'),
  pwd: Yup.string()
    .required('Password is required')
    .matches(RegEx.PWD, 'Password is invalid')
    .min(6, 'Password must be at least 6 characters'),
  refIdAdminUserRoles: Yup.number().integer().required('Role is required'),
  refIdAdminDepartment: Yup.number().integer().required('Department is required'),
  // mediaUrl: Yup.mixed()
  //   .nullable()
  //   .notRequired()
  //   .test('FILE_SIZE', 'Image Is A Required Field', (value) => {
  //     return !value || value.length > 0;
  //   })
  //   .test('FILE_SIZE', 'Image file is too big.', (value) => {
  //     return !value || (value && value.length > 0 && value[0].size <= FILE_SIZE);
  //   })
  //   .test(
  //     'FILE_FORMAT',
  //     'Image file has unsupported format.',
  //     (value) => !value || (value && value.length > 0 && SUPPORTED_FORMATS.includes(value[0].type))
  //   ),
});

function AddUser({
  open,
  handleClose,
  title,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
}): React.ReactElement {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { roleList, departmentList } = useContext(UserAdminContext) as UserAdminContextType;
  const [image, setImage] = useState<any>();

  const {
    firstName: firstNameErrorMsg,
    lastName: lastNameErrorMsg,
    emailId: emailErrorMsg,
    mobileNumber: phoneErrorMsg,
    pwd: passwordErrorMsg,
    // mediaUrl: uploadimgErrorMsg,
    refIdAdminUserRoles: roleErrMsg,
    refIdAdminDepartment: departmentErrMsg,
  } = errors;

  const [addADminUser] = useCreateAdminUserMutation({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
    onCompleted() {
      handleClose();
    },
  });
  const onSave = (data: any) => {
    // console.log(data);
    addADminUser({
      variables: {
        input: {
          emailId: data.emailId,
          firstName: data.firstName,
          lastName: data.lastName,
          mediaUrl: data.mediaUrl,
          mobileNumber: data.mobileNumber,
          pwd: btoa(data.pwd),
          refIdAdminDepartment: data.refIdAdminDepartment,
          refIdAdminUserRoles: data.refIdAdminUserRoles,
        },
      },
      refetchQueries: [GetAdminUsersListDocument, 'getAdminUsersList'],
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)} autoComplete='off'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <React.Fragment>
            <div className={classes.formGrid}>
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='firstName'
                placeholder='First Name'
                name='firstName'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='firstName'
                autoFocus
                helperText={firstNameErrorMsg?.message}
                error={firstNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='lastName'
                placeholder='Last Name'
                id='lastName'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='lastName'
                helperText={lastNameErrorMsg?.message}
                error={lastNameErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='email'
                placeholder='Email'
                name='emailId'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                autoComplete='emailId'
                helperText={emailErrorMsg?.message}
                error={emailErrorMsg ? true : false}
              />
              <InputField
                control={control}
                margin='normal'
                fullWidth
                name='mobileNumber'
                placeholder='Phone'
                type='tel'
                id='mobileNumber'
                InputProps={{ disableUnderline: true }}
                variant='filled'
                autoComplete='mobile'
                helperText={phoneErrorMsg?.message}
                error={phoneErrorMsg ? true : false}
              />

              <Controller
                control={control}
                name='refIdAdminUserRoles'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disablePortal
                    id='select-role-textbox'
                    size='small'
                    fullWidth
                    popupIcon={<KeyboardArrowDownIcon />}
                    options={roleList}
                    getOptionLabel={(option: any) => option.roleName}
                    onChange={(event, item) => {
                      onChange(item?.idAdminUserRoles);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='filled'
                        InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                        label='Role'
                        helperText={roleErrMsg?.message}
                        error={roleErrMsg ? true : false}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name='refIdAdminDepartment'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disablePortal
                    id='select-dept-textbox'
                    size='small'
                    fullWidth
                    popupIcon={<KeyboardArrowDownIcon />}
                    options={departmentList}
                    getOptionLabel={(option: any) => option.departmentName}
                    onChange={(event, item) => {
                      onChange(item?.idAdminDepartment);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='filled'
                        InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                        label='Department'
                        helperText={departmentErrMsg?.message}
                        error={departmentErrMsg ? true : false}
                      />
                    )}
                  />
                )}
              />

              <InputField
                control={control}
                margin='normal'
                fullWidth
                id='pwd'
                placeholder='Password'
                name='pwd'
                variant='filled'
                InputProps={{ disableUnderline: true }}
                type='password'
                autoComplete='password'
                helperText={passwordErrorMsg?.message}
                error={passwordErrorMsg ? true : false}
              />

              {/* <input
                accept='image/*'
                type='file'
                // {...register('mediaUrl')}
                id='select-image'
                style={{ display: 'none' }}
              /> */}
              {/* <label htmlFor='select-image'>
                <div className={classes.uploadButton}>{image ? image : 'Upload picture'}</div>
              </label> */}
              <div className={classes.actionBtn}>
                <Button size='medium' type='submit'>
                  Save
                </Button>
                <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    </Dialog>
  );
}

export default AddUser;
