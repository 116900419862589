const muiChip = {
  MuiChip: {
    styleOverrides: {
      root: {
        height: 40,
        fontSize: 18,
      },
    },
  },
};

export default muiChip;
