import { makeStyles } from '@mui/styles';
import { ALTO, GRAY_66, GRAY_83, GRAY_90, LIGHT_SHADE_GRAY } from 'common/theme/colors';

export default makeStyles({
  customTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    fontSize: '13px',
    position: 'relative',
    paddingLeft: '12px',
    overflowY: 'auto',
    '& .table--wrapper': {
      border: '1px solid',
      borderColor: GRAY_83,
      backgroundColor: GRAY_90,
      borderTop: 'none',
      flex: '1 1 auto',
    },
    '& .table--head': {
      position: 'absolute',
      minHeight: '50px',
      borderBottom: `1px solid ${GRAY_83}`,
      width: 'calc(100% - 15px)',
    },
    '& .table--body': {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'column',
      border: 0,
      borderTop: '1px solid',
      borderColor: ALTO,
      paddingTop: '50px',
    },
    '& .row': {
      display: 'flex',
      flex: '0 0 45px',
      '&.table--head': {
        '& .column': {
          opacity: '0.7',
          fontWeight: 'normal',
          border: 'none',
        },
      },
      '&.active': {
        backgroundColor: LIGHT_SHADE_GRAY,
        border: '1px solid',
        borderColor: ALTO,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        // '& .column': {
        //   border: 'none',
        //   '&:first-child': {
        //     border: 'none',
        //     borderRight: '1px solid',
        //     borderColor: GRAY_66,
        //   },
        // },
        '&:before': {
          content: '""',
          borderStyle: 'solid',
          borderWidth: '22px 13px 24px 0',
          borderColor: `transparent ${LIGHT_SHADE_GRAY} transparent transparent`,
          left: '-13px',
          position: 'absolute',
          zIndex: 1,
        },
        '&:after': {
          content: '""',
          display: 'block',
          height: 0,
          overflow: 'hidden',
        },
      },
      '& .column': {
        flex: '1 1',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: GRAY_83,
        borderTop: 0,
        borderLeft: 0,
        paddingLeft: '30px',
        '&:first-child': {
          flex: '1.5',
          fontWeight: 600,
        },
        '&:last-child': {
          borderRight: 0,
        },
      },
    },
    '&.fare--table': {
      '& .table--body': {
        border: '1px solid',
        borderColor: GRAY_66,
      },
    },
  },
});
