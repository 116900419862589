import muiButton from './button';
import muiOutlinedInput from './muiOutlinedInput';
import muiRating from './rating';
import muiFabButton from './fabButton';
import muiChip from './chip';
import muiAlert from './alert';
import muiFilledInput from './muiFilledInput';

export const overrides = {
  components: {
    ...muiButton,
    ...muiOutlinedInput,
    ...muiFilledInput,
    ...muiRating,
    ...muiFabButton,
    ...muiChip,
    ...muiAlert,
  },
};
