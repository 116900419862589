export const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  button: string,
  openModal: boolean,
  setOpenModal: (value: boolean) => void,
  setAnchorElement: (value: HTMLElement) => void
) => {
  if (button === 'settings') {
    setAnchorElement(event.currentTarget);
    setOpenModal(!openModal);
  }
};

export const handleClose = (
  openModal: boolean,
  setOpenModal: (value: boolean) => void,
  setAnchorElement: (value: null) => void,
) => {
  setAnchorElement(null);
  setOpenModal(!openModal);
};

export const handleLogoutClick = (
  openModal: boolean,
  history: (value: string) => void,
  setOpenModal: (value: boolean) => void,
  isLoggedInVar: (value: boolean) => void
) => {
  localStorage.removeItem('token');
  localStorage.removeItem('admin');
  localStorage.removeItem('SUBJECTS');
  isLoggedInVar(false);
  setOpenModal(!openModal);
  history('/login');
};
