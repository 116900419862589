export const searchListKeys = [
  {
    value: 'Vehicle name',
    label: 'Vehicle Name',
  },
  {
    value: 'Vehicle number',
    label: 'Vehicle Number',
  },
];
