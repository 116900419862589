import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

export default function IconBreadcrumbs(props:any) {
  let navigate = useNavigate();
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' ,fontSize:20, fontWeight:400,cursor:'pointer'}}
          color="inherit"
          onClick={()=>navigate("/admin/users", { replace: true })}
        >
          {props.heading}
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {props?.userData}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
