import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DriverEntity } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  profileGrid: {
    display: 'grid',
    gridRowGap: 10,
  },
  contactGrid: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  contactInfoGrid: {
    display: 'grid',
    gridRowGap: 10,
  },
  badgeGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    width: 250,
  },
  badgeImage: {
    width: 50,
  },
});

function DriverProfileInfo({ data }: { data: DriverEntity }) {
  const classes = useStyles();

  return (
    <div className={classes.profileGrid}>
      <Typography noWrap fontWeight={500} variant='h4'>
        Profile
      </Typography>
      <Typography noWrap fontWeight={500} variant='h5'>
        About
      </Typography>
      <Typography>{data?.driverDetails?.about ? data.driverDetails.about : ''}</Typography>
      <div className={classes.contactGrid}>
        <div className={classes.contactInfoGrid}>
          <Typography fontWeight={500}>Contact info</Typography>
          <Typography fontWeight={400}>{data?.emailId ? data.emailId : ''}</Typography>
          <Typography fontWeight={400}>{data?.mobileNumber ? '+' + data.mobileNumber : ''}</Typography>
        </div>
        <div className={classes.contactInfoGrid}>
          <Typography fontWeight={500}>Experience</Typography>
          <Typography fontWeight={400}>
            {data?.driverDetails?.experience ? data.driverDetails.experience : ''}
          </Typography>
        </div>
        <div className={classes.contactInfoGrid}>
          <Typography fontWeight={500}>Awards and Badges</Typography>
          <div className={classes.badgeGrid}>
            {data?.badges && data?.badges.length > 0
              ? data.badges.map((badge) => (
                  <img
                    key={badge.idDriverUserBadges}
                    src={`/images/driverBadge4.svg`}
                    alt={badge.refIdBadgeUser.badgeName}
                    className={classes.badgeImage}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverProfileInfo;
