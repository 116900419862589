import Grid from '@mui/material/Grid';
import CustomerCard from './CustomerCard';
import { Journey } from 'gql/generated/query.graphql';

function CustomerCompeletedRideTable({ completedRides, rideLoading }: { completedRides: Journey[], rideLoading: boolean }) {
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {completedRides.length ? completedRides.map((completedRide: Journey) => (
        <Grid item xs={12} sm={8} md={6} key={completedRide.idJourney}>
          <CustomerCard ride={completedRide} />
        </Grid>
      )) : <Grid item xs={12} sm={8} md={6}>
          Loading....
      </Grid>
      }
    </Grid>
  );
}

export default CustomerCompeletedRideTable;
