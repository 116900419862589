import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ModalProps } from './types';
import useStyles from './styled';

const ModalDialog: React.FC<ModalProps> = ({
  open,
  title = null,
  children,
  isDeleteModal,
  cancelName = 'Cancel',
  submitName = 'Save',
  onClose,
  onSubmit,
  width= '600px',
}: ModalProps) => {
  const { modalDialog, deleteModal } = useStyles();
  return (
    <Dialog  className={`${modalDialog} ${isDeleteModal ? deleteModal : ''}` } open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{maxWidth: width}}>{children}</DialogContent>
      <DialogActions>
        {submitName && <Button type='submit' size="medium" onClick={onSubmit}>{submitName}</Button>}
        {cancelName && <Button size="medium" onClick={onClose}>{cancelName}</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
