import React, { useContext, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GRAY_86 } from 'common/theme/colors';
import { InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { AbilityContext } from 'common/casl/abilityContext';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles({
  headerGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchGrid: {
    display: 'grid',
    gridTemplateColumns: '350px 50px',
  },
  searchDropIcon: {
    background: GRAY_86,
    // display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    height: 45,
    // width: 50,
    marginRight: 14,
  },
  searchButtonGrid: {
    display: 'grid',
    gridTemplateColumns: '300px auto',
    gridColumnGap: 10,
    alignItems: 'center',
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchBox: {
    height: 40,
  },
});

const SearchHeader = ({
  title,
  buttonText,
  handleOpen,
  handleOpenInviteModal,
  onChangeInput,
  showButton,
  showSearch = true,
  disabledButton = false,
  setSearchInput,
  searchListKeys = [],
  subject,
}: {
  title: string;
  buttonText?: string;
  handleOpen?: () => void;
  handleOpenInviteModal?: () => void;
  setSearchInput?: any;
  onChangeInput?: any;
  showButton?: boolean;
  showSearch?: boolean;
  disabledButton?: boolean;
  searchListKeys?: any | [];
  subject: string;
}): React.ReactElement => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState(searchListKeys[0]?.value);
  const ability = useContext(AbilityContext);
  const handleChange = (event: any) => {
    setSearchInput({ [`${searchKey}`]: event.target.value });
  };

  return (
    <div className={classes.headerGrid}>
      <Typography fontWeight={500} variant='h5'>
        {title}
      </Typography>
      <div className={classes.searchWrapper}>
        {showSearch && (
          <>
            <TextField
              id='search-box'
              variant='outlined'
              placeholder='Search'
              size='small'
              onChange={handleChange}
              className={classes.searchBox}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              style={{ height: 37 }}
              className={classes.searchDropIcon}
            >
              {searchListKeys.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {showButton && ability.can('create', subject) && (
          <Fab
            variant='extended'
            size='medium'
            color='primary'
            aria-label='add'
            onClick={handleOpen}
            sx={{ textTransform: 'none' }}
            disabled={disabledButton}
          >
            <AddIcon />
            {buttonText}
          </Fab>
        )}
      </div>
    </div>
  );
};

export default SearchHeader;
