import { makeStyles } from '@mui/styles';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box, Drawer as MuiDrawer, Typography, IconButton } from '@mui/material';
import { MILD_GREY, SILVER } from 'common/theme/colors';
import { Menu, MenuLinksKey } from './Menu';
import DrawerProvider from './drawerProvider';
import { MenuItems } from './menuItems';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';

export type MenuItemType = {
  title: string;
  icon?: string;
  items?: MenuItemType[];
  link?: string;
  type: MenuLinksKey;
  disable?: boolean;
};

const drawerWidth = 360;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: '0 0 10px #9b8f8f',
  backgroundColor: MILD_GREY,
  zIndex: '1000',
  padding: '0 15px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
    backgroundColor: MILD_GREY,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  left: '0',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStyles = makeStyles({
  root: {
    '& > div > div.menuOpened': {
      backgroundColor: SILVER,
      borderRadius: '5px',
    },
    '& .MuiList-root img.submenuicon': {
      display: 'none',
    },
    '& div.MuiPaper-root > div.menuOpened > img.submenuicon': {
      display: 'block',
    },
    '& .MuiTouchRipple-child': {
      color: 'transparent',
    },
    '& .textdecor-none': {
      textDecoration: 'none',
    },
  },
});
export default function Sidebar({
  openDrawer,
  clickToggle,
}: {
  openDrawer: boolean;
  clickToggle: () => void;
}): React.ReactElement {
  const menuLists: MenuItemType[] = Menu;
  const { root } = useStyles();
  const admin = localStorage.getItem('admin');
  return (
    <DrawerProvider isDrawerOpen={openDrawer}>
      <Drawer variant='permanent' open={openDrawer} className={openDrawer ? root : ''}>
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' textAlign='center'>
          {openDrawer ? (
            <Box sx={{ py: '40px' }}>
              <img src={`/images/userimg.png`} alt='' />
              <Typography variant='h6'>{admin ? JSON.parse(admin).firstName : 'Admin'}</Typography>
              <Typography variant='body2'>Admin</Typography>
            </Box>
          ) : (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={clickToggle}
              edge='start'
              sx={{ marginTop: '10px', marginBottom: '20px' }}
            >
              <img style={{ maxWidth: '100%' }} src={`/images/menu.png`} alt='logo' loading='lazy' />
            </IconButton>
          )}
        </Box>
        {menuLists.map((item: MenuItemType, key: number) => {
          console.log('item?.type', item?.type);
          return (
            <Can I='read' a={String(SUBJECTS[item?.type ?? ''])} key={key}>
              <MenuItems listItems={item} />
            </Can>
          );
        })}
      </Drawer>
    </DrawerProvider>
  );
}
