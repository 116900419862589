import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import Rating from '@mui/material/Rating';
import Box from '@mui/system/Box';
import { makeStyles } from '@mui/styles';
import TableActionComponent from 'components/driver-management/TableActionComponent';
import { useGetDriversAdminLazyQuery } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
  },
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

function DriverListTable({ searchInput }: { searchInput: any }) {
  const classes = useStyles();
  const [driversData, setDriversData] = useState([] as any);

  const [totalRows, setTotalRows] = useState(0 as any);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [getDriversAdmin, { data: driverAdminData, loading: isLoading }] = useGetDriversAdminLazyQuery();

  useEffect(() => {
    getDriversAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!isLoading && driverAdminData && driverAdminData.getDriversAdmin) {
      let arrData: any = [];
      driverAdminData?.getDriversAdmin.results.forEach((element: any) => {
        arrData.push({ ...element, id: element['idDriverUser'] });
      });
      setDriversData(arrData);
      setTotalRows(driverAdminData?.getDriversAdmin.totalRows);
    }
  }, [driverAdminData]);

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'mobileNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'idMeter',
      headerName: 'Meter Id',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'idDriverEmp',
      headerName: 'Driver Employee Id',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'licensePlate',
      headerName: 'Driver Vehicle',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) =>  params.row?.driverDetails?.refIdVehicle?.licensePlate,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) =>  params.row?.startDate ? new Date(params.row.startDate).toDateString() : null,
    },
    {
      field: 'profile',
      headerName: 'Profile',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.mediaUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.mediaUrl)}
          src={params.row?.mediaUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Profile image'
          loading='lazy'
        />
      ),
    },
    {
      field: 'emiratesIdFront',
      headerName: 'Emirates Id Front',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[0]?.frontImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[0]?.frontImageUrl)}
          src={params.row?.identityDocuments[0]?.frontImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Emirates Id Front'
          loading='lazy'
        />
      ),
    },
    {
      field: 'emiratesIdBack',
      headerName: 'Emirates Id Back',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[0]?.backImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[0]?.backImageUrl)}
          src={params.row?.identityDocuments[0]?.backImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Emirates Id Front'
          loading='lazy'
        />
      ),
    },
    {
      field: 'RTAPermit',
      headerName: 'RTA Permit Front',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[1]?.frontImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[1]?.frontImageUrl)}
          src={params.row?.identityDocuments[1]?.frontImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='RTA Permit'
          loading='lazy'
        />
      ),
    },
    {
      field: 'RTAPermitBack',
      headerName: 'RTA Permit Back',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[1]?.backImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[1]?.backImageUrl)}
          src={params.row?.identityDocuments[1]?.backImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='Emirates Id Front'
          loading='lazy'
        />
      ),
    },
    {
      field: 'license',
      headerName: 'license Front',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[2]?.frontImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[2]?.frontImageUrl)}
          src={params.row?.identityDocuments[2]?.frontImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='license'
          loading='lazy'
        />
      ),
    },
    {
      field: 'licenseBack',
      headerName: 'license Back',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.identityDocuments[2]?.backImageUrl,
      renderCell: (params: any) => (
        <img
          onClick={() => window.open(params.row?.identityDocuments[2]?.backImageUrl)}
          src={params.row?.identityDocuments[2]?.backImageUrl}
          style={{ objectFit: 'cover', width: 100, height: 100, cursor: 'pointer' }}
          alt='license Back'
          loading='lazy'
        />
      ),
    },
    {
      field: 'rating',
      headerName: 'Rating',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row.driverDetails?.rating,
      renderCell: (params: GridRenderCellParams<number>) => (
        <div className={classes.ratingGrid}>
          <div>{params.row.driverDetails?.rating || ''}</div>
          <Rating
            value={params.row.driverDetails?.rating || 0}
            size='small'
            readOnly
            precision={0.5}
            className={classes.ratingStyle}
          />
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TableActionComponent params={params.row} />
      ),
      sortable: false,
    },
  ];

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={driversData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={isLoading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
      />
    </Box>
  );
}

export default DriverListTable;
