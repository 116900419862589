import React, { useEffect, useState } from 'react';
import DriverDetails from 'components/driver-management/DriverDetails';
import DriverSearch from 'components/driver-management/DriverSearch';
import { makeStyles } from '@mui/styles';
import DriverUpComingTable from 'components/driver-management/DriverUpcomingTable';
import DriverProfileInfo from 'components/driver-management/DriverProfileInfo';
import DriverCompeletedRideTable from 'components/driver-management/DriverCompeletedRideTable';
import { useLocation } from 'react-router-dom';
import {
  useGetDriverAdminQuery,
  useGetAdminDriverRidesQuery,
  Ride_Listing_Classification,
  DriverEntity,
  RideDriver,
} from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  mainGrid: {
    display: 'grid',
    gridRowGap: 30,
  },
});

interface LocationStateType {
  state: {
    driverId: string;
  };
}
const DEFAULT_LIMIT = 5;
const DEFAULT_PAGE = 0;
const searchListKeys = [
  {
    value: 'journeyId',
    label: 'ID',
  },
];

function DriverProfile() {
  const classes = useStyles();
  const [driverData, setDriverData] = useState<DriverEntity | any>({});
  const [isUpComingRide, setIsUpComingRide] = useState<Boolean>(true);
  const [upcomingRides, setUpcomingRides] = useState<RideDriver[]>([]);
  const [totalUpcomingRows, setTotalUpcomingRows] = useState<number>(0);
  const [totalCompletedRows, setTotalCompletedRows] = useState<number>(0);
  const [completedRides, setCompletedRides] = useState<RideDriver[]>([]);
  const [searchInput, setSearchInput] = useState({ journeyId: null });
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const {
    state: { driverId },
  } = useLocation() as unknown as LocationStateType;
  const getRideStatus = (rideStatus: boolean) => {
    setIsUpComingRide(rideStatus);
  };

  const { loading, data }: { loading: boolean; data: any } = useGetDriverAdminQuery({
    variables: {
      driverId,
    },
  });
  const { loading: rideLoading, data: ridesData }: { loading: boolean; data: any } = useGetAdminDriverRidesQuery({
    variables: {
      input: {
        userId: driverId,
        category: isUpComingRide ? Ride_Listing_Classification.Upcoming : Ride_Listing_Classification.Completed,
        limit,
        offset: page * limit,
        journeyId: searchInput.journeyId ? parseFloat(searchInput.journeyId) : null,
      },
    },
  });

  useEffect(() => {
    if (!loading && data && data.getDriverAdmin) {
      setDriverData(data.getDriverAdmin);
    }
    return () => {};
  }, [data, loading]);

  useEffect(() => {
    if (!rideLoading && ridesData && ridesData.getAdminDriverRides) {
      if (isUpComingRide) {
        setUpcomingRides(ridesData.getAdminDriverRides.results);
        setTotalUpcomingRows(ridesData.getAdminDriverRides.totalRows);
      } else {
        setCompletedRides(ridesData.getAdminDriverRides.results);
        setTotalCompletedRows(ridesData.getAdminDriverRides.totalRows);
      }
    }
    return () => {};
  }, [ridesData, rideLoading, limit, offset]);

  useEffect(() => {
    if (!isUpComingRide) {
      setLimit(totalCompletedRows);
      setPage(DEFAULT_PAGE);
    } else {
      setLimit(DEFAULT_LIMIT);
    }
    return () => {};
  }, [isUpComingRide, totalCompletedRows]);

  return (
    <div className={classes.mainGrid}>
      <DriverDetails data={driverData} />
      <DriverProfileInfo data={driverData} />
      <DriverSearch setRideStatus={getRideStatus} searchListKeys={searchListKeys} setSearchInput={setSearchInput} />
      {isUpComingRide ? (
        <DriverUpComingTable
          data={upcomingRides}
          totalRows={totalUpcomingRows}
          pageSize={limit}
          setPageSize={setLimit}
          setOffset={setOffset}
          setPage={setPage}
        />
      ) : (
        <DriverCompeletedRideTable data={completedRides} />
      )}
    </div>
  );
}

export default DriverProfile;
