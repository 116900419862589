import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { DRIVER_PROFILE } from 'routers/config';
import DeleteModal from 'common/deleteModal';
import UpdateUser from './UpdateUser';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';
import { GetDriverAdminDocument, useDeleteDriverAccountAdminMutation } from 'gql/generated/query.graphql';
import AddUser from './Add-user';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

function TableActionComponent({
  params,
  disabledShowButton = false,
  disabledEditButton = false,
  disabledDeleteButton = false,
}: {
  params: any;
  disabledShowButton?: boolean;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) {
  const classes = useStyles();
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isOpenDelete, setOpenDelete] = React.useState(false);

  const [deleteDriverAccountAdmin] = useDeleteDriverAccountAdminMutation();

  const handleClose = () => {
    setOpen(false);
  };
  const deleteUser = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const goToProfile = (event: React.MouseEvent) => {
    event.stopPropagation();
    history(DRIVER_PROFILE, { state: { driverId: params.idDriverUser } });
  };

  const openEditDialog = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const deleteSelectedDriver = async() => {
    await deleteDriverAccountAdmin({
      variables: {
        id: params.idDriverUser
      }, refetchQueries: [GetDriverAdminDocument, 'getDriversAdmin'],
    });
    setOpenDelete(false);
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='show' size='small' onClick={(e) => goToProfile(e)} disabled={disabledShowButton}>
          <VisibilityIcon />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog} disabled={disabledEditButton}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='delete' size='small' onClick={(e) => deleteUser(e)} disabled={disabledDeleteButton}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <AddUser open={editOpen} handleClose={handleEditClose} title='Update Driver' existingDriver={params} action='update' />
      <DeleteModal open={isOpenDelete} onCancel={() => setOpenDelete(false)} onSubmit={deleteSelectedDriver} />
    </div>
  );
}

export default TableActionComponent;
