export enum MenuLinksKey {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  USER_ROLE_MANAGEMENT = 'USER_ROLE_MANAGEMENT',
  KPI = 'KPI',
  HEAT_MAPS = 'HEAT_MAPS',
  CONFIGURATION = 'CONFIGURATION',
  BOOKING_MANAGEMENT = 'BOOKING_MANAGEMENT',
}

export const Menu = [
  {
    title: 'Users',
    icon: '/images/sidebaricon.png',
    type: MenuLinksKey.USER_MANAGEMENT,
    disable: true,
    items: [
      {
        title: 'All Users',
        icon: '/images/leftarrow.png',
        link: 'users',
        type: MenuLinksKey.USER_MANAGEMENT,
      },
      {
        title: 'Roles',
        link: 'roles',
        type: MenuLinksKey.USER_ROLE_MANAGEMENT,
      },
    ],
  },
  {
    title: 'KPI',
    icon: '/images/kpi.png',
    link: 'kpi',
    type: MenuLinksKey.KPI,
    disable: true,
  },
  {
    title: 'Heat Maps',
    link: 'heatmap',
    icon: '/images/heatmap.png',
    type: MenuLinksKey.HEAT_MAPS,
    disable: true,
  },
  {
    title: 'Configurations',
    link: '/',
    icon: '/images/settings.png',
    type: MenuLinksKey.CONFIGURATION,
    items: [
      {
        title: 'Mode Management',
        disable: true,
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        title: 'Music Management',
        link: 'music',
        disable: true,
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        title: 'Location Management',
        link: 'location',
        disable: true,
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        title: 'Area Management',
        link: 'area',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        title: 'Fare Management',
        link: 'fare',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'customer',
        title: 'Customer Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'driver',
        title: 'Driver Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'supplier',
        title: 'Supplier Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'Vehicle',
        title: 'Vehicle Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'driver-leave-management',
        title: 'Driver Leave Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'company',
        title: 'Company Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'secretCode',
        title: 'Secret Code Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'waitingListManagement',
        title: 'Waiting List Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'promoCode',
        title: 'Promo List Management',
        type: MenuLinksKey.CONFIGURATION,
      },
      {
        link: 'referralCode',
        title: 'Referrals List Management',
        type: MenuLinksKey.CONFIGURATION,
      },
    ],
  },
  {
    title: 'Booking Management',
    link: 'booking',
    icon: '/images/calendar.png',
    type: MenuLinksKey.BOOKING_MANAGEMENT,
    items: [
      {
        link: 'booking/assigned',
        title: 'Booking',
        type: MenuLinksKey.BOOKING_MANAGEMENT,
      },
      {
        link: 'booking-reschedule',
        title: 'ReSchedule Rides',
        type: MenuLinksKey.BOOKING_MANAGEMENT,
      },
    ],
  },
];
