import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import DeleteModal from 'common/deleteModal';
import { Can } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';
import { GetSuppliersDocument, useDeleteSupplierMutation } from 'gql/generated/query.graphql';
import AddSupplier from './AddSupplier';

const useStyles = makeStyles({
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
  actionImage: {
    width: 18,
  },
});

function TableActionComponent({
  params,
  disabledEditButton = false,
}: {
  params: any;
  disabledEditButton?: boolean;
  disabledDeleteButton?: boolean;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isOpenDelete, setOpenDelete] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [deleteSupplier] = useDeleteSupplierMutation();

  const deleteUser = () => {
    setOpenDelete(true);
  };

  const openEditDialog = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const deleteSelectedSupplier = () => {

     deleteSupplier({
      variables: {
        idSupplier: params?.idSupplier,
      },
        refetchQueries: [GetSuppliersDocument, 'getSuppliers'],
    });
    setOpenDelete(false);
  };

  return (
    <div className={classes.ActionGrid}>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='edit' size='small' onClick={openEditDialog}>
          <img src={`/images/editIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
        <IconButton aria-label='delete' size='small' onClick={deleteUser}>
          <img src={`/images/deleteIcon.svg`} alt='' className={classes.actionImage} />
        </IconButton>
      </Can>
      {editOpen && <AddSupplier open={editOpen} handleClose={handleEditClose} title={'Edit Supplier'} params={params} />}
      <DeleteModal open={isOpenDelete} onCancel={() => setOpenDelete(false)} onSubmit={deleteSelectedSupplier} />
    </div>
  );
}

export default TableActionComponent;
