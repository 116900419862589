import * as Yup from 'yup';
import { MouseEvent } from 'react';
import { AreaProps } from '../area-list/types';

const schema = Yup.object().shape({
  areaName: Yup.string()
    .required('Area name is required')
    .matches(/^[^\s]/, 'Cannot start area name with spaces'),
  areaTypeRefId: Yup.string().required('Area Type is required'),
  // areaMap: Yup.mixed().required('File is required'),
});

interface IFormInput {
  areaName: string;
  areaTypeRefId: number;
  geom: string;
}
interface areaEditInput extends Partial<IFormInput> {
  areaName?: string | undefined;
  areaTypeRefId?: number  | undefined;
  geom?: string  | undefined;
}

interface ModalArea {
  isOpen: boolean;
  title?: string;
  data?: AreaProps | null;
  type?: 'add' | 'edit' | 'viewEdit';
  onCancel: (event: MouseEvent) => void;
  onSubmit: (data: any) => void;
}

export { schema, type IFormInput, type ModalArea, type areaEditInput };
