import { GRAY_8A, LIGHT_GREY } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';
import { GRAY_86 } from 'common/theme/colors';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles({
  loginGrid: {
    display: 'Grid',
    gridTemplateRows: '150px 50px auto auto',
    gridRowGap: 10,
    height: '100vh',
    backgroundColor: LIGHT_GREY,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  boxWrapperStyle: {
    backgroundColor: GRAY_86,
    borderRadius: 5,
    marginTop: 15,
    padding: 30,
    width: '35vw',
    minWidth: 350,
  },
  description: {
    fontSize: 14,
    color: GRAY_8A,
  },
  logoStyle: {
    height: '75%',
  },
});

type PreLoginLayoutProps = {
  children: React.ReactNode;
  title: string;
  desc: string;
};

const PreLoginLayout = ({ children, title, desc }: PreLoginLayoutProps) => {
  const { loginGrid, logoStyle, description, boxWrapperStyle } = useStyles();

  return (
    <div className={loginGrid}>
      <img className={logoStyle} src={`logo192.png`} alt='logo' loading='lazy' />
      <Typography fontWeight={500} variant='h4'>
        {title}
      </Typography>
      {desc && (
        <Typography fontWeight={400} className={description}>
          {desc}
        </Typography>
      )}
      <Box component='div' className={boxWrapperStyle}>
        {children}
      </Box>
    </div>
  );
};

export default PreLoginLayout;
