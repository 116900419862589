import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputField from 'common/forms/InputField';
import PreLoginLayout from 'common/layouts/preLoginLayout';
import { useUpdateAdminPasswordMutation } from 'gql/generated/query.graphql';
interface IFormInput {
  newPassword: string;
  cnfPassword: string;
}

const schema = Yup.object().shape({
  newPassword: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters'),
  cnfPassword: Yup.string()
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

function NewPassword() {
  const history = useNavigate();
  const [updateAdminPassword] = useUpdateAdminPasswordMutation({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
    onCompleted() {
      history('/admin');
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { newPassword: newPasswordErrorMsg, cnfPassword: cnfPasswordErrorMsg } = errors || {};

  const onPasswordSubmit = (data: any) => {
    updateAdminPassword({
      variables: {
        password: btoa(data.cnfPassword),
      },
    });
  };

  return (
    <PreLoginLayout title='New Password' desc=''>
      <form onSubmit={handleSubmit(onPasswordSubmit)}>
        <InputField
          control={control}
          margin='normal'
          fullWidth
          name='newPassword'
          placeholder='New Password'
          type='password'
          helperText={newPasswordErrorMsg?.message}
          error={newPasswordErrorMsg ? true : false}
        />
        <InputField
          control={control}
          margin='normal'
          fullWidth
          name='cnfPassword'
          placeholder='Confirm New Password'
          type='password'
          helperText={cnfPasswordErrorMsg?.message}
          error={cnfPasswordErrorMsg ? true : false}
        />

        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3 }}>
          Submit
        </Button>
      </form>
    </PreLoginLayout>
  );
}

export default NewPassword;
