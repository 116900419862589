import { makeStyles } from '@mui/styles';

export default makeStyles({
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '5px',
  },
});
