import { Box, Typography, Card, IconButton, Divider, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SUBJECTS } from 'common/casl/ability';
import { Can } from 'common/casl/abilityContext';

export type CardWithEditDeleteProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  onEdit: () => void;
  onDelete?: () => void;
};

const useStyles = makeStyles({
  controlPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '22px 0 0 20px',
    width: '400px',
    '& .MuiCardHeader-root': {
      '& .MuiTypography-root': {
        fontWeight: 700,
        lineHeight: '35px',
        letterSpacing: '0.15px',
        textTransform: 'capitalize',
      },
    },
  },
});
// FOR Area and Fare only
const CardWithEditDelete = ({ title, children, onEdit, onDelete }: CardWithEditDeleteProps) => {
  const { controlPanel } = useStyles();
  return (
    <Card className={controlPanel}>
      <Box display='flex' justifyContent='space-between' alignItems='center' px={3.1} py={2.1}>
        <Box display='flex' alignItems='center'>
          {title}
        </Box>

        <Box>
          <Can I='update' a={SUBJECTS.CONFIGURATION.toString()}>
            <IconButton sx={{ p: 0 }} onClick={onEdit} aria-label='edit'>
              <img src='/images/edit.png' alt='' />
            </IconButton>
          </Can>
          <Can I='delete' a={SUBJECTS.CONFIGURATION.toString()}>
            <IconButton sx={{ ml: 1.5, p: 0 }} onClick={onDelete} aria-label='delete'>
              <img src='/images/delete.png' alt='' />
            </IconButton>
          </Can>
        </Box>
      </Box>
      <Divider />
      <CardContent component='ul' sx={{ px: 3.1, py: 2.1, m: 0, '& > li': { px: 0, pt: 0 } }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default CardWithEditDelete;
