import { Autocomplete, Box, Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import { throttle } from 'lodash';
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useMemo, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { GRAY_86 } from 'common/theme/colors';

const autocompleteService: any = { current: null };

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

type InputFieldType = Partial<TextFieldProps> & {
  control: Control<any>;
  defaultValue?: any;
};

const PlacesAutocomplete = ({
  name = '',
  defaultValue = null,
  control,
  label,
  variant = 'filled',
  size = 'small',
  ...rest
}: InputFieldType) => {
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>([]);

  const myFetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    myFetch({ input: inputValue }, (results: any) => {
      if (active) {
        let newOptions: any[] | ((prevState: any[]) => any[]) = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, myFetch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id={name}
          size={size}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            onChange({
              locationCustomName: newValue.description,
              locationName: newValue.structured_formatting.main_text,
              locationType: 'JOURNEY',
              placeId: newValue.place_id,
            });
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant='filled'
              InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length])
            );
            return (
              <li {...props}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Grid>
                  <Grid item xs>
                    {parts.map(
                      (
                        part: {
                          highlight: any;
                          text: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
                        },
                        index: Key | null | undefined
                      ) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      )
                    )}

                    <Typography variant='body2' color='text.secondary'>
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
};

export default PlacesAutocomplete;
