import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';

import { TableFields } from './TableFields';
import { useGetSuppliersLazyQuery } from 'gql/generated/query.graphql';

const SupplierListTable = ({ searchInput }: { searchInput: any }) => {
  const [suppliersData, setSuppliersData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const classes = useStyles();
  const columns = TableFields(classes);

  const [getAllSuppliers, { data: supplierData, loading: isLoading }] = useGetSuppliersLazyQuery();

  useEffect(() => {
    getAllSuppliers({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!isLoading && supplierData && supplierData.getSuppliersAdmin.results) {
      let arrData: any = [];

      supplierData?.getSuppliersAdmin.results.forEach((element: any) => {
        arrData.push({ ...element, id: element['idSupplier'] });
      });
      setSuppliersData(arrData);
      setTotalRows(supplierData?.getSuppliersAdmin.totalRows);
    }
  }, [supplierData]);

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        loading={isLoading}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        pagination
        pageSize={pageSize}
        paginationMode='server'
        rows={suppliersData}
        rowHeight={75}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '50px auto',
  },
  userImage: {
    width: 40,
  },
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

export default SupplierListTable;