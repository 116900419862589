import { DARK_GREY } from '../colors';

const muiRating = {
  MuiRating: {
    styleOverrides: {
      root: {
        color: DARK_GREY,
      },
    },
  },
};

export default muiRating;
