import { useState } from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';

import AddSupplier from 'components/supplier-management/AddSupplier';
import { searchListKeys } from './constants';
import SearchHeader from 'common/search-header';
import { SUBJECTS } from 'common/casl/ability';
import SupplierListTable from 'components/supplier-management/SupplierListTable';

const SupplierList = () => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState({});
  const classes = useStyles();

  return (
    <Box className={classes.tableGrid}>
      <SearchHeader
        title={'Supplier List'}
        buttonText={'Add Supplier'}
        showButton={true}
        showSearch={false}
        handleOpen={() => setOpen(true)}
        setSearchInput={setSearchInput}
        searchListKeys={searchListKeys}
        subject={SUBJECTS.CONFIGURATION.toString()}
      />
      <SupplierListTable searchInput={searchInput} />
      {open && <AddSupplier open={open} handleClose={() => setOpen(false)} title={'Add New Supplier'} />}
    </Box>
  );
};

const useStyles = makeStyles({
  tableGrid: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridRowGap: 20,
    width: '100%',
  },
});

export default SupplierList;
