import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, Collapse, ListItemText } from '@mui/material';
import { SILVER, BLACK } from 'common/theme/colors';
import { DrawerContext } from './drawerProvider';
import { MenuItemType } from '.';

const SingleLevel = ({ listItems }: { listItems: MenuItemType }) => {
  const { title, icon, link, type, disable } = listItems;
  const { isDrawerOpen, routerState } = useContext(DrawerContext);
  const { type: routerStateType } = routerState || {};
  const isActive = routerStateType === type;

  return (
    <Link
      to={!disable ? link ?? '#' : '#'}
      state={{ type: type }}
      className={`textdecor-none ${disable ? 'disable' : ''}`}
    >
      <ListItem style={!isDrawerOpen && isActive ? { backgroundColor: SILVER } : {}} button>
        <ListItemIcon>
          <img src={icon} alt='' />
        </ListItemIcon>
        <ListItemText sx={{ color: BLACK }} primary={title} />
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({ listItem }: { listItem: MenuItemType }) => {
  const { title, icon, items, type, disable } = listItem;
  const { isDrawerOpen, routerState } = useContext(DrawerContext);
  const { type: routerStateType } = routerState || {};
  const isActive = routerStateType === type;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    if (!disable) setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        button
        style={!isDrawerOpen && isActive ? { backgroundColor: SILVER } : {}}
        onClick={handleClick}
        className={open ? 'menuOpened' : 'noSubmenu' + (disable ? ' disable' : '')}
      >
        <ListItemIcon>
          <img src={icon} alt='' />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? (
          <img src={'/images/minus.png'} className='submenuicon' alt='' />
        ) : (
          <img src={'/images/plus.png'} className='submenuicon' alt='' />
        )}
      </ListItem>
      <Box display={isDrawerOpen ? 'block' : 'none'}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {items?.map((item: MenuItemType, key: number) => (
              <MenuItems key={`${item}_${key}`} listItems={item} />
            ))}
          </List>
        </Collapse>
      </Box>
    </React.Fragment>
  );
};

export const MenuItems = ({ listItems }: { listItems: MenuItemType }) => {
  if (listItems && listItems.items && listItems.items.length > 0) {
    return <MultiLevel listItem={listItems} />;
  }
  return <SingleLevel listItems={listItems} />;
};
