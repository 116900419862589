import React, { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import CustomerData from 'mock-data/CustomerUpcomingData';
import { GRAY_86, GRAY_8A, LIGHT_BLACK } from 'common/theme/colors';
import Typography from '@mui/material/Typography';
import { Box, grid } from '@mui/system';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Journey } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 400px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 400,
      fontSize: 18,
      color: GRAY_8A,
    },
    '& .super-app-theme--dark-cell': {
      fontWeight: 400,
      fontSize: 18,
      color: LIGHT_BLACK,
    },
  },
  pickUpIcon: {
    height: 50,
  },
  pickupGrid: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
});

function CustomerUpComingTable({
  data,
  setOffset,
  totalRows,
  pageSize,
  setPageSize,
  setPage,
}: {
  data: any;
  setOffset: any;
  totalRows: number;
  pageSize: number;
  setPageSize: any;
  setPage: any;
}) {
  const classes = useStyles();
  const [ridesData, setRidesData] = useState([] as any);

  useEffect(() => {
    let arrData: any = [];
    data.forEach((ride: Journey) => {
      arrData.push({ ...ride, id: ride.idJourney, city: 'Dubai' });
    });
    setRidesData(arrData);

    return () => {};
  }, [data]);

  const [rowCountState, setRowCountState] = useState(totalRows || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (totalRows !== undefined ? totalRows : prevRowCountState));
  }, [totalRows, setRowCountState]);

  const column = [
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      flex: 1,
      type: 'date',
      valueGetter: (params: { row: { pickupTime: any } }) => params.row.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div>{moment(new Date(params.row.pickupTime)).format('DD MMM')}</div>
      ),
    },
    {
      field: 'pickUp',
      headerName: 'Pick-up & drop-off',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupGrid}>
          <img src={`/images/pickupIcon.png`} alt='' className={classes.pickUpIcon} />
          <div className={classes.pickupDetail}>
            <Typography noWrap fontWeight={600}>
              {params.row.pickupRefIdLocation ? params.row.pickupRefIdLocation.locationName : 'No Data'}
            </Typography>
            <Typography noWrap fontWeight={600}>
              {params.row.dropoffRefIdLocation ? params.row.dropoffRefIdLocation.locationName : 'No Data'}
            </Typography>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      field: 'pickUpTime',
      headerName: 'Time',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      valueGetter: (params: { row: any }) => params.row?.actualPickupTime ?? params.row?.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div>
            {params.row.actualPickupTime
              ? moment(params.row.actualPickupTime).format('hh:mm A')
              : moment(params.row.pickupTime).format('hh:mm A')}
          </div>
          <div>
            {params.row.actualDropOffTime
              ? moment(params.row.actualDropOffTime).format('hh:mm A')
              : moment(params.row.estimatedDroppoff).format('hh:mm A')}
          </div>
        </div>
      ),
    },
    {
      field: 'mode',
      headerName: 'Mode',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row?.refIdModes?.modeName,
      renderCell: (params: GridRenderCellParams<string>) => <div>{params.row?.refIdModes?.modeName}</div>,
    },
    {
      field: 'addons',
      headerName: 'Addons',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Typography noWrap fontWeight={200} component={'span'} variant='body2'>
          {params.row.addons &&
            params.row.addons
              .filter((addon: any) => addon.quantity)
              .map((addon: any) => <div key={addon?.refIdAddonItem}>{addon?.itemDetails?.itemName}</div>)}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: 'pricing',
      headerName: 'Ride Fare',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      valueGetter: (params: any) => params.row.pricing.total,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>{`${params.row?.pricing?.currency ? params.row?.pricing?.currency : 'AED'}  ${
            params.row.pricing?.total ? params.row.pricing.total : 0
          }`}</div>
        );
      },
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      sortable: false,
    },
  ];

  if (process.env.REACT_APP_DISPLAY_DEBUG_FIELDS) {
    column.splice(0, 0, {
      field: 'statusID',
      headerName: 'Id / Status',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.idJourney,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <Typography noWrap fontWeight={200} component={'span'} variant='body2'>
            <div>{params.row.idJourney}</div>
            <div>{params.row.status}</div>
          </Typography>
        </div>
      ),
    });
  }

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={ridesData}
        columns={column}
        rowHeight={100}
        disableColumnFilter
        disableColumnMenu
        pagination
        rowCount={rowCountState}
        pageSize={pageSize}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[10, 20, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </Box>
  );
}

export default CustomerUpComingTable;
