import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/system';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, Typography, Menu, MenuItem, Paper, Dialog,  Autocomplete, TextField, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GRAY_86, GRAY_8A, LIGHT_BLACK } from 'common/theme/colors';
import { GetAdminBookingSummaryDocument, useCancelRideByAdminMutation, Journey, useGetAvailableDriversForLocationLazyQuery } from 'gql/generated/query.graphql';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModalDialog from 'common/modalDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { EDIT_BOOKING } from 'routers/config';
import { ACTIVITY_LIFECYCLE } from 'routers/config';
import AssignDriverModal from './AssignDriverModal';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 475px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 400,
      fontSize: 18,
      color: GRAY_8A,
      overflowX: 'visible !important',
    },
    '& .super-app-theme--dark-cell': {
      fontWeight: 400,
      fontSize: 18,
      color: LIGHT_BLACK,
      overflowX: 'visible !important',
    }
  },
  pickUpIcon: {
    height: 50,
  },
  pickupGrid: {
    display: 'grid',
    overflow: 'auto',
    gridTemplateColumns: '40px auto',
    maxHeight: '80px',
  },
  overflowSet: {
    overflow: 'unset',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
    color: LIGHT_BLACK,
  },
  userImage: {
    width: 40,
  },
  actionCell: {
    display: 'grid',
    alignItems: 'center',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  radioGroup: {
    display: 'flex',
    marginTop: '4px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radioGroupLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroupInput: {
    marginTop: '0',
    transform: 'scale(1.3)',
  },
  verticalLine: {
    width: 1,
    height: 14,
    backgroundColor: '#ccc',
    margin: '2px 0px 2px 2px',
  },
});

function BookingListTable(props: any) {
  const classes = useStyles();
  const [openModal, setopenModal] = React.useState(false);
  const [openDriverModal, setopenDriverModal] = React.useState(false);
  const [journeyid, setJourneyId]= useState<string>('');
  const { bookingData, setOffset, totalRows, pageSize, setPageSize, setPage } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useNavigate();
  const [selectedRide, setSelectedRide] = React.useState<any>([]);
  const [cancelRideByAdmin] = useCancelRideByAdminMutation({
    onError: (error) => {
      console.log(error.networkError?.message);
    },
    onCompleted(res) {
      if (res?.cancelRideByAdmin?.success === true) {
        setAnchorEl(null);
        setopenModal(false);
      }
    },
    refetchQueries: [GetAdminBookingSummaryDocument, 'getAdminBookingSummary'],
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleValueChange = (newValue:boolean) => {
    setopenDriverModal(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLifeCycle = () => {
    const ride = bookingData.find((journey: any) => journey.id === selectedRide[0]);
    history(ACTIVITY_LIFECYCLE, { state: { params: ride } });
  };

  const handleAssignDriver = () => {
    const ride = bookingData.find((journey: any) => journey.id === selectedRide[0]);
    setJourneyId(ride?.idJourney);
    setopenDriverModal(true);
  };

  onclose = ()=> {
    setopenDriverModal(false);
  };

  const handleCancel = () => {
    if (selectedRide) {
      cancelRideByAdmin({
        variables: {
          journeyId: parseFloat(selectedRide[0]),
        },
      });
    } else {
      setAnchorEl(null);
    }
  };

  const handleEdit = () => {
    const ride = bookingData.find((journey: any) => journey.id === selectedRide[0]);
    history(EDIT_BOOKING, { state: { params: ride } });
  };

  const highlightFareCell = (params: Record<string, any>) => {
    const estimatedGreaterCostPercantage = +params.row?.estimatedCost + (params.row?.estimatedCost / 100) * 10;
    const estimatedLesserCostPercantage = +params.row?.estimatedCost - (params.row?.estimatedCost / 100) * 10;
    return (
      params.row?.pricing.total <= estimatedLesserCostPercantage ||
      params.row?.pricing.total >= estimatedGreaterCostPercantage
    );
  };

  const column = [
    {
      field: 'journeyId',
      headerName: 'Journey Id',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.tripId,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div className={classes.overflowSet}>
            <div>{params.row?.tripId || `A0${params.row?.idJourney}`}</div>
          </div>
        </div>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      flex: 1,
      type: 'date',
      valueGetter: (params: { row: { pickupTime: any } }) => params.row?.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <div className={classes.overflowSet}>{moment(params.row?.pickupTime).format('DD MMM')}</div>
        </div>
      ),
    },
    {
      field: 'customer',
      headerName: 'Customer',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 260,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.customer?.firstName,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
          <div className={classes.overflowSet}>
            {params.row?.customer ? `${params.row?.customer?.firstName} ${params.row?.customer?.lastName}` : ''}
          </div>
        </div>
      ),
    },
    {
      field: 'driverName',
      headerName: 'Driver',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 260,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.driverName,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.overflowSet}>
          {params.row?.driverName}
        </div>
      )
    },
    {
      field: 'pickUp',
      headerName: 'Pick-up & drop-off',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 260,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupGrid}>
          <div className={classes.radioGroup}>
            <label className={classes.radioGroupLabel}>
              <input type='radio' className={classes.radioGroupInput} name='journeyStops' disabled />
            </label>
            <div className={classes.verticalLine}></div>
            <label className={classes.radioGroupLabel}>
              <input type='radio' className={classes.radioGroupInput} name='journeyStops' disabled />
            </label>
            {params.row?.stops &&
              params.row?.stops.map(() => {
                return (
                  <>
                    <div className={classes.verticalLine}></div>
                    <label className={classes.radioGroupLabel}>
                      <input type='radio' className={classes.radioGroupInput} name='journeyStops' disabled />
                    </label>
                  </>
                );
              })}
          </div>
          <div className={classes.pickupDetail}>
            <div className={classes.overflowSet}>
              {params.row?.pickupRefIdLocation ? params.row?.pickupRefIdLocation?.locationName : 'No Data'}
            </div>
            {params.row?.stops &&
              params.row?.stops.map((stop: any) => {
                return (
                  <div key={stop.locations?.idLocation} className={classes.overflowSet}>
                    {stop.locations?.locationName ? stop.locations?.locationName : 'No Data'}
                  </div>
                );
              })}
            <div className={classes.overflowSet}>
              {params.row?.dropoffRefIdLocation ? params.row?.dropoffRefIdLocation?.locationName : 'No Data'}
            </div>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      field: 'pickupTime',
      headerName: 'Time',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 160,
      valueGetter: (params: { row: any }) => params.row?.actualPickupTime ?? params.row?.pickupTime,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div className={classes.overflowSet}>
            {params.row?.actualPickupTime
              ? moment(params.row?.actualPickupTime).format('hh:mm A')
              : moment(params.row?.pickupTime).format('hh:mm A')}
          </div>
          <div className={classes.overflowSet}>
            {params.row?.actualDropOffTime
              ? moment(params.row?.actualDropOffTime).format('hh:mm A')
              : moment(params.row?.estimatedDroppoff).format('hh:mm A')}
          </div>
        </div>
      ),
    },
    {
      field: 'customerPhoneNo',
      headerName: 'Phone No',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 260,
      renderCell: (params: GridRenderCellParams<any>) => <div>{params.row?.customer?.mobileNumber}</div>,
      sortable: false,
    },
    {
      field: 'refIdModes',
      headerName: 'Mode',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: any) => params?.row?.refIdModes?.modeName,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className={classes.textCapitalize}>
          <div className={classes.overflowSet}>{params?.row?.refIdModes?.modeName ?? ''}</div>
        </div>
      ),
    },
    {
      field: 'addons',
      headerName: 'Addons',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className={classes.overflowSet}>
          {params.row?.addons &&
            params.row?.addons
              .filter((addon: any) => addon.quantity)
              .map((addon: any) => (
                <div key={addon?.refIdAddonItem}>
                  {addon?.itemDetails?.itemName} x {addon?.quantity}
                </div>
              ))}
        </div>
      ),
      sortable: false,
    },
    {
      field: 'estimatedCost',
      headerName: 'Estimated Fare',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: Record<string, any>) => params.row?.estimatedCost,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Box>{`AED  ${params.row?.estimatedCost ? params.row?.estimatedCost : 0}`}</Box>;
      },
    },
    {
      field: 'pricing',
      headerName: 'Actual Fare',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: any) => params.row?.pricing.total,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box style={{ backgroundColor: highlightFareCell(params) && params.row.status === 'completed' ? 'lightcoral' : '' }}>
            {`${params.row?.pricing?.currency ? params.row?.pricing?.currency : 'AED'}  ${params.row?.pricing?.total ? params.row?.pricing.total : 0
              }`}
          </Box>
        );
      },
    },
    {
      field: 'actual_estimated_pricing',
      headerName: 'Estimated Fare',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      valueGetter: (params: any) => params.row?.pricing.total,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div className={classes.overflowSet}>{`${
            params.row?.pricing?.currency ? params.row?.pricing?.currency : 'AED'
          }  ${params.row?.pricing?.total ? params.row?.pricing.total : 0}`}</div>
        );
      },
    },
    {
      field: 'promoCode',
      headerName: 'Promo Code',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 260,
      renderCell: (params: GridRenderCellParams<any>) => <div>{params.row?.promoCode}</div>,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <IconButton
            className={classes.actionCell}
            onClick={(params) => handleClick(params)}
          >
            <MoreVertIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Menu
            id={`${params.id}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: '100',
              },
            }}
          >
            {['LifeCycle'].map((option) => {
              return (
                <MenuItem key={option} onClick={handleLifeCycle} >
                  {option}
                </MenuItem>
              );
            })}
            {['Assign Driver'].map((option) => {
              return (
                <MenuItem key={option} onClick={handleAssignDriver} >
                  {option}
                </MenuItem>
              );
            })}
            {['Edit'].map((option) => {
              return (
                <MenuItem key={option} onClick={handleEdit} disabled={params.row?.journeyType === 'RETURN'}>
                  {option}
                </MenuItem>
              );
            })}
            {['Cancel'].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  setopenModal(true);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
    },
  ];

  if (process.env.REACT_APP_DISPLAY_DEBUG_FIELDS) {
    column.splice(0, 0, {
      field: 'statusID',
      headerName: 'Id / Status',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 160,
      flex: 1,
      valueGetter: (params: { row: any }) => params.row?.idJourney,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <div className={classes.overflowSet}>
            <div>{params.row?.idJourney}</div>
            <div>{params.row?.status}</div>
          </div>
        </div>
      ),
    });
  }

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={bookingData}
        columns={props.bookingStatus === 10 ? column.filter((element) => element.field !== 'actual_estimated_pricing') : column.filter(element => element.field !== 'pricing' && element.field !== 'estimatedCost')}
        rowHeight={100}
        disableColumnFilter
        disableColumnMenu
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
        onSelectionModelChange={(newSelectedRide) => {
          setSelectedRide(newSelectedRide);
        }}
      />
      <ModalDialog
        open={openModal}
        cancelName={'cancel'}
        submitName={'ok'}
        title={''}
        onSubmit={handleCancel}
        onClose={() => {
          setopenModal(false);
          setAnchorEl(null);
        }}
      >
        <div className=''>Are you sure want to cancel this booking?</div>
      </ModalDialog>
      <AssignDriverModal open={openDriverModal} journey={journeyid} closeValue={handleValueChange} />
    </Box>
  );
}

export default BookingListTable;
