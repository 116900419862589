const onSaveForm = async (imageData: any, selectedFile: any) => {
  return await fetch(imageData?.getAdminSignedUrl.signedUrl as string, {
    ...formBody, body: selectedFile
  });
};

const formBody = {
  method: 'PUT',
  headers: {
    'Content-Type': 'multipart/form-data',
  }
};

export { onSaveForm, formBody };

