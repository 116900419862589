import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Dialog, FormControl, IconButton, InputLabel, Select, Typography } from '@mui/material';

import {
  useGetAllPromotionsQueryResult,
  useAssignCustomerUserPromoMutation,
  useUnAssignCustomerUserPromoMutation,
  GetCustomerAssignedPromotions,
} from 'gql/generated/query.graphql';
import { useQuery } from '@apollo/client';

import { Parameters } from './types';

function AssignmentToUser({ params, handleAssignModalClose, title }: Parameters) {
  const classes = useStyles();
  const [customerPromo, setCustomerPromo] = useState<number>();
  const [promoIds, setPromoIds] = useState<number[]>([]);

  const [assignCustomerPromoMutation] = useAssignCustomerUserPromoMutation();
  const [unassignCustomerPromoMutation] = useUnAssignCustomerUserPromoMutation();
  const { data: promotions } = useGetAllPromotionsQueryResult({});

  let promoDetails = promotions?.getAllPromotions || [];
  const currentDate = new Date();

  const {
    loading,
    error,
    data: customerUserPromotionsId,
  } = useQuery(GetCustomerAssignedPromotions, {
    variables: { customerId: params?.idCustomerUser },
  });

  useEffect(() => {
    setPromoIds([]);
    if (!loading) {
      customerUserPromotionsId.getCustomerAssignedPromotion.forEach((item: any) => {
        setPromoIds((prevPromoIds) => [...prevPromoIds, item.promotionId]);
      });
    }
  }, [customerUserPromotionsId]);

  const assignSelectedPromo = (idPromotion: number) =>
    assignCustomerPromoMutation({
      variables: {
        input: {
          refIdCustomerUser: params?.idCustomerUser,
          refIdIdPromotion: idPromotion,
          isSelfAdded: true,
          promotionStatus: true,
        },
      },
      refetchQueries: [GetCustomerAssignedPromotions, 'getCustomerAssignedPromotion'],
    });

  const unassignSelectedPromo = (idPromotion: number) =>
    unassignCustomerPromoMutation({
      variables: {
        input: {
          refIdCustomerUser: params?.idCustomerUser,
          refIdIdPromotion: idPromotion,
          isSelfAdded: true,
          promotionStatus: false,
        },
      },
      refetchQueries: [GetCustomerAssignedPromotions, 'getCustomerAssignedPromotion'],
    });

  const setAddStyleClass = (promo: any) =>
    promoIds.includes(promo?.idPromotion) ? classes.actionImageDisabled : classes.actionImageEnabled;

  const setRemoveStyleClass = (promo: any) =>
    promoIds.includes(promo?.idPromotion) ? classes.actionImageEnabled : classes.actionImageDisabled;

  const renderPromoList = promoDetails.map(
    (promo: any) =>
      promo?.isActive &&
      promo?.promoType === 'UNIQUE' &&
      new Date(promo?.activeTillDate) > currentDate && (
        <div className={classes.assignButton} key={promo.idPromotion}>
          <div className={classes.transformText}>
            <span>{promo.promoCode}</span>
          </div>

          <div className={classes.buttonContainer}>
            <IconButton
              aria-label='add-remove-promo'
              size='large'
              onClick={() => assignSelectedPromo(promo?.idPromotion)}
              disabled={promoIds.includes(promo?.idPromotion)}
            >
              <img src={`/images/add.png`} alt='assign-promotion' className={setAddStyleClass(promo)} />
            </IconButton>
            <IconButton
              aria-label='add-remove-promo'
              size='large'
              onClick={() => unassignSelectedPromo(promo?.idPromotion)}
              disabled={!promoIds.includes(promo?.idPromotion)}
            >
              <img src={`/images/remove.png`} alt='unassign-promotion' className={setRemoveStyleClass(promo)} />
            </IconButton>
          </div>
        </div>
      )
  );
  return (
    <Dialog
      open={true}
      maxWidth='md'
      onClose={handleAssignModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.boxContent}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {title}
        </Typography>
        <div className={classes.actionGrid}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Assign Promotion</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='promoVal'
              value={customerPromo}
              label='promoLocationVal'
              onChange={(event) => setCustomerPromo(+event!.target!.value!)}
            >
              {renderPromoList}
            </Select>
          </FormControl>
        </div>
        <div className={classes.container}>
          <Button variant='contained' color='success' size='small' onClick={handleAssignModalClose}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AssignmentToUser;

const useStyles = makeStyles({
  actionGrid: {
    display: 'contents',
    marginTop: 20,
  },
  actionImageDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    width: 24,
  },
  actionImageEnabled: {
    width: 24,
  },
  addRemoveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  assignButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  boxContent: {
    padding: '30px 20px',
    width: 500,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    marginTop: 50,
  },
  removeButton: {
    marginLeft: '8px',
  },
  transformText: {
    alignItems: 'center',
    display: 'flex',
    margin: '0 16px',
    textTransform: 'capitalize',
  },
});
