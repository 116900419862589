export default {
  promo: {
    promoCodeError: 'Sorry, max 15 letters(a-z A-Z 0-9) are allowed.',
    promoCodeTitleError: 'Sorry, max 20 letters are allowed.',
    promoCodeDescriptionError: 'Sorry, max 200 letters are allowed.',
    promoAlreadyExists: 'Promo code already exists.',
    percentageDiscountValueError: 'Discount cannot be greater than 100 in percentage unit.',
    discountValueError: 'Discount value must be greater then 0.',
    promoUsagelimitErrorMessage: 'Promo usage limit must be greater then 0.',
    minimumBaseFareErrorMessage: 'Minimum Base fare value must be greater then 0.',
    totalUsageLimitMinimumValErrorMessage: 'Total usage limit must be greater then 0.',
    perCustomerUsageLimitMinimumValErrorMessage: 'Per customer usage limit must be greater then 0.',
    maxDisountMinimumValErrorMessage: 'Max discount must be greater then 0.',
    totalUsageLimitErrorForBoth: 'Total usage limit must be greater then per customer usage limit and max discount.',
    totalUsageLimitErrorForPerCustomerUsageLimit: 'Total usage limit must be greater then per customer usage limit.',
    totalUsageLimitErrorForMaxDiscount: 'Total usage limit must be greater then max discount.',
    perCustomerUsageLimitErrorForMaxDiscount: 'Per customer usage limit must be greater then max disocunt.',
  },
  date: {
    dateError: 'Active From Date must be smaller then Active Till Date',
  },
};
