import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Dialog, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';

import { DARK_GREY, LIGHT_GREY } from 'common/theme/colors';
import InputField from 'common/forms/InputField';
import { schema } from './constants';
import { UseVerifyOTPOnCreateSupplierDocument } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 363,
  },
  formGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
  },
  label: {
    marginBottom: -5,
  },
});

function OtpModal({
  open,
  handleClose,
  title,
  setIsVerifyOtp,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  data: any;
  setIsVerifyOtp: Dispatch<SetStateAction<boolean>>;
}): ReactElement {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const [verifyOTP] = useMutation(UseVerifyOTPOnCreateSupplierDocument);

  const onSave = (formInput: any) => {
    verifyOTP({
      variables: {
        input: {
          email: data.email,
          mobileNumber: data.phone,
          emailOtp: btoa(formInput?.emailOtp),
          mobileOtp: btoa(formInput?.phoneOTP),
        },
      },
    }).then(
      (response) =>
        response?.data?.verifyOTPOnCreateSupplier.success &&
        (() => {
          setIsVerifyOtp(true);
          handleClose();
        })()
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <Box className={classes.formGrid}>
            <InputField
              autoComplete='email'
              control={control}
              fullWidth
              id='emailOtp'
              InputProps={{ disableUnderline: true }}
              margin='normal'
              name='emailOtp'
              placeholder='Email OTP'
              required
              variant='filled'
            />
            <InputField
              autoComplete='phoneOTP'
              control={control}
              fullWidth
              id='phoneOTP'
              InputProps={{ disableUnderline: true }}
              margin='normal'
              name='phoneOTP'
              placeholder='Mobile OTP'
              required
              type='tel'
              variant='filled'
            />
            <Box className={classes.actionBtn}>
              <Button size='medium' type='submit'>
                Verify
              </Button>
              <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default OtpModal;
