import { Navigate, Outlet } from 'react-router-dom';
import LogInPage from 'pages/account-mangement/login';
import DashboardPage from 'pages/dashboard';
import BookingManagement from 'pages/booking-management';
import AccountManagement from 'pages/account-mangement';
import ForgotPasswordPage from 'pages/account-mangement/forgotPassword';
import NewPasswordPage from 'pages/account-mangement/newpassword';
import CustomerList from 'pages/customer-management';
import CompanyList from 'pages/company-management';
import SecretCodeList from 'pages/secret-code-management';
import PromoCodeList from 'pages/promo-code-management';
import { LOGIN, FORGOT_PASSWORD, CODE_VERIFICATION, NEW_PASSWORD } from './config';
import TripActivityLifeCycle from 'pages/booking-management/TripActivityLifeCycle';
import CodeVerificationPage from 'pages/account-mangement/codeVerification';
import AreaManagement from 'pages/area-management';
import FareManagement from 'pages/fare-management';
import CustomerProfile from 'pages/customer-management/CustomerProfile';
import BookingRescheduled from 'pages/booking-management/BookingReschedule';
import DriverList from 'pages/driver-management';
import VehicleList from 'pages/vehicle-management';
import SupplierList from 'pages/supplier-management';
import DriverLeave from 'pages/driver-leave-management/DriverLeave';
import DriverLeaveManagement from 'pages/driver-leave-management/DriverLeaveManagement';
import DriverProfile from 'pages/driver-management/DriverProfile';
import BookRide from 'pages/booking-management/BookRide';
import RescheduleRide from 'pages/booking-management/RescheduleRide';
import EditBooking from 'pages/booking-management/EditBooking';
import Users from 'pages/users-management';
import UserProfile from 'pages/users-management/UserProfile';
import RoleList from 'pages/role-management';
import WaitingListManagement from 'pages/waiting-list-management';
import ReferralCodeList from 'pages/referral-code-management';
/* We should pass <Outlet /> to render the children */

const routes = (isLoggedIn: boolean) => [
  {
    path: '/',
    element: !isLoggedIn ? <AccountManagement /> : <Navigate to='/admin' />,
    children: [
      { path: LOGIN, element: <LogInPage /> },
      { path: FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
      { path: CODE_VERIFICATION, element: <CodeVerificationPage /> },
      { path: NEW_PASSWORD, element: <NewPasswordPage /> },
      { path: '', element: <Navigate to={LOGIN} /> },
    ],
  },
  {
    path: '/admin',
    element: isLoggedIn ? <DashboardPage /> : <Navigate to={LOGIN} />,
    children: [
      { path: '', element: <Navigate to='customer' /> },
      { path: 'users', element: <Users /> },
      { path: 'user-profile', element: <UserProfile /> },
      // { path: 'booking', element: <>Booking</> },
      { path: 'modes', element: <>Modes</> },
      { path: 'roles', element: <RoleList /> },
      { path: 'profile', element: <>Profile</> },
      { path: 'area', element: <AreaManagement /> },
      { path: 'fare', element: <FareManagement /> },
      { path: '', element: <CustomerList /> },
      { path: 'customer', element: <CustomerList /> },
      { path: 'customer-profile', element: <CustomerProfile /> },
      { path: 'booking/:filter', element: <BookingManagement /> },
      { path: 'booking-reschedule', element: <BookingRescheduled /> },
      { path: 'driver', element: <DriverList /> },
      { path: 'vehicle', element: <VehicleList /> },
      { path: 'supplier', element: <SupplierList /> },
      { path: 'driver-leave', element: <DriverLeave /> },
      { path: 'driver-leave-management', element: <DriverLeaveManagement /> },
      { path: 'lifecycle', element: <TripActivityLifeCycle /> },
      { path: 'driver-profile', element: <DriverProfile /> },
      { path: 'book-a-ride', element: <BookRide /> },
      { path: 'reschedule-ride', element: <RescheduleRide /> },
      { path: 'booking/edit', element: <EditBooking /> },
      { path: 'company', element: <CompanyList /> },
      { path: 'secretCode', element: <SecretCodeList /> },
      { path: 'promoCode', element: <PromoCodeList /> },
      { path: 'referralCode', element: <ReferralCodeList /> },
      { path: 'waitingListManagement', element: <WaitingListManagement /> },
      {
        path: '*',
        element: <Navigate to={'customer'} />,
      },
    ],
  },
  {
    path: '*',
    element: <>Page Not Found</>,
  },
];

export default routes;
