import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { overrides } from './overrides';
import { DARK_GREY, CYAN } from './colors';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: DARK_GREY,
    },
    secondary: {
      main: CYAN,
    },
    error: {
      main: red.A400,
    },
  },
  ...overrides,
});

export default MuiTheme;
