import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import TableActionComponent from 'components/user-management/userTableAction';
import { useGetAdminUsersListLazyQuery } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      height: '8vh',
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },
  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },
  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },
  userImage: {
    width: 40,
  },
  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

function UserListTable({ searchInput }: { searchInput: any }) {
  const classes = useStyles();
  const [customerData, setCustomerData] = useState([] as any);
  const [totalRows, setTotalRows] = useState(0 as any);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [getAdminUsersList, { data, loading, error }] = useGetAdminUsersListLazyQuery({
    onError: (error) => {
      // console.log(error.networkError?.message);
    },
  });

  useEffect(() => {
    getAdminUsersList({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!loading && data && data.getAdminUsersList) {
      const arrData = data?.getAdminUsersList.results.map((element) => {
        return {
          ...element,
          id: element['idAdminUser'],
          adminRole: element.role?.roleName,
          department: element.details?.department?.departmentName,
        };
      });
      setCustomerData(arrData);
      setTotalRows(data?.getAdminUsersList.totalRows);
    }
  }, [data]);

  const column = [
    {
      field: 'id',
      headerName: '',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
        </div>
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'adminRole',
      headerName: 'Role',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },

    {
      field: 'department',
      headerName: 'Department',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },

    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'mobileNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        // disabledEditButton disabledDeleteButton
        <TableActionComponent params={params.row} />
      ),
    },
  ];

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={customerData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={(newPage) => {
          setPage(newPage);
          setOffset(newPage);
        }}
        rowsPerPageOptions={[5, 10, 20]}
        rowCount={totalRows}
      />
    </Box>
  );
}

export default UserListTable;
