import Box from '@mui/system/Box';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { FieldValues, WaitingListData } from './helpers';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import TableActionComponent from 'components/waiting-list-management/TableActionComponent';
import { useGetWaitingListAdminLazyQuery } from 'gql/generated/query.graphql';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 200px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 300,
      fontSize: 18,
    },
  },

  ratingStyle: {
    color: GRAY_8A,
    marginTop: 1,
  },

  ratingGrid: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
  },

  idGrid: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
    alignItems: 'center',
  },

  userImage: {
    width: 40,
  },

  ActionGrid: {
    display: 'grid',
    gridTemplateColumns: '30px 30px 30px',
    gridColumnGap: 2,
  },
});

const WaitingListManagementTable = ({ searchInput }: { searchInput: FieldValues }) => {
  const [waitingListData, setWaitingListData] = useState<WaitingListData[]>([]);
  const [totalRows, setTotalRows] = useState<any>(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [getWaitingListAdmin, { data, loading }] = useGetWaitingListAdminLazyQuery();
  const classes = useStyles();

  useEffect(() => {
    getWaitingListAdmin({
      variables: {
        input: {
          limit: pageSize,
          offset: page * pageSize,
          filter: searchInput,
        },
      },
    });
  }, [pageSize, offset, page, searchInput]);

  useEffect(() => {
    if (!loading && data && data.getWaitingListAdmin) {
      const arrData = data?.getWaitingListAdmin.results.map((element) => ({
        ...element,
        id: element['idWaitingList'],
        createdDate: moment(element['createdDate']).format('YYYY-MM-DD'),
      }));
      setWaitingListData(arrData);
      setTotalRows(data?.getWaitingListAdmin.totalRows);
    }
  }, [data]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setOffset(newPage);
  };

  const column = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.idGrid}>
          <img src='/images/userimg.png' className={classes.userImage} />
          <div>{params.value ? params.value.substring(0, 8).toUpperCase() : 'No Id'}</div>
        </div>
      ),
    },
    {
      field: 'emailId',
      headerName: 'E-mail',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'mobileNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'createdDate',
      headerName: 'Created at',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 200,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<string>) => <TableActionComponent params={params.row} />,
      sortable: false,
    },
  ];

  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={waitingListData}
        columns={column}
        rowHeight={75}
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        paginationMode='server'
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 20, 100]}
        rowCount={totalRows}
      />
    </Box>
  );
};

export default WaitingListManagementTable;
