import { TextField, TextFieldProps } from '@mui/material';
import { Controller, Control } from 'react-hook-form';

type InputFieldType = Partial<TextFieldProps> & {
  control: Control<any>;
  defaultValue?: unknown;
};

const InputField = ({
  name = '',
  defaultValue = '',
  control,
  label,
  variant = 'outlined',
  size = 'medium',
  ...rest
}: InputFieldType) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          className="inputRounded"
          fullWidth
          onChange={onChange}
          value={value}
          id={name}
          label={label}
          variant={variant}
          size={size}
          {...rest}
        />
      )}
    />
  );
};

export default InputField;
