import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GRAY_8A, LIGHT_BLACK, LIGHT_GREY } from 'common/theme/colors';
import { Journey } from 'gql/generated/query.graphql';
import moment from 'moment';

const useStyles = makeStyles({
  ratingStyle: {
    color: GRAY_8A,
  },
  badgeImage: {
    width: 25,
  },
  badgeImageGrid: {
    display: 'grid',
    gridTemplateColumns: '25px 25px',
    gridColumnGap: 5,
  },
  modeInfo: {
    background: LIGHT_GREY,
    color: LIGHT_BLACK,
    padding: '0px 10px',
    borderRadius: 5,
  },
  cityGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    justifyContent: 'start',
    alignItems: 'center',
    gridColumnGap: 10,
  },
});
const WINDOW_SIZE_TO_SHOW_DIVIDER = 1024;
function CustomerCard({ ride }: { ride: Journey }) {
  const classes = useStyles();
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card
      sx={{
        minWidth: 275,
        background: ' #FFFFFF',
        border: '1px solid #E7E7E7',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        boxSizing: 'border-box',
      }}
    >
      <CardContent>
        <Grid container direction='row' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {ride?.driver && (
            <Grid
              item
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='center'
              spacing={1}
              md={3}
              xs
            >
              <Grid item>
                {/* TODO: use profilePicture */}
                <Avatar alt='' src={'/images/userimg.png'} sx={{ width: 70, height: 70 }} />
              </Grid>
              <Grid item>
                <Typography fontWeight={500}>{ride.driver?.firstName}</Typography>
              </Grid>
              <Grid item>
                <Rating
                  value={ride?.snapshot?.driverRating ?? 0}
                  size='small'
                  readOnly
                  precision={0.5}
                  className={classes.ratingStyle}
                />
              </Grid>
              <Grid item>
                {/* TODO: must get from API */}
                <div className={classes.badgeImageGrid}>
                  {ride.driver?.badges && ride.driver?.badges.length > 0
                    ? ride.driver?.badges.map((badge) => (
                        <img
                          key={badge.idDriverUserBadges}
                          src={`/images/driverBadge4.svg`}
                          alt={badge.refIdBadgeUser.badgeName}
                          className={classes.badgeImage}
                        />
                      ))
                    : null}
                </div>
              </Grid>
            </Grid>
          )}

          {currentWindowSize >= WINDOW_SIZE_TO_SHOW_DIVIDER && (
            <Divider sx={{ marginTop: 5 }} orientation='vertical' variant='middle' flexItem />
          )}
          <Grid item container direction='column' rowSpacing={2} md xs={12}>
            <Grid item container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography fontWeight={300}>{moment(new Date(ride.pickupTime)).format('ddd, D MMM')}</Typography>
              </Grid>
              <Grid item>
                <div className={classes.modeInfo}>
                  <Typography fontWeight={500}>{`Mode: ${ride.refIdModes?.modeType}`}</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.cityGrid}>
                <Typography fontWeight={300}>{'From : '}</Typography>
                <Typography
                  sx={{
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }}
                  fontWeight={500}
                >
                  {ride.pickupRefIdLocation.locationName}
                </Typography>
                <img src={'/images/arrowIcon.svg'} alt='' />
                <Typography
                  sx={{
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }}
                  fontWeight={500}
                >
                  {ride.dropoffRefIdLocation.locationName}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography>{ride?.snapshot?.driverComments ? `"${ride?.snapshot?.driverComments}"` : ''}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CustomerCard;
