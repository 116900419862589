import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GRAY_86 } from 'common/theme/colors';
import { DRIVER_LEAVE_MANAGEMENT } from 'routers/config';
import { useNavigate, useLocation  } from 'react-router-dom';
import {
    GetDriverLeave
} from 'gql/generated/query.graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import DriverLeaveTable from './DriverLeaveTable';

const DRIVER_LEAVE_TITLE: string = 'Driver Leave Management';
const APPLY_LEAVE: string = 'Apply Leave';

const useStyles = makeStyles({
    tableGrid: {
        display: 'grid',
        gridTemplateRows: '50px auto',
        gridRowGap: 20,
        width: '100%',
    },
    headerGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    searchGrid: {
        display: 'grid',
        gridTemplateColumns: '350px 50px',
    },
    searchDropIcon: {
        background: GRAY_86,
        alignItems: 'center',
        justifyItems: 'center',
        height: 45,
        marginRight: 14,
    },
    searchButtonGrid: {
        display: 'grid',
        gridTemplateColumns: '300px auto',
        gridColumnGap: 10,
        alignItems: 'center',
    },
    searchWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    searchBox: {
        height: 40,
    },
});

interface LocationStateType {
    state: {
        params: any;
    };
}

function DriverLeaveManagement() {
    const {
      state: { params },
    } = useLocation() as unknown as LocationStateType;
    const classes = useStyles();
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState({});
    const handleOpen = () => {
        history(DRIVER_LEAVE_MANAGEMENT, { state: { params: params, edit: false } });
    };
    const handleClose = () => setOpen(false);

    const [addDriverLeave, { data: successData }] = useLazyQuery(GetDriverLeave);

    useEffect(() => {
        addDriverLeave({
          variables: {
            input: {
              limit: 10,
              offset: 0,
              filter: {},
            },
          },
        });
      }, []);

  const [deleteDriverLeave, { data: leaveData }] = useLazyQuery(GetDriverLeave);

  useEffect(() => {
    deleteDriverLeave({
      variables: {
        input: {
          limit: 10,
          offset: 0,
          filter: {},
        },
      },
    });
  }, []);

  return (
    <div className={classes.tableGrid}>
        <div className={classes.headerGrid}>
            <Typography fontWeight={500} variant='h5'>
                {DRIVER_LEAVE_TITLE}
            </Typography>
            <div className={classes.searchWrapper}>
                <Fab
                    variant='extended'
                    size='medium'
                    color='primary'
                    aria-label='add'
                    onClick={handleOpen}
                    sx={{ textTransform: 'none' }}
                >
                    <AddIcon />
                    {APPLY_LEAVE}
                </Fab>
            </div>
        </div>
        <DriverLeaveTable searchInput={searchInput} />
    </div>
    );
}

export default DriverLeaveManagement;
