import { Box, Button, MenuItem, Typography } from '@mui/material';
import ModalDialog from 'common/modalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputField from 'common/forms/InputField';
import * as Yup from 'yup';
import { DARK_GREY, LIGHT_GREY, WHITE } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';

import { RegEx } from 'utils/Regex';
import Strings from 'utils/constants';

const schema = Yup.object().shape({
  areaId: Yup.number().required(Strings.addFare.areaIdError),
  baseNightShift: Yup.string()
    .required(Strings.addFare.baseNightShiftError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
  baseMorningShift: Yup.string()
    .required(Strings.addFare.baseMorningShiftError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
  bookingFee: Yup.string()
    .required(Strings.addFare.bookingFeeError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
  farePerMin: Yup.string()
    .required(Strings.addFare.farePerMinError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
  farePerKm: Yup.string()
    .required(Strings.addFare.farePerKmError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
});

interface IFormInput {
  areaId: number;
  baseNightShift: string;
  baseMorningShift: string;
  bookingFee: string;
  farePerMin: string;
  farePerKm: string;
}

interface ModalFare {
  isOpen: boolean;
  onClose: any;
  onSubmit: any;
  type?: string;
  fareList: any;
  fare: any;
}

let useStyles = makeStyles({
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    marginTop: '15px',
    width: '200px',
  },
});

const AddFare: React.FC<ModalFare> = ({ isOpen, fare, fareList, onSubmit, onClose, type = 'add' }: ModalFare) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { actionBtn } = useStyles();

  const onSubmitData = (inputData: any) => {
    onSubmit({ ...inputData, areaId: fare.idArea });
  };

  const {
    areaId: areaIdError,
    baseNightShift: baseNightShiftError,
    baseMorningShift: baseMorningShiftError,
    bookingFee: bookingFeeError,
    farePerMin: farePerMinError,
    farePerKm: farePerKmError,
  } = errors || {};

  return (
    <ModalDialog
      open={isOpen}
      title={`${type == 'add' ? 'Add New' : 'Update'} Fare`}
      onClose={onClose}
      width='400px'
      cancelName={null}
      submitName={null}
    >
      <Box component={'form'} onSubmit={handleSubmit(onSubmitData)}>
        <Box display='flex' gap='10px' flexDirection='column'>
          <InputField
            control={control}
            select
            placeholder='Select Area/Location'
            name='areaId'
            type="number"
            disabled
            defaultValue={fare?.idArea}
            value={fare?.idArea}
            InputProps={{
              disableUnderline: true,
            }}
            helperText={areaIdError?.message}
            error={areaIdError ? true : false}
          >
            {fareList?.map((row: any) => (
              <MenuItem value={row['idArea']} key={row['idArea']}>
                {row['areaName']}
              </MenuItem>
            ))}
          </InputField>
          <Box>
            <Typography variant='subtitle1' gutterBottom component='div'>
              Bare fare
            </Typography>
            <Box display='flex' gap='10px'>
              <InputField
                control={control}
                placeholder='Night Shift Fare'
                name='baseNightShift'
                type="number"
                defaultValue={fare?.fare?.baseNightShift}
                InputProps={{
                  disableUnderline: true,
                }}
                helperText={baseNightShiftError?.message}
                error={baseNightShiftError ? true : false}
              />
              <InputField
                control={control}
                placeholder='Morning Shift Fare'
                name='baseMorningShift'
                type="number"
                defaultValue={fare?.fare?.baseMorningShift}
                InputProps={{
                  disableUnderline: true,
                }}
                helperText={baseMorningShiftError?.message}
                error={baseMorningShiftError ? true : false}
              />
            </Box>
          </Box>
          <InputField
            control={control}
            placeholder='Booking Fare'
            defaultValue={fare?.fare?.bookingFee}
            name='bookingFee'
            type="number"
            InputProps={{
              disableUnderline: true,
            }}
            helperText={bookingFeeError?.message}
            error={bookingFeeError ? true : false}
          />
          <InputField
            control={control}
            placeholder='Fare / Min'
            defaultValue={fare?.fare?.farePerMin}
            name='farePerMin'
            type="number"
            InputProps={{
              disableUnderline: true,
            }}
            helperText={farePerMinError?.message}
            error={farePerMinError ? true : false}
          />
          <InputField
            control={control}
            placeholder='Fare / KM'
            defaultValue={fare?.fare?.farePerKm}
            name='farePerKm'
            type="number"
            InputProps={{
              disableUnderline: true,
            }}
            helperText={farePerKmError?.message}
            error={farePerKmError ? true : false}
          />
          <div className={actionBtn}>
            <Button sx={{ color: WHITE }} type='submit'>
              {type == 'add' ? 'Save' : 'Update'}
            </Button>
            <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default AddFare;
