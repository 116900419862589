import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GRAY_86, GRAY_8A } from 'common/theme/colors';
import { Customer, RideSummaryResponse } from 'gql/generated/query.graphql';
import moment from 'moment';

const useStyles = makeStyles({
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '120px auto',
    height: 125,
    gridColumnGap: 10,
    borderBottom: '1px solid',
    borderBottomColor: GRAY_86,
  },
  userImage: {
    width: 100,
  },
  customerInfo: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '50% 50%',
  },
  ratingStyle: {
    color: GRAY_8A,
  },
  userName: {
    display: 'grid',
    gridTemplateColumns: 'auto 20px auto',
    justifyContent: 'start',
    alignItems: 'center',
    gridColumnGap: 20,
  },
  rideInfoGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'center',
    alignContent: 'space-between',
    gridColumnGap: 25,
  },
  rideDetailGrid: {
    display: 'grid',
    justifyContent: 'start',
    gridTemplateColumns: 'auto auto auto auto',
    alignItems: 'center',
    gridColumnGap: 5,
  },
  rideIcon: {
    width: 40,
    paddingRight: 10,
  },
});

function CustomerDetails({ data, rideSummary }: { data: Customer; rideSummary: RideSummaryResponse }) {
  const classes = useStyles();

  return (
    <div className={classes.detailGrid}>
      <img src={`/images/userimg.png`} alt='' className={classes.userImage} />
      <div className={classes.customerInfo}>
        <div className={classes.userName}>
          <Typography fontWeight={500} variant='h4'>
            {`${data.firstName} ${data.lastName}`}
          </Typography>
          <Typography fontWeight={500} variant='h5'>
            {`${data?.rating ? data.rating : 0}`}
          </Typography>
          <Rating
            value={data?.rating ? data.rating : 0}
            size='small'
            readOnly
            precision={0.5}
            className={classes.ratingStyle}
          />
        </div>
        <div className={classes.rideInfoGrid}>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/rideIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500} variant='h6'>
              {rideSummary.totalRides}
            </Typography>
            <Typography fontWeight={300} variant='h6'>
              {'Rides'}
            </Typography>
          </div>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/mileIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500} variant='h6'>
              {`${rideSummary?.totalMiles ? (rideSummary.totalMiles * 0.62137).toFixed(1) : 0}+`}
            </Typography>
            <Typography fontWeight={300} variant='h6'>
              {'Miles'}
            </Typography>
          </div>
          <div className={classes.rideDetailGrid}>
            <img src={`/images/clockIcon.png`} alt='' className={classes.rideIcon} />
            <Typography fontWeight={500} variant='h6'>
              {`${rideSummary?.totalHours ? (rideSummary.totalHours / 60).toFixed(1) : 0}+`}
            </Typography>
            <Typography fontWeight={300} variant='h6'>
              {'Hours'}
            </Typography>
          </div>
          <Typography fontWeight={300} variant='h6' color={GRAY_8A}>
            {`Joined since  ${moment(new Date(data.createdDate)).format('D MMM, YYYY')}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
