import { ListItem, Typography } from '@mui/material';
import { useState } from 'react';

import SalikFare from '../salik-fare';
import FareGridLayout from '../fareGridLayout';
import { FareTabs } from 'pages/fare-management';
import DeleteModal from 'common/deleteModal';

interface ControlProps {
  fare: any;
  updateSalikFare: (data: UpdateSalikFareInput) => void;
  deleteSalikFare: (salikId: number) => void;
}

export interface UpdateSalikFareInput {
  idSalik: number;
  salikFee: string;
}

const SalikControl: React.FC<ControlProps> = ({ fare, updateSalikFare, deleteSalikFare }: ControlProps) => {
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);

  const updateData = (data: UpdateSalikFareInput) => {
    setOpenEdit(false);
    updateSalikFare({
      idSalik: fare.salik.idSalik,
      salikFee: data.salikFee,
    });
  };

  const deleteSelectedSalik = (fare: any) => {
    deleteSalikFare(fare.salik.idSalik);
    setOpenDelete(false);
  };
  return (
    <FareGridLayout
      type={FareTabs.SALIK}
      minFare={fare?.salik?.salikFee}
      onEdit={() => setOpenEdit(true)}
      onDelete={() => setOpenDelete(true)}
    >
      {isOpenEdit && (
        <SalikFare type='edit' isOpen={isOpenEdit} fare={fare} onSubmit={updateData} onClose={() => setOpenEdit(!1)} />
      )}
      {isOpenDelete && (
        <DeleteModal
          open={isOpenDelete}
          onCancel={() => setOpenDelete(false)}
          onSubmit={() => deleteSelectedSalik(fare)}
        />
      )}
      <ListItem sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h6' gutterBottom component='div'>
          {fare?.areaName}
        </Typography>
        <Typography display='flex' alignItems='center' gap='5px' variant='subtitle1' gutterBottom component='div'>
          Area Type:
          <Typography mb='0' variant='subtitle2' gutterBottom component='div'>
            {fare?.refIdAreaType?.areaTypeName}
          </Typography>
        </Typography>
      </ListItem>
    </FareGridLayout>
  );
};

export default SalikControl;
