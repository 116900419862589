import { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Dialog, Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';

import { DARK_GREY, GRAY_86, LIGHT_GREY } from 'common/theme/colors';
import InputField from 'common/forms/InputField';
import { schema, supplierPartnerTypesData, formData } from './constants';
import OtpModal from './OtpModal';
import { onSaveForm } from 'utils/externalApiCalls';
import SupplierTypeSelector from './SupplierTypeSelector';
import {
  GetAllEmiratesDocument,
  GetSuppliersDocument,
  OwnerTypes,
  useCreateSupplierAdminMutation,
  useGetSaveAdminSignedUrlLazyQuery,
  UseSendSupplierOTPDocument,
  useUpdateSupplierMutation,
} from 'gql/generated/query.graphql';
import { ValidImage } from 'components/driver-management/constants';

const useStyles = makeStyles({
  boxContent: {
    padding: '30px 20px',
    width: 700,
  },
  dividerText: {
    fontWeight: '500',
    gridColumn: 'span 2',
  },
  formGrid: {
    display: 'Grid',
    gridTemplateColumns: '320px 320px',
    gap: 10,
    alignItems: 'center',
  },
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    gridColumn: 'span 2',
    justifyItems: 'center',
    justifyContent: 'start',
  },
  dateFields: {
    margin: 0,
    borderRadius: '100',
  },
  btnUpload: {
    margin: '18px 0px',
    width: '100%',
    height: '58%',
    justifyContent: 'flex-start',
    backgroundColor: LIGHT_GREY,
    color: DARK_GREY,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '18px',
  },
  imageShow: {
    width: 60,
    height: 60,
    cursor: 'pointer'
  },
  label: {
    marginBottom: -5,
  },
});

const AutoCompleteStyle = {
  border: '0.5px solid',
  borderColor: GRAY_86,
  borderRadius: 5,
};

const AddSupplier = ({
  open,
  handleClose,
  title,
  params,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  params?: any;
}): ReactElement => {
  const [openOtp, setOpenOtp] = useState(false);
  const [emirates, setEmirates] = useState([] as any);
  const [emailEdits, setEmailEdits] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [licenceEdit, setLicenceEdit] = useState('');
  const [emIdEdit, setEmIdEdit] = useState('');
  const [supplierData, setSupplierData] = useState({} as any);
  const [isVerifyOtp, setIsVerifyOtp] = useState<boolean>(false);
  const [tradeLicense, setTradeLicense] = useState<string | null>(null);
  const [emiratesIdBack, setEmiratesIdBack] = useState<string | null>(null);
  const [supplierPartnerTypes, setsupplierPartnerTypes] = useState([] as any);
  const [cancelledCheque, setCancelledCheque] = useState<string | null>(null);
  const [emiratesIdFront, setEmiratesIdFront] = useState<string | null>(null);
  const [contractDocument, setContractDocument] = useState<string | null>(null);
  const [fileError, setFileError] = useState<any>({
    tradeLicenseimg: '',
    contractDocument: '',
    cancelledCheque: '',
    emiratesIdFront: '',
    emiratesIdBack: '',
  });

  const classes = useStyles();

  const [createSupplier] = useCreateSupplierAdminMutation();
  const [updateSupplier] = useUpdateSupplierMutation();
  const { loading, error, data: emiratesData } = useQuery(GetAllEmiratesDocument);
  const [sendOTP] = useMutation(UseSendSupplierOTPDocument);

  useEffect(() => {
    setsupplierPartnerTypes(supplierPartnerTypesData);
    setEmirates(emiratesData?.getAllEmirates);
  }, [emiratesData?.getAllEmirates]);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(()=>{},[emailEdits,phoneEdit,licenceEdit,emIdEdit]);
  useEffect(() => {
    if (params) {
      isVerifyOtp &&
        updateSupplier({
          variables: {
            supplierId: params?.idSupplier,
            input: {
              name: supplierData?.supplierName || '',
              email: supplierData.email,
              licenceNo: supplierData.tradeLicense,
              mobileNumber: supplierData.phone,
              startDate: new Date(supplierData.startDate).toISOString(),
              type: supplierData?.supplierPartnerType,
              refIdTaxiSupplierColor: parseInt(
                supplierData?.refIdTaxiSupplierColor?.idColor || supplierData?.refIdTaxiSupplierColor
              ),
              bankDetails: [
                {
                  IBAN: supplierData?.ibanNumber,
                  accName: supplierData?.accountName,
                  bankAccNo: supplierData?.accountNumber,
                  cancelledChequeUrl: '',
                  ownerType: OwnerTypes.Supplier,
                  bankName: supplierData?.bankName,
                },
              ],
              address: [
                {
                  area: supplierData?.areaName,
                  addressNo: supplierData?.buildingNumber,
                  city: supplierData?.emirate,
                  ownerType: OwnerTypes.Supplier,
                  street: supplierData?.streetName,
                  country: 'UAE',
                },
              ],
              identityDocuments: [
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'LICENCE',
                  idDocument: '',
                  frontImageUrl: tradeLicense || params?.identityDocuments[0]?.frontImageUrl,
                  expiryDate: null,
                },
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'ZED_CONTRACT',
                  idDocument: '',
                  frontImageUrl: contractDocument || params?.identityDocuments[1]?.frontImageUrl,
                  expiryDate: null,
                },
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'RTA_PERMIT',
                  idDocument: '',
                  frontImageUrl: cancelledCheque || params?.identityDocuments[2]?.frontImageUrl,
                  expiryDate: null,
                },
                {
                  backImageUrl: emiratesIdBack || params?.identityDocuments[3]?.backImageUrl,
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'EMIRATES_ID',
                  idDocument: supplierData?.emirateId,
                  frontImageUrl: emiratesIdFront || params?.identityDocuments[3]?.frontImageUrl,
                  expiryDate: null,
                },
              ],
            },
          },
          refetchQueries: [GetSuppliersDocument, 'getSuppliers'],
        }).then((response) => {
          response?.data ? handleClose() : setIsVerifyOtp(false);
        });
      getAdminSignedUrl();
    } else {
      isVerifyOtp &&
        createSupplier({
          variables: {
            input: {
              name: supplierData?.supplierName || '',
              email: supplierData.email,
              licenceNo: supplierData.tradeLicense,
              mobileNumber: supplierData.phone,
              startDate: new Date(supplierData.startDate || moment().add(1, 'days').format('YYYY-MM-DD')).toISOString(),
              type: supplierData?.supplierPartnerType,
              refIdTaxiSupplierColor: parseInt(supplierData?.refIdTaxiSupplierColor),
              bankDetails: [
                {
                  IBAN: supplierData?.ibanNumber,
                  accName: supplierData?.accountName,
                  bankAccNo: supplierData?.accountNumber,
                  cancelledChequeUrl: '',
                  ownerType: OwnerTypes.Supplier,
                  bankName: supplierData?.bankName,
                },
              ],
              address: [
                {
                  area: supplierData?.areaName,
                  addressNo: supplierData?.buildingNumber,
                  city: supplierData?.emirate,
                  ownerType: OwnerTypes.Supplier,
                  street: supplierData?.streetName,
                  country: 'UAE',
                },
              ],
              identityDocuments: [
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'LICENCE',
                  idDocument: '',
                  frontImageUrl: tradeLicense,
                  expiryDate: null,
                },
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'ZED_CONTRACT',
                  idDocument: '',
                  frontImageUrl: contractDocument,
                  expiryDate: null,
                },
                {
                  backImageUrl: '',
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'RTA_PERMIT',
                  idDocument: '',
                  frontImageUrl: cancelledCheque,
                  expiryDate: null,
                },
                {
                  backImageUrl: emiratesIdBack,
                  documentOwner: OwnerTypes.Supplier,
                  documentType: 'EMIRATES_ID',
                  idDocument: supplierData.emirateId,
                  frontImageUrl: emiratesIdFront,
                  expiryDate: null,
                },
              ],
            },
          },
          refetchQueries: [GetSuppliersDocument, 'getSuppliers'],
        }).then((response) => {
          response?.data ? handleClose() : setIsVerifyOtp(false);
        });
      getAdminSignedUrl();
    }
  }, [isVerifyOtp]);

  const onSave = (data: any) => {
    // handleUpdateState(params , data)
    const filesErrorMsgs = [];
    Object.keys(fileError).forEach((item) => {
      if (fileError[item] !== '') filesErrorMsgs.push(true);
    });
    filesErrorMsgs?.length === 0 &&
      sendOTP({
        variables: {
          input: {
            mobileNumber: data.phone,
            emailId: data.email,
            licenceNo: data.tradeLicense,
            emirateId: data.emirateId,
            editSupplierParams: params
              ? {
                 mobileNumber: params?.mobileNumber !== data?.phone ? data?.phone : '',
                 emailId: params?.email !== data?.email ? data?.email : '',
                 licenceNo: params?.licenceNo !== data?.tradeLicense ? data?.tradeLicense : '',
                 emirateId: params?.identityDocuments[3]?.idDocument !== data?.emirateId  ?  data?.emirateId : ''
                }
              : null,
          },
        },
      }).then((response) => {
        response.data?.sendSupplierOTP.sid && setOpenOtp(true);
      });
    setSupplierData(data);
    setIsVerifyOtp(false);
  };

  const [getAdminSignedUrl, { data: imageData }] = useGetSaveAdminSignedUrlLazyQuery();

  const handleFileInput = async (fileData: any) => {
    await getAdminSignedUrl();
    if (fileData?.target?.type === 'file') {
      if (fileData?.target?.id === 'tradeLicenseimg')
        ValidImage(fileData) &&
          onSaveForm(imageData, fileData?.target?.files[0]).then((response) => setTradeLicense(response.url));
      else if (fileData?.target?.id === 'contractDocument')
        ValidImage(fileData) &&
          onSaveForm(imageData, fileData?.target?.files[0]).then((response) => setContractDocument(response.url));
      else if (fileData?.target?.id === 'cancelledCheque')
        ValidImage(fileData) &&
          onSaveForm(imageData, fileData?.target?.files[0]).then((response) => setCancelledCheque(response.url));
      else if (fileData?.target?.id === 'emiratesIdFront')
        ValidImage(fileData) &&
          onSaveForm(imageData, fileData?.target?.files[0]).then((response) => setEmiratesIdFront(response.url));
      else if (fileData?.target?.id === 'emiratesIdBack')
        ValidImage(fileData) &&
          onSaveForm(imageData, fileData?.target?.files[0]).then((response) => setEmiratesIdBack(response.url));
    }
  };

  const renderInputFields = (fields: Record<string, any>, error: Record<string, any>) => {
    return fields.map((field: any, index: number) => {
      const { onChange, onBlur, name, ref } = register(field.name);
      if (field.type === 'file') {
        return (
          <InputField
            key={index}
            control={control}
            type={field.type || 'text'}
            id={field.id}
            name={name}
            label={field?.label}
            margin='normal'
            fullWidth
            variant='filled'
            InputProps={field?.InputProps}
            defaultValue={field?.defaultValue}
            InputLabelProps={field?.InputLabelProps}
            className={field?.className ? classes.dateFields : ''}
            required={!params ? field?.required : false}
            disabled={field?.disabled}
            value={field?.value}
            placeholder={field?.placeholder}
            autoComplete={field?.autoComplete}
            helperText={fileError[field.id]}
            error={!fileError[field.id] ? false : true}
            onChange={(file) => {
              handleFileInput(file);
              ValidImage(file)
                ? setFileError({ ...fileError, [field.id]: '' })
                : setFileError({ ...fileError, [field?.id]: 'Size is larger or something is wrong with file ' });
            }}
          />
        );
      } else {
        return (
          <InputField
            key={index}
            control={control}
            type={field.type || 'text'}
            id={field.id}
            name={name}
            label={field?.label}
            margin='normal'
            fullWidth
            variant='filled'
            InputProps={field?.InputProps}
            defaultValue={field?.defaultValue}
            InputLabelProps={field?.InputLabelProps}
            className={field?.className ? classes.dateFields : ''}
            required={field?.required}
            disabled={field?.disabled}
            placeholder={field?.placeholder}
            autoComplete={field?.autoComplete}
            helperText={error[field.id]?.message}
            error={error[field.id] ? true : false}
          />
        );
      }
    });
  };

  useEffect(() => {
    if (params) {
      setValue('email', params?.email);
      setValue('phone', params?.mobileNumber);
      setValue('startDate', moment(params?.startDate).format('YYYY-MM-DD'));
      setValue('supplierPartnerType', params?.type);
      setValue('refIdTaxiSupplierColor', params?.taxiSupplierColor);
      setValue('ibanNumber', params?.bankDetails[0]?.IBAN);
      setValue('accountName', params?.bankDetails[0]?.accName);
      setValue('accountNumber', params?.bankDetails[0]?.bankAccNo);
      setValue('bankName', params?.bankDetails[0]?.bankName);
      setValue('emirate', params?.address[0].city);
      setValue('areaName', params?.address[0]?.area);
      setValue('buildingNumber', params?.address[0]?.addressNo);
      setValue('streetName', params?.address[0]?.street);
      setValue('emirateId', params?.identityDocuments[3]?.idDocument);
      setValue('supplierName', params?.name);
      setValue('tradeLicense', params?.licenceNo);
    }
  }, [params, setValue]);

  if (loading) return <p>Loading...</p>;
  if (error && emirates?.length) return <p>Error: {error.message}</p>;

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className={classes.boxContent}>
        <form onSubmit={handleSubmit(onSave)}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <Box className={classes.formGrid}>
            <SupplierTypeSelector control={control} error={errors} params={params} />
            {renderInputFields(formData.contactDetails, errors)}
            <Box>
              <Typography className={classes.label} variant='subtitle1'>
                Upload Registered Trade License
              </Typography>
              {renderInputFields([formData.documents.tradeLicenseFile], errors)}
              {params && (
                <img
                  onClick={() => window.open(tradeLicense || params?.identityDocuments[0]?.frontImageUrl)}
                  src={tradeLicense || params?.identityDocuments[0]?.frontImageUrl}
                  className={classes.imageShow}
                />
              )}
            </Box>
            <Box>
              <Typography className={classes.label} variant='subtitle1'>
                Supplier Contract Document with ZED
              </Typography>
              {renderInputFields([formData.documents.contractFile], errors)}
              {params && (
                <img
                  onClick={() => window.open(contractDocument || params?.identityDocuments[1]?.frontImageUrl)}
                  src={contractDocument || params?.identityDocuments[1]?.frontImageUrl}
                  className={classes.imageShow}
                />
              )}
            </Box>
            <Typography className={classes.dividerText} variant='h6' component='h2'>
              Supplier Bank Details
            </Typography>
            {renderInputFields(formData.bankDetails, errors)}
            <Box>
              <Typography className={classes.label} variant='subtitle1'>
                Upload Cancelled Cheque
              </Typography>
              {renderInputFields([formData.documents.chequeFile], errors)}
              {params && (
                <img
                  onClick={() => window.open(cancelledCheque || params?.identityDocuments[2]?.frontImageUrl)}
                  src={cancelledCheque || params?.identityDocuments[2]?.frontImageUrl}
                  className={classes.imageShow}
                />
              )}
            </Box>
            <Typography className={classes.dividerText} variant='h6' component='h2'>
              Supplier Registered Address
            </Typography>
            {renderInputFields(formData.addressDetails, errors)}
            <Controller
              control={control}
              name='emirate'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  id='emirate'
                  size='small'
                  fullWidth
                  popupIcon={<KeyboardArrowDownIcon />}
                  options={emirates}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(_event, item) => {
                    onChange(item?.name);
                  }}
                  defaultValue={params ? { name: value } : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      InputProps={{ ...params.InputProps, disableUnderline: true, style: AutoCompleteStyle }}
                      label='Emirate'
                      helperText={errors.emirate?.message}
                      error={errors.emirate ? true : false}
                    />
                  )}
                />
              )}
            />
            <Typography className={classes.dividerText} variant='h6' component='h2'>
              Emirates ID Details
            </Typography>
            <Box>
              <Typography className={classes.label} variant='subtitle1'>
                Front Image
              </Typography>
              {renderInputFields([formData.documents.emiratesIdFileFront], errors)}
              {params && (
                <img
                  onClick={() => window.open(emiratesIdFront || params?.identityDocuments[3]?.frontImageUrl)}
                  src={emiratesIdFront || params?.identityDocuments[3]?.frontImageUrl}
                  className={classes.imageShow}
                />
              )}
            </Box>
            <Box>
              <Typography className={classes.label} variant='subtitle1'>
                Back Image
              </Typography>
              {renderInputFields([formData.documents.emiratesIdFileBack], errors)}
              {params && (
                <img
                  onClick={() => window.open(emiratesIdBack || params?.identityDocuments[3]?.backImageUrl)}
                  src={emiratesIdBack || params?.identityDocuments[3]?.backImageUrl}
                  className={classes.imageShow}
                />
              )}
            </Box>
            {renderInputFields(formData.emiratesDetails, errors)}
            <Box className={classes.actionBtn}>
              <Button size='medium' type='submit'>
                Save
              </Button>
              <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      {openOtp && (
        <OtpModal
          setIsVerifyOtp={setIsVerifyOtp}
          data={supplierData}
          open={openOtp}
          handleClose={() => setOpenOtp(false)}
          title='Verify the OTPs'
        />
      )}
    </Dialog>
  );
};

export default AddSupplier;
