import { Box, Typography, Button } from '@mui/material';
import ModalDialog from 'common/modalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputField from 'common/forms/InputField';
import * as Yup from 'yup';
import { DARK_GREY, LIGHT_GREY, WHITE } from 'common/theme/colors';
import { makeStyles } from '@mui/styles';

import { RegEx } from 'utils/Regex';
import Strings from 'utils/constants';

const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

const schema = Yup.object().shape({
  surgeFare: Yup.string()
    .required(Strings.addFare.surgeFareError)
    .matches(RegEx.POSITIVE_NUMBERS, Strings.addFare.numberFieldError),
  fromTime: Yup.mixed()
    .required('From Time is required')
    .test('Time Format is required', 'Time Format is Mismated', (value) => {
      return value.match(timeReg);
    }),
  toTime: Yup.mixed()
    .required('To Time is required')
    .test('Time Format is required', 'Time Format is Mismated', (value) => {
      return value.match(timeReg);
    }),
});

interface IFormInput {
  surgeFare: string;
  fromTime: string;
  toTime: string;
}

let useStyles = makeStyles({
  actionBtn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 20,
    marginTop: '15px',
  },
});

interface ModalSurgeFare {
  isOpen: boolean;
  onClose: any;
  onSubmit: any;
  type?: string;
  fare: any;
}

const SurgeFare: React.FC<ModalSurgeFare> = ({ isOpen, fare, onSubmit, onClose, type = 'add' }: ModalSurgeFare) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { actionBtn } = useStyles();

  const onSubmitData = (inputData: any) => {
    let data = {
      surgeEndTime: inputData.toTime,
      surgeFare: inputData.surgeFare,
      surgeStartTime: inputData.fromTime,
    };
    onSubmit(data);
  };

  const { surgeFare: surgeFareError, fromTime: fromTimeError, toTime: toTimeError } = errors || {};

  return (
    <ModalDialog
      open={isOpen}
      title={`${type == 'add' ? 'Add' : 'Update'} Surge Fare`}
      onClose={onClose}
      cancelName={null}
      submitName={null}
    >
      <Box component={'form'} onSubmit={handleSubmit(onSubmitData)}>
        <Box>
          <Box display='flex' gap='10px'>
            <Typography variant='button' gutterBottom>
              {fare?.areaName}
            </Typography>
            <Typography variant='caption' gutterBottom>
              {fare?.refIdAreaType.areaTypeName}
            </Typography>
          </Box>
          <InputField
            control={control}
            placeholder='Surge Fare'
            name='surgeFare'
            defaultValue={fare?.surge?.surgeFare}
            InputProps={{
              disableUnderline: true,
            }}
            type="number"
            helperText={surgeFareError?.message}
            error={surgeFareError ? true : false}
            sx={{ mb: 2 }}
          />
          <Typography variant='button' gutterBottom>
            Select Time
          </Typography>
          <Box display='flex' gap='10px'>
            <InputField
              control={control}
              placeholder='00:00'
              name='fromTime'
              defaultValue={fare?.surge?.surgeStartTime}
              InputProps={{
                disableUnderline: true,
              }}
              helperText={fromTimeError?.message}
              error={fromTimeError ? true : false}
            />
            <InputField
              control={control}
              placeholder='00:00'
              name='toTime'
              defaultValue={fare?.surge?.surgeEndTime}
              InputProps={{
                disableUnderline: true,
              }}
              helperText={toTimeError?.message}
              error={toTimeError ? true : false}
            />
          </Box>
          <div className={actionBtn}>
            <Button sx={{ color: WHITE }} type='submit'>
              {type == 'add' ? 'Save' : 'Update'}
            </Button>
            <Button sx={{ backgroundColor: LIGHT_GREY, color: DARK_GREY }} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default SurgeFare;
