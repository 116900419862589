import React, { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { GRAY_86, GRAY_8A, LIGHT_BLACK } from 'common/theme/colors';
import { JOURNEY_STATUS } from './JourneyConstant';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles({
  tableHeader: {
    height: 'calc(100vh - 400px)',
    '& .super-app-theme--header': {
      backgroundColor: GRAY_86,
      fontWeight: 500,
      fontSize: 18,
    },
    '& .super-app-theme--cell': {
      fontWeight: 400,
      fontSize: 18,
      color: GRAY_8A,
    },
    '& .super-app-theme--dark-cell': {
      fontWeight: 400,
      fontSize: 18,
      color: LIGHT_BLACK,
    },
  },
  pickUpIcon: {
    height: 50,
  },
  pickupGrid: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
  },
  pickupDetail: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: 5,
  },
});

function ActivityLifeCycleTable({
  data,
  setOffset,
  totalRows,
}: {
  data: any;
  setOffset: any;
  totalRows: number;
  pageSize: number;
  setPageSize: any;
  setPage: any;
}) {
  const classes = useStyles();
  const [ridesData, setRidesData] = useState([] as any);

  useEffect(() => {
    setRidesData(data);
  }, [data]);

  const [rowCountState, setRowCountState] = useState(totalRows || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (totalRows !== undefined ? totalRows : prevRowCountState));
  }, [totalRows, setRowCountState]);

  const column = [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      flex: 1,
      type: 'date',
      valueGetter: (params: { row: { createdDate: any } }) => params.row?.createdDate,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div>{moment.unix(params.row?.createdDate/1000).format('dddd, MMMM Do, YYYY h:mm:ss A') }</div>
      ),
    },
    {
      field: 'event',
      headerName: 'Event',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 200,
      valueGetter: (params: any) =>params.row?.rideStatusID,
      renderCell: (params: GridRenderCellParams<string>) => <div>{ JOURNEY_STATUS[(params.row?.rideStatusID)]}</div>,
    },
  ];

  if (process.env.REACT_APP_DISPLAY_DEBUG_FIELDS) {
    column.splice(0, 0, {
      field: 'statusID',
      headerName: 'Id / Status',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--dark-cell',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.rideStatusID,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className={classes.pickupDetail}>
          <Typography noWrap fontWeight={200} component={'span'} variant='body2'>
            <div>{params.row?.rideStatusID}</div>
            <div>{params.row.status}</div>
          </Typography>
        </div>
      ),
    });
  }
  return (
    <Box className={classes.tableHeader}>
      <DataGrid
        rows={ridesData}
        columns={column}
        getRowId={(row: any) => (Math.random() + 1)}
        rowHeight={100}
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  );
}

export default ActivityLifeCycleTable;
